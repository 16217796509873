import React, { useEffect, useState } from 'react';
import './AddWarehouseDetails.scss';
import { connect } from 'react-redux';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';

import { axiosInstance } from '../../../global';
import { fill_warehouse } from '../../../redux/warehouse/warehouseActions';

function AddWarehouse2({ token, fill_warehouse, setCheck }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const [governrate, setGovernrate] = useState(null);
  const [area, setArea] = useState(null);
  const [location, setLocation] = useState(null);
  const [areaID, setAreaID] = useState(null);
  const [governratID, setGovernratID] = useState(null);
  const [governrateDd, setGovernrateDd] = useState(null);
  const [areaDd, setAreayDd] = useState(null);
  const [geoLocation, setgeoLocation] = useState({
    locationId: '',
    address: '',
    remarks: ''
  });

  useEffect(() => {
    axiosInstance
      .get(`/Governorate/all`)
      .then((response) => {
        const tempStatus = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setGovernrate(tempStatus);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    axiosInstance
      .get(`/Area/all`)
      .then((response) => {
        const tempArea = response.data.data.map((area) => {
          return {
            id: area.id,
            name: area.name,
            governratsID: area.governorate.id
          };
        });
        console.log();
        const filterdTempArea = tempArea.filter((item) => item.governratsID === governratID);
        setArea(filterdTempArea);
      })
      .catch((error) => {});
  }, [governrate, governratID]);

  useEffect(() => {
    axiosInstance
      .get(`/Location/all`)
      .then((response) => {
        const tempLocation = response.data.data.map((location) => {
          return {
            id: location.id,
            name: location.name,
            areaId: location.area.id
          };
        });
        const filterdTempLocation = tempLocation.filter((item) => item.areaId === areaID);
        setLocation(filterdTempLocation);
      })
      .catch((error) => {});
  }, [area, areaID]);

  useEffect(() => {
    fill_warehouse(geoLocation);
    if (Object.values(geoLocation).filter((val, index) => val === '' || !val).length) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [geoLocation]);

  function handleDdInputChange(e) {
    setGovernrateDd(e.target.value);
    setGovernratID(e.target.value);
  }
  function handleDd1InputChange(e) {
    setAreayDd(e.target.value);
    setAreaID(e.target.value);
  }
  function handleAddInputChange(e) {
    setgeoLocation({ ...geoLocation, [e.target.name]: e.target.value });
  }

  return (
    <div className='warehouse'>
      <div className='warehouse__geo'>
        <span className='Title'>بيانات العنوان</span>
        <div className='flex flex-column gap-5'>
          <div className='flex flex-row gap-5'>
            <div className='w-full flex gap-5'>
              <div className='flex w-full flex-column gap-4'>
                <label className='addwarehouse__label'>
                  المحافظة<span style={Style}>*</span>
                </label>
                <Dropdown
                  name='governerate'
                  value={governrateDd}
                  onChange={handleDdInputChange}
                  options={governrate}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر المحافظة'
                />
              </div>
            </div>

            <div className='w-full flex gap-5'>
              <div className='flex w-full flex-column gap-4'>
                <label className='addwarehouse__label'>
                  المدينة<span style={Style}>*</span>
                </label>
                <Dropdown
                  name='area'
                  value={areaDd}
                  onChange={handleDd1InputChange}
                  options={area}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر المدينة'
                  disabled={!governrateDd}
                />
              </div>
            </div>

            <div className='w-full flex gap-5'>
              <div className='flex w-full flex-column gap-4'>
                <label className='addwarehouse__label'>
                  المنطقة<span style={Style}>*</span>
                </label>
                <Dropdown
                  name='locationId'
                  value={geoLocation.locationId}
                  onChange={handleAddInputChange}
                  options={location}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر المنطقة'
                  disabled={!areaDd}
                />
              </div>
            </div>
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addwarehouse__label'>
              العنوان<span style={Style}>*</span>
            </label>
            <InputTextarea
              placeholder='العنوان مفصل'
              name='address'
              value={geoLocation.address}
              onChange={handleAddInputChange}
              rows={3}
            />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addwarehouse__label'>
              ملاحظات اخرى<span style={Style}>*</span>
            </label>
            <InputTextarea
              placeholder='ملاحظات عن المخزن بشكل عام'
              name='remarks'
              value={geoLocation.remarks}
              onChange={handleAddInputChange}
              rows={3}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_warehouse: (warehouse) => dispatch(fill_warehouse(warehouse))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWarehouse2);
