import React, { useEffect, useRef, useState } from 'react';
import { Steps } from 'primereact/steps';

import Sidebar from '../../../components/Sidebar/Sidebar';

import './EditTransaction.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { editTransaction, fetchTransaction } from '../../../redux/warehouse/warehouseActions';

import EditTransaction2 from './EditTransaction2';
import EditTransaction1 from './EditTransaction1';

const EditTransaction = ({ activeIndexnum, fetchTransaction, editTransaction, transaction, token, loading, error }) => {
  const toast = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOk, setIsFormOk] = useState(false);

  useEffect(() => {
    fetchTransaction(params.id, token);
    if (activeIndexnum === '' || activeIndexnum === undefined) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndexnum);
    }
  }, []);

  const items = [{ label: 'بيانات الاذن للعميل' }, { label: 'عناصر الطلب' }];

  function handleOnClick() {
    setActiveIndex(activeIndex + 1);
    if (activeIndex >= 1) {
      setActiveIndex(0);
    }
  }

  function handleِEditOnClick() {
    try {
      editTransaction(transaction, token);
      toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم تعديل الاذن بنجاح' });
      setTimeout(() => navigate('/warehouses/transactions/'), 1500);
    } catch (errorEditTransaction) {
      console.log(error);
      console.log(errorEditTransaction);
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية تعديل الاذن' });
    }
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <div className='flex flex-column gap-5 edit__transaction__main'>
        <div className='flex steps card'>
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} />
        </div>
        <div>
          <div>
            {activeIndex === 0 && <EditTransaction1 setCheck={setIsFormOk} transactionBase={transaction} />}
            {activeIndex === 1 && <EditTransaction2 setCheck={setIsFormOk} />}
          </div>
          <div className='btn mb-5'>
            {activeIndex != 1 && (
              <Button className='btn__nextbtn' onClick={handleOnClick} disabled={!isFormOk}>
                الخطوة التالية
              </Button>
            )}
            {activeIndex === 1 &&
              (loading ? (
                <ProgressSpinner />
              ) : (
                <Button className='btn__nextbtn' onClick={handleِEditOnClick} disabled={!isFormOk}>
                  تعديل الاذن
                </Button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    transaction: state.warehouse.transaction,
    loading: state.warehouse.loading,
    error: state.warehouse.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransaction: (id, token) => dispatch(fetchTransaction(id, token)),
    editTransaction: (transaction, token) => dispatch(editTransaction(transaction, token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTransaction);
