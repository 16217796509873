import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Sidebar from '../../../components/Sidebar/Sidebar';
import GenericPage from '../../../components/components/GenericPage/GenericPage';

function CustomerScreen() {
  const navigate = useNavigate();
  const apiUrl = '/Customer/list';
  const routeUrl = '/customers/details/';
  const addUrl = '/customers/addCustomer';

  return (
    <div className='flex w-full'>
      <Sidebar />
      <GenericPage
        tabs={[
          {
            tableHeader: 'قائمة العملاء',
            label: 'قائمة العملاء',
            isHidden: true,
            entityName: 'عميل',
            dataKey: 'tab1',
            columns: [
              { header: 'العميل', field: 'fullName', isChip: false },
              { header: 'عدد الأجهزة', field: 'numberOfDevices', isChip: false },
              { header: 'العنوان الرئيسي', field: 'defaultAddressAddress', isChip: false },
              { header: 'رقم الهاتف', field: 'phoneNb', isChip: false },
              { header: 'طريقة التواصل', field: 'preferredContactMethodName', isChip: false },
              { header: 'الحالة', field: 'statusName', isChip: true }
            ],
            apiUrl: apiUrl,
            edit: false,
            emptyStateUrl: addUrl,
            rowOnClick: (rowData) => {
              navigate(routeUrl + rowData.id);
            }
          }
        ]}
        headerTitle={'العملاء'}
        headerIcon={'../images/productbox.svg'}
        headerButtons={[
          {
            label: 'إضافة عميل جديد',
            onClick: () => {
              navigate(addUrl);
            }
          }
        ]}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerScreen);
