import React from 'react';

import Tabs from '../../../components/Tabs/Tabs';
import Sidebar from '../../../components/Sidebar/Sidebar';
import ListEntityTable from '../../../components/Tables/ListEntityTable';

function UsersAndEmployees() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title'>
          <i className='pi pi-database main__title--icon'></i> بيانات الموظفين و المستخدمين
        </span>

        <div className='mt-5 pr-4 pl-4'>
          <Tabs
            headers={[
              'الاقسام',
              'الدرجات الوظيفية',
              'الاجازات',
              'نظام الحضور',
              'الصلاحيات و الادوار',
              'بيانات مفردات المرتبات'
            ]}
            contents={[
              <ListEntityTable
                columns={[
                  { header: 'الاقسام', field: 'name', isChip: true },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='Department'
                headerTitle='الاقسام'
                entityName='قسم'
                entityNameDefined='القسم'
                addBtnText='إضافة قسم جديد'
                type={'generalList'}
              />,
              <ListEntityTable
                columns={[
                  { header: 'الدرجات الوظيفية', field: 'name', isChip: true },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='Position'
                headerTitle='الدرجات الوظيفية'
                entityName='درجة وظيفية'
                entityNameDefined='الدرجة الوظيفية'
                addBtnText='إضافة درجة وظيفية جديدة'
                type={'generalList'}
              />,
              <ListEntityTable
                columns={[
                  { header: 'انواع الاجازات', field: 'name', isChip: true },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='VacationsType'
                headerTitle='انواع الاجازات'
                entityName='نوع اجازة'
                entityNameDefined='نوع الاجازة'
                addBtnText='إضافة نوع اجازة جديد'
                type={'generalList'}
              />,
              <ListEntityTable
                columns={[
                  { header: 'انواع أنظمة الحضور', field: 'name', isChip: true },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='AttendanceType'
                headerTitle='انواع أنظمة الحضور'
                entityName='نظام حضور'
                entityNameDefined='نظام الحضور'
                addBtnText='إضافة أنظمة حضور جديدة'
                type={'generalList'}
              />,
              <ListEntityTable
                columns={[
                  { header: 'الادوار', field: 'name', isChip: true },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='UsersType'
                headerTitle='الادوار'
                entityName='دور'
                entityNameDefined='الدور'
                addBtnText='إضافة ادوار جديدة'
                type={'generalList'}
              />,
              <ListEntityTable
                columns={[
                  { header: 'انواع مفردات المرتبات', field: 'name', isChip: true },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='PayrollItem'
                headerTitle='انواع مفردات المرتبات'
                entityName='مفردات مرتب'
                entityNameDefined='مفردات المرتب'
                addBtnText='إضافة انواع مفردات مرتب '
                type={'generalList'}
              />
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default UsersAndEmployees;
