import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Rating } from 'primereact/rating';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import Sidebar from '../../components/Sidebar/Sidebar';
import HeaderCard from '../../components/Cards/Home header/HeaderCard';
import { axiosInstance } from '../../global';

import DetailsSheetGrid from './DetailsSheetGrid';
import './DetailsSheet.scss';

function DetailsSheet({ token }) {
  const Style1 = { color: 'black' };
  const Style2 = { color: 'red' };
  const toast = useRef(null);
  const params = useParams();
  const navigate = useNavigate();

  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);

  const [myData, setMyData] = useState();
  const [customerData, setCustomerData] = useState();
  const [revesionData, setRevesionData] = useState();
  const [invoiceData, setInvoiceData] = useState();

  if (params.type === 'contracts') {
    useEffect(() => {
      axiosInstance
        .get(`/Contract/${params.id}`)
        .then((response) => {
          setMyData(response.data.data);
          setLoading1(false);
        })
        .catch((error) => {});
    }, [params.id]);

    useEffect(() => {
      axiosInstance
        .get(`/Customer/${params.customerId}`)
        .then((response) => {
          setCustomerData(response.data.data);
          setLoading2(false);
        })
        .catch((error) => {});
    }, [params.customerId]);
  }

  if (params.type === 'activity' || params.type === 'activities') {
    useEffect(() => {
      axiosInstance
        .get(`/CustomersActivity/${params.id}`)
        .then((response) => {
          setMyData(response.data.data);
          setLoading1(false);
        })
        .catch((error) => {});
    }, [params.id]);

    useEffect(() => {
      axiosInstance
        .get(`/Customer/${params.customerId}`)
        .then((response) => {
          setCustomerData(response.data.data);
          setLoading2(false);
        })
        .catch((error) => {});
    }, [params.customerId]);

    useEffect(() => {
      axiosInstance
        .get(`/Revision/getByCustomerActivity/${params.id}`)
        .then((response) => {
          setRevesionData(response.data.data);
          setLoading3(false);
        })
        .catch((error) => {});
    }, [params.id]);
  }

  if (params.type === 'complaint') {
    useEffect(() => {
      axiosInstance
        .get(`/CustomersComplaint/details/${params.id}`)
        .then((response) => {
          setMyData(response.data.data);
          setLoading1(false);
        })
        .catch((error) => {});
    }, [params.id]);

    useEffect(() => {
      axiosInstance
        .get(`/Customer/${params.customerId}`)
        .then((response) => {
          setCustomerData(response.data.data);
          setLoading2(false);
        })
        .catch((error) => {});
    }, [params.customerId]);

    useEffect(() => {
      axiosInstance
        .get(`/Revision/getByCustomerComplain/${params.id}`)
        .then((response) => {
          setRevesionData(response.data.data);
          setLoading3(false);
        })
        .catch((error) => {});
    }, [params.id]);
  }

  if (params.type === 'followUp' || params.type === 'visit') {
    useEffect(() => {
      axiosInstance
        .get(`/Visit/details/${params.id}`)
        .then((response) => {
          setMyData(response.data.data);
          setInvoiceData(response.data.data.proformaInvoice);
          setLoading1(false);
        })
        .catch((error) => {});
    }, [params.id]);

    useEffect(() => {
      axiosInstance
        .get(`/Customer/${params.customerId}`)
        .then((response) => {
          setCustomerData(response.data.data);
          setLoading2(false);
        })
        .catch((error) => {});
    }, [params.customerId]);

    useEffect(() => {
      axiosInstance
        .get(`/Revision/getByVisit/${params.id}`)
        .then((response) => {
          setRevesionData(response.data.data);
          setLoading3(false);
        })
        .catch((error) => {});
    }, [params.id]);
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='details_main w-full'>
        {/*====================================== nav ========================================*/}
        {params.type === 'contracts' && (
          <div className='detailsheet__header'>
            <Toast ref={toast} />
            <ConfirmDialog />

            <div className='detailsheet__header__right'>
              <Button
                className='font-bold text-black-alpha-90'
                icon='pi pi-arrow-right'
                rounded
                text
                onClick={() => navigate(-1)}
              />
              <span className='detailsheet__header__right--text'> بيانات العقد</span>
            </div>

            <div className='detailsheet__header__left'>
              <Button
                className='detailsheet__header__left__btn'
                // onClick={() => navigate(`/customers/editCustomer/${params.id}`)}
                icon='pi pi-pencil'
                style={Style1}
                text
              >
                تعديل بيانات العقد
              </Button>

              {/* {myData.status.name === 'Active' && ( */}
              <Button
                className='detailsheet__header__left__btn'
                // onClick={showConfirmDeleteCustomer}
                icon='pi pi-trash'
                style={Style2}
                text
              >
                إيقاف العقد
              </Button>
              {/* )} */}
            </div>
          </div>
        )}

        {params.type === 'activity' && (
          <div className='detailsheet__header'>
            <Toast ref={toast} />
            <ConfirmDialog />

            <div className='detailsheet__header__right'>
              <Button
                className='font-bold text-black-alpha-90'
                icon='pi pi-arrow-right'
                rounded
                text
                onClick={() => navigate(-1)}
              />
              <span className='detailsheet__header__right--text'> بيانات الاتصال</span>
            </div>

            <div className='detailsheet__header__left'>
              <Button
                className='detailsheet__header__left__btn'
                // onClick={() => navigate(`/customers/editCustomer/${params.id}`)}
                icon='pi pi-pencil'
                style={Style1}
                text
              >
                تعديل بيانات الاتصال
              </Button>

              <Button
                className='detailsheet__header__left btn__nextbtn'
                // onClick={showConfirmDeleteCustomer}
              >
                تقييم
              </Button>
            </div>
          </div>
        )}

        {params.type === 'activities' && (
          <div className='detailsheet__header'>
            <Toast ref={toast} />
            <ConfirmDialog />

            <div className='detailsheet__header__right'>
              <Button
                className='font-bold text-black-alpha-90'
                icon='pi pi-arrow-right'
                rounded
                text
                onClick={() => navigate(-1)}
              />
              <span className='detailsheet__header__right--text'> بيانات الاتصال</span>
            </div>

            <div className='detailsheet__header__left'>
              <Button
                className='detailsheet__header__left__btn'
                onClick={() => navigate(`/Activites/Edit/${params.id}`)}
                icon='pi pi-pencil'
                style={Style1}
                text
              >
                تعديل بيانات الاتصال
              </Button>
            </div>
          </div>
        )}

        {params.type === 'complaint' && (
          <div className='detailsheet__header'>
            <Toast ref={toast} />
            <ConfirmDialog />

            <div className='detailsheet__header__right'>
              <Button
                className='font-bold text-black-alpha-90'
                icon='pi pi-arrow-right'
                rounded
                text
                onClick={() => navigate(-1)}
              />
              <span className='detailsheet__header__right--text'> بيانات الشكوى</span>
            </div>

            <div className='detailsheet__header__left'>
              <Button
                className='detailsheet__header__left__btn'
                // onClick={() => navigate(`/customers/editCustomer/${params.id}`)}
                icon='pi pi-pencil'
                style={Style1}
                text
              >
                تعديل بيانات الشكوى
              </Button>

              <Button
                className='detailsheet__header__left btn__nextbtn'
                // onClick={showConfirmDeleteCustomer}
              >
                تقييم
              </Button>
            </div>
          </div>
        )}

        {params.type === 'followUp' && (
          <div className='detailsheet__header'>
            <Toast ref={toast} />
            <ConfirmDialog />

            <div className='detailsheet__header__right'>
              <Button
                className='font-bold text-black-alpha-90'
                icon='pi pi-arrow-right'
                rounded
                text
                onClick={() => navigate(-1)}
              />
              <span className='detailsheet__header__right--text'> بيانات الزيارة</span>
            </div>

            <div className='detailsheet__header__left'>
              <Button
                className='detailsheet__header__left__btn'
                // onClick={() => navigate(`/customers/editCustomer/${params.id}`)}
                icon='pi pi-pencil'
                style={Style1}
                text
              >
                تعديل بيانات الزيارة
              </Button>

              <Button
                className='detailsheet__header__left btn__nextbtn'
                // onClick={showConfirmDeleteCustomer}
              >
                تقييم
              </Button>
            </div>
          </div>
        )}

        {params.type === 'visit' && (
          <div className='detailsheet__header'>
            <Toast ref={toast} />
            <ConfirmDialog />

            <div className='detailsheet__header__right'>
              <Button
                className='font-bold text-black-alpha-90'
                icon='pi pi-arrow-right'
                rounded
                text
                onClick={() => navigate(-1)}
              />
              <span className='detailsheet__header__right--text'> بيانات الزيارة</span>
            </div>

            <div className='detailsheet__header__left'>
              <Button
                className='detailsheet__header__left__btn'
                onClick={() => navigate(`/visit/EditVisit/${params.id}/${params.customerId}`)}
                icon='pi pi-pencil'
                style={Style1}
                text
              >
                تعديل بيانات الزيارة
              </Button>
            </div>
          </div>
        )}
        {/*====================================== body ========================================*/}
        {params.type === 'contracts' && (
          <div className='detailsheet__contentgeneral'>
            {loading1 ? (
              <div className='flex justify-content-center'>
                <ProgressSpinner />
              </div>
            ) : (
              <div className='customerdetails__header__card'>
                <HeaderCard
                  title={'تاريخ التعاقد'}
                  num={myData.contractDate}
                  imageUrl={'/images/alt.svg'}
                  color={'#6D54DE33'}
                />
                <HeaderCard
                  title={'تاريخ الانتهاء'}
                  num={myData.expiryDate}
                  imageUrl={'/images/alt.svg'}
                  color={'#6D54DE33'}
                />
                <HeaderCard
                  title={'حالة العقد'}
                  num={<Chip label={myData.status?.name} />}
                  imageUrl={'/images/briefcase.svg'}
                  color={'#FFEDD0'}
                />
                <HeaderCard
                  title={'منشئ العقد'}
                  num={myData.user?.userName}
                  imageUrl={'/images/user1.svg'}
                  color={'#C050E838'}
                />
              </div>
            )}
            <div className='detailsheet__contentgeneral__grid'>
              {loading2 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={['الاسم كامل', 'حالة العميل', 'رقم الهاتف']}
                  // contents={[customerData?.fullName]}
                  contents={[
                    <div className='flex flex-row gap-5'>
                      {customerData.fullName}
                      <Link
                        to={`/customers/details/${params.customerId}`}
                        className='primary__data--link text-blue-600'
                      >
                        عرض بيانات العميل{` > `}
                      </Link>
                    </div>,
                    <Chip label={customerData.status?.name} />,
                    customerData?.mobile
                  ]}
                  title={'بيانات العميل'}
                />
              )}
              {loading1 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={[
                    'نوع الجهاز',
                    'كود العقد',
                    'تاريخ انشاء العقد',
                    'تاريخ انتهاء العقد',
                    'نوع التقسيط', //
                    'سعر البيع',
                    'عدد الاقساط',
                    'نوع التركيب', //
                    'المقدم',
                    'مبلغ القسط',
                    'عمولة الموظف',
                    'حالة العقد',
                    'تفاصيل الطلب', //
                    'ملاحظات اخرى'
                  ]}
                  contents={[
                    <div className='flex flex-row gap-5'>
                      {myData.customerDevice.device.name}
                      <Link
                        to={`/product/details/${myData.customerDevice.device.id}/customerView`}
                        className='primary__data--link text-blue-600'
                      >
                        عرض تفاصيل الجهاز{` > `}
                      </Link>
                    </div>,
                    myData.code,
                    myData.contractDate,
                    myData.expiryDate,
                    myData.sellingPrice,
                    myData.sellingPrice,
                    myData.nbOfInstallments,
                    myData.code,
                    myData.downPayment,
                    myData.installmentAmount,
                    <div className='flex flex-row gap-5'>
                      {myData.commission}
                      <Link to={``} className='primary__data--link text-blue-600'>
                        تفاصيل العمولة {` > `}
                      </Link>
                    </div>,
                    <Chip label={myData.status?.name} />,
                    myData.remarks,
                    myData.remarks
                  ]}
                  title={'بيانات العقد'}
                />
              )}
            </div>
          </div>
        )}

        {params.type === 'activity' && (
          <div className='detailsheet__contentgeneral'>
            {loading1 ? (
              <div className='flex justify-content-center'>
                <ProgressSpinner />
              </div>
            ) : (
              <div className='customerdetails__header__card'>
                <HeaderCard
                  title={'الاجراء المتخذ'}
                  num={myData.decision.name}
                  imageUrl={'/images/alt.svg'}
                  color={'#6D54DE33'}
                />
                <HeaderCard
                  title={'تاريخ الاتصال'}
                  num={myData.activityDateTime}
                  imageUrl={'/images/alt.svg'}
                  color={'#6D54DE33'}
                />
                <HeaderCard
                  title={'حالة الاتصال'}
                  num={<Chip label={myData.contactStatus} />}
                  // num={<Tag severity='success' value={myData.contactStatus}></Tag>}
                  imageUrl={'/images/briefcase.svg'}
                  color={'#FFEDD0'}
                />
                <HeaderCard
                  title={'منشئ الاتصال'}
                  num={myData.user?.userName}
                  imageUrl={'/images/user1.svg'}
                  color={'#C050E838'}
                />
              </div>
            )}
            <div className='detailsheet__contentgeneral__grid'>
              {loading3 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={[
                    'تاريخ المراجعة',
                    'كود المراجعة',
                    'منشئ المراجعة',
                    'نوع المراجعة',
                    'التقييم',
                    'ملاحظات المراجع',
                    'ملاحظات اخرى'
                  ]}
                  contents={[
                    revesionData?.creationDateTime,
                    revesionData?.code,
                    revesionData.user.userName,
                    revesionData.type.name,
                    revesionData.evaluationType.name,
                    revesionData.reviewerComment,
                    revesionData.remarks
                  ]}
                  title={'تفاصيل المراجعة'}
                />
              )}
              {loading2 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={['الاسم كامل', 'حالة العميل', 'رقم الهاتف']}
                  contents={[customerData?.fullName, <Chip label={customerData.status?.name} />, customerData?.mobile]}
                  title={'بيانات العميل'}
                />
              )}
              {loading1 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={[
                    'نوع الاتصال',
                    'حالة الاتصال',
                    'فئة الاتصال',
                    'تصنيف الاتصال',
                    ' سبب الاتصال',
                    'طريقة الاتصال',
                    'الاجراء المتخذ',
                    'تفاصيل الطلب',
                    'تفاصيل الرد على العميل',
                    'ملاحظات اخرى'
                  ]}
                  contents={[
                    myData.contactType,
                    <Chip label={myData.contactStatus} />,
                    myData.contactFamily,
                    myData.contactCategory,
                    myData.reason.name,
                    myData.contactMethod,
                    myData.decision.name,
                    myData.requestDetails,
                    myData.responseDetails,
                    myData.remarks
                  ]}
                  title={'بيانات الاتصال'}
                />
              )}
            </div>
          </div>
        )}

        {params.type === 'activities' && (
          <div className='detailsheet__contentgeneral'>
            {loading1 ? (
              <div className='flex justify-content-center'>
                <ProgressSpinner />
              </div>
            ) : (
              <div className='customerdetails__header__card'>
                <HeaderCard
                  title={'الاجراء المتخذ'}
                  num={myData.decision.name}
                  imageUrl={'/images/alt.svg'}
                  color={'#6D54DE33'}
                />
                <HeaderCard
                  title={'تاريخ الاتصال'}
                  num={myData.activityDateTime}
                  imageUrl={'/images/alt.svg'}
                  color={'#6D54DE33'}
                />
                <HeaderCard
                  title={'حالة الاتصال'}
                  num={<Chip label={myData.contactStatus} />}
                  // num={<Tag severity='success' value={myData.contactStatus}></Tag>}
                  imageUrl={'/images/briefcase.svg'}
                  color={'#FFEDD0'}
                />
                <HeaderCard
                  title={'منشئ الاتصال'}
                  num={myData.user?.userName}
                  imageUrl={'/images/user1.svg'}
                  color={'#C050E838'}
                />
              </div>
            )}
            <div className='detailsheet__contentgeneral__grid'>
              {loading3 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={[
                    'تاريخ المراجعة',
                    'كود المراجعة',
                    'منشئ المراجعة',
                    'نوع المراجعة',
                    'التقييم',
                    'ملاحظات المراجع',
                    'ملاحظات اخرى'
                  ]}
                  contents={[
                    revesionData?.creationDateTime,
                    revesionData?.code,
                    revesionData.user.userName,
                    revesionData.type.name,
                    revesionData.evaluationType.name,
                    revesionData.reviewerComment,
                    revesionData.remarks
                  ]}
                  title={'تفاصيل المراجعة'}
                />
              )}
              {loading2 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={['الاسم كامل', 'حالة العميل', 'رقم الهاتف']}
                  contents={[customerData?.fullName, <Chip label={customerData.status?.name} />, customerData?.mobile]}
                  title={'بيانات العميل'}
                />
              )}
              {loading1 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={[
                    'نوع الاتصال',
                    'حالة الاتصال',
                    'فئة الاتصال',
                    'تصنيف الاتصال',
                    ' سبب الاتصال',
                    'طريقة الاتصال',
                    'الاجراء المتخذ',
                    'تفاصيل الطلب',
                    'تفاصيل الرد على العميل',
                    'ملاحظات اخرى'
                  ]}
                  contents={[
                    myData.contactType,
                    <Chip label={myData.contactStatus} />,
                    myData.contactFamily,
                    myData.contactCategory,
                    myData.reason.name,
                    myData.contactMethod,
                    myData.decision.name,
                    myData.requestDetails,
                    myData.responseDetails,
                    myData.remarks
                  ]}
                  title={'بيانات الاتصال'}
                />
              )}
            </div>
          </div>
        )}

        {params.type === 'complaint' && (
          <div className='detailsheet__contentgeneral'>
            {loading1 ? (
              <div className='flex justify-content-center'>
                <ProgressSpinner />
              </div>
            ) : (
              <div className='customerdetails__header__card'>
                <HeaderCard
                  title={'الاجراء المتخذ'}
                  num={myData?.creationDateTime}
                  imageUrl={'/images/alt.svg'}
                  color={'#6D54DE33'}
                />
                <HeaderCard
                  title={'تاريخ الشكوى'}
                  num={myData?.creationDateTime}
                  imageUrl={'/images/alt.svg'}
                  color={'#6D54DE33'}
                />
                <HeaderCard
                  title={'حالة الشكوى'}
                  // num={<Chip label={myData.status?.name} />}
                  num={<Tag severity='success' value={myData.customersComplaintsStatus}></Tag>}
                  imageUrl={'/images/briefcase.svg'}
                  color={'#FFEDD0'}
                />
                <HeaderCard
                  title={'منشئ الاتصال'}
                  num={myData?.customersActivityUserName}
                  imageUrl={'/images/user1.svg'}
                  color={'#C050E838'}
                />
              </div>
            )}
            <div className='detailsheet__contentgeneral__grid'>
              {loading3 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={[
                    'تاريخ المراجعة',
                    'كود المراجعة',
                    'منشئ المراجعة',
                    'نوع المراجعة',
                    'التقييم',
                    'ملاحظات المراجع',
                    'ملاحظات اخرى'
                  ]}
                  contents={[
                    revesionData?.creationDateTime,
                    revesionData?.code,
                    revesionData.user.userName,
                    revesionData.type.name,
                    revesionData.evaluationType.name,
                    revesionData.reviewerComment,
                    revesionData.remarks
                  ]}
                  title={'تفاصيل المراجعة'}
                />
              )}
              {loading2 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={['الاسم كامل', 'حالة العميل', 'رقم الهاتف']}
                  contents={[customerData?.fullName, <Chip label={customerData.status?.name} />, customerData?.mobile]}
                  title={'بيانات العميل'}
                />
              )}
              {loading1 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={[
                    'نوع الشكوى',
                    'حالة الشكوى',
                    'فئة الشكوى',
                    'تصنيف الشكوى',
                    ' سبب الشكوى',
                    'طريقة الشكوى',
                    'الاجراء المتخذ', //
                    'تفاصيل الطلب',
                    'تفاصيل الرد على العميل', //
                    'ملاحظات اخرى'
                  ]}
                  contents={[
                    myData.complaintTypeName,
                    <Chip label={myData.customersComplaintsStatus} />,
                    myData.complaintCategoryName,
                    myData.complaintFamilyName,
                    myData.activityReasonName,
                    myData.customerContactMethodName,
                    '',
                    myData.complaintDetails,
                    '',
                    myData.complaintRemarks
                  ]}
                  title={'بيانات الشكوى'}
                />
              )}
            </div>
          </div>
        )}

        {params.type === 'followUp' && (
          <div className='detailsheet__contentgeneral'>
            {loading1 ? (
              <div className='flex justify-content-center'>
                <ProgressSpinner />
              </div>
            ) : (
              <div className='customerdetails__header__card'>
                <HeaderCard
                  title={'تم تحصيل'}
                  num={myData.visitDate}
                  imageUrl={'/images/alt.svg'}
                  color={'#6D54DE33'}
                />
                <HeaderCard
                  title={'تاريخ الزيارة'}
                  num={myData.visitDate}
                  imageUrl={'/images/alt.svg'}
                  color={'#6D54DE33'}
                />
                <HeaderCard
                  title={'حالة الزيارة'}
                  num={<Chip label={myData.visitStatus} />}
                  // num={<Tag severity='success' value={myData.contactStatus}></Tag>}
                  imageUrl={'/images/briefcase.svg'}
                  color={'#FFEDD0'}
                />
                <HeaderCard
                  title={'منشئ الزيارة'}
                  num={myData.visitCreatedBy}
                  imageUrl={'/images/user1.svg'}
                  color={'#C050E838'}
                />
              </div>
            )}
            <div className='detailsheet__contentgeneral__grid'>
              {loading3 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={[
                    'تاريخ المراجعة',
                    'كود المراجعة',
                    'منشئ المراجعة',
                    'نوع المراجعة',
                    'التقييم',
                    'ملاحظات المراجع',
                    'ملاحظات اخرى'
                  ]}
                  contents={[
                    revesionData?.creationDateTime,
                    revesionData?.code,
                    revesionData.user.userName,
                    revesionData.type.name,
                    revesionData.evaluationType.name,
                    revesionData.reviewerComment,
                    revesionData.remarks
                  ]}
                  title={'تفاصيل المراجعة'}
                />
              )}
              {loading2 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={['الاسم كامل', 'حالة العميل', 'رقم الهاتف']}
                  contents={[customerData?.fullName, <Chip label={customerData.status?.name} />, customerData?.mobile]}
                  title={'بيانات العميل'}
                />
              )}
              {loading1 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={[
                    'الجهاز المشكو عنه',
                    'حالة الزيارة',
                    'كود الجهاز',
                    'تاريخ انشاء العقد',
                    'نوع العقد',
                    'رقم العقد',
                    'صلاحية العقد', //
                    'تاريخ انتهاء العقد',
                    'نوع الزيارة',
                    'اختر مستوى رضاء العميل',
                    'الفني',
                    'ملاحظات الفني',
                    'ملاحظات اخرى'
                  ]}
                  contents={[
                    myData.deviceName,
                    <Chip label={myData.visitStatus} />,
                    myData.deviceCode,
                    myData.contractCreationDate,
                    myData.contactType,
                    myData.contractCode,
                    myData.contractExpiryDate, //
                    myData.contractExpiryDate,
                    myData.visitType,
                    <Rating value={myData.complaintSatisfactionLevel} cancel={false} />,
                    myData.technicianName,
                    myData.technicianRemarks,
                    myData.otherRemarksVisit
                  ]}
                  title={'بيانات الزيارة'}
                />
              )}
            </div>
            <div className='detailsheet__contentgeneral__table'>
              <div className='mb-4'>
                <div className='tbl'>
                  <div className='tbl__header'>
                    <span className='header__title'> {'الفاتورة المراد تحصيلها (ما قبل الزيارة)'}</span>
                  </div>
                  <DataTable
                    value={invoiceData}
                    className='tbl__table'
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                  >
                    <Column field='type' header='اسم العملية'></Column>
                    <Column field='itemDescription' header='تم اختياره'></Column>
                    <Column field='amount' header='السعر'></Column>
                    <Column field='userType' header='الدور'></Column>
                    <Column field='statues' header='الحالة'></Column>
                  </DataTable>
                </div>
              </div>
              <div className='mb-4'>
                <div className='tbl'>
                  <div className='tbl__header'>
                    <span className='header__title'> {'الفاتورة المراد تحصيلها (ما بعد الزيارة)'}</span>
                  </div>
                  <DataTable value={''} className='tbl__table' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
                    <Column field='' header='اسم العملية'></Column>
                    <Column field='' header='تم اختياره'></Column>
                    <Column field='' header='السعر'></Column>
                    <Column field='' header='الدور'></Column>
                    <Column field='' header='الحالة'></Column>
                  </DataTable>
                </div>
              </div>

              <div className='detailsheet__contentgeneral__table__footer'>
                <div className='flex flex-column gap-4'>
                  <div className='flex flex-row gap-4'>
                    <span>{`كود الزيارة :${''}`}</span>
                    <span>{`التاريخ :${''}`}</span>
                    <span>{`العملة :${''}`}</span>
                  </div>
                  <div className='flex flex-row gap-4'>
                    <span>{`القيمة الاجمالية للفاتورة :${''}`}</span>
                    <span>{`وسيلة الدفع :${''}`}</span>
                  </div>
                </div>

                <div className='flex flex-column gap-4'>
                  <div className='flex flex-row gap-4'>
                    <span>{`اجمالي المطلوب من العميل في الزيارة :${''}`}</span>
                  </div>
                  <div className='flex flex-row gap-4'>
                    <span>{`اجمالي المدفوع للفني في الزيارة :${''}`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {params.type === 'visit' && (
          <div className='detailsheet__contentgeneral'>
            {loading1 ? (
              <div className='flex justify-content-center'>
                <ProgressSpinner />
              </div>
            ) : (
              <div className='customerdetails__header__card'>
                <HeaderCard
                  title={'تم تحصيل'}
                  num={myData.visitDate}
                  imageUrl={'/images/alt.svg'}
                  color={'#6D54DE33'}
                />
                <HeaderCard
                  title={'تاريخ الزيارة'}
                  num={myData.visitDate}
                  imageUrl={'/images/alt.svg'}
                  color={'#6D54DE33'}
                />
                <HeaderCard
                  title={'حالة الزيارة'}
                  num={<Chip label={myData.visitStatus} />}
                  // num={<Tag severity='success' value={myData.contactStatus}></Tag>}
                  imageUrl={'/images/briefcase.svg'}
                  color={'#FFEDD0'}
                />
                <HeaderCard
                  title={'منشئ الزيارة'}
                  num={myData.visitCreatedBy}
                  imageUrl={'/images/user1.svg'}
                  color={'#C050E838'}
                />
              </div>
            )}
            <div className='detailsheet__contentgeneral__grid'>
              {loading3 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={[
                    'تاريخ المراجعة',
                    'كود المراجعة',
                    'منشئ المراجعة',
                    'نوع المراجعة',
                    'التقييم',
                    'ملاحظات المراجع',
                    'ملاحظات اخرى'
                  ]}
                  contents={[
                    revesionData?.creationDateTime,
                    revesionData?.code,
                    revesionData.user.userName,
                    revesionData.type.name,
                    revesionData.evaluationType.name,
                    revesionData.reviewerComment,
                    revesionData.remarks
                  ]}
                  title={'تفاصيل المراجعة'}
                />
              )}
              {loading2 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={['الاسم كامل', 'حالة العميل', 'رقم الهاتف']}
                  contents={[customerData?.fullName, <Chip label={customerData.status?.name} />, customerData?.mobile]}
                  title={'بيانات العميل'}
                />
              )}
              {loading1 ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <DetailsSheetGrid
                  headers={[
                    'الجهاز المشكو عنه',
                    'حالة الزيارة',
                    'كود الجهاز',
                    'تاريخ انشاء العقد',
                    'نوع العقد',
                    'رقم العقد',
                    'صلاحية العقد', //
                    'تاريخ انتهاء العقد',
                    'نوع الزيارة',
                    'اختر مستوى رضاء العميل',
                    'الفني',
                    'ملاحظات الفني',
                    'ملاحظات اخرى'
                  ]}
                  contents={[
                    myData.deviceName,
                    <Chip label={myData.visitStatus} />,
                    myData.deviceCode,
                    myData.contractCreationDate,
                    myData.contactType,
                    myData.contractCode,
                    myData.contractExpiryDate, //
                    myData.contractExpiryDate,
                    myData.visitType,
                    <Rating value={myData.complaintSatisfactionLevel} cancel={false} />,
                    myData.technicianName,
                    myData.technicianRemarks,
                    myData.otherRemarksVisit
                  ]}
                  title={'بيانات الزيارة'}
                />
              )}
            </div>
            <div className='detailsheet__contentgeneral__table'>
              <div className='mb-4'>
                <div className='tbl'>
                  <div className='tbl__header'>
                    <span className='header__title'> {'الفاتورة المراد تحصيلها (ما قبل الزيارة)'}</span>
                  </div>
                  <DataTable
                    value={invoiceData}
                    className='tbl__table'
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                  >
                    <Column field='type' header='اسم العملية'></Column>
                    <Column field='itemDescription' header='تم اختياره'></Column>
                    <Column field='amount' header='السعر'></Column>
                    <Column field='userType' header='الدور'></Column>
                    <Column field='statues' header='الحالة'></Column>
                  </DataTable>
                </div>
              </div>
              <div className='mb-4'>
                <div className='tbl'>
                  <div className='tbl__header'>
                    <span className='header__title'> {'الفاتورة المراد تحصيلها (ما بعد الزيارة)'}</span>
                  </div>
                  <DataTable value={''} className='tbl__table' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
                    <Column field='' header='اسم العملية'></Column>
                    <Column field='' header='تم اختياره'></Column>
                    <Column field='' header='السعر'></Column>
                    <Column field='' header='الدور'></Column>
                    <Column field='' header='الحالة'></Column>
                  </DataTable>
                </div>
              </div>

              <div className='detailsheet__contentgeneral__table__footer'>
                <div className='flex flex-column gap-4'>
                  <div className='flex flex-row gap-4'>
                    <span>{`كود الزيارة :${''}`}</span>
                    <span>{`التاريخ :${''}`}</span>
                    <span>{`العملة :${''}`}</span>
                  </div>
                  <div className='flex flex-row gap-4'>
                    <span>{`القيمة الاجمالية للفاتورة :${''}`}</span>
                    <span>{`وسيلة الدفع :${''}`}</span>
                  </div>
                </div>

                <div className='flex flex-column gap-4'>
                  <div className='flex flex-row gap-4'>
                    <span>{`اجمالي المطلوب من العميل في الزيارة :${''}`}</span>
                  </div>
                  <div className='flex flex-row gap-4'>
                    <span>{`اجمالي المدفوع للفني في الزيارة :${''}`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsSheet);
