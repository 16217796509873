import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { axiosInstance } from '../../../global';
import { fill_customer } from '../../../redux/customer/customerActions';
import './EditCustomersDetails.scss';

function EditCustomers1({ token, fill_customer, setCheck, customerBase }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const params = useParams();
  const [Status, setStatus] = useState(null);
  const [CustomerTypes, setCustomerTypes] = useState(null);
  const [contactMethod, setContactMethod] = useState(null);
  const [customer, setCustomer] = useState({});

  useEffect(() => {
    setCustomer(customerBase);
    axiosInstance
      .get(`/CustomersType/all`)
      .then((response) => {
        const tempsetCustomersType = response.data.data.map((category) => {
          return {
            id: category.id,
            name: category.name
          };
        });
        setCustomerTypes(tempsetCustomersType);
      })
      .catch((error) => {}); // Customer Type

    axiosInstance
      .get(`/Status/dropdown`)
      .then((response) => {
        const tempStatus = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setStatus(tempStatus);
      })
      .catch((error) => {}); // Status

    axiosInstance
      .get(`/ContactMethod/all`)
      .then((response) => {
        const tempContactMethod = response.data.data.map((contactMethod) => {
          return {
            id: contactMethod.id,
            name: contactMethod.name
          };
        });
        setContactMethod(tempContactMethod);
      })
      .catch((error) => {}); // contact Method
  }, []);

  useEffect(() => {
    fill_customer(customer);
    // if (
    //   Object.values(customer).filter(
    //     (val, index) =>
    //       (val === '' || !val) && Object.keys(customer)[index] !== 'remarks' && Object.keys(customer)[index] !== 'code'
    //   ).length
    // ) {
    //   setCheck(false);
    // } else {
    //   setCheck(true);
    // }
  }, [customer]);

  function handleAddInputChange(e) {
    setCustomer({ ...customer, [e.target.name]: e.target.value || e.value });
  }

  function handleInputNumberChange1(e) {
    setCustomer({ ...customer, nationalNb: e.value });
  }
  function handleInputNumberChange2(e) {
    setCustomer({ ...customer, whatsappNb: e.value });
  }
  function handleInputNumberChange3(e) {
    setCustomer({ ...customer, mobile: e.value });
  }
  function handleInputNumberChange4(e) {
    setCustomer({ ...customer, phoneNb: e.value });
  }
  return (
    <div className='customer'>
      <div className='customer__content'>
        {/* page right content============================================== */}
        <div className='customer__content__right'>
          <span className='Title'>بيانات العميل</span>

          <div className='flex w-full flex-column gap-4'>
            <label className='addcustomer__label'>
              اختر نوع العميل<span style={Style}>*</span>
            </label>
            <Dropdown
              name='customersTypeId'
              value={customer.customersTypeId}
              onChange={handleAddInputChange}
              options={CustomerTypes}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر نوع العميل'
            />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addcustomer__label'>
              الاسم كامل <span style={Style}>*</span>
            </label>
            <InputText name='fullName' value={customer.fullName} onChange={handleAddInputChange} />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addcustomer__label'>
              الرقم القومي <span style={Style}>*</span>
            </label>
            <InputNumber
              name='nationalNb'
              value={customer.nationalNb}
              onChange={handleInputNumberChange1}
              useGrouping={false}
            />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addcustomer__label'>
              الوظيفة <span style={Style}>*</span>
            </label>
            <InputText name='jobTitle' value={customer.jobTitle} onChange={handleAddInputChange} />
          </div>

          <span className='Title'>بيانات التواصل</span>

          <div className='flex w-full flex-column gap-4'>
            <label className='addcustomer__label'>
              اختر طريقة التواصل المفضلة <span style={Style}>*</span>
            </label>
            <Dropdown
              name='preferredContactMethodId'
              value={customer.preferredContactMethodId}
              onChange={handleAddInputChange}
              options={contactMethod}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر طريقة التواصل'
            />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addcustomer__label'>
              رقم الواتساب <span style={Style}>*</span>
            </label>
            <InputNumber
              name='whatsappNb'
              value={customer.whatsappNb}
              onChange={handleInputNumberChange2}
              useGrouping={false}
            />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addcustomer__label'>
              رقم الموبايل <span style={Style}>*</span>
            </label>
            <InputNumber
              name='mobile'
              value={customer.mobile}
              onChange={handleInputNumberChange3}
              useGrouping={false}
            />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addcustomer__label'>
              رقم موبايل او هاتف اخر <span style={Style}>*</span>
            </label>
            <InputNumber
              name='phoneNb'
              value={customer.phoneNb}
              onChange={handleInputNumberChange4}
              useGrouping={false}
            />
          </div>
        </div>

        {/* page left content============================================== */}

        <div className='customer__content__left'>
          <span className='Title'> حالة الحساب</span>

          <div className='flex w-full flex-column gap-4'>
            <label className='addcustomer__label'>
              حالة الحساب <span style={Style}>*</span>
            </label>
            <Dropdown
              name='statusId'
              value={customer.statusId}
              onChange={handleAddInputChange}
              options={Status}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر حالة الحساب'
            />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addcustomer__label'>
              ملاحظات اخرى<span style={Style}>*</span>
            </label>
            <InputTextarea name='remarks' value={customer.remarks} onChange={handleAddInputChange} rows={5} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_customer: (customer) => dispatch(fill_customer(customer))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomers1);
