import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

import { API_BASE_URL, axiosInstance } from '../../../global';
import Tabs from '../../../components/Tabs/Tabs';
import Sidebar from '../../../components/Sidebar/Sidebar';


import './SupplierDetails.scss';
import HeaderCard from '../../../components/Cards/Home header/HeaderCard';
import WarehouseDetailsTable from '../../../components/Tables/WarehouseDetailsTable';

function SupplierDetails({ token }) {
  const Style1 = { color: 'black' };
  const Style2 = { color: 'red' };

  const [supplier, setSupplier] = useState({
    id: null,
    name: '',
    country: {
      id: null,
      name: ''
    },
    phone: '',
    email: '',
    website: '',
    paymentTerm: {
      id: null,
      name: ''
    },
    deliveryTerm: {
      id: null,
      name: ''
    },
    contactPerson: '',
    contactPersonMobile: '',
    address: '',
    status: {
      id: null,
      name: ''
    },
    transDateTime: '',
    user: {
      id: null,
      firstName: ''
    },
    remarks: ''
  });
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`/Supplier/${params.id}`)
      .then((response) => {
        setSupplier(response.data.data);
      })
      .catch((error) => {});
  }, []);

  function showConfirmDeleteSupplier(e) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف المورد؟',
      header: 'تأكيد حذف المورد',
      accept: () => {
        handleDeleteSupplier();
      },
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      draggable: false,
      resizable: false,
      tagKey: 1
    });
  }

  function handleDeleteSupplier() {
    axiosInstance
      .delete(`/Supplier/${params.id}`)
      .then(() => {
        toast.current.show({ severity: 'success', summary: 'تم حذف المورد', detail: '', life: 1500 });
        setTimeout(() => navigate('/warehouses/suppliers'), 1500);
      })
      .catch(() => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء حذف المورد', detail: '', life: 1500 });
      });
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className=' main w-full'>
        <div className='supplier-details__header1'>
          <ConfirmDialog tagKey={1} />
          <div className='supplier-details__header1__right'>
            <Button
              className='font-bold text-black-alpha-90'
              icon='pi pi-arrow-right'
              rounded
              text
              onClick={() => navigate(-1)}
            />
            <span className='supplier-details__header1__right--text'>تفاصيل المورد "{supplier.name}"</span>
          </div>

          <div className='supplier-details__header__left'>
            <Button
              className='supplier-details__header1__left__btn'
              onClick={() => navigate(`/warehouses/supplier/edit/${params.id}`)}
              icon='pi pi-pencil'
              style={Style1}
              text
            >
              تعديل البيانات المورد
            </Button>

            <Button
              className='supplier-details__header1__left__btn'
              onClick={showConfirmDeleteSupplier}
              icon='pi pi-trash'
              style={Style2}
              text
            >
              حذف المورد
            </Button>
          </div>
        </div>
        <div className='supplier-details'>
          <div className='supplier-details__header__card'>
            <HeaderCard
              title={'الاقساط المطلوبة'}
              num={'14 قسط'}
              imageUrl={'/images/coins.svg'}
              color={'#F9D8D4'}
              txtColor={'red'}
            />
            <HeaderCard
              title={'الاقساط المدفوعة'}
              num={'1500 ج.م'}
              imageUrl={'/images/officelife.svg'}
              color={'#6D54DE33'}
            />
            <HeaderCard
              title={'عدد الاجهزة'}
              num={'5 أجهزة'}
              imageUrl={'/images/user_box_light.svg'}
              color={'#FFEDD0'}
            />
            <HeaderCard title={'شكاوى العميل'} num={'5 شكاوي'} imageUrl={'/images/coins.svg'} color={'#C050E838'} />
          </div>
          <div className='supplier-details__content'>
            <div className='supplier-details__content__uper'>
              <div className='supplier-details__content__uper__right'>
                <span className='supplier-details__content__uper__right__supplier__name'>{supplier.name}</span>
                <div className='primary'>
                  <div className='primary__data'>
                    <span className='primary__data--label'> كود المورد: </span>
                    <span className='primary__data--text'>89573578357935</span>
                  </div>
                  <div className='primary__data'>
                    <Chip label={supplier.status.name} />
                  </div>
                  <span className='primary__data--title'>بيانات التواصل</span>
                  <div className='primary__data'>
                    <span className='primary__data--label'> طريقة التواصل: </span>
                    <span className='primary__data--text'>واتساب</span>
                  </div>
                  <div className='primary__data'>
                    <span className='primary__data--label'> رقم الهاتف: </span>
                    <span className='primary__data--text'>{supplier.phone}</span>
                  </div>
                </div>
              </div>
              <div className='supplier-details__content__uper__left'>
                <div className='primary'>
                  <span className='primary__data--title'>بيانات المورد</span>
                  <div className='primary__data'>
                    <span className='primary__data--label'>الــــــــدولة: </span>
                    <span className='primary__data--text'>{supplier.country.name}</span>
                  </div>
                  <span className='primary__data--title'>الملاحظات</span>
                  <div className='primary__data'>
                    <span className='primary__data--text'>{supplier.remarks}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Tabs
            headers={['حركات المورد', 'فواتير المورد']}
            contents={[
              <WarehouseDetailsTable
                haveChoice={true}
                choiceRoute='/warehouses/transaction/details'
                showId={false}
                columns={[
                  { header: 'رقم العملية', field: 'transNb', isChip: false },
                  { header: 'اﻻسم', field: 'name', isChip: false },
                  { header: 'نوع اﻻذن', field: 'name', isChip: false },
                  { header: 'نوع المصدر', field: 'name', isChip: false },
                  { header: 'المصدر', field: 'name', isChip: false },
                  { header: 'نوع المستقبل', field: 'name', isChip: false },
                  { header: 'المستقبل', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'approved', isChip: true }
                ]}
                urlName='Supplier/all'
                headerTitle='حركات المورد'
                entityNameDefined='الاذن'
                addBtnText='إذن صرف'
              />,
              <WarehouseDetailsTable
                haveChoice={true}
                choiceRoute='/warehouses/transaction/details'
                showId={false}
                columns={[
                  { header: 'كود الفاتورة', field: 'transNb', isChip: false },
                  { header: 'التاريخ', field: 'name', isChip: false },
                  { header: 'طريقة الدفع', field: 'name', isChip: false },
                  { header: 'نسبة الخصم', field: 'name', isChip: false },
                  { header: 'الخصم', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'approved', isChip: true }
                ]}
                urlName='Supplier/all'
                headerTitle='فواتير المورد'
                entityNameDefined='الفاتورة'
                addBtnText='فاتورة'
              />
            ]}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierDetails);
