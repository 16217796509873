import React, { useEffect, useRef, useState } from 'react';
import { Steps } from 'primereact/steps';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';

import Sidebar from '../../../../components/Sidebar/Sidebar';

import AddConnection1 from './AddConnection1';
import './AddConnection.scss';

const AddConnection = ({ activeIndexnum }) => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (activeIndexnum === '' || activeIndexnum === undefined) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndexnum);
    }
  }, []);

  const items = [
    { label: 'بيانات الاتصال الاساسية' },
    { label: 'اضافة طلب زيارة' },
    { label: 'تفاصيل الزيارة' },
    { label: 'مراجعة الطلب' }
  ];

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <div className='flex flex-column gap-5 add__visit__main'>
        <div className='details__header'>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='details__header1__right--text'>اضافة اتصال / زيارة جديدة</span>
        </div>
        <div className='flex steps card'>
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true} />
        </div>
        <div>
          <div>{activeIndex === 0 && <AddConnection1 />}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddConnection);
