import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { Link } from 'react-router-dom';

import { axiosInstance } from '../../../global';
import './AddProductDetails.scss';
import AddStatus from '../../../components/Modals/AddStatus';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';

const schema = yup.object().shape({
  skuCode: yup.string().required('مطلوب كود المنتج'),
  name: yup.string().required('مطلوب اسم المنتج'),
  details: yup.string().required('مطلوب وصف المنتج'),
  productCategory: yup.string().required('مطلوب فئة المنتج'),
  manufacturer: yup.string().required('مطلوب تصنيف المنتج'),
  brand: yup.string().required('مطلوب الماركة'),
  countryOfOrigin: yup.string().required('مطلوب بلد المنشأ'),
  nbOfStages: yup
    .number()
    .typeError('يجب أن يكون عدد المراحل رقمًا')
    .min(1, 'مطلوب عدد المراحل')
    .required('مطلوب عدد المراحل'),
  installmentTypesIds: yup.array().min(1, 'مطلوب طريقة تقسيط واحدة على الأقل').required('مطلوب طرق التقسيط'),
  status: yup.string().required('مطلوب حالة المنتج')
});

const AddProduct1 = forwardRef(({ token, fill_product }, ref) => {
  const [statuses, setStatuses] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [brands, setBrands] = useState([]);
  const [country, setCountry] = useState([]);
  const [installments, setInstallments] = useState([]);
  const [addItemVisible, setAddItemVisible] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched'
  });

  const onSubmit = (data) => {
    fill_product(data);
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit(onSubmit),
    triggerValidation: () => trigger(),
    getFormValues: getValues
  }));

  useEffect(() => {
    console.log('Ref in AddProduct1:', ref); // Debugging log

    axiosInstance
      .get('/ProductsCategory/all')
      .then((response) => {
        const tempCategory = response.data.data.map((category) => ({
          id: category.id,
          name: category.name
        }));
        setProductCategory(tempCategory);
      })
      .catch((error) => {}); // product category

    axiosInstance
      .get('/Status/dropdown')
      .then((response) => {
        const tempStatus = response.data.data.map((status) => ({
          id: status.id,
          name: status.name
        }));
        setStatuses(tempStatus);
      })
      .catch((error) => {}); // product status

    axiosInstance
      .get('/ProductsFamily/all')
      .then((response) => {
        const tempManufacturer = response.data.data.map((manufacturer) => ({
          id: manufacturer.id,
          name: manufacturer.name
        }));
        setManufacturer(tempManufacturer);
      })
      .catch((error) => {}); // product manufacturer

    axiosInstance
      .get('/Brand/all')
      .then((response) => {
        const tempBrand = response.data.data.map((brand) => ({
          id: brand.id,
          name: brand.name
        }));
        setBrands(tempBrand);
      })
      .catch((error) => {}); // product brand

    axiosInstance
      .get('/Country/all')
      .then((response) => {
        const tempCountry = response.data.data.map((country) => ({
          id: country.id,
          name: country.name
        }));
        setCountry(tempCountry);
      })
      .catch((error) => {}); // product country of origin

    axiosInstance
      .get('/InstallmentsType/all')
      .then((response) => {
        const tempInstallment = response.data.data.map((installment) => ({
          id: installment.id,
          name: installment.name
        }));
        setInstallments(tempInstallment);
      })
      .catch((error) => {}); // installment types
  }, []);

  return (
    <form className='product'>
      <div className='product__content'>
        {/* page right content */}
        <div className='product__content__right'>
          <span className='Title'>عن المنتج</span>
          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>كود المنتج</label>
            <InputText disabled={true} />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              رقم المنتج / SKU<span style={{ color: 'red', padding: '0.7rem' }}>*</span>
            </label>
            <Controller
              name='skuCode'
              control={control}
              render={({ field }) => <InputText {...field} onBlur={() => trigger('skuCode')} />}
            />
            {errors.skuCode && <p style={{ color: 'red' }}>{errors.skuCode.message}</p>}
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              اسم المنتج بالعربية<span style={{ color: 'red', padding: '0.7rem' }}>*</span>
            </label>
            <Controller
              name='name'
              control={control}
              render={({ field }) => <InputText {...field} onBlur={() => trigger('name')} />}
            />
            {errors.name && <p style={{ color: 'red' }}>{errors.name.message}</p>}
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              وصف المنتج بالعربية<span style={{ color: 'red', padding: '0.7rem' }}>*</span>
            </label>
            <Controller
              name='details'
              control={control}
              render={({ field }) => <InputTextarea {...field} rows={5} onBlur={() => trigger('details')} />}
            />
            {errors.details && <p style={{ color: 'red' }}>{errors.details.message}</p>}
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              فئة المنتج <span style={{ color: 'red', padding: '0.7rem' }}>*</span>
            </label>
            <Controller
              name='manufacturer'
              control={control}
              render={({ field }) => (
                <CustomDropdown
                  {...field}
                  options={manufacturer}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر الفئة'
                  onBlur={() => trigger('manufacturer')}
                />
              )}
            />
            {errors.manufacturer && <p style={{ color: 'red' }}>{errors.manufacturer.message}</p>}
            <div className='flex flex-row gap-2'>
              <button className='addbtn' type={'button'}>
                +
              </button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل فئة جديدة
              </Link>
            </div>
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              تصنيف المنتج<span style={{ color: 'red', padding: '0.7rem' }}>*</span>
            </label>
            <Controller
              name='productCategory'
              control={control}
              render={({ field }) => (
                <CustomDropdown
                  {...field}
                  options={productCategory}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر التصنيف'
                  onBlur={() => trigger('productCategory')}
                />
              )}
            />
            {errors.productCategory && <p style={{ color: 'red' }}>{errors.productCategory.message}</p>}
            <div className='flex flex-row gap-2'>
              <button className='addbtn' type={'button'}>
                +
              </button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل تصنيف جديد
              </Link>
            </div>
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              الماركة<span style={{ color: 'red', padding: '0.7rem' }}>*</span>
            </label>
            <Controller
              name='brand'
              control={control}
              render={({ field }) => (
                <CustomDropdown
                  {...field}
                  options={brands}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر الماركة'
                  onBlur={() => trigger('brand')}
                />
              )}
            />
            {errors.brand && <p style={{ color: 'red' }}>{errors.brand.message}</p>}
            <div className='flex flex-row gap-2'>
              <button className='addbtn' type={'button'}>
                +
              </button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل ماركة جديدة
              </Link>
            </div>
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              بلد المنشأ<span style={{ color: 'red', padding: '0.7rem' }}>*</span>
            </label>
            <Controller
              name='countryOfOrigin'
              control={control}
              render={({ field }) => (
                <CustomDropdown
                  {...field}
                  options={country}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر بلد المنشأ'
                  onBlur={() => trigger('countryOfOrigin')}
                />
              )}
            />
            {errors.countryOfOrigin && <p style={{ color: 'red' }}>{errors.countryOfOrigin.message}</p>}
            <div className='flex flex-row gap-2'>
              <button className='addbtn' type={'button'}>
                +
              </button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل بلد منشأ جديدة
              </Link>
            </div>
          </div>
        </div>

        {/* page left content */}
        <div className='product__content__left'>
          <span className='Title'>مراحل المنتج</span>

          <div className='flex w-full flex-column gap-4'>
            <div className='stageNumNote'>
              <li className='pi pi-info-circle'></li>
              ستقوم ب أضافة المراحل بالتفصيل فالخطوة القادمة
            </div>
            <label className='addproduct__label'>
              عدد مراحل الفلتر<span style={{ color: 'red', padding: '0.7rem' }}>*</span>
            </label>
            <Controller
              name='nbOfStages'
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  showButtons
                  min={0}
                  max={1000}
                  value={field.value || 0}
                  onValueChange={(e) => {
                    setValue('nbOfStages', e.value);
                  }}
                  onChange={(e) => {
                    setValue('nbOfStages', e.value);
                  }}
                  onBlur={() => trigger('nbOfStages')}
                />
              )}
            />
            {errors.nbOfStages && <p style={{ color: 'red' }}>{errors.nbOfStages.message}</p>}
          </div>

          <span className='Title'>عن الدفع و طرق التقسيط </span>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              طرق التقسيط<span style={{ color: 'red', padding: '0.7rem' }}>*</span>
            </label>
            <Controller
              name='installmentTypesIds'
              control={control}
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  options={installments}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  placeholder='ابحث عن طرق التقسيط'
                  maxSelectedLabels={5}
                  onBlur={() => trigger('installmentTypesIds')}
                />
              )}
            />
            {errors.installmentTypesIds && <p style={{ color: 'red' }}>{errors.installmentTypesIds.message}</p>}
            <div className='flex flex-row gap-2'>
              <button className='addbtn' type={'button'}>
                +
              </button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل طرق تقسيط جديدة
              </Link>
            </div>
          </div>

          <span className='Title'>حالة المنتج</span>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              حالة المنتج<span style={{ color: 'red', padding: '0.7rem' }}>*</span>
            </label>
            <Controller
              name='status'
              control={control}
              render={({ field }) => (
                <CustomDropdown
                  {...field}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر حالة الحساب'
                  onBlur={() => trigger('status')}
                />
              )}
            />
            {errors.status && <p style={{ color: 'red' }}>{errors.status.message}</p>}
            <div className='flex flex-row gap-2'>
              <button onClick={() => setAddItemVisible(true)} className='addbtn' type={'button'}>
                +
              </button>
              <label className='addtitle'>اضف حالات للمنتج جديدة</label>
            </div>
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>ملاحاظات</label>
            <Controller
              name='remarks'
              control={control}
              render={({ field }) => <InputTextarea {...field} rows={5} />}
            />
          </div>
        </div>
      </div>
      <AddStatus
        visible={addItemVisible}
        setVisible={setAddItemVisible}
        title={'اضافة حالة جديدة'}
        entityName={'حالة'}
        entityNameDefined={'حالة'}
        onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية إضافة حالة جديدة؟`}
        onAddHandler={(item) => {
          console.log('Adding new status:', item);
          setAddItemVisible(false); // Close modal after adding
        }}
      />
    </form>
  );
});

export default AddProduct1;
