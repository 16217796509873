import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './style/reset.scss';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import UsersAndEmployees from './pages/Lists/UsersAndEmployees/UsersAndEmployees';
import Location from './pages/Lists/Locations/Location';
import Products from './pages/Lists/Products/Products';
import General from './pages/Lists/General/General';
import Client from './pages/Lists/Client/Client';
import VacationRequest from './pages/Employees/vacationRequest/VacationRequest';
import Details from './pages/Employees/Details/Details';
import Employees from './pages/Employees/employeeScreen/Employees';
import AddProduct from './pages/Products/AddProduct/AddProduct';
import ProductsScreen from './pages/Products/ProductScreen/ProductsScreen';
import WarehousesLists from './pages/Lists/Warehouses/WarehousesLists';
import EditProduct from './pages/Products/EditProduct/EditProduct';
import SpareScreen from './pages/Products/SpareScreen/SpareScreen';
import AddSpares from './pages/Products/AddSpares/AddSpares';
import ProductsDetails from './pages/Products/Details/ProductsDetails';
import StagesFromDetails from './components/Stages/StagesFromDetails';
import PriceList from './pages/Products/PriceList/PriceList';
import InstallmentList from './pages/Products/Installment/InstallmentList';
import ServicesList from './pages/Products/Services/ServicesList';
import Warehouses from './pages/Warehouses/Warehouses';
import AddWarehouse from './pages/Warehouses/AddWarehouse/AddWarehouse';
import WarehouseDetails from './pages/Warehouses/Details/WarehouseDetails';
import EditSpares from './pages/Products/EditSpares/EditSpares';
import PaymentTerms from './pages/Warehouses/PaymentTerms';
import PayrollList from './pages/Employees/Payroll/PayrollList';
import PriceListDetails from './pages/Products/PriceList/PriceListDetails';
import PriceListEditDetails from './pages/Products/PriceList/PriceListEditDetails';
import EditWarehouse from './pages/Warehouses/EditWarehouse/EditWarehouse';
import Transactions from './pages/Warehouses/Transactions';
import ActivitesScreen from './pages/Visits/ActivitesScreen/ActivitesScreen';
import AddConnection from './pages/Visits/AddVisit/AddConnection/AddConnection';
import AddConnection1 from './pages/Visits/AddVisit/AddConnection/AddConnection1';
import AddEditVisit from './pages/Visits/AddVisit/AddRealVisit/AddEditVisit';
import { WarehouseTransactionsMatrixList } from './pages/AdminLists/Warehouse/WarehouseTransactionsMatrixList';
import { SystemParamsList } from './pages/AdminLists/SystemParams/SystemParamsList';
import './style/custom.scss';
import ServicesListDetails from './pages/Products/Services/ServicesListDetails';
import ServicesListEditDetails from './pages/Products/Services/ServicesListEditDetails';
import CustomerScreen from './pages/Customers/CustomerScreen/CustomerScreen';
import AddCustomer from './pages/Customers/AddCustomers/AddCustomer';
import EditCustomers from './pages/Customers/EditCustomers/EditCustomers';
import CustomersDetails from './pages/Customers/Details/CustomersDetails';
import EmployeesManagement from './pages/Employees/EmployeesManagement/EmployeesManagement';
import AddIssuing from './pages/Warehouses/AddTransaction/AddIssuing';
import AddReceiving from './pages/Warehouses/AddTransaction/AddReceiving';
import AddConvenant from './pages/Warehouses/AddConvenant/AddConvenant';
import Suppliers from './pages/Warehouses/Suppliers';
import AddSupplier from './pages/Warehouses/AddSupplier/AddSupplier';
import CustomerGeoLocation from './pages/Customers/CustomerLists/CustomerGeoLocation';
import CustomerDevices from './pages/Customers/CustomerLists/CustomerDevices';
import CustomerComplaints from './pages/Customers/CustomerLists/CustomerComplaints';
import SupplierDetails from './pages/Warehouses/SupplierDetails/SupplierDetails';
import EditSupplier from './pages/Warehouses/EditSupplier/EditSupplier';
import TransactionDetails from './pages/Warehouses/TransactionDetails/TransactionDetails';
import DetailsSheet from './components/DetailsSheet/DetailsSheet';
import EditTransaction from './pages/Warehouses/EditTransaction/EditTransaction';
import VisitsGeneralList from './pages/Visits/VisitsGeneralList/VisitsGeneralList';
import FollowUpScreen from './pages/Visits/FollowUpScreen/FollowUpScreen';
import VisitsScreen from './pages/Visits/VisitsScreen/VisitsScreen';
import AddEditEmployee from './pages/Employees/AddEditEmployee/AddEditEmployee';
import EmployeesWorkingGroupDetails from './pages/Employees/EmployeesManagement/EmployeesWorkingGroupDetails';
import NotFound from './pages/NotFound/NotFound';
import ContractInstallments from './pages/Contracts/ContractLists/ContractInstallments';
import ContractScreen from './pages/Contracts/ContractScreen/ContractScreen';
import AddActivity from './pages/Contracts/AddEditContract/AddActivity/AddActivity';
import AddEditContract from './pages/Contracts/AddEditContract/AddEditContract';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />}></Route>
        <Route
          exact
          path="/"
          element={
            <ProtectedRoute>
              <Navbar />
              <Home />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        {/************** Lists Module  **************/}
        <Route
          path="/lists/users-and-employees"
          element={
            <ProtectedRoute>
              <Navbar />
              <UsersAndEmployees />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/lists/client"
          element={
            <ProtectedRoute>
              <Navbar />
              <Client />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/lists/locations"
          element={
            <ProtectedRoute>
              <Navbar />
              <Location />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/lists/products"
          element={
            <ProtectedRoute>
              <Navbar />
              <Products />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/lists/warehouses"
          element={
            <ProtectedRoute>
              <Navbar />
              <WarehousesLists />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/lists/general"
          element={
            <ProtectedRoute>
              <Navbar />
              <General />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        {/************** Employee Module  **************/}
        <Route
          path="/employees/vacationRequest"
          element={
            <ProtectedRoute>
              <Navbar />
              <VacationRequest />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/employees/payroll"
          element={
            <ProtectedRoute>
              <Navbar />
              <PayrollList />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/employees/details/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <Details />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/employees/edit/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddEditEmployee />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/employees/"
          element={
            <ProtectedRoute>
              <Navbar />
              <Employees />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/employees/add"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddEditEmployee />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/employees/management"
          element={
            <ProtectedRoute>
              <Navbar />
              <EmployeesManagement />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/employees/management/working-groups/details/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <EmployeesWorkingGroupDetails />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        {/************** Customers Module  **************/}
        <Route
          path="/customers/"
          element={
            <ProtectedRoute>
              <Navbar />
              <CustomerScreen />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/customers/addCustomer"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddCustomer />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/customers/editCustomer/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <EditCustomers />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/customers/details/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <CustomersDetails />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/customers/GeoLocation"
          element={
            <ProtectedRoute>
              <Navbar />
              <CustomerGeoLocation />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/customers/Devices"
          element={
            <ProtectedRoute>
              <Navbar />
              <CustomerDevices />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/customers/Complaints"
          element={
            <ProtectedRoute>
              <Navbar />
              <CustomerComplaints />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/customer/Contract/details/:id/:customerId/:type"
          element={
            <ProtectedRoute>
              <Navbar />
              <DetailsSheet />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/customer/activity/details/:id/:customerId/:type"
          element={
            <ProtectedRoute>
              <Navbar />
              <DetailsSheet />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/customer/complaint/details/:id/:customerId/:type"
          element={
            <ProtectedRoute>
              <Navbar />
              <DetailsSheet />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/customer/followUp/details/:id/:customerId/:type"
          element={
            <ProtectedRoute>
              <Navbar />
              <DetailsSheet />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        {/************** Products Module  **************/}
        <Route
          path="/products/"
          element={
            <ProtectedRoute>
              <Navbar />
              <ProductsScreen />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/product/details/:id/:custId/:type"
          element={
            <ProtectedRoute>
              <Navbar />
              <ProductsDetails />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/product/details/addStage/:id/:stgNb"
          element={
            <ProtectedRoute>
              <Navbar />
              <StagesFromDetails />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/products/edit/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <EditProduct />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/products/addProduct"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddProduct />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/productSpares/"
          element={
            <ProtectedRoute>
              <Navbar />
              <SpareScreen />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/productSpares/addSpareParts"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddSpares />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/productSpares/editSpareParts/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <EditSpares />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="product/installments"
          element={
            <ProtectedRoute>
              <Navbar />
              <InstallmentList />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="product/priceList"
          element={
            <ProtectedRoute>
              <Navbar />
              <PriceList />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/product/priceList/add"
          element={
            <ProtectedRoute>
              <Navbar />
              <PriceListDetails />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/product/priceList/edit/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <PriceListEditDetails />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="product/services"
          element={
            <ProtectedRoute>
              <Navbar />
              <ServicesList />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/product/services/add"
          element={
            <ProtectedRoute>
              <Navbar />
              <ServicesListDetails />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/product/services/edit/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <ServicesListEditDetails />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        {/************** Warehouses Module  **************/}
        <Route
          path="/warehouse/details/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <WarehouseDetails />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouse/edit/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <EditWarehouse />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouses/"
          element={
            <ProtectedRoute>
              <Navbar />
              <Warehouses />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouse/add"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddWarehouse />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouses/transactions/"
          element={
            <ProtectedRoute>
              <Navbar />
              <Transactions />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouses/transactions/issuing/add"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddIssuing />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouses/transactions/receiving/add"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddReceiving />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouses/transactions/covenant/add"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddConvenant />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouses/transactions/details/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <TransactionDetails />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouses/transactions/edit/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <EditTransaction />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouses/payment-terms/"
          element={
            <ProtectedRoute>
              <Navbar />
              <PaymentTerms />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouses/suppliers/"
          element={
            <ProtectedRoute>
              <Navbar />
              <Suppliers />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouses/supplier/add"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddSupplier />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouses/supplier/edit/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <EditSupplier />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/warehouses/supplier/details/:id"
          element={
            <ProtectedRoute>
              <Navbar />
              <SupplierDetails />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        {/************** contract Module  **************/}
        <Route
          path="/Contract/Customer/Installments"
          element={
            <ProtectedRoute>
              <Navbar />
              <ContractInstallments />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/Contract/"
          element={
            <ProtectedRoute>
              <Navbar />
              <ContractScreen />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/:type/details/:id/:customerId"
          element={
            <ProtectedRoute>
              <Navbar />
              <DetailsSheet />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/:type/AddConnection"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddConnection1 />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/Contract/:type/:contractId/:customerId/:deviceId"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddEditContract />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/Contract/Activity/Add/:contractId/:customerId/:deviceId"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddActivity />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        {/************** visits Module  **************/}
        <Route
          path="/Activites/"
          element={
            <ProtectedRoute>
              <Navbar />
              <ActivitesScreen />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        
        <Route
          path="/Visits/"
          element={
            <ProtectedRoute>
              <Navbar />
              <VisitsScreen />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/Activites/addVisitConnection"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddConnection />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/Activites/:type/:customerId/:deviceId"
          element={
            <ProtectedRoute>
              <Navbar />
              <AddEditVisit />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path='/Activites/:type/:activityId'
          element={
            <ProtectedRoute>
              <Navbar />
              <AddEditVisit />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path='/visit/:type/:visitId/:customerId'
          element={
            <ProtectedRoute>
              <Navbar />
              <AddEditVisit />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/Visits/GeneralLists"
          element={
            <ProtectedRoute>
              <Navbar />
              <VisitsGeneralList />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/Visits/followUp"
          element={
            <ProtectedRoute>
              <Navbar />
              <FollowUpScreen />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/visits/details/:type/:id/:customerId"
          element={
            <ProtectedRoute>
              <Navbar />
              <DetailsSheet />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/activities/details/:type/:id/:customerId"
          element={
            <ProtectedRoute>
              <Navbar />
              <DetailsSheet />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>


        {/************** Admin Module  **************/}
        <Route
          path="/admin/systemParams"
          element={
            <ProtectedRoute>
              <Navbar />
              <SystemParamsList />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/warehouse"
          element={
            <ProtectedRoute>
              <Navbar />
              <WarehouseTransactionsMatrixList />
              <Footer />
            </ProtectedRoute>
          }
        ></Route>
        <Route path="*" element={<NotFound />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
