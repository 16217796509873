import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { Rating } from 'primereact/rating';
import { Knob } from 'primereact/knob';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';

import { axiosInstance } from '../../global';

import '../../style/Tables.scss';

function ActivitesScreenTable({
  token,
  columns,
  urlName,
  headerTitle,
  addBtnText,
  entityNameDefined,
  path,
  type,
  detailsPath
}) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [myList, setMyList] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`/${urlName}`)
      .then((response) => {
        setMyList(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [urlName]);

  const filteredList = useMemo(() => {
    if (!searchTerm) return myList;
    else if (type === 'visit') {
      return myList.filter((item) => item.customer.fullName.toLowerCase().includes(searchTerm.toLowerCase()));
    } else if (type === 'activities') {
      return myList.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }, [searchTerm, myList]);

  function renderColumnBody(rowData, field, isChip, isKnob, isRate) {
    const value = field.split('.').reduce((o, i) => o[i], rowData);
    if (isChip) {
      return (
        <Chip
          // style={{
          //   backgroundColor: isActive ? '#E8F8F2' : '#F8E9E9',
          //   border: `2px solid ${isActive ? '#39B54A' : '#B92026'}`,
          //   borderRadius: '25px',
          //   padding: '0 10px',
          //   alignItems: 'center'
          // }}
          // icon={
          //   <span
          //     style={{
          //       width: '10px',
          //       height: '10px',
          //       backgroundColor: isActive ? '#39B54A' : '#B92026',
          //       borderRadius: '50%',
          //       marginLeft: '8px'
          //     }}
          //   ></span>
          // }
          label={value}
        />
      );
    } else if (isKnob) {
      return <Knob value={value} min={-365} max={365} size={50} valueColor='#EEBA00' />;
    } else if (isRate) {
      return <Rating value={value} cancel={false} />;
    } else {
      return <span>{value}</span>;
    }
  }

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>سجل {headerTitle}</span>
        <button className='tbl__addbtn'>
          <Link className='tbl__addbtn__link' to={path}>
            <img src='/images/plus.svg' alt={addBtnText} />
            <span>{addBtnText}</span>
          </Link>
        </button>
        <Toast ref={toast} />
        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              className='header__tools__input'
              placeholder='ابحث بالاسم'
              onChange={(e) => setSearchTerm(e.target.value)}
              type='search'
            />
          </span>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={filteredList}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
          {type === 'activity' && (
            <>
              <Column header='العميل' body={(rowData) => <span>{rowData.customer.fullName}</span>} />
              <Column header='الجهاز' body={(rowData) => <span>{rowData.customerDevice.device.name}</span>} />
            </>
          )}
          {columns.map((column, index) => (
            <Column
              key={index}
              header={column.header}
              body={(rowData) => renderColumnBody(rowData, column.field, column.isChip, column.isKnob, column.isRate)}
            />
          ))}
          <Column
            header='خيارات'
            body={(rowData) => (
              <Link to={`${detailsPath}/${type}/${rowData.id}/${rowData.customer.id}`}>
                <Button label={entityNameDefined} icon='pi pi-exclamation-circle' severity='secondary' text />
              </Link>
            )}
          />
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state.user.token
});

export default connect(mapStateToProps)(ActivitesScreenTable);
