import React from 'react';

import Tabs from '../../../components/Tabs/Tabs';
import Sidebar from '../../../components/Sidebar/Sidebar';
import ListEntityTable from '../../../components/Tables/ListEntityTable';
import ProductsTable from '../../../components/Tables/ProductsTable';

function Products() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title'>
          <i className='pi pi-database main__title--icon'></i> بيانات المنتجات و الخدمات
        </span>

        <div className='mt-5 pr-4 pl-4'>
          <Tabs
            headers={[
              'بيانات التقسيط',
              'بيانات المراحل',
              'بيانات الماركات',
              'بيانات الخدمات',
              'بيانات المنتجات',
              'بيانات العقود',
              'بيانات الاجهزة'
            ]}
            contents={[
              <ListEntityTable
                columns={[
                  { header: 'انواع الدفع', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='PaymentType'
                headerTitle='انواع الدفع'
                entityName='نوع الدفع'
                entityNameDefined='نوع الدفع'
                addBtnText='إضافة انواع دفع جديدة'
                type='generalList'
              />,
              <ListEntityTable
                columns={[
                  { header: 'انواع المراحل', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='ProductsStagesType'
                headerTitle='بيانات المراحل'
                entityName='بيانات مرحلة'
                entityNameDefined='بيانات المرحلة'
                addBtnText='إضافة انواع مراحل جديدة'
                type='generalList'
              />,
              <ListEntityTable
                columns={[
                  { header: 'انواع الماركات', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='Manufacturer'
                headerTitle='بيانات الماركات'
                entityName='نوع الماركة'
                entityNameDefined='نوع الماركة'
                addBtnText='إضافة انواع ماركات جديدة'
                type='generalList'
              />,
              <ListEntityTable
                columns={[
                  { header: 'انواع الخدمات', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='Service'
                headerTitle='بيانات الخدمات'
                entityName='نوع الخدمة'
                entityNameDefined='نوع الخدمة'
                addBtnText='إضافة انواع خدمات جديدة'
                type='generalList'
              />,
              <ProductsTable />,
              <ListEntityTable
                columns={[
                  { header: 'انواع العقود', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='ContractsType'
                headerTitle='بيانات العقود'
                entityName='نوع العقد'
                entityNameDefined='نوع العقد'
                addBtnText='إضافة نوع عقد جديد'
                type='generalList'
              />,
              <ListEntityTable
                columns={[
                  { header: 'انواع الاجهزة', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='CustomersDevicesType'
                headerTitle='بيانات اجهزة العملاء'
                entityName='نوع الجهاز'
                entityNameDefined='نوع الجهاز'
                addBtnText='إضافة نوع جهاز جديد'
                type='generalList'
              />
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Products;
