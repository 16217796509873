import { axiosInstance } from '../../global';

import {
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  ADD_CUSTOMER_REQUEST,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAILURE,
  EDIT_CUSTOMER_REQUEST,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILURE,
  CHANGE_CUSTOMER_STATE,
  CLEAR_CUSTOMER_STATE
} from './customerTypes';


export const fetchCustomer = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_CUSTOMER_REQUEST });
    axiosInstance
      .get(`/Customer/${id}`)
      .then((response) => {
        const customer = response.data.data;
        dispatch({ type: FETCH_CUSTOMER_SUCCESS, payload: customer });
      })
      .catch((error) => {
        dispatch({ type: FETCH_CUSTOMER_FAILURE, payload: error.message });
      });
  };
};

export const addCustomer = (customer, token) => {
  return (dispatch) => {
    dispatch({ type: ADD_CUSTOMER_REQUEST });
    axiosInstance
      .post(`/Customer`, customer, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch({ type: ADD_CUSTOMER_SUCCESS, payload: response.data.data });
      })
      .catch((error) => {
        dispatch({ type: ADD_CUSTOMER_FAILURE, payload: error.message });
      });
  };
};

export const editCustomer = (customer, token) => {
  return (dispatch) => {
    dispatch({ type: EDIT_CUSTOMER_REQUEST });
    axiosInstance
      .put(`/Customer`, customer, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch({ type: EDIT_CUSTOMER_SUCCESS, payload: response.data.data });
      })
      .catch((error) => {
        dispatch({ type: EDIT_CUSTOMER_FAILURE, payload: error.message });
      });
  };
};

export const fill_customer = (customer) => {
  return {
    type: CHANGE_CUSTOMER_STATE,
    payload: customer
  };
};

export const clearCustomerState = () => {
  return {
    type: CLEAR_CUSTOMER_STATE
  };
};
