import { connect } from 'react-redux';
import React from 'react';

import Sidebar from '../../../components/Sidebar/Sidebar';
import ListEntityTable from '../../../components/Tables/ListEntityTable';
import { login } from '../../../redux/user/userActions';

import AddInstallment from './AddInstallment';

function InstallmentList() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title'>
          <i className='pi pi-database main__title--icon'></i>المنتجات
        </span>

        <div className='mt-5 pr-4 pl-4'>
          <ListEntityTable
            columns={[
              { header: 'اسم النظام', field: 'name', isChip: false },
              { header: 'طريقة الدفع', field: 'paymentType.name', isChip: false },
              { header: 'نوع القسط', field: 'downPaymentAmountType.name', isChip: false },
              { header: 'الفائدة الشهرية', field: 'monthlyInterestPercent', isChip: false },
              { header: 'الفائدة السنوية', field: 'yearlyInterestPercent', isChip: false }
            ]}
            urlName='InstallmentsType'
            headerTitle='أنظمة التقسيط'
            entityName='نظام تقسيط'
            entityNameDefined='نظام تقسيط'
            addBtnText='إضافة أنظمة تقسيط جديدة'
            AddEntityComponent={AddInstallment}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallmentList);
