import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';

import { axiosInstance } from '../../global';
import './Stages.scss';
import CustomDropdown from '../components/Dropdown/CustomDropDown';

const StagesFromDetails = ({ token }) => {
  const navigate = useNavigate();
  const params = useParams();
  const toast = useRef(null);
  const Style = { color: 'red', padding: '0.7rem' };
  const [stageType, setStageType] = useState(null);
  const [stageItem, setStageItem] = useState(null);
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [Stage, setStage] = useState({
    deviceId: params.id,
    stageNb: params.stgNb,
    serial: '',
    stageTypeId: '',
    stageItemId: '',
    mandatoryReplacement: false,
    replacmentMonths: '',
    remarks: ''
  });

  useEffect(() => {
    axiosInstance
      .get(`/ProductsStagesType/all`)
      .then((response) => {
        const tempStageType = response.data.data.map((stageType) => {
          return {
            id: stageType.id,
            name: stageType.name
          };
        });
        setStageType(tempStageType);
      })
      .catch((error) => {}); //product stage type

    axiosInstance
      .get(`/Product/stages`)
      .then((response) => {
        const tempStageItem = response.data.data.map((stageItem) => {
          return {
            id: stageItem.id,
            name: stageItem.name
          };
        });
        setStageItem(tempStageItem);
      })
      .catch((error) => {}); //product stage item
  }, []);

  function handleAddInputChange(e) {
    setStage({ ...Stage, [e.target.name]: e.target.value });
  }
  function handleChekedChange(e) {
    if (e && e.checked !== undefined) {
      setStage({ ...Stage, mandatoryReplacement: e.checked });
    }
  }

  const acceptAdd = () => {
    axiosInstance
      .post(`/ProductsStage`, Stage)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: `تم إضافة مرحلة جديدة بنجاح!`, life: 3000 });
        navigate(-1);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: `فشلت عملية اضافة مرحلة جديدة`, life: 3000 });
      });
  };

  const confirm = () => {
    confirmDialog({
      message: 'هل أنت متأكد من أنك تريد اضافة خطة تقسيط جديدة للمنتج؟',
      header: 'اضافة خطة تقسيط جديدة',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      acceptClassName: 'p-button-success',
      acceptLabel: 'تأكيد',
      rejectLabel: 'إلغاء',
      accept: acceptAdd,
      reject: confirmDialog().hide()
    });
  };

  function HandleAddStage() {
    acceptAdd();
  }
  return (
    <div className='Details__Stage__content w-full flex flex-column gap-5'>
      {/* <Accordion activeIndex={0}>
        <AccordionTab header={'اضافة مرحلة جديدة'}>
          <div className='w-full flex gap-5'>
            <div className='flex w-full flex-column gap-4'>
              <div className='flex w-full flex-column gap-4'>
                <label className='addproduct__label'>
                  رقم المرحلة<span style={Style}>*</span>
                </label>
                <InputNumber name='stageNb' value={Stage.stageNb} disabled={true} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addproduct__label'>
                  كود المرحلة<span style={Style}>*</span>
                </label>
                <InputText type='text' name='serial' value={Stage.serial} onChange={handleAddInputChange} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addproduct__label'>
                  نوع المرحلة<span style={Style}>*</span>
                </label>
                <Dropdown
                  name='stageTypeId'
                  value={Stage.stageTypeId}
                  onChange={handleAddInputChange}
                  options={stageType}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر نوع المرحلة'
                />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addproduct__label'>
                  المرحلة<span style={Style}>*</span>
                </label>
                <Dropdown
                  name='stageItemId'
                  value={Stage.stageItemId}
                  onChange={handleAddInputChange}
                  options={stageItem}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر المرحلة'
                />
                <div className='flex w-full flex-row-revers gap-1'>
                  <Checkbox onChange={handleChekedChange} checked={Stage.mandatoryReplacement}></Checkbox>
                  <label className='addproduct__label'>يجب التغيير</label>
                </div>
              </div>

              <div className='flex w-full flex-column gap-4'>
                <label className='addproduct__label'>
                  عدد شهور تغير المرحلة<span style={Style}>*</span>
                </label>
                <Dropdown
                  name='replacmentMonths'
                  value={Stage.replacmentMonths}
                  onChange={handleAddInputChange}
                  options={months}
                  placeholder='اختر عدد شهور تغير المرحلة'
                />
              </div>

              <div className='flex w-full flex-column gap-4'>
                <label className='addproduct__label'>
                  ملاحاظات<span style={Style}>*</span>
                </label>
                <InputTextarea name='remarks' value={Stage.remarks} onChange={handleAddInputChange} rows={5} />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion> */}

      <div className='w-full flex gap-5 p-5'>
        <div className='flex w-full flex-column gap-4'>
          <Toast ref={toast} />
          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              رقم المرحلة<span style={Style}>*</span>
            </label>
            <InputNumber name='stageNb' value={Stage.stageNb} disabled={true} />
          </div>
          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              كود المرحلة<span style={Style}>*</span>
            </label>
            <InputText type='text' name='serial' value={Stage.serial} onChange={handleAddInputChange} />
          </div>
          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              نوع المرحلة<span style={Style}>*</span>
            </label>
            <CustomDropdown
              name='stageTypeId'
              value={Stage.stageTypeId}
              onChange={handleAddInputChange}
              options={stageType}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر نوع المرحلة'
            />
          </div>
          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              المرحلة<span style={Style}>*</span>
            </label>
            <CustomDropdown
              name='stageItemId'
              value={Stage.stageItemId}
              onChange={handleAddInputChange}
              options={stageItem}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر المرحلة'
            />
            <div className='flex w-full flex-row-revers gap-1'>
              <Checkbox onChange={handleChekedChange} checked={Stage.mandatoryReplacement}></Checkbox>
              <label className='addproduct__label'>يجب التغيير</label>
            </div>
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              عدد شهور تغير المرحلة<span style={Style}>*</span>
            </label>
            <CustomDropdown
              name='replacmentMonths'
              value={Stage.replacmentMonths}
              onChange={handleAddInputChange}
              options={months}
              placeholder='اختر عدد شهور تغير المرحلة'
            />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              ملاحاظات<span style={Style}>*</span>
            </label>
            <InputTextarea name='remarks' value={Stage.remarks} onChange={handleAddInputChange} rows={5} />
          </div>
        </div>
      </div>
      <div className='flex w=full flex-row-reverse gap-2 mb-4'>
        <Button className='btn__nextbtn' onClick={() => HandleAddStage()}>
          اضافة
        </Button>
        <Button className='btn__nextbtn' onClick={() => navigate(-1)}>
          العودة
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StagesFromDetails);
