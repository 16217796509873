import React, { useEffect, useRef, useState } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';

import { API_BASE_URL, axiosInstance } from '../../../global';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';

function AddEditInstallment({
  token,
  title,
  entityName,
  visible,
  setVisible,
  statuses,
  onClosePrompt,
  onAddHandler,
  onUpdateHandler,
  initialData,
  onCloseHandler
}) {
  const toast = useRef(null);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDownPaymentType, setSelectedDownPaymentType] = useState(null);
  const [entity, setEntity] = useState({
    id: 0,
    name: '',
    paymentTypeId: null,
    downPaymentAmountTypeId: null,
    downPaymentAmount: 0,
    nbOfInstalments: 0,
    monthlyInterestPercent: 0,
    yearlyInterestPercent: 0,
    statusId: null,
    remarks: ''
  });

  const options = [
    { label: 'نسبة', value: false },
    { label: 'مبلغ', value: true }
  ];

  useEffect(() => {
    axiosInstance
      .get(`/PaymentType/all`)
      .then((response) => {
        setPaymentTypes(response.data.data);
        console.log(paymentTypes);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (initialData) {
      setEntity({
        ...initialData,
        paymentTypeId: initialData.paymentType.id,
        downPaymentAmountTypeId: initialData.downPaymentAmountType.id,
        statusId: initialData.status.id
      });
      setSelectedPaymentType(initialData.paymentType.id);
      setSelectedDownPaymentType(initialData.downPaymentAmountType.id);
      setSelectedStatus(initialData.status.id);
    }
  }, [initialData, paymentTypes, statuses]);

  const handleSave = () => {
    if (initialData) {
      onUpdateHandler(entity);
    } else {
      onAddHandler(entity);
    }
    acceptClose();
  };

  const acceptClose = () => {
    setEntity({
      id: 0,
      name: '',
      paymentTypeId: null,
      downPaymentAmountTypeId: null,
      downPaymentAmount: 0,
      nbOfInstalments: 0,
      monthlyInterestPercent: 0,
      yearlyInterestPercent: 0,
      statusId: null,
      remarks: ''
    });
    onCloseHandler();
    setSelectedPaymentType(null);
    setVisible(false);
  };

  const handleClose = () => {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  };

  const handleInputChange = (e) => {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اسم النظام</label>
              <InputText name='name' value={entity.name} className='w-full' onChange={handleInputChange} />
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>طريقة الدفع</label>
              <div className='flex gap-1'>
                {paymentTypes.length > 0 && (
                  <CustomDropdown
                    name='paymentTypeId'
                    value={selectedPaymentType}
                    onChange={(event) => {
                      setEntity({ ...entity, paymentTypeId: selectedPaymentType });
                      setSelectedPaymentType(event.target.value);
                    }}
                    options={paymentTypes}
                    optionLabel='name'
                    optionValue='id'
                    className='w-full'
                  />
                )}
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>نوع قيمة المقدم</label>
              <SelectButton value={options.name} options={options} disabled={true} />
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>قيمة المقدم</label>
              <div className='flex gap-1'>
                <InputNumber
                  className='w-full'
                  value={entity.downPaymentAmount}
                  onValueChange={handleInputChange}
                  minFractionDigits={2}
                  maxFractionDigits={5}
                  min={0}
                  mode='currency'
                  currency='EGP'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>عدد الاقساط</label>
              <div className='flex gap-1'>
                <InputNumber
                  className='w-full'
                  value={entity.nbOfInstalments}
                  onValueChange={handleInputChange}
                  min={0}
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر حالة {entityName}</label>
              <div className='flex'>
                <CustomDropdown
                  value={selectedStatus}
                  onChange={handleInputChange}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر الحالة'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea name='remarks' value={entity.remarks} className='w-full' onChange={handleInputChange} />
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button className='modals__btn' onClick={handleSave} disabled={!selectedPaymentType}>
              {initialData ? 'تحديث' : `اضافة ${entityName}`}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditInstallment);
