import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import { axiosInstance } from '../../global';
import AddEmployeeDailyAssignment from '../Modals/AddEmployeeDailyAssignment';
import '../../style/Tables.scss';

function EmployeeDailyAssignmentTable({ userId }) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [locations, setLocations] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDailyAssign, setSelectedDailyAssign] = useState(null);
  const [addEmpDailyAssignVisible, setAddEmpDailyAssignVisible] = useState(false);

  function fetchItems() {
    axiosInstance
      .get(`/Employee/all`)
      .then((response) => {
        const tempEmployees = response.data.data.map((employee) => {
          return {
            id: employee.id,
            name: employee.name
          };
        });
        setEmployees(tempEmployees);
        setLoading(false);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/Location/all`)
      .then((response) => {
        const tempLocations = response.data.data.map((location) => {
          return {
            id: location.id,
            name: location.name
          };
        });
        setLocations(tempLocations);
        setLoading(false);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    if (selectedEmployee) {
      axiosInstance
        .get(`/EmployeesDailyLocationsAssignment/allByEmployeeId/${selectedEmployee}`)
        .then((response) => {
          setItems(response.data.data);
          setLoading(false);
        })
        .catch((error) => {});
    } else {
      setItems([]);
    }
  }, [selectedEmployee]);

  function handleSearchNameChange(e) {}

  function handleEmployeeChange(e) {
    setSelectedEmployee(e.value);
    setLoading(true);
  }

  function handleAddDailyAssign(item) {
    axiosInstance
      .post(`/EmployeesDailyLocationsAssignment`, item)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم إضافة المواقع للفني', life: 3000 });
        setSelectedEmployee(null);
        setAddEmpDailyAssignVisible(false);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء إضافة المواقع للفني', life: 3000 });
      });
  }

  function handleShowDeleteAssign(id) {
    setSelectedDailyAssign(id);
    confirmDialog({
      header: 'هل تريد حذف الموقع من جدول اعمال الفني ؟',
      accept: handleDeleteDailyAssign,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      tagKey: 5
    });
  }

  function handleDeleteDailyAssign() {
    axiosInstance
      .delete(`/EmployeesDailyLocationsAssignment/${selectedDailyAssign}`)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم حذف الموقع', life: 3000 });
        setSelectedEmployee(null);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء حذف الموقع', life: 3000 });
      });
  }

  return (
    <div className='tbl'>
      <div className='flex flex-column gap-2 align-items-start mb-4'>
        <span className='text-lg font-bold'>بحث عن الموظف بالاسم</span>
        <Dropdown
          value={selectedEmployee}
          options={employees}
          onChange={handleEmployeeChange}
          optionValue='id'
          optionLabel='name'
          display='chip'
          className='w-5 text-xl'
          filter
        />
      </div>
      <div className='tbl__header'>
        <span className='header__title'>قائمة مجموعات العمل</span>
        <button className='tbl__addbtn' onClick={() => setAddEmpDailyAssignVisible(true)} disabled={!selectedEmployee}>
          <img src='/images/plus.svg' alt='إضافة جدول اعمال' />
          إضافة جدول اعمال
        </button>

        <Toast ref={toast} />
        <ConfirmDialog tagKey={5} />
        <AddEmployeeDailyAssignment
          visible={addEmpDailyAssignVisible}
          setVisible={setAddEmpDailyAssignVisible}
          locations={locations}
          onAddHandler={handleAddDailyAssign}
          employeeId={selectedEmployee}
        />

        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              className='header__tools__input'
              placeholder='ابحث بالاسم'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={items}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
          emptyMessage={
            <div className='w-full flex justify-content-center'>
              <span className='text-lg font-bold'>
                {selectedEmployee
                  ? 'لم تقم بإضافة أي جدول اعمال لهذا الموظف حتى الآن ،قم بالإضافة الآن'
                  : 'قم باختيار موظف لعرض جدول اعماله الخاص'}
              </span>
            </div>
          }
        >
          <Column header='المواقع' field='location.name' />
          <Column header='تاريخ العمل في الموقع' field='date' />
          <Column header='ملاحظات' field='remarks' />

          <Column
            header='خيارات'
            body={(rowData) => (
              <Button
                label='حذف الموقع'
                icon='pi pi-exclamation-circle'
                onClick={() => handleShowDeleteAssign(rowData.id)}
                severity='danger'
                text
              />
            )}
          />
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    userId: state.user.user.id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDailyAssignmentTable);
