export const FETCH_CONTRACT_REQUEST = 'FETCH_CONTRACT_REQUEST';
export const FETCH_CONTRACT_SUCCESS = 'FETCH_CONTRACT_SUCCESS';
export const FETCH_CONTRACT_FAILURE = 'FETCH_CONTRACT_FAILURE';

export const ADD_CONTRACT_REQUEST = 'ADD_CONTRACT_REQUEST';
export const ADD_CONTRACT_SUCCESS = 'ADD_CONTRACT_SUCCESS';
export const ADD_CONTRACT_FAILURE = 'ADD_CONTRACT_FAILURE';

export const EDIT_CONTRACT_REQUEST = 'EDIT_CONTRACT_REQUEST';
export const EDIT_CONTRACT_SUCCESS = 'EDIT_CONTRACT_SUCCESS';
export const EDIT_CONTRACT_FAILURE = 'EDIT_CONTRACT_FAILURE';

export const CHANGE_CONTRACT_STATE = 'CHANGE_CONTRACT_STATE';
export const CLEAR_CONTRACT_STATE = 'CLEAR_CONTRACT_STATE';

