import Sidebar from '../../../components/Sidebar/Sidebar';
import Tabs from '../../../components/Tabs/Tabs';
import ListEntityTable from '../../../components/Tables/ListEntityTable';
import { AddEditDestinationsSources } from '../Warehouse/AddEditDestinationsSources';
import React from 'react';
import { AddEditEndPointMatrix } from './AddEditEndPointMatrix';

export function SystemParamsList() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full addFullHeight'>
        <span className='main__title'>
          <i className='pi pi-database main__title--icon'></i>System Params
        </span>
        <div className='mt-5 pr-4 pl-4'>
          <Tabs
            headers={['Status Matrix', 'Destinations Sources']}
            contents={[
              <ListEntityTable
                columns={[
                  { header: 'endPoint', field: 'endpointName', isChip: true },
                  { header: 'entity', field: 'entityName', isChip: true }
                ]}
                urlName='EndpointEntitiesMatrix'
                headerTitle='EndPoint Entites'
                entityName='EndPoint Entites Matrix'
                entityNameDefined='EndPoint Entites Matrix'
                addBtnText='Add EndPoint Entites Matrix'
                AddEntityComponent={AddEditEndPointMatrix}
                type={'generalList'}
              />,
              <ListEntityTable
                columns={[
                  { header: 'TransTypeName', field: 'name', isChip: true },
                  { header: 'DestinationSource', field: 'direction', isChip: true },
                  { header: 'DefaultApprovalStatus', field: 'allowFirstReceiving', isChip: true, isBoolean: true },
                  { header: 'status', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='DestinationsSource'
                headerTitle='Destinations Sources'
                entityName='Destinations Sources'
                entityNameDefined='Destinations Sources'
                addBtnText='Add Destinations Sources'
                AddEntityComponent={AddEditDestinationsSources}
                type={'generalList'}
              />
            ]}
          />
        </div>
      </div>
    </div>
  );
}
