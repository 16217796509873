import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { Link } from 'react-router-dom';

import { API_BASE_URL, axiosInstance } from '../../../global';
import { fill_product } from '../../../redux/product/productActions';
import './AddSparesDetails.scss';

function AddSpares1({ token, fill_product, setCheck }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const [statuses, setStatuses] = useState(null);
  const [manufacturer, setManufacturer] = useState(null);
  const [productCategory, setProductCategory] = useState(null);
  const [brands, setBrands] = useState(null);
  const [country, setCountry] = useState(null);
  const [product, setproduct] = useState({
    code: '',
    skuCode: '',
    name: '',
    productCategory: '',
    brand: '',
    manufacturer: '',
    nbOfStages: 0,
    status: '',
    countryOfOrigin: '',
    remarks: '',
    description: '',
    installmentTypesIds: []
  });

  useEffect(() => {
    axiosInstance
      .get(`/ProductsCategory/all`)
      .then((response) => {
        const tempCategory = response.data.data.map((category) => {
          return {
            id: category.id,
            name: category.name
          };
        });
        setProductCategory(tempCategory);
      })
      .catch((error) => {}); // product category

    axiosInstance
      .get(`/Status/dropdown`)
      .then((response) => {
        const tempStatus = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setStatuses(tempStatus);
      })
      .catch((error) => {}); // product status

    axiosInstance
      .get(`/Manufacturer/all`)
      .then((response) => {
        const tempManufacturer = response.data.data.map((manufacturer) => {
          return {
            id: manufacturer.id,
            name: manufacturer.name
          };
        });
        setManufacturer(tempManufacturer);
      })
      .catch((error) => {}); // product manufacturer

    axiosInstance
      .get(`/Brand/all`)
      .then((response) => {
        const tempBrand = response.data.data.map((brand) => {
          return {
            id: brand.id,
            name: brand.name
          };
        });
        setBrands(tempBrand);
      })
      .catch((error) => {}); // product brand

    axiosInstance
      .get(`/Country/all`)
      .then((response) => {
        const tempCountry = response.data.data.map((country) => {
          return {
            id: country.id,
            name: country.name
          };
        });
        setCountry(tempCountry);
      })
      .catch((error) => {}); // product country of orgin
  }, []);

  useEffect(() => {
    // console.log(product);
    fill_product(product);
    if (
      Object.values(product).filter(
        (val, index) =>
          (val === '' || !val) &&
          Object.keys(product)[index] !== 'remarks' &&
          Object.keys(product)[index] !== 'code' &&
          Object.keys(product)[index] !== 'installmentTypesIds' &&
          Object.keys(product)[index] !== 'nbOfStages'
      ).length
    ) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [product]);

  function handleAddInputChange(e) {
    setproduct({ ...product, [e.target.name]: e.target.value || e.value });
  }

  function handleAddInputNumberChange(e) {
    setproduct({ ...product, nbOfStages: e.target.value || e.value });
  }
  return (
    <div className='product'>
      <div className='product__content'>
        {/* page right content============================================== */}
        <div className='product__content__right'>
          <span className='Title'>عن القطعة</span>
          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>كود القطعة</label>
            <InputText name='code' value={product.code} onChange={handleAddInputChange} disabled={true} />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              رقم القطعة / SKU<span style={Style}>*</span>
            </label>
            <InputText name='skuCode' value={product.skuCode} onChange={handleAddInputChange} />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              اسم القطعة بالعربية<span style={Style}>*</span>
            </label>
            <InputText name='name' value={product.name} onChange={handleAddInputChange} />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              وصف القطعة بالعربية<span style={Style}>*</span>
            </label>
            <InputTextarea name='description' value={product.description} onChange={handleAddInputChange} rows={5} />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              فئة القطعة<span style={Style}>*</span>
            </label>
            <Dropdown
              name='productCategory'
              value={product.productCategory}
              onChange={handleAddInputChange}
              options={productCategory}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر الفئة'
            />
            <div className='flex flex-row gap-2'>
              <button className='addbtn'>+</button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل فئة جديدة
              </Link>
            </div>
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              تصنيف القطعة <span style={Style}>*</span>
            </label>
            <Dropdown
              name='manufacturer'
              value={product.manufacturer}
              onChange={handleAddInputChange}
              options={manufacturer}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر التصنيف'
            />
            <div className='flex flex-row gap-2'>
              <button className='addbtn'>+</button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل تصنيف جديدة
              </Link>
            </div>
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              الماركة<span style={Style}>*</span>
            </label>
            <Dropdown
              name='brand'
              value={product.brand}
              onChange={handleAddInputChange}
              options={brands}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر الماركة'
            />
            <div className='flex flex-row gap-2'>
              <button className='addbtn'>+</button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل ماركة جديدة
              </Link>
            </div>
          </div>
          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              بلد المنشأ<span style={Style}>*</span>
            </label>
            <Dropdown
              name='countryOfOrigin'
              value={product.countryOfOrigin}
              onChange={handleAddInputChange}
              options={country}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر بلد المنشأ'
            />
            <div className='flex flex-row gap-2'>
              <button className='addbtn'>+</button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل بلد منشأ جديدة
              </Link>
            </div>
          </div>
        </div>

        {/* page left content============================================== */}

        <div className='product__content__left'>
          <span className='Title'>حالة القطعة</span>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              حالة القطعة<span style={Style}>*</span>
            </label>
            <Dropdown
              name='status'
              value={product.status}
              onChange={handleAddInputChange}
              options={statuses}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر حالة الحساب'
            />
            <div className='flex flex-row gap-2'>
              <button className='addbtn'>+</button>
              <Link to={''} className='addtitle'>
                اضف حالات للمنتج جديدة
              </Link>
            </div>
          </div>
          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>ملاحاظات</label>
            <InputTextarea name='remarks' value={product.remarks} onChange={handleAddInputChange} rows={5} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_product: (product) => dispatch(fill_product(product))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSpares1);
