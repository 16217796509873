import React, { useMemo, useRef } from 'react';
import { Toast } from 'primereact/toast';
import * as yup from 'yup';

import Sidebar from '../../../components/Sidebar/Sidebar';
import GenericPage from '../../../components/components/GenericPage/GenericPage';
import useModal from '../../../hooks/useModal';
import GenericModal from '../../../components/components/Modal/GenericModal';
import { axiosInstance } from '../../../global';

import CustomerComplaintForm from './CustomerComplaintForm';

const getEmployeeColumns = () => [
  { header: 'كود الشكوى', field: 'code', isChip: false },
  { header: 'العميل', field: 'customerFullName', isChip: false },
  { header: 'جهاز العميل', field: 'customerDeviceDeviceName', isChip: false },
  { header: 'تصنيف الشكوى', field: 'complaintCategoryName', isChip: false },
  { header: 'تاريخ الشكوى', field: 'complaintDate', isChip: false },
  { header: 'حالة الشكوى', field: 'statusName', isChip: true }
];

const validationSchema = yup.object().shape({
  customerId: yup.string().required('اسم العميل مطلوب'),
  customerDeviceId: yup.string().required('جهاز العميل مطلوب'),
  complaintCategoryId: yup.string().required('تصنيف الشكوى مطلوب'),
  complaintDate: yup.date().required('تاريخ الشكوى مطلوب'),
  remarks: yup.string().required('تفاصيل الشكوى مطلوبة'),
  statusId: yup.string().required('حالة الشكوى مطلوبة')
});

function CustomerComplaints() {
  const columns = useMemo(getEmployeeColumns, []);
  const apiUrl = '/CustomersComplaint/list';
  const addUrl = '/CustomersComplaint';
  const toast = useRef(null);

  const { isVisible, openModal, closeModal, control, handleSubmit, errors, setValue, loading, setLoading, watch } =
    useModal({}, addUrl, () => {}, validationSchema, toast);

  const handleEdit = (id: string) => {
    openModal(); // Open the modal immediately
    setLoading(true);
    axiosInstance
      .get(`${addUrl}/${id}`)
      .then((response) => {
        const data = response.data.data;
        setValue('id', data.id);
        setValue('code', data.code);
        setValue('customerId', data.customer.id);
        setValue('customerDeviceId', data.customerDevice.id);
        setValue('complaintCategoryId', data.complaintCategoryObject.id);
        setValue('complaintDate', new Date(data.complaintDate));
        setValue('issuingDate', new Date(data.issuingDate));
        setValue('remarks', data.remarks);
        setValue('statusId', data.status.id);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const tabs = [
    {
      label: 'شكاوى العملاء',
      tableHeader: 'شكاوى العملاء',
      entityName: 'الشكوى',
      dataKey: 'tab1',
      columns: columns,
      apiUrl: apiUrl,
      isHidden: true,
      edit: true,
      Screentype: '',
      emptyStateUrl: addUrl,
      rowOnClick: (rowData: any) => {
        handleEdit(rowData.id);
      },
      extraParams: {},
      emptyStateButtonClick: undefined
    }
  ];

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <GenericPage
        tabs={tabs}
        headerTitle='العملاء'
        headerIcon='/images/alt.svg'
        headerButtons={[
          {
            label: 'إضافة شكوى جديدة',
            onClick: () => openModal()
          }
        ]}
      />

      <GenericModal
        headerTitle={'إضافة شكوى جديدة'}
        isVisible={isVisible}
        onHide={closeModal}
        handleSubmit={handleSubmit}
        toast={toast}
        loading={loading}
      >
        <CustomerComplaintForm control={control} errors={errors} setValue={setValue} watch={watch} />
      </GenericModal>
    </div>
  );
}

export default CustomerComplaints;
