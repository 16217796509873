import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chip } from 'primereact/chip';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import Tabs from '../../../components/Tabs/Tabs';
import { API_BASE_URL, axiosInstance } from '../../../global';
import Sidebar from '../../../components/Sidebar/Sidebar';
import PersonalData from '../../../components/EmployeeDetails/PersonalData';
import EmployeeSalaryTable from '../../../components/Tables/EmployeeSalaryTable';
import EmployeeVacationTable from '../../../components/Tables/EmployeeVacationTable';
import EmployeeCalendar from '../../../components/EmployeeCalendar/EmployeeCalendar';
import './Details.scss';
import AddPayroll from '../../../components/Modals/AddPayroll';
import AddVacationRequest from '../../../components/Modals/AddVacationRequest';
import PageHeader from '../../../components/components/Header/PageHeader';

function Details({ token }) {
  const toast = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState();
  const [loading, setLoading] = useState(true);
  const [statuses, setStatuses] = useState([]);
  const [addPayrollModalVisible, setAddPayrollModalVisible] = useState(false);
  const [addVacationModalVisible, setAddVacationModalVisible] = useState(false);

  useEffect(() => {
    axiosInstance
      .get(`/Employee/${params.id}`)
      .then((response) => {
        setEmployee(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/Status/dropdown`)
      .then((response) => {
        setStatuses(response.data.data);
      })
      .catch((error) => {});
  }, []);

  function showConfirmBlockEmployee(e) {
    confirmDialog({
      message: 'هل تريد تأكيد حظر الموظف؟',
      header: 'تأكيد حظر الموظف',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept: handleBlockEmployee,
      draggable: false,
      resizable: false,
      tagKey: 5
    });
  }

  function handleBlockEmployee(e) {
    axiosInstance
      .put(
        `/Employee/block/${params.id}`,
        {
          userId: params.id,
          status: 'blocked'
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'تم حظر الموظف',
          detail: '',
          life: 3000
        });
        setTimeout(() => navigate('/employees'), 3000);
      })
      .catch(() => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء حظر الموظف',
          detail: '',
          life: 3000
        });
      });
  }

  function showConfirmDeleteEmployee(e) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف الموظف؟',
      header: 'تأكيد حذف الموظف',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept: handleDeleteEmployee,
      draggable: false,
      resizable: false,
      tagKey: 5
    });
  }

  function handleDeleteEmployee(e) {
    axiosInstance
      .delete(`/Employee/${params.id}`)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'تم حذف الموظف',
          detail: '',
          life: 3000
        });
        setTimeout(() => navigate('/employees'), 3000);
      })
      .catch(() => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء حذف الموظف',
          detail: '',
          life: 3000
        });
      });
  }

  function showConfirmEndSession(e) {
    confirmDialog({
      message: 'هل تريد تأكيد انهاء الاتصال الاجباري',
      header: 'تأكيد انهاء الاتصال الاجباري',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      handleEndSession,
      draggable: false,
      resizable: false
    });
  }

  function handleEndSession(e) {
    // axios
    //   .delete(`/Employee/${params.id}`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`
    //     }
    //   })
    //   .then(() => {
    //     toast.current.show({
    //       severity: 'success',
    //       summary: 'تم حذف الموظف',
    //       detail: '',
    //       life: 3000
    //     });
    //     setTimeout(() => navigate('/employees'), 3000);
    //   })
    //   .catch(() => {
    //     toast.current.show({
    //       severity: 'error',
    //       summary: 'حدث خطأ اثناء حذف الموظف',
    //       detail: '',
    //       life: 3000
    //     });
    //   });
  }

  function handleAddPayroll(payroll) {
    axiosInstance
      .post(`/EmployeesPayrollItem`, payroll)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم اضافة مفردات المرتب', life: 3000 });
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء اضافة مفردات المرتب', life: 3000 });
      });
  }

  function handleAddVacation(vacation) {
    axiosInstance
      .post(`/VacationsRequest`, vacation)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم اضافة الاجازة', life: 3000 });
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء اضافة الاجازة',
          detail: error.response.data.message,
          life: 3000
        });
      });
  }

  return (
    <div className='flex w-full details-tab'>
      <Sidebar />
      <main className='details--wrapper'>
        <PageHeader
          icon={'/images/back-arrow.svg'}
          extraStartContent={<Chip style={{ cursor: 'default' }} label={employee?.status.name} />}
          buttons={[]}
          headerTitle={'تفاصيل الموظف'}
          iconOnClick={() => {
            navigate(-1);
          }}
        />
        <main className='details'>
          <div className='details__tabs'>
            <Tabs
              headers={['بيانات الموظف', 'نشاطات اضافات مفردات المرتب', 'نشاطات الاجازات', 'جدول اعمال الموظف']}
              contents={[
                <PersonalData employee={employee} />,
                <EmployeeSalaryTable />,
                <EmployeeVacationTable />,
                <EmployeeCalendar />
              ]}
            />
            {loading ? (
              <ProgressSpinner />
            ) : (
              <div className='sidepanel'>
                <Toast ref={toast} />
                <ConfirmDialog tagKey={5} />
                <AddPayroll
                  visible={addPayrollModalVisible}
                  setVisible={setAddPayrollModalVisible}
                  statuses={statuses}
                  entityName='مقرد مرتب'
                  title='اضافة مفردات مرتب'
                  onClosePrompt='هل تريد الغاء عملية اضافة مفردات مرتب ؟'
                  employeeId={params.id}
                  onAddHandler={handleAddPayroll}
                />
                <AddVacationRequest
                  visible={addVacationModalVisible}
                  setVisible={setAddVacationModalVisible}
                  statuses={statuses}
                  entityName='اجازة'
                  title='اضافة اجازة للموظف'
                  onClosePrompt='هل تريد الغاء عملية اضافة اجازة للموظف ؟'
                  employeeId={params.id}
                  onAddHandler={handleAddVacation}
                />
                <div className='sidepanel__data'>
                  <img src='/images/employee2.png' alt='' className='sidepanel__data__img' />
                  <div className='flex flex-column gap-2'>
                    <span className='sidepanel__data__name'>{employee.user.userName}</span>
                    <Chip label={employee.position.name} className='sidepanel__data__role' />
                    <span className='sidepanel__data__code'>الرقم المرجعي: #{employee.code}</span>
                    <Chip label={employee.status.name} className='sidepanel__data__status' />
                  </div>
                </div>
                <div className='w-full w-full--auth-container'>
                  <div className='sidepanel__auth-container'>
                    <div className='flex flex-column gap-4'>
                      <span className='category'>تصنيف الموظف</span>
                      <span className='category'>الموقع العمل</span>
                    </div>
                    <div className='flex flex-column gap-4'>
                      <span className='auth'>موظف مستوى أ</span>
                      <span className='auth'>داخل الشركة (إدارى)</span>
                    </div>
                  </div>
                </div>
                <div className='w-full w-full--auth-container'>
                  <div className='sidepanel__actions'>
                    <Button className='p-0' text onClick={() => setAddPayrollModalVisible(true)}>
                      <span className='sidepanel__action'>
                        <img src='/images/label.svg' /> اضافة مفردات مرتب
                      </span>
                    </Button>
                    <Divider />
                    <Button className='p-0' text onClick={() => setAddVacationModalVisible(true)}>
                      <span className='sidepanel__action'>
                        <img src='/images/airplane.svg' /> إضافة إجازات
                      </span>
                    </Button>
                    <Divider />
                    <Link to={`/employees/edit/${params.id}`}>
                      <span className='sidepanel__action'>
                        <img src='/images/security.svg' /> تعديل بيانات الموظف
                      </span>
                    </Link>
                    <Divider />
                    <Button className='p-0' text onClick={showConfirmBlockEmployee}>
                      <span className='sidepanel__action'>
                        <img src='/images/block.svg' /> حظر المستخدم
                      </span>
                    </Button>
                    <Divider />
                    <Button className='p-0' severity='danger' text onClick={showConfirmDeleteEmployee}>
                      <span className='sidepanel__action sidepanel__action--btn'>
                        <i className='pi pi-times' /> حذف المستخدم نهائياً
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </main>
    </div>
  );
}

export default Details;
