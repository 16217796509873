import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { connect } from 'react-redux';
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import './Modals.scss';
import { InputNumber } from 'primereact/inputnumber';

function EditPaymentTerm({
  title,
  entityName,
  visible,
  setVisible,
  statuses,
  onClosePrompt,
  onAddHandler,
  onUpdateHandler,
	onDeleteHandler,
  initialData
}) {
  const toast = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDiscountType, setSelectedDiscountType] = useState(1);
  const [entity, setEntity] = useState({});

  useEffect(() => {
    if (initialData) {
      setSelectedStatus(initialData.status?.id);
      setSelectedDiscountType(initialData.discountPercentage ? 1 : 2);
      setEntity(initialData);
    }
  }, [initialData, statuses]);

  function acceptClose() {
    setEntity({});
    setSelectedStatus(null);
    setVisible(false);
  }

  const handleSave = () => {
    if (initialData) {
      onUpdateHandler(entity);
    } else {
      onAddHandler(entity);
    }

    acceptClose();
  };

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      tagKey: 3
    });
  }

	function handleDelete(entity) {
		onDeleteHandler(entity);
		acceptClose();
	}

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  function handleStatusChange(e) {
    setSelectedStatus(e.value);
    setEntity({ ...entity, statusId: e.value });
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog tagKey={3} />
      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اسم شرط الدفع</label>
              <InputText name='name' value={entity.name} className='w-full' onChange={handleInputChange} />
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>عدد الايام</label>
              <InputText name='days' value={entity.days} className='w-full' onChange={handleInputChange} />
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>الخصم</label>
              <SelectButton
                value={selectedDiscountType}
                onChange={(e) => setSelectedDiscountType(e.value)}
                options={[
                  { label: 'نسبة', value: 1 },
                  { label: 'مبلغ', value: 2 }
                ]}
              />
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>قيمة الخصم</label>
              <InputText
                name={selectedDiscountType === 1 ? 'discountPercentage' : 'discountAmount'}
                value={selectedDiscountType === 1 ? entity.discountPercentage : entity.discountAmount}
                className='w-full'
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>الحالة</label>
              <div className='flex'>
                <Dropdown
                  name='statusId'
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  placeholder='اختر الحالة'
                  className='w-full'
                />
              </div>
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea name='remarks' value={entity.remarks} className='w-full' onChange={handleInputChange} />
            </div>
          </div>

          <div className='flex justify-content-between'>
            <Button
              link
              label='حذف'
              style={{ color: '#E24A34' }}
              severity='danger'
              onClick={(event) => handleDelete(entity)}
            />
            <div className='flex justify-content-end'>
              <Button label='إلغاء' onClick={handleClose} size='small' outlined />
              <button className='modals__btn' onClick={handleSave}>
                {initialData ? 'تحديث' : `اضافة ${entityName}`}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPaymentTerm);
