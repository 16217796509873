import { connect } from 'react-redux';
import React from 'react';

import Sidebar from '../../../components/Sidebar/Sidebar';
import Tabs from '../../../components/Tabs/Tabs';
import { login } from '../../../redux/user/userActions';
import ProductServicesListTable from '../../../components/Tables/ProductServicesListTable';
function ServicesList() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title'>
          <i className='pi pi-database main__title--icon'></i>المنتجات
        </span>

        <div className='mt-5 pr-4 pl-4'>
          <Tabs
            headers={['قائمة الخدمات']}
            contents={[
              <ProductServicesListTable
                urlName='ServicesCostsListMaster'
                headerTitle='قائمة خدمات المنتج'
                addBtnText='إضافة خدمة جديدة'
              />
            ]}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) => dispatch(login(credentials))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesList);
