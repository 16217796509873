export const FETCH_VISIT_REQUEST = 'FETCH_VISIT_REQUEST';
export const FETCH_VISIT_SUCCESS = 'FETCH_VISIT_SUCCESS';
export const FETCH_VISIT_FAILURE = 'FETCH_VISIT_FAILURE';

export const ADD_VISIT_REQUEST = 'ADD_VISIT_REQUEST';
export const ADD_VISIT_SUCCESS = 'ADD_VISIT_SUCCESS';
export const ADD_VISIT_FAILURE = 'ADD_VISIT_FAILURE';

export const EDIT_VISIT_REQUEST = 'EDIT_VISIT_REQUEST';
export const EDIT_VISIT_SUCCESS = 'EDIT_VISIT_SUCCESS';
export const EDIT_VISIT_FAILURE = 'EDIT_VISIT_FAILURE';

export const CHANGE_VISIT_STATE = 'CHANGE_VISIT_STATE';
export const CLEAR_VISIT_STATE = 'CLEAR_VISIT_STATE';



export const FETCH_INSTALLMENTVISIT_REQUEST = 'FETCH_INSTALLMENTVISIT_REQUEST';
export const FETCH_INSTALLMENTVISIT_SUCCESS = 'FETCH_INSTALLMENTVISIT_SUCCESS';
export const FETCH_INSTALLMENTVISIT_FAILURE = 'FETCH_INSTALLMENTVISIT_FAILURE';

export const ADD_INSTALLMENTVISIT_REQUEST = 'ADD_INSTALLMENTVISIT_REQUEST';
export const ADD_INSTALLMENTVISIT_SUCCESS = 'ADD_INSTALLMENTVISIT_SUCCESS';
export const ADD_INSTALLMENTVISIT_FAILURE = 'ADD_INSTALLMENTVISIT_FAILURE';

export const EDIT_INSTALLMENTVISIT_REQUEST = 'EDIT_INSTALLMENTVISIT_REQUEST';
export const EDIT_INSTALLMENTVISIT_SUCCESS = 'EDIT_INSTALLMENTVISIT_SUCCESS';
export const EDIT_INSTALLMENTVISIT_FAILURE = 'EDIT_INSTALLMENTVISIT_FAILURE';

export const CHANGE_INSTALLMENTVISIT_STATE = 'CHANGE_INSTALLMENTVISIT_STATE';
export const CHANGE_INSTALLMENTVISIT_TEMP_STATE = 'CHANGE_INSTALLMENTVISIT_TEMP_STATE';

export const CLEAR_INSTALLMENTVISIT_STATE = 'CLEAR_INSTALLMENTVISIT_STATE';





export const FETCH_STAGEVISIT_REQUEST = 'FETCH_STAGEVISIT_REQUEST';
export const FETCH_STAGEVISIT_SUCCESS = 'FETCH_STAGEVISIT_SUCCESS';
export const FETCH_STAGEVISIT_FAILURE = 'FETCH_STAGEVISIT_FAILURE';

export const ADD_STAGEVISIT_REQUEST = 'ADD_STAGEVISIT_REQUEST';
export const ADD_STAGEVISIT_SUCCESS = 'ADD_STAGEVISIT_SUCCESS';
export const ADD_STAGEVISIT_FAILURE = 'ADD_STAGEVISIT_FAILURE';

export const EDIT_STAGEVISIT_REQUEST = 'EDIT_STAGEVISIT_REQUEST';
export const EDIT_STAGEVISIT_SUCCESS = 'EDIT_STAGEVISIT_SUCCESS';
export const EDIT_STAGEVISIT_FAILURE = 'EDIT_STAGEVISIT_FAILURE';

export const CHANGE_STAGEVISIT_STATE = 'CHANGE_STAGEVISIT_STATE';
export const CHANGE_STAGEVISIT_TEMP_STATE = 'CHANGE_STAGEVISIT_TEMP_STATE';
export const CLEAR_STAGEVISIT_STATE = 'CLEAR_STAGEVISIT_STATE';




export const FETCH_FAULTSVISIT_REQUEST = 'FETCH_FAULTSVISIT_REQUEST';
export const FETCH_FAULTSVISIT_SUCCESS = 'FETCH_FAULTSVISIT_SUCCESS';
export const FETCH_FAULTSVISIT_FAILURE = 'FETCH_FAULTSVISIT_FAILURE';

export const ADD_FAULTSVISIT_REQUEST = 'ADD_FAULTSVISIT_REQUEST';
export const ADD_FAULTSVISIT_SUCCESS = 'ADD_FAULTSVISIT_SUCCESS';
export const ADD_FAULTSVISIT_FAILURE = 'ADD_FAULTSVISIT_FAILURE';

export const EDIT_FAULTSVISIT_REQUEST = 'EDIT_FAULTSVISIT_REQUEST';
export const EDIT_FAULTSVISIT_SUCCESS = 'EDIT_FAULTSVISIT_SUCCESS';
export const EDIT_FAULTSVISIT_FAILURE = 'EDIT_FAULTSVISIT_FAILURE';

export const CHANGE_FAULTSVISIT_STATE = 'CHANGE_FAULTSVISIT_STATE';
export const CHANGE_FAULTSVISIT_TEMP_STATE = 'CHANGE_FAULTSVISIT_TEMP_STATE';
export const CLEAR_FAULTSVISIT_STATE = 'CLEAR_FAULTSVISIT_STATE';




export const FETCH_REQITEMSVISIT_REQUEST = 'FETCH_REQITEMSVISIT_REQUEST';
export const FETCH_REQITEMSVISIT_SUCCESS = 'FETCH_REQITEMSVISIT_SUCCESS';
export const FETCH_REQITEMSVISIT_FAILURE = 'FETCH_REQITEMSVISIT_FAILURE';

export const ADD_REQITEMSVISIT_REQUEST = 'ADD_REQITEMSVISIT_REQUEST';
export const ADD_REQITEMSVISIT_SUCCESS = 'ADD_REQITEMSVISIT_SUCCESS';
export const ADD_REQITEMSVISIT_FAILURE = 'ADD_REQITEMSVISIT_FAILURE';

export const EDIT_REQITEMSVISIT_REQUEST = 'EDIT_REQITEMSVISIT_REQUEST';
export const EDIT_REQITEMSVISIT_SUCCESS = 'EDIT_REQITEMSVISIT_SUCCESS';
export const EDIT_REQITEMSVISIT_FAILURE = 'EDIT_REQITEMSVISIT_FAILURE';

export const CHANGE_REQITEMSVISIT_STATE = 'CHANGE_REQITEMSVISIT_STATE';
export const CHANGE_REQITEMSVISIT_TEMP_STATE = 'CHANGE_REQITEMSVISIT_TEMP_STATE';
export const CLEAR_REQITEMSVISIT_STATE = 'CLEAR_REQITEMSVISIT_STATE';



export const FETCH_SERVICEVISIT_REQUEST = 'FETCH_SERVICEVISIT_REQUEST';
export const FETCH_SERVICEVISIT_SUCCESS = 'FETCH_SERVICEVISIT_SUCCESS';
export const FETCH_SERVICEVISIT_FAILURE = 'FETCH_SERVICEVISIT_FAILURE';

export const ADD_SERVICEVISIT_REQUEST = 'ADD_SERVICEVISIT_REQUEST';
export const ADD_SERVICEVISIT_SUCCESS = 'ADD_SERVICEVISIT_SUCCESS';
export const ADD_SERVICEVISIT_FAILURE = 'ADD_SERVICEVISIT_FAILURE';

export const EDIT_SERVICEVISIT_REQUEST = 'EDIT_SERVICEVISIT_REQUEST';
export const EDIT_SERVICEVISIT_SUCCESS = 'EDIT_SERVICEVISIT_SUCCESS';
export const EDIT_SERVICEVISIT_FAILURE = 'EDIT_SERVICEVISIT_FAILURE';

export const CHANGE_SERVICEVISIT_STATE = 'CHANGE_SERVICEVISIT_STATE';
export const CHANGE_SERVICEVISIT_TEMP_STATE = 'CHANGE_SERVICEVISIT_TEMP_STATE';
export const CLEAR_SERVICEVISIT_STATE = 'CLEAR_SERVICEVISIT_STATE';


export const CHANGE_VISITCOST_STATE = 'CHANGE_VISITCOST_STATE';
