import {
  FETCH_USER,
  USER_LOGOUT,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_RESETPASSWORD_REQUEST,
  USER_RESETPASSWORD_SUCCESS,
  USER_RESETPASSWORD_FAILURE
} from './userTypes';
import { axiosInstance } from '../../global';


export const login = (credintials) => {
  return (dispatch) => {
    dispatch(user_login_request());
    axiosInstance
      .post(`/auth/login`, credintials)
      .then((responseLogin) => {
        const { access_token, refresh_token } = responseLogin.data.data;
        localStorage.setItem('token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
        axiosInstance
          .get(`/auth/me`)
          .then((responseFetchUser) => {
            dispatch(user_login_success(access_token));
            dispatch(fetch_user_request(responseFetchUser.data.data));
            localStorage.setItem('user', JSON.stringify(responseFetchUser.data.data));
            location.pathname = '/';
          })
          .catch((errorFetchUser) => {
            dispatch(user_login_failure(errorFetchUser.message));
          });
      })
      .catch((errorLogin) => {
        dispatch(user_login_failure(errorLogin.message));
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(user_logout());
  };
};

export const resetPassword = (newPassword) => {
  return (dispatch) => {
    dispatch(user_resetpassword_request());
    axiosInstance
      .post(`/reset-password`, { newPassword })
      .then((response) => {
        dispatch(user_resetpassword_success());
      })
      .catch((error) => {
        dispatch(user_resetpassword_failure(error.message));
      });
  };
};

export const fetch_user_request = (user) => {
  return {
    type: FETCH_USER,
    payload: user
  };
};

export const user_login_request = () => {
  return {
    type: USER_LOGIN_REQUEST
  };
};

export const user_login_success = (token) => {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: token
  };
};

export const user_login_failure = (error) => {
  return {
    type: USER_LOGIN_FAILURE,
    payload: error
  };
};

export const user_resetpassword_request = () => {
  return {
    type: USER_RESETPASSWORD_REQUEST
  };
};

export const user_resetpassword_success = () => {
  return {
    type: USER_RESETPASSWORD_SUCCESS
  };
};

export const user_resetpassword_failure = (error) => {
  return {
    type: USER_RESETPASSWORD_FAILURE,
    payload: error
  };
};

export const user_logout = () => {
  return {
    type: USER_LOGOUT,
    payload: ''
  };
};
