import React from 'react';
import { connect } from 'react-redux';

import Sidebar from '../../../components/Sidebar/Sidebar';
import Tabs from '../../../components/Tabs/Tabs';
import ListEntityTable from '../../../components/Tables/ListEntityTable';
import CustomerEntityTable from '../../../components/Tables/CustomerEntityTable';

function CustomerDevices() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title__custom'>
          <i className='main__title__custom--icon'>
            <img src='../images/productbox.svg' alt='' />
          </i>
          <span>العملاء</span>
        </span>

        <div className='mt-5 pr-4 pl-4'>
          <Tabs
            headers={['الأجهزة', 'الزيارات', 'بيانات التقسيط']}
            contents={[
              <ListEntityTable
                columns={[
                  { header: 'كود الجهاز', field: 'device.code', isChip: false },
                  { header: 'العميل', field: 'customer.fullName', isChip: false },
                  { header: 'اسم الجهاز', field: 'device.name', isChip: false },
                  { header: 'فئة المنتج', field: 'device.id', isChip: false },
                  { header: 'نظام التقسيط', field: 'installmentsType', isChip: false },
                  { header: 'تاريخ العقد', field: 'contractDate', isChip: false }
                ]}
                urlName='CustomersDevice'
                headerTitle='الأجهزة'
                entityName='جهاز'
                entityNameDefined='الجهاز'
                addBtnText='إضافة جهاز جديد'
                isCustomerDevice={true}
              />,
              <Tabs
                headers={['الكل']}
                contents={[
                  <CustomerEntityTable
                    columns={[
                      { header: 'نوع الزيارة', field: 'type.name', isChip: false },
                      { header: 'الفنى', field: 'assignedRep.name', isChip: false },
                      { header: 'الاهمية', field: 'customerActivity.satisfactionLevel', isRate: true },
                      { header: 'تاريخ الزيارة', field: 'visitDate', isChip: false },
                      { header: 'الحالة', field: 'status.name', isChip: true }
                    ]}
                    urlName={`Visit/all`}
                    headerTitle='قائمة المتابعات'
                    entityNameDefined='تفاصيل الزيارة'
                    entityName='الزيارة'
                    addBtnText='إضافة زيارة للعميل'
                    isDevice={false}
                    path='/customer/followUp/details'
                    type='followUp'
                    // customerId={params.id}
                  />
                ]}
              />,
              <CustomerEntityTable
                columns={[
                  // { header: 'اسم الجهاز', field: 'product.name', isChip: false },
                  // { header: 'فئة المنتج', field: 'product.productCategory.name', isChip: false },
                  { header: 'نظام التقسيط', field: 'installmentsType.name', isChip: false },
                  { header: 'تاريخ التقسيط', field: 'collectionDate', isChip: false },
                  { header: 'قيمة القسط', field: 'installmentAmount', isChip: false },
                  { header: 'المدفوع', field: 'totalPaidAmount', isChip: false },
                  { header: 'المتبقى من السداد', field: 'remainingAmount', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true }
                ]}
                urlName={`ContractsInstallment/all`}
                headerTitle='قائمة الاقساط'
                entityNameDefined='تفاصيل القسط'
                entityName='القسط'
                addBtnText='إضافة جهاز للعميل'
                isDevice={false}
                type='installment'
              />
            ]}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDevices);
