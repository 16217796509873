import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from '../../redux/user/userActions';
import './Footer.scss';

function Footer() {
  return (
    <footer className='footer'>
      <div className='footer__uper'>
        <a className='footer__lower__link' href='/'>
          الرئيسية
        </a>
        <div className='ver_div'>|</div>
        <a className='footer__lower__link' href='#'>
          الشروط والأحكام
        </a>
        <div className='ver_div'>|</div>
        <a className='footer__lower__link' href='#'>
          سياسة الخصوصية
        </a>
        <div className='ver_div'>|</div>
        <a className='footer__lower__link' href='#'>
          تواصل معنا
        </a>
      </div>

      <div className='hors_div'></div>

      <div className='footer__lower'>
        <a className='footer__lower__link' href='#'>
          إذهب الى الاعلى
          <i className='icon pi pi-arrow-up'></i>
        </a>
        <span>Powered by Oneclick </span>
        <span>@Company name 2023 </span>
      </div>
    </footer>
  );
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
