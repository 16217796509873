import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Chips } from 'primereact/chips';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './Modals.scss';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';

function AddUsersToGroups({ visible, setVisible, employees, groupId, onAddHandler }) {
  const toast = useRef(null);
  const [entity, setEntity] = useState({
    employeesId: []
  });
  function acceptClose() {
    setEntity({
      employeesId: []
    });
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: 'هل تريد الغاء عملية اضافة مستخدمين الى مجموعة العمل ؟',
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      tagKey: 2
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog tagKey={2} />
      <Dialog
        header='إضافة موظفين إلى المجموعة'
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر الموظف</label>
              <div className='flex gap-1'>
                <MultiSelect
                  display='chip'
                  options={employees}
                  optionValue='id'
                  optionLabel='name'
                  value={entity.employeesId}
                  name='employeesId'
                  className='w-full'
                  placeholder='اختر الموظف'
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button className='modals__btn' onClick={() => onAddHandler(entity)}>
              اضافة الى المجموعة
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default AddUsersToGroups;
