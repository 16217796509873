import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Chips } from 'primereact/chips';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputTextarea } from 'primereact/inputtextarea';

import './Modals.scss';
import { InputNumber } from 'primereact/inputnumber';

import { axiosInstance } from '../../global';

function AddTransactionProduct({ warehouseId, visible, setVisible, onAddHandler }) {
  const toast = useRef(null);
  const [productTypes, setProductTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [productFamilies, setProductFamilies] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [warehouseSections, setWarehouseSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const [warehouseLocations, setWarehouseLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [entity, setEntity] = useState({
    name: '',
    productId: null,
    qty: 0,
    price: 50,
    amount: 0,
    availableQty: 1000,
    whLocationId: null
  });

  useEffect(() => {
    axiosInstance
      .get('/ProductsType/all')
      .then((response) =>
        setProductTypes(
          response.data.data.map((item) => {
            return { id: item.id, name: item.name };
          })
        )
      )
      .catch((error) => {});
    axiosInstance
      .get(`/WarehousesSection/allByWarehouseId/${warehouseId}`)
      .then((response) =>
        setWarehouseSections(
          response.data.data.map((item) => {
            return { id: item.id, name: item.name };
          })
        )
      )
      .catch((error) => {});
  }, []);

  useEffect(() => {
    axiosInstance
      .get(`/ProductsFamily/getByTypeId/${selectedType}`)
      .then((response) =>
        setProductFamilies(
          response.data.data.map((item) => {
            return { id: item.id, name: item.name };
          })
        )
      )
      .catch((error) => {});
  }, [selectedType]);

  useEffect(() => {
    axiosInstance
      .get(`/ProductsCategory/getByFamilyId/${selectedFamily}`)
      .then((response) =>
        setProductCategories(
          response.data.data.map((item) => {
            return { id: item.id, name: item.name };
          })
        )
      )
      .catch((error) => {});
  }, [selectedFamily]);

  useEffect(() => {
    axiosInstance
      .get(`/Product/allByCategory/${selectedCategory}`)
      .then((response) =>
        setProducts(
          response.data.data.map((item) => {
            return { id: item.id, name: item.name };
          })
        )
      )
      .catch((error) => {});
  }, [selectedCategory]);

  useEffect(() => {
    axiosInstance
      .get(`/WarehousesLocation/allBySectionId/${selectedSection}`)
      .then((response) =>
        setWarehouseLocations(
          response.data.data.map((item) => {
            return { id: item.id, name: item.name };
          })
        )
      )
      .catch((error) => {});
  }, [selectedSection]);

  function acceptClose() {
    setEntity({
      name: '',
      productId: null,
      qty: 0,
      price: 50,
      amount: 0,
      availableQty: 1000,
      whLocationId: null
    });
    setVisible(false);
    setSelectedType(null);
    setSelectedFamily(null);
    setSelectedCategory(null);
    setSelectedProduct(null);
    setSelectedSection(null);
    setSelectedLocation(null);
  }

  function handleClose() {
    confirmDialog({
      header: 'هل أنت متأكد من أنك تريد الخروج من عملية إضافة منتج؟',
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      tagKey: 1
    });
  }

  function handleLocationChange(e) {
    setSelectedLocation(e.value);
    setEntity({ ...entity, whLocationId: e.value });
  }

  function handleProductChange(e) {
    const productName = products.filter((item) => item.id === e.value)[0].name;
    setSelectedProduct(e.value);
    setEntity({ ...entity, productId: e.value, name: productName });
  }

  function handleQtyChange(e) {
    setEntity({ ...entity, qty: e.value });
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog tagKey={1} />
      <Dialog
        header='إضافة منتج'
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>نوع المنتج</label>
              <div className='flex'>
                <Dropdown
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.value)}
                  options={productTypes}
                  optionValue='id'
                  optionLabel='name'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>تصنيف المنتج</label>
              <div className='flex'>
                <Dropdown
                  value={selectedFamily}
                  onChange={(e) => setSelectedFamily(e.value)}
                  options={productFamilies}
                  optionValue='id'
                  optionLabel='name'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>فئة المنتج</label>
              <div className='flex'>
                <Dropdown
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.value)}
                  options={productCategories}
                  optionValue='id'
                  optionLabel='name'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>قسم المخزن</label>
              <div className='flex'>
                <Dropdown
                  value={selectedSection}
                  onChange={(e) => setSelectedSection(e.value)}
                  options={warehouseSections}
                  optionValue='id'
                  optionLabel='name'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>موقع التخزين</label>
              <div className='flex'>
                <Dropdown
                  value={selectedLocation}
                  onChange={handleLocationChange}
                  options={warehouseLocations}
                  optionValue='id'
                  optionLabel='name'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>المنتج</label>
              <div className='flex'>
                <Dropdown
                  value={selectedProduct}
                  onChange={handleProductChange}
                  options={products}
                  optionValue='id'
                  optionLabel='name'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>السعر</label>
              <div className='flex'>
                <InputNumber value={entity.price} disabled={true} className='w-full surface-300 opacity-100' />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>الكمية المتاحة</label>
              <div className='flex'>
                <InputNumber value={entity.availableQty} disabled={true} className='w-full surface-300 opacity-100' />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>الكمية</label>
              <div className='flex'>
                <InputNumber
                  name='qty'
                  value={entity.qty}
                  min={1}
                  max={entity.availableQty}
                  className='w-full'
                  onChange={handleQtyChange}
                />
              </div>
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button
              className='modals__btn'
              onClick={() => {
                onAddHandler(entity);
                setVisible(false);
              }}
            >
              اضافة المنتج
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default AddTransactionProduct;
