export const FETCH_ACTIVITY_REQUEST = 'FETCH_ACTIVITY_REQUEST';
export const FETCH_ACTIVITY_SUCCESS = 'FETCH_ACTIVITY_SUCCESS';
export const FETCH_ACTIVITY_FAILURE = 'FETCH_ACTIVITY_FAILURE';

export const ADD_ACTIVITY_REQUEST = 'ADD_ACTIVITY_REQUEST';
export const ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS';
export const ADD_ACTIVITY_FAILURE = 'ADD_ACTIVITY_FAILURE';

export const EDIT_ACTIVITY_REQUEST = 'EDIT_ACTIVITY_REQUEST';
export const EDIT_ACTIVITY_SUCCESS = 'EDIT_ACTIVITY_SUCCESS';
export const EDIT_ACTIVITY_FAILURE = 'EDIT_ACTIVITY_FAILURE';

export const CHANGE_ACTIVITY_STATE = 'CHANGE_ACTIVITY_STATE';
export const CLEAR_ACTIVITY_STATE = 'CLEAR_ACTIVITY_STATE';

