import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import './Tabs.scss';

function Tabs({ headers, contents }) {
  const tabTemplate = (options) => {
    return (
      <button className={`${options.selected ? 'tabs--selected' : 'tabs--unselected'}`} onClick={options.onClick}>
        {options.titleElement.props.children}
      </button>
    );
  };

  return (
    <TabView>
      {headers.map((header, index) => (
        <TabPanel key={index} headerTemplate={tabTemplate} header={header}>
          {contents[index]}
        </TabPanel>
      ))}
    </TabView>
  );
}

export default Tabs;
