import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';

import { axiosInstance } from '../../global';
import './Modals.scss';
import CustomDropdown from '../components/Dropdown/CustomDropDown';

// Validation Schema
const schema = yup.object().shape({
  code: yup.string().required('الكود مطلوب'),
  customerId: yup.string().required('اسم العميل مطلوب'),
  customerDeviceId: yup.string().required('جهاز العميل مطلوب'),
  complaintCategoryId: yup.string().required('تصنيف الشكوى مطلوب'),
  issuingDate: yup.date().required('تاريخ المشكلة مطلوب').typeError('تاريخ المشكلة مطلوب'),
  complaintDate: yup.date().required('تاريخ الشكوى مطلوب').typeError('تاريخ الشكوى مطلوب'),
  statusId: yup.string().required('حالة الشكوى مطلوبة'),
  remarks: yup.string()
});

function AddCustomerComplaint({
                                token,
                                title,
                                entityName,
                                visible,
                                setVisible,
                                statuses,
                                onClosePrompt,
                                onAddHandler
                              }) {
  const toast = useRef(null);
  const [productDisable, setProductDisable] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [complaintsFamilies, setComplaintsFamilies] = useState([]);

  const { control, handleSubmit, reset, formState: { errors }, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
      customerId: '',
      customerDeviceId: '',
      complaintCategoryId: '',
      issuingDate: '',
      complaintDate: '',
      statusId: '',
      remarks: ''
    },
    mode: 'onBlur',
  });

  useEffect(() => {
    axiosInstance.get(`/Customer/all`).then((response) => {
      setCustomers(response.data.data);
    }).catch((error) => {});

    axiosInstance.get(`/ComplaintsFamily/all`).then((response) => {
      setComplaintsFamilies(response.data.data);
    }).catch((error) => {});
  }, []);

  const selectedCustomer = watch('customerId');
  useEffect(() => {
    if (selectedCustomer) {
      axiosInstance.get(`/CustomersDevice/allByCustomer/${selectedCustomer}`).then((response) => {
        setProducts(response.data.data);
      }).catch((error) => {});
      setProductDisable(false);
    } else {
      setProductDisable(true);
    }
  }, [selectedCustomer]);

  function acceptClose() {
    reset();
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  function onSubmit(data) {
    onAddHandler(data);
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog tagKey={2} />
      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-column gap-5'>
            <div className='w-full flex gap-5'>
              <div className='w-full flex flex-column gap-2'>
                <label className='modals__label'> الكود</label>
                <Controller
                  name='code'
                  control={control}
                  render={({ field }) => <InputText {...field} className='w-full' disabled />}
                />
                <span className='text-red-500'>{errors.code?.message}</span>
              </div>
            </div>

            <div className='w-full flex gap-5'>
              <div className='w-full flex flex-column gap-2'>
                <label className='modals__label'> اسم العميل</label>
                <Controller
                  name='customerId'
                  control={control}
                  render={({ field }) => (
                    <CustomDropdown
                      {...field}
                      options={customers}
                      optionValue='id'
                      optionLabel='fullName'
                      display='chip'
                      placeholder='اختر العميل'
                      className='w-full'
                      onChange={(e) => {
                        field.onChange(e.value);
                        setValue('customerId', e.value);
                      }}
                    />
                  )}
                />
                <span className='text-red-500'>{errors.customerId?.message}</span>
              </div>
            </div>

            <div className='w-full flex gap-5'>
              <div className='w-full flex flex-column gap-2'>
                <label className='modals__label'> جهاز العميل</label>
                <Controller
                  name='customerDeviceId'
                  control={control}
                  render={({ field }) => (
                    <CustomDropdown
                      {...field}
                      options={products}
                      optionValue='device.id'
                      optionLabel='device.name'
                      display='chip'
                      placeholder='اختر جهاز العميل'
                      className='w-full'
                      disabled={productDisable}
                    />
                  )}
                />
                <span className='text-red-500'>{errors.customerDeviceId?.message}</span>
              </div>
            </div>

            <div className='w-full flex gap-5'>
              <div className='w-full flex flex-column gap-2'>
                <label className='modals__label'> تصنيف الشكوى</label>
                <Controller
                  name='complaintCategoryId'
                  control={control}
                  render={({ field }) => (
                    <CustomDropdown
                      {...field}
                      options={complaintsFamilies}
                      optionValue='id'
                      optionLabel='name'
                      display='chip'
                      placeholder='اختر التصنيف'
                      className='w-full'
                    />
                  )}
                />
                <span className='text-red-500'>{errors.complaintCategoryId?.message}</span>
              </div>
            </div>

            <div className='w-full flex gap-5'>
              <div className='w-full flex flex-column gap-2'>
                <label className='modals__label'>تفاصيل الشكوى</label>
                <Controller
                  name='remarks'
                  control={control}
                  render={({ field }) => <InputTextarea {...field} className='w-full' rows={3} />}
                />
                <span className='text-red-500'>{errors.remarks?.message}</span>
              </div>
            </div>

            <div className='w-full flex gap-5'>
              <div className='w-full flex flex-column gap-2'>
                <label className='modals__label'> تاريخ المشكلة </label>
                <Controller
                  name='issuingDate'
                  control={control}
                  render={({ field }) => (
                    <Calendar {...field} dateFormat='mm/dd/yy' showIcon className='w-full' />
                  )}
                />
                <span className='text-red-500'>{errors.issuingDate?.message}</span>
              </div>
            </div>

            <div className='w-full flex gap-5'>
              <div className='w-full flex flex-column gap-2'>
                <label className='modals__label'> تاريخ الشكوى</label>
                <Controller
                  name='complaintDate'
                  control={control}
                  render={({ field }) => (
                    <Calendar {...field} dateFormat='mm/dd/yy' showIcon className='w-full' />
                  )}
                />
                <span className='text-red-500'>{errors.complaintDate?.message}</span>
              </div>
            </div>

            <div className='w-full flex gap-5'>
              <div className='w-full flex flex-column gap-2'>
                <label className='modals__label'>اختر حالة {entityName}</label>
                <Controller
                  name='statusId'
                  control={control}
                  render={({ field }) => (
                    <CustomDropdown
                      {...field}
                      options={statuses}
                      optionValue='id'
                      optionLabel='name'
                      display='chip'
                      placeholder='اختر الحالة'
                      className='w-full'
                    />
                  )}
                />
                <span className='text-red-500'>{errors.statusId?.message}</span>
              </div>
            </div>

            <div className='flex justify-content-end'>
              <Button label='إلغاء' onClick={handleClose} size='small' outlined />
              <Button type='submit' label={`اضافة ${entityName}`} className='modals__btn' />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
}

export default AddCustomerComplaint;
