import React, { useEffect, useRef, useState } from 'react';
import { Steps } from 'primereact/steps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { editWarehouse, fetchWarehouse } from '../../../redux/warehouse/warehouseActions';
import Sidebar from '../../../components/Sidebar/Sidebar';

import EditWarehouse2 from './EditWarehouse2';
import EditWarehouse1 from './EditWarehouse1';
import './EditWarehouse.scss';

const EditWarehouse = ({ activeIndexnum, fetchWarehouse, editWarehouse, warehouse, token, loading, error }) => {
  const toast = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOk, setIsFormOk] = useState(false);
  const [editDone, setEditDone] = useState(false);

  useEffect(() => {
    fetchWarehouse(params.id, token);
    if (activeIndexnum === '' || activeIndexnum === undefined) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndexnum);
    }
  }, []);

  const items = [{ label: 'بيانات المخزن الاساسية' }, { label: 'تفاصيل العنوان و المنطقة' }];

  function handleOnClick() {
    setActiveIndex(activeIndex + 1);
    if (activeIndex >= 1) {
      setActiveIndex(0);
    }
  }

  useEffect(() => {
    if (editDone) {
      if (!loading && error.length) {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية تعديل المخزن' });
      } else {
        navigate('/warehouses');
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم تعديل المخزن بنجاح' });
      }
    }
  }, [loading, error]);

  function handleِEditOnClick() {
    try {
      editWarehouse(warehouse, token);
      setEditDone(true);
    } catch (errorEditWarehouse) {
      console.log(error);
      console.log(errorEditWarehouse);
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية تعديل المخزن' });
    }
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <div className='flex flex-column gap-5 edit__wh__main'>
        <div className='flex steps card'>
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} />
        </div>
        {loading ? (
          <ProgressSpinner />
        ) : (
          <div>
            <div>
              {activeIndex === 0 && <EditWarehouse1 setCheck={setIsFormOk} warehouseBase={warehouse} />}
              {activeIndex === 1 && <EditWarehouse2 setCheck={setIsFormOk} warehouseBase={warehouse} />}
            </div>
            <div className='btn'>
              {activeIndex != 1 && (
                <Button className='btn__nextbtn' onClick={handleOnClick} disabled={!isFormOk}>
                  الخطوة التالية
                </Button>
              )}
              {activeIndex === 1 &&
                (loading ? (
                  <ProgressSpinner />
                ) : (
                  <Button className='btn__nextbtn' onClick={handleِEditOnClick} disabled={!isFormOk}>
                    حفظ التعديلات
                  </Button>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    warehouse: state.warehouse.warehouse,
    loading: state.warehouse.loading,
    error: state.warehouse.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWarehouse: (id, token) => dispatch(fetchWarehouse(id, token)),
    editWarehouse: (warehouse, token) => dispatch(editWarehouse(warehouse, token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditWarehouse);
