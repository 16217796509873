import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Message } from 'primereact/message';
import { connect } from 'react-redux';

import './Modals.scss';
import { InputTextarea } from 'primereact/inputtextarea';

import { API_BASE_URL, axiosInstance } from '../../global';

function EditProduct({
  token,
  title,
  entityName,
  entityBase,
  selectedLevel,
  visible,
  setVisible,
  statuses,
  onClosePrompt,
  onEditHandler
}) {
  const toast = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [families, setFamilies] = useState([]);
  const [types, setTypes] = useState([]);
  const [entity, setEntity] = useState({
    id: null,
    name: null,
    statusId: null,
    productTypeId: null,
    productFamilyId: null,
    remarks: ''
  });

  useEffect(() => {
    axiosInstance
      .get(`/ProductsFamily/all`)
      .then((response) => {
        setFamilies(response.data.data);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/ProductsType/all`)
      .then((response) => {
        setTypes(response.data.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (visible) {
      setEntity({
        id: entityBase.id,
        name: entityBase.name,
        statusId: entityBase.status.id,
        productTypeId: entityBase?.productType?.id || entityBase?.productsType?.id,
        productFamilyId: entityBase?.productFamily?.id,
        remarks: entityBase.remarks
      });
      setSelectedStatus(entityBase.status.id);
      setSelectedType(entityBase?.productType?.id || entityBase?.productsType?.id);
      setSelectedFamily(entityBase?.productFamily?.id);
    }
  }, [visible, entityBase]);

  function acceptClose() {
    setEntity({
      id: null,
      name: null,
      statusId: null,
      productTypeId: null,
      productFamilyId: null,
      remarks: ''
    });
    setSelectedStatus(null);
    setSelectedFamily(null);
    setSelectedType(null);
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  function handleStatusChange(e) {
    setSelectedStatus(e.value);
    setEntity({ ...entity, statusId: e.value });
  }

  function handleFamilyChange(e) {
    setSelectedFamily(e.value);
    setEntity({ ...entity, productFamilyId: e.value });
  }

  function handleTypeChange(e) {
    setSelectedType(e.value);
    setEntity({ ...entity, productTypeId: e.value });
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog tagKey={3} />
      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <Message
            severity='info'
            text={`يجب اختيار نوع المنتج ${selectedLevel > 1 ? 'والتصنيف' : ''} اولاً حتى يكون بأمكانك اضافة ${
              selectedLevel > 1 ? 'فئة' : 'تصنيف'
            } المنتجات داخلها`}
            className='text-black-alpha-90 font-semibold'
          />

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>نوع المنتج</label>
              <div className='flex gap-1'>
                <Dropdown
                  value={selectedType}
                  onChange={handleTypeChange}
                  options={types}
                  optionLabel='name'
                  optionValue='id'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          {selectedLevel > 1 && (
            <div className='w-full flex gap-5'>
              <div className='w-full flex flex-column gap-2'>
                <label className='modals__label'>تصنيف المنتج</label>
                <div className='flex gap-1'>
                  <Dropdown
                    value={selectedFamily}
                    onChange={handleFamilyChange}
                    options={families}
                    optionLabel='name'
                    optionValue='id'
                    className='w-full'
                  />
                </div>
              </div>
            </div>
          )}

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اسم {entityName}</label>
              <InputText name='name' value={entity.name} className='w-full' onChange={handleInputChange} />
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر حالة {entityName}</label>
              <div className='flex'>
                <Dropdown
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  placeholder='اختر الحالة'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea
                name='remarks'
                value={entity.remarks || ''}
                className='w-full'
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button className='modals__btn' onClick={() => onEditHandler(entity)}>
              تعديل {entityName}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
