import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';

interface GenericModalProps {
  headerTitle: string;
  isVisible: boolean;
  onHide: () => void;
  handleSubmit: () => void;
  toast: React.RefObject<Toast>;
  children: React.ReactNode;
  loading: boolean;
  isEditMode?: boolean;
}

const GenericModal: React.FC<GenericModalProps> = ({
  headerTitle,
  isVisible,
  onHide,
  handleSubmit,
  toast,
  children,
  loading,
  isEditMode = false
}) => (
  <Dialog
    header={headerTitle}
    visible={isVisible}
    style={{ width: '30vw', minWidth: '30vm' }}
    onHide={onHide}
    className='modals'
    draggable={false}
    resizable={false}
    closeOnEscape={false}
  >
    <Toast ref={toast} />
    <ConfirmDialog style={{ minWidth: '20vw' }} />
    {loading ? (
      <div className='flex justify-content-center align-items-center' style={{ height: '100px' }}>
        <ProgressSpinner />
      </div>
    ) : (
      <form onSubmit={handleSubmit} className='flex flex-column gap-5'>
        {children}
        <div className='flex justify-content-end'>
          <Button label='إلغاء' onClick={onHide} size='small' outlined type='button' />
          <Button type='submit' label={isEditMode ? 'تعديل' : 'إضافة'} size='small' className='modals__btn' />
        </div>
      </form>
    )}
  </Dialog>
);

export default GenericModal;
