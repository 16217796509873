import React from 'react';
import { Link } from 'react-router-dom';
import { Chip } from 'primereact/chip';
import { Divider } from 'primereact/divider';
import './PersonalData.scss';

function PersonalData({ employee }) {
  if (!employee) {
    return <div>Loading...</div>; // or any other loading indicator
  }
  return (
    <div className='personaldata'>
      <span className='personaldata__name'>{employee.name}</span>
      <div className='primary'>
        <span className='primary__title'>بيانات الموظف</span>
        <div className='primary__data'>
          <span className='primary__data--label'>البريد الالكتروني: </span>
          <span className='primary__data--text'>{employee?.user.email}</span>
        </div>
        {/*<div className='primary__data'>*/}
        {/*  <span className='primary__data--label'>كلمة السر: </span>*/}
        {/*  <span className='primary__data--text'>{employee?.user.password}</span>*/}
        {/*</div>*/}
        <div className='primary__data'>
          <span className='primary__data--label'>رقم الهاتف: </span>
          <span className='primary__data--text'>{employee?.user.phoneNumber}</span>
        </div>
        <div className='primary__data'>
          <span className='primary__data--label'>دور الموظف و صلاحياته: </span>
          <span className='primary__data--text'>{employee?.position.name}</span>
        </div>
        <div className='primary__data'>
          <span className='primary__data--label'>حالة الحساب: </span>
          <Chip label={employee?.status.name} />
          {employee?.status.name === "Active" && <Link to={`/employees/edit/${employee?.id}`} className='primary__data--link text-red-600'>
            {/*إنهاء الاتصال الاجباري &larr;*/}
          </Link>}
        </div>
      </div>
      <Divider />
      <div className='secondary'>
        <div className='flex justify-content-between'>
          <span className='secondary__title'>بيانات الموظف</span>
        </div>
        <div className='secondary__row'>
          <div className='secondary__data'>
            <span className='secondary__data--label'>تاريخ التعيين</span>
            <div className='secondary__data--container'>
              <span>{employee?.hiringDate}</span>
            </div>
          </div>
          <div className='secondary__data'>
            <span className='secondary__data--label'>نظام الحضور</span>
            <div className='secondary__data--container'>
              <span>{employee?.attendanceType.name}</span>
            </div>
          </div>
        </div>
        <div className='secondary__row'>
          <div className='secondary__data'>
            <span className='secondary__data--label'>القسم</span>
            <div className='secondary__data--container'>
              <span>{employee?.department.name}</span>
            </div>
          </div>
          <div className='secondary__data'>
            <span className='secondary__data--label'>الدرجة الوظيفية</span>
            <div className='secondary__data--container'>
              <span>{employee?.position.name}</span>
            </div>
          </div>
        </div>
        <div className='secondary__row'>
          <div className='secondary__data'>
            <span className='secondary__data--label'>الرئيس المباشر</span>
            <div className='secondary__data--container'>
              <span>{employee?.directManager ? employee?.directManager.name : 'لا يوجد'}</span>
            </div>
          </div>
          <div className='secondary__data'>
            <span className='secondary__data--label'>الجنس</span>
            <div className='secondary__data--container'>
              <span>{employee?.gender.name}</span>
            </div>
          </div>
        </div>
        <div className='secondary__row'>
          <div className='secondary__data'>
            <span className='secondary__data--label'>العنوان</span>
            <div className='secondary__data--container'>
              <span>{employee?.address}</span>
            </div>
          </div>
          <div className='secondary__data'>
            <span className='secondary__data--label'>الرقم القومي</span>
            <div className='secondary__data--container'>
              <span>{employee?.nationalNb}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalData;
