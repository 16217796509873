import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Chips } from 'primereact/chips';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputTextarea } from 'primereact/inputtextarea';

import './Modals.scss';
import { ProgressSpinner } from 'primereact/progressspinner';

import { axiosInstance } from '../../global';

function EditWarehouseLocation({ visible, setVisible, onEditHandler, entityBase }) {
  const toast = useRef(null);
  const [statuses, setStatuses] = useState([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [warehousees, setWarehouses] = useState([]);
  const [warehouseSections, setWarehouseSections] = useState([]);
  const [entity, setEntity] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (Object.values(entityBase).length) {
      setSelectedWarehouseId(entityBase.warehouse.id);
      setEntity({
        warehouseSectionId: entityBase.warehouseSection.id,
        name: entityBase.name,
        code: entityBase.code,
        plantCode: entityBase.plantCode,
        statusId: entityBase.status.id,
        remarks: entityBase.remarks
      });
    }
  }, [entityBase]);

  useEffect(() => {
    axiosInstance
      .get('/Status/all')
      .then((response) =>
        setStatuses(
          response.data.data.map((item) => {
            return { id: item.id, name: item.name };
          })
        )
      )
      .catch((error) => {});
    axiosInstance
      .get('/Warehouse/all')
      .then((response) => {
        setWarehouses(
          response.data.data.map((item) => {
            return { id: item.id, name: item.name };
          })
        );
        setLoading(false);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (selectedWarehouseId) {
      axiosInstance
        .get(`/WarehousesSection/allByWarehouseId/${selectedWarehouseId}`)
        .then((response) =>
          setWarehouseSections(
            response.data.data.map((item) => {
              return { id: item.id, name: item.name };
            })
          )
        )
        .catch((error) => {});
    }
  }, [selectedWarehouseId]);

  function acceptClose() {
    setEntity({});
    setVisible(false);
    setSelectedWarehouseId(null);
  }

  function handleClose() {
    confirmDialog({
      header: 'هل أنت متأكد من أنك تريد الخروج من عملية تعديل موقع مخزن جديد؟',
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
			tagKey: 2
    });
  }

  function handleWarehouseChange(e) {
    setSelectedWarehouseId(e.value);
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.value || e.target.value });
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog tagKey={2} />
      <Dialog
        header='تعديل موقع مخزن'
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          {loading && <ProgressSpinner />}
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر المخزن</label>
              <div className='flex'>
                <Dropdown
                  value={selectedWarehouseId}
                  onChange={handleWarehouseChange}
                  options={warehousees}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر قسم المخزن</label>
              <div className='flex'>
                <Dropdown
                  value={entity.warehouseSectionId}
                  onChange={handleInputChange}
                  options={warehouseSections}
                  name='warehouseSectionId'
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  className='w-full'
                  disabled={!selectedWarehouseId}
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اضف اسم موقع المخزن</label>
              <div className='flex'>
                <InputText name='name' value={entity.name} className='w-full' onChange={handleInputChange} />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>كود الPlant</label>
              <div className='flex'>
                <InputText name='plantCode' value={entity.plantCode} className='w-full' onChange={handleInputChange} />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>كود موقع المخزن</label>
              <div className='flex'>
                <InputText name='code' value={entity.code} className='w-full' onChange={handleInputChange} />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر حالة موقع المخزن</label>
              <div className='flex'>
                <Dropdown
                  value={entity.statusId}
                  onChange={handleInputChange}
                  options={statuses}
                  name='statusId'
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea name='remarks' value={entity.remarks} className='w-full' onChange={handleInputChange} />
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button
              className='modals__btn'
              onClick={() => {
                onEditHandler(entity);
                setVisible(false);
              }}
            >
              تعديل موقع المخزن
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default EditWarehouseLocation;
