import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import '../../style/Tables.scss';
import { Link, useParams } from 'react-router-dom';
import { Checkbox } from 'antd';

import { API_BASE_URL, axiosInstance } from '../../global';

function ProductStagesTable({ token }) {
  const toast = useRef(null);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [addItemVisible, setAddItemVisible] = useState(false);

  function fetchItems() {
    axiosInstance
      .get(`/ProductsStage/allByProductId/${params.id}`)
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>قائمة مراحل المنتج</span>
        <button className='tbl__addvacationbtn'>
          <Link to={`/product/details/addStage/${params.id}/${items.length + 1}`} className='tbl__addbtn__link'>
            <img src='/images/plus.svg' alt={'إضافة مرحلة جديدة'} />
            إضافة مرحلة جديدة
          </Link>
        </button>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={items}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
          <Column header='رقم المرحلة' field='stageNb' />
          <Column header='كود المرحلة' field='serial' />
          <Column header='نوع المرحلة' field='stageType.name' />
          <Column header='المرحلة' field='stageItem.name' />
          <Column
            header='يجب التغير'
            body={(rowData) => <Checkbox checked={rowData.mandatoryReplacement} disabled={true} />}
          />
          <Column header='عدد شهور تغير المرحلة' field='replacmentMonths' />
          {/* <Column
            header='خيارات'
            body={(rowData) => (
              // <Link to={`/vacation/details/${rowData.id}`}>
              <Link to={``}>
                <Button label='عرض المرحلة' icon='pi pi-exclamation-circle' severity='secondary' link />
              </Link>
            )}
          /> */}
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductStagesTable);
