import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

import Tabs from '../../../components/Tabs/Tabs';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { axiosInstance } from '../../../global';

// import ProductsDetailsContent from './ProductsDetailsContent';
// import ProductsPricesTable from '../../../components/Tables/ProductsPricesTable';
import './CustomersDetails.scss';
import CustomersDetailsContent from './CustomersDetailsContent';
// import ProductInstallmentsType from './ProductInstallmentsType';
// import ProductStagesTable from '../../../components/Tables/ProductStagesTable';

function CustomersDetails({ token }) {
  const toast = useRef(null);

  const Style1 = { color: 'black' };
  const Style2 = { color: 'red' };

  const [customer, setCustomer] = useState();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`/Customer/${params.id}`)
      .then((response) => {
        setCustomer(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function showConfirmDeleteCustomer(e) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف العميل',
      header: 'تأكيد حذف العميل',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept: handleDeleteCustomer,
      draggable: false,
      resizable: false
    });
  }

  function handleDeleteCustomer(e) {
    axiosInstance
      .delete(`/Customer/${params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'تم حذف العميل',
          detail: '',
          life: 3000
        });
        setTimeout(() => navigate('/customers'), 3000);
      })
      .catch(() => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء حذف العميل',
          detail: '',
          life: 3000
        });
      });
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className=' main w-full'>
        <div className='customerdetails__header1'>
          <Toast ref={toast} />
          <ConfirmDialog />

          <div className='customerdetails__header1__right'>
            <Button
              className='font-bold text-black-alpha-90'
              icon='pi pi-arrow-right'
              rounded
              text
              onClick={() => navigate(-1)}
            />
            <span className='customerdetails__header1__right--text'>تفاصيل العميل</span>
            <Chip label={customer?.status.name} />
          </div>

          <div className='customerdetails__header__left'>
            <Button
              className='customerdetails__header1__left__btn'
              onClick={() => navigate(`/customers/editCustomer/${params.id}`)}
              icon='pi pi-pencil'
              style={Style1}
              text
            >
              تعديل بيانات العميل
            </Button>

            <Button
              className='customerdetails__header1__left__btn'
              onClick={showConfirmDeleteCustomer}
              icon='pi pi-trash'
              style={Style2}
              text
            >
              حذف العميل
            </Button>
          </div>
        </div>
        <div className='customerdetails__contentgeneral'>
          <CustomersDetailsContent customer={customer} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersDetails);
