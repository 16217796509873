import React, { useEffect, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { login } from '../../../redux/user/userActions';
import Tabs from '../../../components/Tabs/Tabs';
import './General.scss';
import Sidebar from '../../../components/Sidebar/Sidebar';
import StatusTable from '../../../components/Tables/StatusTable';
import CurrencyTable from '../../../components/Tables/CurrencyTable';

function General() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className=' main w-full'>
        <span className='main__title'>
          <i className='pi pi-database main__title--icon'></i> البيانات الاساسية
        </span>
        <div className='mt-5 pr-4 pl-4'>
          <Tabs
            headers={['الحالات', 'العملات']}
            contents={[
              <StatusTable
                urlName='Status'
                headerTitle='الحالات'
                entityName='حالة'
                entityNameDefined='الحالة'
                addBtnText='إضافة حالة'
              />,
              <CurrencyTable
                urlName='Currency'
                headerTitle='العملات'
                entityName='عملة'
                entityNameDefined='العملة'
                addBtnText='إضافة عملة'
              />
            ]}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) => dispatch(login(credentials))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(General);
