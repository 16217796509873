import React from 'react';
import { Button, ButtonProps } from 'primereact/button';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

interface CustomButtonProps extends ButtonProps {
  label?: string;
  icon?: string;
  iconPos?: 'left' | 'right' | 'top' | 'bottom';
  loading?: boolean;
  loadingIcon?: string;
  severity?: 'success' | 'help' | 'warning' | 'secondary' | 'info' | 'danger';
  disabled?: boolean;
  raised?: boolean;
  rounded?: boolean;
  text?: boolean;
  outlined?: boolean;
  size?: 'small' | 'large';
  link?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  style?: React.CSSProperties;
  badge?: string;
  badgeClassName?: string;
  tooltip?: string;
  tooltipOptions?: any;
  visible?: boolean;
}

const CustomButton = React.forwardRef<any, CustomButtonProps>(({
                                                                 label,
                                                                 icon,
                                                                 iconPos,
                                                                 loading,
                                                                 loadingIcon,
                                                                 severity,
                                                                 disabled,
                                                                 raised,
                                                                 rounded,
                                                                 text,
                                                                 outlined,
                                                                 size,
                                                                 link,
                                                                 onClick,
                                                                 className,
                                                                 style,
                                                                 badge,
                                                                 badgeClassName,
                                                                 tooltip,
                                                                 tooltipOptions,
                                                                 visible = true,
                                                                 ...rest
                                                               }, ref) => {
  return (
    <Button
      label={label}
      icon={icon}
      iconPos={iconPos}
      loading={loading}
      loadingIcon={loadingIcon}
      severity={severity}
      disabled={disabled}
      raised={raised}
      rounded={rounded}
      text={text}
      outlined={outlined}
      size={size}
      link={link}
      onClick={onClick}
      className={className}
      style={style}
      badge={badge}
      badgeClassName={badgeClassName}
      tooltip={tooltip}
      tooltipOptions={tooltipOptions}
      visible={visible}
      ref={ref}
      {...rest}
    />
  );
});

export default CustomButton;
