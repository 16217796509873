import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';

import { axiosInstance } from '../global';

type FetchDataState<T> = {
  data: T | null;
  loading: boolean;
  error: string | null;
  totalCount: number;
};

const useDebounce = (func: Function, delay: number) => {
  const timeoutRef = useRef<number | undefined>();

  return useCallback((...args: any[]) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      func(...args);
    }, delay);
  }, [func, delay]);
};

export const useFetchData = <T>(url: string, initialState?: T, extraParams?: Record<string, any>) => {
  const [state, setState] = useState<FetchDataState<T>>({
    data: initialState ?? null,
    loading: true,
    error: null,
    totalCount: 0
  });

  const [retryCount, setRetryCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = useCallback(
    async (signal: AbortSignal) => {
      try {
        const response = await axiosInstance.get<{ data: T; totalCount: number }>(url, {
          signal,
          params: { page, size: pageSize, search: searchTerm, ...extraParams }
        });
        setState({ data: response.data.data, loading: false, error: null, totalCount: response.data.totalCount });
      } catch (err) {
        if (axios.isCancel(err)) {
        } else {
          let errorMessage = 'Unknown error';
          if (axios.isAxiosError(err)) {
            errorMessage = err.response?.data?.message || err.message;
          } else {
            errorMessage = (err as Error).message;
          }

          setState((prevState) => ({
            ...prevState,
            loading: false,
            error: errorMessage,
            totalCount: 0
          }));
        }
      }
    },
    [url, page, pageSize, searchTerm]
  );

  const debouncedFetchData = useDebounce(fetchData, 300);

  const debouncedSetSearchTerm = useDebounce((term: SetStateAction<string>) => {
    setSearchTerm(term);
  }, 300);

  const mutate = useCallback(() => {
    setState((prevState) => ({ ...prevState, loading: true }));
    setRetryCount((prevCount) => prevCount + 1);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setState((prevState) => ({ ...prevState, loading: true }));
    debouncedFetchData(signal);

    return () => {
      controller.abort();
    };
  }, [debouncedFetchData, retryCount, url]);

  useEffect(() => {
    if (state.error && retryCount < 3) {
      const retryDelay = Math.min(1000 * 2 ** retryCount, 30000); // Exponential backoff, max 30s
      const retryTimer = setTimeout(() => {
        setRetryCount((prevCount) => prevCount + 1);
      }, retryDelay);

      return () => clearTimeout(retryTimer);
    }
  }, [state.error, retryCount]);

  return {
    ...state,
    mutate,
    retry: () => setRetryCount((prevCount) => prevCount + 1),
    page,
    setPage,
    pageSize,
    setPageSize,
    searchTerm,
    setSearchTerm: debouncedSetSearchTerm
  };
};
