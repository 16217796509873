import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Sidebar from '../../../components/Sidebar/Sidebar';
import GenericPage from '../../../components/components/GenericPage/GenericPage';


function ActivitesScreen() {
  const navigate = useNavigate();

  return (
    <div className='flex w-full'>
      <Sidebar />

      <GenericPage
        tabs={[
          {
            isHidden: true,
            label: 'بيانات التواصل',
            tableHeader:'',
            dataKey: 'التواصل',
            emptyStateButtonClick: () => {},
            apiUrl: 'CustomersActivity/all',
            edit: false,
            columns: [
              {
                header: 'سبب التواصل',
                field: 'reason.name',
                isChip: false
              },
              { header: 'الاجراء المتخذ', field: 'decision.name', isChip: false },
              { header: 'مستوى الرضى', field: 'satisfactionLevel', isRate: true },
              { header: 'تاريخ الاتصال', field: 'activityDateTime', isChip: false }
            ],
            emptyStateUrl: '',
            entityName: 'بيانات التواصل',
            rowOnClick: (rowData) => {
              navigate(`/Activities/details/activities/${rowData.id}/${rowData.customer.id}`);
            },
          }
        ]}
        headerTitle={'الزيارات'}
        headerIcon={'../images/briefcase1.svg'}
        headerButtons={[
          {
            label: 'اضافة اتصال / زيارة جديدة',
            onClick: () => {
              navigate('/Activites/addVisitConnection')
            }
          }
        ]}
      />
      {/*<div className='w-full'>*/}
      {/*  <span className='main__title__custom'>*/}
      {/*    <i className='main__title__custom--icon'>*/}
      {/*      <img src='../images/briefcase1.svg' alt='' />*/}
      {/*    </i>*/}
      {/*    <span>الزيارات</span>*/}
      {/*  </span>*/}

      {/*  <div className='mt-5 pr-4 pl-4'>*/}
      {/*    <ActivitesScreenTable*/}
      {/*      columns={[*/}
      {/*        { header: 'سبب التواصل', field: 'reason.name', isChip: false },*/}
      {/*        { header: 'الاجراء المتخذ', field: 'decision.name', isChip: false },*/}
      {/*        { header: 'مستوى الرضى', field: 'satisfactionLevel', isRate: true },*/}
      {/*        { header: 'تاريخ الاتصال', field: 'activityDateTime', isChip: false }*/}
      {/*      ]}*/}
      {/*      urlName='CustomersActivity/all'*/}
      {/*      headerTitle='التواصل'*/}
      {/*      entityNameDefined='تفاصيل الاتصال'*/}
      {/*      addBtnText='اضافة اتصال / زيارة جديدة'*/}
      {/*      path='/Activites/addVisitConnection'*/}
      {/*      type={'activities'}*/}
      {/*      detailsPath='/activities/details'*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}



export default ActivitesScreen;
