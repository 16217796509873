import React from 'react';
import './DetailsSheetGrid.scss';

const DetailsSheetGrid = ({ headers, contents, title }) => {
  return (
    <div className='grid__details'>
      <span className='grid__details__title'>{title}</span>
      <div className='grid__details__content'>
        <div className='grid__details__content__container'>
          {headers.map((header, index) => (
            <div className='grid__details__content__container__body' key={index}>
              <div className='grid__details__content__container__body__right'>{header}</div>
              <div className='grid__details__content__container__body__left'>{contents[index]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailsSheetGrid;
