import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Message } from 'primereact/message';
import { Chip } from 'primereact/chip';
import { Dropdown } from 'primereact/dropdown';

import './ProductsDetails.scss';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'antd';

import ViewInstallmentsDetails from '../../../components/Modals/ViewInstallmentsDetails';
import { axiosInstance } from '../../../global';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';

function ProductInstallmentsType({ token }) {
  const [installments, setInstallments] = useState([]);
  const [newInstallments, setNewInstallments] = useState();
  const [newSelectedInstallment, setNewSelectedInstallment] = useState();
  const [ViewItemVisible, setViewItemVisible] = useState(false);
  const [check, setcheck] = useState(false);
  const [planId, setplanId] = useState(null);
  const params = useParams();
  const toast = useRef(null);
  const [addedInstallment, setAddedInstallment] = useState({
    productId: '',
    installmentTypeId: '',
    statusId: ''
  });

  useEffect(() => {
    fetchInstallments();
    axiosInstance
      .get(`/InstallmentsType/all`)
      .then((response) => {
        const tempInstallments = response.data.data.map((installments) => {
          return {
            id: installments.id,
            name: installments.name
          };
        });
        setNewInstallments(tempInstallments);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setAddedInstallment({ productId: parseInt(params.id), installmentTypeId: newSelectedInstallment, statusId: 1 });
  }, [newSelectedInstallment]);

  function handleAddInputChange(e) {
    setNewSelectedInstallment(e.target.value || e.value);
    setcheck(true);
  }

  function handleInstallmentPlaneClick(id) {
    setViewItemVisible(true);
    setplanId(id);
  }

  const acceptAdd = () => {
    console.log(addedInstallment);
    axiosInstance
      .post(`/ProductsInstallmentTypesMatrix`, addedInstallment)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: `تم إضافة خطة تقسيط جديدة بنجاح!`, life: 3000 });
        window.location.reload();
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: `فشلت عملية إضافة خطة تقسيط جديدة`, life: 3000 });
      });
  };

  const fetchInstallments = () => {
    axiosInstance
      .get(`/ProductsInstallmentTypesMatrix/allByProductId/${params.id}`)
      .then((response) => {
        setInstallments(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const acceptdelete = (id) => {
    axiosInstance
      .delete(`/ProductsInstallmentTypesMatrix/${id}`)
      .then((response) => {
        // toast.current.show({ severity: 'success', summary: `تم حذف الخطة بنجاح!`, life: 3000 });
        // window.location.reload();
        fetchInstallments();
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: `فشلت عملية حذف الخطة `, life: 3000 });
      });
  };

  const confirm1 = () => {
    confirmDialog({
      message: 'هل أنت متأكد من أنك تريد اضافة خطة تقسيط جديدة للمنتج؟',
      header: 'اضافة خطة تقسيط جديدة',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      acceptClassName: 'p-button-success',
      acceptLabel: 'تأكيد',
      rejectLabel: 'إلغاء',
      accept: acceptAdd,
      reject: confirmDialog().hide()
    });
  };

  const confirmDelete = (id) => {
    confirmDialog({
      message: 'هل أنت متأكد من أنك تريد حذف هذة الخطة ',
      header: 'حذف خطة تقسيط ',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      acceptClassName: 'p-button-danger',
      acceptLabel: 'تأكيد',
      rejectLabel: 'إلغاء',
      accept: acceptdelete(id),
      reject: confirmDialog().hide()
    });
  };
  const decline = () => {
    confirmDialog({
      message: 'الخطة موجودة بالفعل',
      header: 'اضافة خطة تقسيط جديدة',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      acceptClassName: 'p-button-danger',
      acceptLabel: 'تأكيد',
      rejectLabel: 'إلغاء',
      reject: confirmDialog().hide()
    });
  };

  function HandleAddInstallment() {
    if (installments.find((item) => item.installmentType.id === newSelectedInstallment)) {
      // Item exists in the array
      decline();
    } else {
      // Item does not exist in the array
      confirm1();
    }
  }

  function HandleDeleteInstallment(planId) {
    confirmDelete(planId);
  }

  return (
    <div className='details'>
      <div className='details__inst__main'>
        <Toast ref={toast} />
        {ViewItemVisible && (
          <ViewInstallmentsDetails visible={ViewItemVisible} setVisible={setViewItemVisible} id={planId} key={1} />
        )}
        <span className='details__inst__main__title'>خطط تقسيط المنتج</span>
        <Message
          severity='info'
          text={`يمكنك اضافة خطط التقسيط المناسبة للمنتج و اللتي ستظهر و تستخدم من قبل العميل`}
          className='text-black-alpha-90 font-semibold'
        />

        <div className='flex w-full flex-column gap-2'>
          <label className='inst__label'>أختر التقسيطات</label>
          <div className='flex w-full flex-row gap-4'>
            <CustomDropdown
              value={newSelectedInstallment}
              onChange={handleAddInputChange}
              options={newInstallments}
              optionValue='id'
              optionLabel='name'
              placeholder='ابحث عن طرق التقسيط'
              className='installment__dropdown'
            />
            <button className='installment__addbtn' onClick={() => HandleAddInstallment()} disabled={!check}>
              <img src='/images/plus.svg' alt={'add button'} />
              اضافة خطط تقسيط جديدة
            </button>
          </div>
        </div>

        <div className='inst__chips flex flex-column gap-4 mt-4'>
          {installments.map((item, index) => (
            <>
              <div className='inst__collection'>
                <div className='flex flex-row gap-2'>
                  <Chip key={index} label={`${item.installmentType.name}`} />
                  <Button
                    className='deleteInstallment__btn'
                    onClick={() => HandleDeleteInstallment(item?.installmentType.id)}
                  >
                    <i className='pi pi-times'></i>
                  </Button>
                </div>
                <Link
                  onClick={() => handleInstallmentPlaneClick(item?.installmentType.id)}
                  className='primary__data--link text-blue-600'
                >
                  تفاصيل الخطة
                </Link>
              </div>
              <div className='hors_div'></div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductInstallmentsType);
