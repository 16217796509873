import React, { useState, useEffect } from 'react';
import { message, Upload } from 'antd';
import { connect } from 'react-redux';

import { fill_product } from '../../redux/product/productActions';

const { Dragger } = Upload;

const UploadFile = ({ fill_product, setCheck }) => {
  // const [imagesLists, setImagesLists] = useState([]);
  // const [images, setimages] = useState({
  //   id: '',
  //   image: [],
  //   preferenceNb: '',
  //   showInGalary: true,
  //   showInStore: true
  // });

  const props = {
    name: 'file',
    multiple: true,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        // Update the imagesList state with the new file information
        // setImagesLists([...imagesLists, info.file.uid]);
        message.success(`${info.file.name} file uploaded successfully.`);
        setCheck(true);
      } else if (status === 'error') {
        setCheck(false);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    }
  };

  // useEffect(() => {
  //   setimages({ id: '', image: imagesLists, preferenceNb: 1, showInGalary: true, showInStore: true });
  // }, [imagesLists]);

  // useEffect(() => {
  //   // fill_product({ productsImages: images });
  //   if (images.image.length === 0) {
  //     setCheck(false);
  //   } else {
  //     setCheck(true);
  //   }
  // }, [images]);
  return (
    <Dragger {...props}>
      <p className='ant-upload-text'>انقر هنا لإضافة الصورة أو اسحب الصورة هنا</p>
      <p className='ant-upload-hint'>يمكنك إضافة صورة JPG, PNG, JPEG أو WEBP</p>
    </Dragger>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_product: (product) => dispatch(fill_product(product))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
