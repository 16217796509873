import React, { useEffect } from 'react';
import { Control, Controller, FieldErrors, UseFormWatch } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';

import { useApiState } from '../../../hooks/useApiState';
import { axiosInstance } from '../../../global';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';

interface CustomerComplaintFormProps {
  control: Control<any>;
  errors: FieldErrors;
  setValue: (name: string, value: any) => void;
  watch: UseFormWatch<any>;
}

const CustomerComplaintForm: React.FC<CustomerComplaintFormProps> = ({
                                                                       control,
                                                                       errors,
                                                                       setValue,
                                                                       watch
                                                                     }) => {
  const selectedCustomer = watch('customerId');

  const fetchCustomer = async () => {
    const response = await axiosInstance.get(`/Customer/all`);
    return response.data;
  };

  const fetchStatus = async () => {
    const response = await axiosInstance.get(`/Status/dropdown`);
    return response.data;
  };

  const fetchComplaintsFamily = async () => {
    const response = await axiosInstance.get(`/ComplaintsFamily/list`);
    return response.data;
  };

  const fetchCustomersDevice = async () => {
    if (!selectedCustomer) return { data: [] };
    const response = await axiosInstance.get(`/CustomersDevice/allByCustomer/${selectedCustomer}`);
    return response.data;
  };

  const {
    state: { isLoading: isCustomerLoading, error: customerError, data: customerData },
    lazyApiCall: getCustomerData
  } = useApiState({ isLazy: true, apiCall: fetchCustomer });

  const {
    state: { isLoading: isStatusLoading, error: statusError, data: statusData },
    lazyApiCall: getStatusData
  } = useApiState({ isLazy: true, apiCall: fetchStatus });

  const {
    state: { isLoading: isComplaintsFamilyLoading, error: complaintsFamilyError, data: complaintsFamilyData },
    lazyApiCall: getComplaintsFamilyData
  } = useApiState({ isLazy: true, apiCall: fetchComplaintsFamily });

  const {
    state: { isLoading: isCustomersDeviceLoading, error: customersDeviceError, data: customersDeviceData },
    lazyApiCall: getCustomersDeviceData
  } = useApiState({ isLazy: true, apiCall: fetchCustomersDevice });

  useEffect(() => {
    getCustomerData();
    getStatusData();
    getComplaintsFamilyData();
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      getCustomersDeviceData();
    }
  }, [selectedCustomer]);

  return (
    <div className="flex flex-column gap-5">
      <div className="w-full flex gap-5">
        <div className="w-full flex flex-column gap-2">
          <label className="modals__label">الكود</label>
          <Controller
            name="code"
            control={control}
            render={({ field }) => <InputText {...field} className="w-full" />}
          />
          <span className="text-red-500">{errors.code?.message as React.ReactNode}</span>
        </div>
      </div>

      <div className="w-full flex gap-5">
        <div className="w-full flex flex-column gap-2">
          <label className="modals__label">اسم العميل</label>
          <Controller
            name="customerId"
            control={control}
            render={({ field }) => (
              <CustomDropdown
                {...field}
                options={customerData?.data ?? []}
                optionValue="id"
                optionLabel="fullName"
                placeholder="اختر العميل"
                className="w-full"
                disabled={isCustomerLoading}
                onChange={(e) => {
                  field.onChange(e.value);
                  setValue('customerId', e.value);
                }}
              />
            )}
          />
          <span className="text-red-500">{errors.customerId?.message as React.ReactNode}</span>
        </div>
      </div>

      <div className="w-full flex gap-5">
        <div className="w-full flex flex-column gap-2">
          <label className="modals__label">جهاز العميل</label>
          <Controller
            name="customerDeviceId"
            control={control}
            render={({ field }) => (
              <CustomDropdown
                {...field}
                options={customersDeviceData?.data ?? []}
                optionValue="device.id"
                optionLabel="device.name"
                placeholder="اختر جهاز العميل"
                className="w-full"
                disabled={isCustomersDeviceLoading}
              />
            )}
          />
          <span className="text-red-500">{errors.customerDeviceId?.message as React.ReactNode}</span>
        </div>
      </div>

      <div className="w-full flex gap-5">
        <div className="w-full flex flex-column gap-2">
          <label className="modals__label">تصنيف الشكوى</label>
          <Controller
            name="complaintCategoryId"
            control={control}
            render={({ field }) => (
              <CustomDropdown
                {...field}
                options={complaintsFamilyData?.data ?? []}
                optionValue="id"
                optionLabel="name"
                placeholder="اختر التصنيف"
                className="w-full"
                disabled={isComplaintsFamilyLoading}
              />
            )}
          />
          <span className="text-red-500">{errors.complaintCategoryId?.message as React.ReactNode}</span>
        </div>
      </div>

      <div className="w-full flex gap-5">
        <div className="w-full flex flex-column gap-2">
          <label className="modals__label">تفاصيل الشكوى</label>
          <Controller
            name="remarks"
            control={control}
            render={({ field }) => <InputTextarea {...field} className="w-full" rows={3} />}
          />
          <span className="text-red-500">{errors.remarks?.message as React.ReactNode}</span>
        </div>
      </div>

      <div className="w-full flex gap-5">
        <div className="w-full flex flex-column gap-2">
          <label className="modals__label">تاريخ المشكلة</label>
          <Controller
            name="issuingDate"
            control={control}
            render={({ field }) => (
              <Calendar {...field} dateFormat="mm/dd/yy" showIcon className="w-full" />
            )}
          />
          <span className="text-red-500">{errors.issuingDate?.message as React.ReactNode}</span>
        </div>
      </div>

      <div className="w-full flex gap-5">
        <div className="w-full flex flex-column gap-2">
          <label className="modals__label">تاريخ الشكوى</label>
          <Controller
            name="complaintDate"
            control={control}
            render={({ field }) => (
              <Calendar {...field} dateFormat="mm/dd/yy" showIcon className="w-full" />
            )}
          />
          <span className="text-red-500">{errors.complaintDate?.message as React.ReactNode}</span>
        </div>
      </div>

      <div className="w-full flex gap-5">
        <div className="w-full flex flex-column gap-2">
          <label className="modals__label">اختر حالة</label>
          <Controller
            name="statusId"
            control={control}
            render={({ field }) => (
              <CustomDropdown
                {...field}
                options={statusData?.data ?? []}
                optionValue="id"
                optionLabel="name"
                placeholder="اختر الحالة"
                className="w-full"
                disabled={isStatusLoading}
              />
            )}
          />
          <span className="text-red-500">{errors.statusId?.message as React.ReactNode}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomerComplaintForm;
