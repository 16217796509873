import { AnyObject, Lazy, ObjectSchema } from 'yup';
import { Toast } from 'primereact/toast';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { confirmDialog } from 'primereact/confirmdialog';
import axios from 'axios';

import { axiosInstance } from '../global';

const useModal = (
  defaultValues: Record<string, any>,
  apiUrl: string,
  onSuccess: () => void,
  schema: ObjectSchema<Record<string, any>, AnyObject, any, ''> | Lazy<{ [x: string]: any }>,
  toast: React.RefObject<Toast>
) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const openModal = (initialValues: any = {}) => {
    setIsVisible(true);
    setIsEdit(initialValues.id !== undefined);
    reset({ ...initialValues });
  };

  const closeModal = () => {
    confirmDialog({
      header: 'هل ترغب في الالغاء ؟',
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      accept: () => {
        reset(defaultValues);
        setIsVisible(false);
      }
    });
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const response = await (isEdit ? axiosInstance.put(apiUrl, data) : axiosInstance.post(apiUrl, data));

      if (response.status === 200 || response.status === 201 || response.status === 202) {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: isEdit ? 'تم التعديل بنجاح' : 'تم الإضافة بنجاح',
          life: 3000
        });
        onSuccess();
        reset(defaultValues);
        setIsVisible(false);
        setLoading(false);
      } else {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: isEdit ? 'فشلت عملية التعديل' : 'فشلت عملية الإضافة',
          life: 3000
        });
        setLoading(false);
      }
    } catch (err) {
      let errorMessage: string;
      if (axios.isAxiosError(err)) {
        errorMessage = err.response?.data?.message || err.message;
      } else {
        errorMessage = (err as Error).message;
      }
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: `Failed to save data ${errorMessage}`,
        life: 3000
      });
      setLoading(false);
    }
  };

  return {
    isVisible,
    openModal,
    closeModal,
    control,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    toast,
    setValue,
    getValues,
    watch,
    loading, // Return loading state
    setLoading
  };
};

export default useModal;
