import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userReducers';
import employeeReducer from './employee/employeeReducers';
import customerReducer from './customer/customerReducers';
import productReducer from './product/productReducers';
import warehouseReducer from './warehouse/warehouseReducers';
import activityReducer from  './activity/activityReducers';
import visitReducer from  './visit/visitReducers';
import visitReducer1 from  './visit/visitReducers1';
import visitReducer2 from  './visit/visitReducers2';
import visitReducer3 from  './visit/visitReducers3';
import visitReducer4 from  './visit/visitReducers4';
import visitReducer5 from  './visit/visitReducers5';
import visitCostReducer from './visit/visitCostReducer';
import contractReducer from './contract/contractReducers';


const store = configureStore({
  reducer: {
    user: userReducer,
    employee: employeeReducer,
    customer: customerReducer,
    product: productReducer,
    warehouse: warehouseReducer,
    activity: activityReducer,
    visit: visitReducer,
    visitInstallments: visitReducer1,
    visitStages: visitReducer2,
    visitFaults: visitReducer3,
    visitRequstedItems: visitReducer4,
    visitServices: visitReducer5,
    visitCost: visitCostReducer,
    contract: contractReducer
  },
  middleware: [thunk]
});

export default store;
