import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Chips } from 'primereact/chips';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { connect } from 'react-redux';

import './Modals.scss';
import { InputTextarea } from 'primereact/inputtextarea';

import { API_BASE_URL, axiosInstance } from '../../global';

function AddProduct({
  token,
  title,
  entityName,
  selectedLevel,
  visible,
  setVisible,
  statuses,
  onClosePrompt,
  onAddHandler
}) {
  const toast = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [families, setFamilies] = useState([]);
  const [types, setTypes] = useState([]);
  const [entity, setEntity] = useState({
    names: [],
    statusId: null,
    productTypeId: null,
    productFamilyId: null,
    remarks: ''
  });

  useEffect(() => {
    axiosInstance
      .get(`/ProductsFamily/all`)
      .then((response) => {
        setFamilies(response.data.data);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/ProductsType/all`)
      .then((response) => {
        setTypes(response.data.data);
      })
      .catch((error) => {});
  }, []);

  function acceptClose() {
    setEntity({
      names: [],
      statusId: null,
      productTypeId: null,
      productFamilyId: null,
      remarks: ''
    });
    setSelectedStatus(null);
    setSelectedFamily(null);
    setSelectedType(null);
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  function handleStatusChange(e) {
    setSelectedStatus(e.value);
    setEntity({ ...entity, statusId: e.value });
  }

  function handleFamilyChange(e) {
    setSelectedFamily(e.value);
    setEntity({ ...entity, productFamilyId: e.value });
  }

  function handleTypeChange(e) {
    setSelectedType(e.value);
    setEntity({ ...entity, productTypeId: e.value });
  }

  function handleAddItemChipBtn(e) {
    const kbEvent = new KeyboardEvent('keydown', {
      bubbles: true,
      cancelable: true,
      key: 'Enter'
    });
    document.body.dispatchEvent(kbEvent);
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog tagKey={2} />
      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <Message
            severity='info'
            text={`يجب اختيار نوع المنتج ${selectedLevel > 1 ? 'والتصنيف' : ''} اولاً حتى يكون بأمكانك اضافة ${
              selectedLevel > 1 ? 'فئة' : 'تصنيف'
            } المنتجات داخلها`}
            className='text-black-alpha-90 font-semibold'
          />

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر نوع المنتج</label>
              <div className='flex gap-1'>
                <Dropdown
                  value={selectedType}
                  onChange={handleTypeChange}
                  options={types}
                  optionLabel='name'
                  optionValue='id'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          {selectedLevel > 1 && (
            <div className='w-full flex gap-5'>
              <div className='w-full flex flex-column gap-2'>
                <label className='modals__label'>اختر تصنيف المنتج</label>
                <div className='flex gap-1'>
                  <Dropdown
                    value={selectedFamily}
                    onChange={handleFamilyChange}
                    options={families}
                    optionLabel='name'
                    optionValue='id'
                    className='w-full'
                  />
                </div>
              </div>
            </div>
          )}

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اضف اسم/اسماء {entityName} الجديدة</label>
              <div className='flex gap-1'>
                <Chips
                  name='names'
                  value={entity.names}
                  addOnBlur={true}
                  className='w-full'
                  onChange={handleInputChange}
                  disabled={!selectedType || (selectedLevel > 1 && !selectedType && !selectedFamily)}
                />
                <Button
                  label='اضف'
                  severity='secondary'
                  outlined
                  raised
                  onClick={handleAddItemChipBtn}
                  disabled={!selectedType || (selectedLevel > 1 && !selectedType && !selectedFamily)}
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر حالة {entityName}</label>
              <div className='flex'>
                <Dropdown
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  placeholder='اختر الحالة'
                  className='w-full'
                  disabled={!selectedType || (selectedLevel > 1 && !selectedType && !selectedFamily)}
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea
                name='remarks'
                value={entity.remarks}
                className='w-full'
                onChange={handleInputChange}
                disabled={!selectedType || (selectedLevel > 1 && !selectedType && !selectedFamily)}
              />
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button
              className='modals__btn'
              onClick={() => onAddHandler(entity)}
              disabled={!selectedType || (selectedLevel > 1 && !selectedType && !selectedFamily)}
            >
              اضافة {entityName}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
