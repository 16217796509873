import React, { useRef, useState } from 'react';

import Sidebar from '../../../components/Sidebar/Sidebar';

import './EditSupplier.scss';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '../../../global';

import EditSupplier1 from './EditSupplier1';

const EditSupplier = ({ token }) => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [supplier, setSupplier] = useState({});
  const [isFormOk, setIsFormOk] = useState(false);

  function handleِEditOnClick() {
    try {
      delete supplier.userId;
      delete supplier.transDateTime;
      axiosInstance
        .put('/Supplier', supplier)
        .then((res) => {
          toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم تعديل مورد بنجاح' });
          setTimeout(() => navigate('/warehouses/suppliers/'), 1500);
        })
        .catch((error) => {
          toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية تعديل مورد' });
        });
    } catch (errorEditSupplier) {
      console.log(error);
      console.log(errorEditSupplier);
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية تعديل مورد' });
    }
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <div className='flex flex-column gap-5 edit__supplier__main'>
        <div className='flex steps card'></div>
        <div>
          <div>
            <EditSupplier1 setCheck={setIsFormOk} supplier={supplier} setSupplier={setSupplier} />
          </div>
          <div className='btn mb-5'>
            <Button className='btn__nextbtn' onClick={handleِEditOnClick} disabled={!isFormOk}>
              حفظ تعديل المورد
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSupplier);
