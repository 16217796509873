import { connect } from 'react-redux';

import UploadFile from '../../../components/UploadFile/UploadFile';
import './EditSparesDetails.scss';

function EditSpares2({ setCheck, productBase }) {
  const Style = { color: 'red', padding: '0.7rem' };
  return (
    <div className='product'>
      <div className='product__images'>
        <div className='flex flex-column gap-5'>
          <span className='Title'>صور المنتج</span>
          <div className='w-full flex gap-5'>
            <div className='flex w-full flex-column gap-4'>
              <label className='addproduct__label'>
                صورة الصنف<span style={Style}>*</span>
              </label>
              <UploadFile setCheck={setCheck} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSpares2);
