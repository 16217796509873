import React, { useRef } from 'react';
import { connect } from 'react-redux';

import { login } from '../../../redux/user/userActions';
import './Client.scss';
import Sidebar from '../../../components/Sidebar/Sidebar';
import useModal from '../../../hooks/useModal';

import * as yup from 'yup';

import ContactMethodTable from '../../../components/Tables/ContantMethodTable';
import ComplaintCategoryTable from '../../../components/Tables/ComplaintCategoryTable';
import ComplaintFamilyTable from '../../../components/Tables/ComplaintFamilyTable';
import ComplaintTypeTable from '../../../components/Tables/ComplaintTypeTable';
import Tabs from '../../../components/Tabs/Tabs';

const complaintTypeSchema = yup.object().shape({
  customerId: yup.string().required('اسم العميل مطلوب'),
  customerDeviceId: yup.string().required('جهاز العميل مطلوب'),
  complaintCategoryId: yup.string().required('تصنيف الشكوى مطلوب'),
  complaintDate: yup.date().required('تاريخ الشكوى مطلوب'),
  remarks: yup.string().required('تفاصيل الشكوى مطلوبة'),
  statusId: yup.string().required('حالة الشكوى مطلوبة')
});

function Client() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className=' main w-full'>
        <span className='main__title'>
          <i className='pi pi-database main__title--icon'></i> بيانات العملاء
        </span>
        <div className='mt-5 pr-4 pl-4'>
          <Tabs
            headers={['انواع الشكاوي', 'تصنيفات الشكاوي', 'فئات الشكاوي', 'بيانات التواصل']}
            contents={[
              <ComplaintTypeTable
                urlName='ComplaintsType'
                headerTitle='انواع الشكاوي'
                entityName='نوع شكوي'
                entityNameDefined='الشكوي'
                addBtnText='إضافة شكوي'
              />,
              <ComplaintFamilyTable
                urlName='ComplaintsFamily'
                headerTitle='تصنيفات الشكاوي'
                entityName='تصنيف شكوي'
                entityNameDefined='التصنيف'
                addBtnText='إضافة تصنيف شكوي'
              />,
              <ComplaintCategoryTable
                urlName='ComplaintsCategory'
                headerTitle='فئات الشكاوي'
                entityName='فئة شكوي'
                entityNameDefined='الفئة'
                addBtnText='إضافة فئة شكوي'
              />,
              <ContactMethodTable
                urlName='ContactMethod'
                headerTitle='بيانات التواصل'
                entityName='بيان تواصل'
                entityNameDefined='بيان التواصل'
                addBtnText='إضافة بيان التواصل'
              />
            ]}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) => dispatch(login(credentials))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Client);
