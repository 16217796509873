import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Link, useNavigate } from 'react-router-dom';

import { API_BASE_URL, axiosInstance } from '../../global';
import AddWarehouseSection from '../Modals/AddWarehouseSection';
import AddWarehouseLocation from '../Modals/AddWarehouseLocation';
import EditWarehouseSection from '../Modals/EditWarehouseSection';
import EditWarehouseLocation from '../Modals/EditWarehouseLocation';
import '../../style/Tables.scss';

function WarehouseDetailsTable({
  token,
  columns,
  showId,
  haveChoice,
  choiceRoute,
  urlName,
  addEditUrl,
  headerTitle,
  entityNameDefined,
  addBtnText,
  index
}) {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedForEdit, setSelectedForEdit] = useState({});
  const [newlist, setNewlist] = useState([]);
  const speeddialItems = [
    {
      label: 'اضافة اذن صرف',
      icon: 'pi pi-upload',
      command: () => navigate('/warehouses/transactions/issuing/add')
    },
    {
      label: 'اضافة اذن استلام',
      icon: 'pi pi-download',
      command: () => navigate('/warehouses/transactions/receiving/add')
    }
  ];

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    if (!editModalVisible) setSelectedForEdit({});
  }, [editModalVisible]);

  function fetchItems() {
    setLoading(true);
    axiosInstance
      .get(`/${urlName}`)
      .then((response) => {
        setNewlist(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSearchNameChange(e) {}

  function showEditModal(entity) {
    console.log(entity);
    setSelectedForEdit(entity);
    setEditModalVisible(true);
  }

  function addHandler(entity) {
    axiosInstance
      .post(`/${addEditUrl}`, entity)
      .then((response) => {
        fetchItems();
        toast.current.show({
          severity: 'success',
          summary: 'عملية ناجحة',
          detail: `تم إضافة ${entityNameDefined} بنجاح`
        });
        setSelectedForEdit({});
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: 'عملية خاطئة',
          detail: `فشلت عملية إضافة ${entityNameDefined}`
        });
      });
  }

  function editHandler(entity) {
    axiosInstance
      .put(`/${addEditUrl}`, entity)
      .then((response) => {
        fetchItems();
        toast.current.show({
          severity: 'success',
          summary: 'عملية ناجحة',
          detail: `تم تعديل ${entityNameDefined} بنجاح`
        });
        setSelectedForEdit({});
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: 'عملية خاطئة',
          detail: `فشلت عملية تعديل ${entityNameDefined}`
        });
      });
  }

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>{headerTitle}</span>
        <Toast ref={toast} />
        <div className='header__tools'>
          {index > 0 ? (
            <button className='tbl__addbtn tbl__addbtn--cst4 m-0' onClick={() => setAddModalVisible(true)}>
              <img src='/images/plus.svg' />
              إضافة {addBtnText}
            </button>
          ) : (
            <>
              <SpeedDial
                model={speeddialItems}
                direction='left'
                className='tbl__addbtn--cst4 m-0'
                buttonTemplate={(props) => <Button label='اضافة' severity='secondary' onClick={props.onClick} />}
              />
              <Tooltip target='.p-speeddial-action' position='bottom' />
            </>
          )}
        </div>
      </div>
      {index === 1 ? (
        <>
          <AddWarehouseSection visible={addModalVisible} setVisible={setAddModalVisible} onAddHandler={addHandler} />
          <EditWarehouseSection
            visible={editModalVisible}
            setVisible={setEditModalVisible}
            onEditHandler={editHandler}
            entityBase={selectedForEdit}
          />
        </>
      ) : index === 2 ? (
        <>
          <AddWarehouseLocation visible={addModalVisible} setVisible={setAddModalVisible} onAddHandler={addHandler} />
          <EditWarehouseLocation
            visible={editModalVisible}
            setVisible={setEditModalVisible}
            onEditHandler={editHandler}
            entityBase={selectedForEdit}
          />
        </>
      ) : null}

      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={newlist}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          {showId && <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />}
          {columns.map((column, index) => (
            <Column
              key={index}
              header={column.header}
              body={(rowData) =>
                column.isChip ? (
                  <Chip
                    label={
                      column.field.split('.').length > 1
                        ? rowData[column.field.split('.')[0]][column.field.split('.')[1]]
                        : rowData[column.field.split('.')[0]]
                    }
                  />
                ) : (
                  <span>
                    {column.field.split('.').length > 1
                      ? rowData[column.field.split('.')[0]][column.field.split('.')[1]]
                      : rowData[column.field.split('.')[0]]}
                  </span>
                )
              }
            />
          ))}
          {haveChoice && (
            <Column
              header='خيارات'
              body={(rowData) => (
                <Button
                  label={`تعديل ${entityNameDefined}`}
                  icon='pi pi-exclamation-circle'
                  severity='secondary'
                  text
                  onClick={() => showEditModal(rowData)}
                />
              )}
            />
          )}
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseDetailsTable);
