import React from 'react';
import './HeaderCard.scss';

const HeaderCard = ({ title, num, imageUrl, percent, chartImage, color, txtColor }) => {
  const Style = { background: `${color}` };
  const Style2 = { color: `${txtColor}` };
  return (
    <div className='card'>
      <div className='card__upper'>
        <div className='card__upper__img' style={Style}>
          <img src={imageUrl} alt='CardImage' />
        </div>
        <div className='card__upper__title'>
          <span className='card__upper__title__txt'>{title}</span>

          <div className='card__upper__title__coll'>
            {chartImage && (
              <div className='card__upper__title__coll__chartimg'>
                <img src={chartImage} alt='ChartImage' />
              </div>
            )}
            <div className='card__upper__title__coll__percent'>
              <span style={Style2}>{percent}</span>
            </div>
          </div>
        </div>
      </div>

      <div className='card__lower'>
        <span className='card__lower__num'>{num}</span>
      </div>
    </div>
  );
};

export default HeaderCard;
