import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import EditWorkingGroups from '../../../components/Modals/EditWorkingGroups';
import { useFetchData } from '../../../hooks/useFetchData';
import EmployeesWorkingGroupsDetailsTable from '../../../components/Tables/EmployeesWorkingGroupsDetailsTable';
import { axiosInstance } from '../../../global';
import Tabs from '../../../components/Tabs/Tabs';
import Sidebar from '../../../components/Sidebar/Sidebar';

function EmployeesWorkingGroupDetails({ userId }) {
  const toast = useRef(null);
  const params = useParams();
  const { data: groupDetails, loading: groupDetailsLoading } = useFetchData(
    `/EmployeesTeamsMatrix/allByTeamId/${params.id}`,
    [],
    3
  );
  const { data: statuses } = useFetchData(`/Status/dropdown`, [], 3);
  const [editWorkGroupVisible, setEditWorkGroupVisible] = useState(false);
  const [contents, setContents] = useState([]);

  useEffect(() => {
    if (!groupDetailsLoading) {
      groupDetails.positions.unshift('الكل');
      let temp = [];
      groupDetails.positions.forEach((position) => {
        temp.push(groupDetails.employees.filter((employee) => employee.position === position));
      });
      temp.shift();
      temp.unshift(groupDetails.employees);
      setContents(temp);
    }
  }, [groupDetailsLoading]);

  function handleShowEditWorkGroup() {
    setEditWorkGroupVisible(true);
  }

  function handleEditWorkGroup(item) {
    axiosInstance
      .put(`/Team`, item)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم تعديل مجموعة العمل', life: 3000 });
        setEditWorkGroupVisible(false);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء تعديل مجموعة العمل', life: 3000 });
      });
  }

  function showConfirmDeleteWorkGroup(e) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف مجموعة العمل؟',
      header: 'تأكيد حذف مجموعة العمل',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept: handleDeleteWorkGroup,
      draggable: false,
      resizable: false,
      tagKey: 1
    });
  }
  function handleDeleteWorkGroup() {
    axiosInstance
      .delete(`/Team/${params.id}`)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم حذف مجموعة العمل', life: 3000 });
        setEditWorkGroupVisible(false);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء حذف مجموعة العمل', life: 3000 });
      });
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      {groupDetailsLoading ? (
        <ProgressSpinner />
      ) : (
        <div className='w-full'>
          <div className='w-full flex justify-content-between'>
            <span className='main__title'>
              <i className='main__title--icon'>
                <img src='../images/note1.svg' alt='' />
              </i>
              تفاصيل المجموعة ({groupDetails.team.name})
            </span>

            <div className='wh-details__header__left'>
              <Button className='gap-2' onClick={() => handleShowEditWorkGroup()} icon='pi pi-pencil' text>
                تعديل مجموعة العمل
              </Button>

              <Button className='gap-2 text-red-500' onClick={showConfirmDeleteWorkGroup} icon='pi pi-trash' text>
                حذف مجموعة العمل
              </Button>
            </div>
            <EditWorkingGroups
              visible={editWorkGroupVisible}
              setVisible={setEditWorkGroupVisible}
              statuses={statuses}
              onEditHandler={handleEditWorkGroup}
              onDeleteHandler={handleDeleteWorkGroup}
              initialData={groupDetails.team}
              userId={userId}
            />
          </div>
          <Toast ref={toast} />
          <ConfirmDialog tagKey={1} />
          <div className='mt-5 pr-4 pl-4'>
            <Tabs
              headers={groupDetails.positions}
              contents={contents.map((data, index) => (
                <EmployeesWorkingGroupsDetailsTable key={index} items={data} />
              ))}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesWorkingGroupDetails);
