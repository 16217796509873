import React, { useEffect, useState } from 'react';
import './EditTransactionDetails.scss';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import { fill_transaction } from '../../../redux/warehouse/warehouseActions';
import { axiosInstance } from '../../../global';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';

function EditTransaction1({ transactionBase, fill_transaction, setCheck }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const [transactionType, setTransactionType] = useState(null);
  const [warehouses, setWarehouses] = useState(null);
  const [suppliers, setSuppliers] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [destSrc, setDestSrc] = useState(null);
  const [transTypes, setTransTypes] = useState(null);
  const [sources, setSources] = useState(null);
  const [selectedSrcType, setSelectedSrcType] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState(null);
  const [destinations, setDestinations] = useState(null);
  const [selectedDestType, setSelectedDestType] = useState(null);
  const [selectedDest, setSelectedDest] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [transaction, setTransaction] = useState({});

  useEffect(() => {
    if (transactionBase.transType?.id === 1) {
      setSelectedSrc(transactionBase.warehouse.id);
      switch (transactionBase.destination.id) {
        case 1:
          setSelectedDest(transactionBase.destinationWarehouse.id);
          break;
        case 2:
          setSelectedDest(transactionBase.supplier.id);
          break;
        case 3:
          setSelectedDest(transactionBase.customer.id);
          break;
        default:
          break;
      }
    } else if (transactionBase.transType?.id === 2) {
      setSelectedDest(transactionBase.warehouse.id);
      switch (transactionBase.destination.id) {
        case 1:
          setSelectedSrc(transactionBase.warehouse.id);
          break;
        case 2:
          setSelectedSrc(transactionBase.supplier.id);
          break;
        case 3:
          setSelectedSrc(transactionBase.customer.id);
          break;
        default:
          break;
      }
    }
    setTransaction({
      ...transactionBase,
      sourceId: transactionBase.source?.id,
      destinationId: transactionBase.destination?.id,
      transTypeId: transactionBase.transType?.id,
      warehouseId: transactionBase.warehouse?.id,
      destinationWarehouseId: transactionBase.destinationWarehouse?.id,
      supplierId: transactionBase.supplier?.id,
      customerId: transactionBase.customer?.id
    });
    setTransactionType(transactionBase.transType?.id);
    setSelectedSrcType(transactionBase.source?.id);
    setSelectedDestType(transactionBase.destination?.id);
    setSelectedWarehouse(transactionBase.warehouse?.id);
  }, [transactionBase]);

  useEffect(() => {
    axiosInstance
      .get(`/DestinationsSource/all`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        setDestSrc(tempList);
      })
      .catch(() => {});
    axiosInstance
      .get(`/Warehouse/all`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        setWarehouses(tempList);
      })
      .catch(() => {});
    axiosInstance
      .get(`/TransType/dropdown`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        setTransTypes(tempList);
      })
      .catch(() => {});
    axiosInstance
      .get(`/Supplier/all`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        setSuppliers(tempList);
      })
      .catch(() => {});
    axiosInstance
      .get(`/Customer/all`)
      .then((response) => {
        const tempList = response.data.data.map((item) => {
          return {
            id: item.id,
            name: item.fullName
          };
        });
        setCustomers(tempList);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    switch (selectedSrcType) {
      case 1:
        setSources(warehouses);
        break;
      case 2:
        setSources(suppliers);
        break;
      case 3:
        setSources(customers);
        break;
      default:
        break;
    }
  }, [selectedSrcType]);

  useEffect(() => {
    switch (selectedDestType) {
      case 1:
        setDestinations(warehouses);
        break;
      case 2:
        setDestinations(suppliers);
        break;
      case 3:
        setDestinations(customers);
        break;
      default:
        break;
    }
  }, [selectedDestType]);

  useEffect(() => {
    fill_transaction(transaction);
    if (!transaction.name) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [transaction]);

  function handleAddInputChange(e) {
    setTransaction({ ...transaction, [e.target.name]: e.target.value || e.value });
  }

  function handleTransTypeChange(e) {
    setTransactionType(e.value);
    setTransaction({ ...transaction, transTypeId: e.value });
  }

  function setTransKey(switchValue, firstKey, value) {
    switch (switchValue) {
      case 1:
        setTransaction({ ...transaction, [firstKey]: value });
        break;
      case 2:
        setTransaction({ ...transaction, supplierId: value });
        break;
      case 3:
        setTransaction({ ...transaction, customerId: value });
        break;
      default:
        break;
    }
  }

  function handleSrcChange(e) {
    setSelectedSrc(e.value);
    setTransKey(selectedSrcType, 'warehouseId', e.value);
  }

  function handleDestChange(e) {
    setSelectedDest(e.value);
    setTransKey(selectedDestType, 'destinationWarehouseId', e.value);
  }

  return (
    <div className='transaction'>
      <form>
        <div className='transaction__content'>
          <div className='transaction__content__right'>
            <span className='Title'>البيانات الاساسية للاذن</span>
            <div className='flex w-full flex-column gap-4'>
              <label className='addtransaction__label'>
                اسم اﻻذن<span style={Style}>*</span>
              </label>
              <InputText name='name' value={transaction.name} onChange={handleAddInputChange} />
            </div>
            <div className='flex w-full flex-column gap-4'>
              <label className='addtransaction__label'>
                نوع الاذن<span style={Style}>*</span>
              </label>
              <CustomDropdown
                value={transaction.transTypeId}
                options={transTypes}
                optionValue='id'
                optionLabel='name'
                disabled={true}
                onChange={handleTransTypeChange}
              />
            </div>
            <div className='flex w-full flex-column gap-4'>
              <label className='addtransaction__label'>
                المخزن<span style={Style}>*</span>
              </label>
              <CustomDropdown
                name='warehouseId'
                value={selectedWarehouse}
                onChange={(e) => {
                  setSelectedWarehouse(e.value);
                  handleAddInputChange(e);
                }}
                options={warehouses}
                optionValue='id'
                optionLabel='name'
                placeholder='اختر المخزن'
              />
            </div>
          </div>
          <div className='transaction__content__left'>
            <span className='Title'>بيانات المستقبل</span>
            <div className='flex w-full flex-column gap-4'>
              <label className='addtransaction__label'>
                نوع المستقبل<span style={Style}>*</span>
              </label>
              <CustomDropdown
                name='destinationId'
                value={selectedDestType}
                onChange={(e) => {
                  setSelectedDestType(e.value);
                  handleAddInputChange(e);
                }}
                options={destSrc}
                optionValue='id'
                optionLabel='name'
                placeholder='اختر نوع المستقبل'
                disabled={transactionType === 2}
              />
            </div>
            {selectedDestType < 4 && (
              <div className='flex w-full flex-column gap-4'>
                <label className='addtransaction__label'>
                  المستقبل<span style={Style}>*</span>
                </label>
                <CustomDropdown
                  value={selectedDest}
                  onChange={handleDestChange}
                  options={destinations}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر المستقبل'
                  disabled={transactionType === 2}
                />
              </div>
            )}
            <span className='Title'>بيانات المصدر</span>
            <div className='flex w-full flex-column gap-4'>
              <label className='addtransaction__label'>
                نوع المصدر<span style={Style}>*</span>
              </label>
              <CustomDropdown
                name='sourceId'
                value={selectedSrcType}
                onChange={(e) => {
                  setSelectedSrcType(e.value);
                  handleAddInputChange(e);
                }}
                options={destSrc}
                optionValue='id'
                optionLabel='name'
                placeholder='اختر نوع المصدر'
                disabled={transactionType === 1}
              />
            </div>
            {selectedSrcType < 4 && (
              <div className='flex w-full flex-column gap-4'>
                <label className='addtransaction__label'>
                  المصدر<span style={Style}>*</span>
                </label>
                <CustomDropdown
                  value={selectedSrc}
                  onChange={handleSrcChange}
                  options={sources}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر المصدر'
                  disabled={transactionType === 1}
                />
              </div>
            )}
            <span className='Title'>اخرى</span>
            <div className='flex w-full flex-column gap-4'>
              <label className='addtransaction__label'>ملاحظات</label>
              <InputTextarea
                placeholder='ملاحظات عن اﻻذن بشكل عام'
                name='remarks'
                value={transaction.remarks}
                onChange={handleAddInputChange}
                rows={3}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.user.id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_transaction: (transaction) => dispatch(fill_transaction(transaction))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTransaction1);
