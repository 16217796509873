import { axiosInstance } from '../../global';

import {
  FETCH_CONTRACT_REQUEST,
  FETCH_CONTRACT_SUCCESS,
  FETCH_CONTRACT_FAILURE,
  ADD_CONTRACT_REQUEST,
  ADD_CONTRACT_SUCCESS,
  ADD_CONTRACT_FAILURE,
  EDIT_CONTRACT_REQUEST,
  EDIT_CONTRACT_SUCCESS,
  EDIT_CONTRACT_FAILURE,
  CHANGE_CONTRACT_STATE,
  CLEAR_CONTRACT_STATE
} from './contractTypes';



//====================fetch data===================================
export const fetchContract = (id) => {
  return (dispatch) => {
    dispatch(fetch_contract_request());
    axiosInstance
      .get(`/Contract/${id}`)
      .then((responseContractData) => {
        const tempContract = responseContractData.data.data;
        const formattedContract = {
        id:tempContract.id,
        customerDeviceId: tempContract.customerDevice.id,
        code: tempContract.code,
        typeId: tempContract.type.id,
        customerActivityId: tempContract.customerActivity.id,
        reference: tempContract.reference,
        contractDate: tempContract.contractDate,
        installmentsTypeId: tempContract.installmentsType.id,
        sellingPrice: tempContract.sellingPrice,
        downPayment: tempContract.downPayment,
        nbOfInstallments: tempContract.nbOfInstallments,
        installmentAmount: tempContract.installmentAmount,
        freeInstallation: tempContract.freeInstallation,
        expiryDate: tempContract.expiryDate,
        commission: tempContract.commission,
        beneficiaryEmployeeId: tempContract.beneficiaryEmployee.id,
        freezeDate: tempContract.freezeDate,
        freezedByUserId: tempContract.freezedByUser.id,
        statusId: tempContract.status.id,
        userId: tempContract.user.id,
        remarks: tempContract.remarks
        }
        dispatch(fetch_contract_success(formattedContract));
      })
      .catch((errorContractData) => {
        console.log({errorContractData});
        dispatch(fetch_contract_failure(errorContractData.message));
      });
  };
};
const fetch_contract_request = () => {
  return {
    type: FETCH_CONTRACT_REQUEST
  };
};
const fetch_contract_success = (contract) => {
  return {
    type: FETCH_CONTRACT_SUCCESS,
    payload: contract
  };
};
const fetch_contract_failure = (error) => {
  return {
    type: FETCH_CONTRACT_FAILURE,
    payload: error
  };
};

//=====================add data======================================
export const addContract = (contract) => {
  return (dispatch) => {
    dispatch(add_contract_request());
    axiosInstance
      .post(`/Contract`, contract)
      .then((response) => {
        dispatch(add_contract_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_contract_failure(error.message));
      });
  };
};
const add_contract_request = () => {
  return {
    type: ADD_CONTRACT_REQUEST
  };
};
const add_contract_success = (contract) => {
  return {
    type: ADD_CONTRACT_SUCCESS,
    payload: contract
  };
};
const add_contract_failure = (error) => {
  return {
    type: ADD_CONTRACT_FAILURE,
    payload: error
  };
};

//=====================edit data=====================================
export const editContract = (contract) => {
  const payload = {
        id:contract.id,
        customerDeviceId: contract.customerDeviceId,
        code: contract.code,
        typeId: contract.typeId,
        customerActivityId: contract.customerActivityId,
        reference: contract.reference,
        contractDate: contract.contractDate,
        installmentsTypeId: contract.installmentsTypeId,
        sellingPrice: contract.sellingPrice,
        downPayment: contract.downPayment,
        nbOfInstallments: contract.nbOfInstallments,
        installmentAmount: contract.installmentAmount,
        freeInstallation: contract.freeInstallation,
        expiryDate: contract.expiryDate,
        commission: contract.commission,
        beneficiaryEmployeeId: contract.beneficiaryEmployeeId,
        freezeDate: contract.freezeDate,
        freezedByUserId: contract.freezedByUserId,
        statusId: contract.statusId,
        userId: contract.userId,
        remarks: contract.remarks
  }
  return (dispatch) => {
    dispatch(edit_contract_request());
    axiosInstance
      .put(`/Contract`, payload)
      .then((response) => {
        dispatch(edit_contract_success(response.data.data));
      })
      .catch((error) => {
        dispatch(edit_contract_failure(error.message));
      });
  };
};
const edit_contract_request = () => {
  return {
    type: EDIT_CONTRACT_REQUEST
  };
};
const edit_contract_success = (contract) => {
  return {
    type: EDIT_CONTRACT_SUCCESS,
    payload: contract
  };
};
const edit_contract_failure = (error) => {
  return {
    type: EDIT_CONTRACT_FAILURE,
    payload: error
  };
};

//=================fill with data=====================================
export const fill_contract = (contract) => {
  return (dispatch) => {
    dispatch(change_contract_state(contract));
  };
};
const change_contract_state = (contract) => {
  return {
    type: CHANGE_CONTRACT_STATE,
    payload: contract
  };
};

export const clearContractState = () => {
  return {
    type: CLEAR_CONTRACT_STATE
  };
};
