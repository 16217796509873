import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { useNavigate, useParams } from 'react-router-dom';

import { axiosInstance } from '../../global';
import '../../style/Tables.scss';

function ProductServicesListTable({ token, urlName, headerTitle, addBtnText }) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`/${urlName}/all`)
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
  }, []);

  function handleSearchNameChange(e) {}

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>جدول {headerTitle}</span>

        <button className='tbl__addbtn' onClick={() => navigate(`/product/services/add`)}>
          <img src='/images/plus.svg' alt={addBtnText} />
          {addBtnText}
        </button>
        <Toast ref={toast} />

        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              className='header__tools__input'
              placeholder='ابحث بالاسم'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={items}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
          <Column header='الدولة' body={(rowData) => <span>{rowData.country}</span>} />
          <Column header='المحافظة' body={(rowData) => <span>{rowData.governorate}</span>} />
          <Column header='العملة' body={(rowData) => <span>{rowData.currency}</span>} />
          <Column header='تاريخ النفاذ' body={(rowData) => <span>{rowData.date}</span>} />
          <Column header='اخر تاريخ تحديث' body={(rowData) => <Chip label={rowData.transDateTime} />} />
          <Column
            header='خيارات'
            body={(rowData) => (
              <span>
                <Button
                  label={`مشاهدة و تعديل القائمة`}
                  icon='pi pi-eye'
                  severity='secondary'
                  text
                  onClick={() => navigate(`/product/services/edit/${rowData.id}`)}
                />
              </span>
            )}
          />
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductServicesListTable);
