import React, { useEffect, useState } from 'react';
import './EditProductDetails.scss';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { fill_product } from '../../../redux/product/productActions';
import { axiosInstance } from '../../../global';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';

function EditProduct2({ setCheck, fill_product, nbOfStages, productsStages }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [stagesNum, setStagesNum] = useState(0);
  const [stageTypes, setStageTypes] = useState(null);
  const [stageItems, setStageItems] = useState(null);
  const [check, setchecks] = useState(true);
  const [stageList, setStageList] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`/ProductsStagesType/all`)
      .then((response) => {
        const tempStageType = response.data.data.map((stageType) => {
          return {
            id: stageType.id,
            name: stageType.name
          };
        });
        setStageTypes(tempStageType);
      })
      .catch((error) => {
      });
    axiosInstance
      .get(`/Product/stages`)
      .then((response) => {
        const tempStageItem = response.data.data.map((stageItem) => {
          return {
            id: stageItem.id,
            name: stageItem.name
          };
        });
        setStageItems(tempStageItem);
      })
      .catch((error) => {
      });
    setStagesNum(nbOfStages);
    setStageList(productsStages);
  }, []);

  useEffect(() => {
    fill_product({ productsStages: stageList });
  }, [stageList]);

  function handleStageNumChange(e) {
    setStagesNum(e.value);
  }

  function enableStageNum() {
    setchecks(false);
  }

  function handleAddInputChange(e, index) {
    stageList[index][e.target.name] = e.value || e.target.value;
    setStageList([...stageList]);
  }

  function handleChekedChange(e, index) {
    stageList[index].mandatoryReplacement = e.checked;
    setStageList([...stageList]);
  }

  return (
    <div className="product">
      <div className="product__stages">
        <span className="Title">تفاصيل المراحل</span>
        <div className="w-full flex gap-5">
          <div className="flex w-full flex-column gap-4">
            <div className="stageNumNote">
              <li className="pi pi-info-circle"></li>
              قم بأضافة عدد المراحل حتى يكون بأمكانك اضافة تفاصيل المراحل
            </div>
            <label className="addproduct__label">
              عدد مراحل الفلتر المضافة<span style={Style}>*</span>
            </label>
            <InputNumber name="numStages" value={stagesNum} onChange={handleStageNumChange} disabled={check} />
            <div className="flex flex-row gap-2">
              <Button to={''} className="addtitle" onClick={enableStageNum}>
                <li className=" icon pi pi-pencil"></li>
                تعديل عدد المراحل
              </Button>
            </div>
            <div className="w-full flex">
              <Accordion className="w-full">
                {stageList.map((stage, index) => (
                  <AccordionTab header={`مرحلة ${index + 1}`} key={index}>
                    <div className="w-full flex gap-5">
                      <div className="flex w-full flex-column gap-4">
                        <div className="flex w-full flex-column gap-4">
                          <label className="addproduct__label">
                            رقم المرحلة<span style={Style}>*</span>
                          </label>
                          <InputNumber name="stageNb" value={stage.stageNb} disabled={true} />
                        </div>
                        <div className="flex w-full flex-column gap-4">
                          <label className="addproduct__label">
                            كود المرحلة<span style={Style}>*</span>
                          </label>
                          <InputText
                            type="text"
                            name="serial"
                            value={stage.serial}
                            onChange={(e) => handleAddInputChange(e, index)}
                          />
                        </div>
                        <div className="flex w-full flex-column gap-4">
                          <label className="addproduct__label">
                            نوع المرحلة<span style={Style}>*</span>
                          </label>
                          <CustomDropdown
                            name="stageTypeId"
                            value={stage.stageTypeId}
                            onChange={(e) => handleAddInputChange(e, index)}
                            options={stageTypes}
                            optionValue="id"
                            optionLabel="name"
                            placeholder="اختر نوع المرحلة"
                          />
                        </div>
                        <div className="flex w-full flex-column gap-4">
                          <label className="addproduct__label">
                            المرحلة<span style={Style}>*</span>
                          </label>
                          <CustomDropdown
                            name="stageItemId"
                            value={stage.stageItemId}
                            onChange={(e) => handleAddInputChange(e, index)}
                            options={stageItems}
                            optionValue="id"
                            optionLabel="name"
                            placeholder="اختر المرحلة"
                          />
                          <div className="flex w-full flex-row gap-1">
                            <Checkbox
                              onChange={(e) => handleChekedChange(e, index)}
                              checked={stage.mandatoryReplacement}
                            ></Checkbox>
                            <label className="addproduct__label">يجب التغيير</label>
                          </div>
                        </div>
                        <div className="flex w-full flex-column gap-4">
                          <label className="addproduct__label">
                            عدد شهور تغير المرحلة<span style={Style}>*</span>
                          </label>
                          <CustomDropdown
                            name="replacmentMonths"
                            value={stage.replacmentMonths}
                            onChange={(e) => handleAddInputChange(e, index)}
                            options={months}
                            placeholder="اختر عدد شهور تغير المرحلة"
                          />
                        </div>
                        <div className="flex w-full flex-column gap-4">
                          <label className="addproduct__label">
                            ملاحاظات<span style={Style}>*</span>
                          </label>
                          <InputTextarea
                            name="remarks"
                            value={stage.remarks}
                            onChange={(e) => handleAddInputChange(e, index)}
                            rows={5}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionTab>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    productsStages: state.product.product.productsStages,
    nbOfStages: state.product.product.nbOfStages
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_product: (product) => dispatch(fill_product(product))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct2);
