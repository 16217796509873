import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { message } from 'antd';

import AddVacationRequest from '../Modals/AddVacationRequest';
import { API_BASE_URL, axiosInstance } from '../../global';
import './VacationRequestTable.scss';

function VacationRequestTable({ token }) {
  const toast = useRef(null);
  const [addVisible, setAddVisible] = useState(false);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [editingItem, setEditingItem] = useState('');

  function fetchItems() {
    axiosInstance
      .get(`/VacationsRequest/all`)
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    axiosInstance
      .get(`/Status/all`)
      .then((response) => {
        const tempStatus = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setStatus(tempStatus);
      })
      .catch((error) => {});
  }, []);

  function handleEditItemSelection(item) {
    setAddVisible(true);
    setEditingItem(item);
  }

  function handleUpdate(item) {
    axiosInstance
      .put(`/VacationsRequest`, item)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: `تم تعديل اجازة للموظف بنجاح!`,
          life: 3000
        });
        fetchItems();
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: `فشلت عملية تعديل اجازة للموظف جديدة`,
          life: 3000
        });
      });
  }

  function handleAdd(item) {
    axiosInstance
      .post(`/VacationsRequest`, item)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: `تم إضافة اجازة للموظف بنجاح!`,
          life: 3000
        });
        fetchItems();
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: `فشلت عملية إضافة اجازة للموظف جديدة`,
          detail: error.response.data.message,
          life: 3000
        });
      });
  }

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>جدول طلبات الاجازات</span>
        <button className='tbl__addvacationbtn' onClick={() => setAddVisible(true)}>
          <img src='/images/plus.svg' alt={'اضافة اجازة للموظف'} />
          اضافة اجازة للموظف
        </button>
        <Toast ref={toast} />
        <AddVacationRequest
          visible={addVisible}
          setVisible={setAddVisible}
          title='إضافة اجازة'
          entityName='طلب اجازة'
          statuses={status}
          onClosePrompt='هل أنت متأكد من أنك تريد الخروج من عملية إضافة طلب اجازة جديد؟'
          onAddHandler={handleAdd}
          onUpdateHandler={handleUpdate}
          initialData={editingItem}
          key={1}
        />
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={items}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
          <Column header='اسم الموظف' body={(rowData) => <span>{rowData.employee.name}</span>} />
          <Column header='نوع الاجازة' body={(rowData) => <span>{rowData.vacationType.name}</span>} />
          <Column
            header='مدة الاجازة'
            body={(rowData) => (
              <span>
                من {rowData.fromDate} الي {rowData.toDates}
              </span>
            )}
          />
          <Column header='عدد ايام الاجازة' body={(rowData) => <span>{rowData.days}</span>} />
          <Column header='مقبولة من' body={(rowData) => <span>{rowData.approvedBy?.name ?? ''}</span>} />
          <Column header='الحالة' body={(rowData) => <Chip label={rowData.status.name} />} />
          <Column
            header='خيارات'
            body={(rowData) => (
              <span>
                <Button
                  label={`تعديل طلب الاجازة`}
                  icon='pi pi-pencil'
                  severity='secondary'
                  text
                  onClick={() => handleEditItemSelection(rowData)}
                />
              </span>
            )}
          />
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VacationRequestTable);
