import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { ProgressSpinner } from 'primereact/progressspinner';

import { API_BASE_URL, axiosInstance } from '../../../global';
import Tabs from '../../../components/Tabs/Tabs';
import Sidebar from '../../../components/Sidebar/Sidebar';

import './WarehouseDetails.scss';
import '../..//Products/Details/ProductsDetails.scss';

import HeaderCard from '../../../components/Cards/Home header/HeaderCard';
import WarehouseDetailsTable from '../../../components/Tables/WarehouseDetailsTable';


function WarehouseDetails({ token }) {
  const Style1 = { color: 'black' };
  const Style2 = { color: 'red' };
  const [isLoading, setIsLoading] = useState(true);
  const [warehouse, setWarehouse] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`/Warehouse/${params.id}`)
      .then((response) => {
        setWarehouse(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [params.id]);

  function showConfirmDeleteWarehouse(e) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف المخزن؟',
      header: 'تأكيد حذف المخزن',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      handleDeleteWarehouse,
      draggable: false,
      resizable: false
    });
  }

  function handleDeleteWarehouse(e) {
    axiosInstance
      .delete(`/Warehouse/${params.id}`)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'تم حذف المخزن',
          detail: '',
          life: 3000
        });
        setTimeout(() => navigate('/warehouse'), 3000);
      })
      .catch(() => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء حذف المخزن',
          detail: '',
          life: 3000
        });
      });
  }


  return (
    <div className='flex w-full'>
      <Sidebar />
      {isLoading ? (
        <div className='center-spinner'>
          <ProgressSpinner />
        </div>
      ) : (
        <div className=' main w-full'>
          <div className='wh-details__header1'>
            <ConfirmDialog />
            <div className='wh-details__header1__right'>
              <Button
                className='font-bold text-black-alpha-90'
                icon='pi pi-arrow-right'
                rounded
                text
                onClick={() => navigate(-1)}
              />
              <span className='wh-details__header1__right--text'>تفاصيل المخزن "{warehouse.name}"</span>
            </div>

            <div className='wh-details__header__left'>
              <Button
                className='wh-details__header1__left__btn'
                onClick={() => navigate(`/warehouse/edit/${params.id}`)}
                icon='pi pi-pencil'
                style={Style1}
                text
              >
                تعديل البيانات المخزن
              </Button>

              <Button
                className='wh-details__header1__left__btn'
                onClick={showConfirmDeleteWarehouse}
                icon='pi pi-trash'
                style={Style2}
                text
              >
                حذف المخزن
              </Button>
            </div>
          </div>
          <div className='details'>
            <div className='customerdetails__header__card'>
              <HeaderCard
                title={'اقسام المخزن'}
                num={'14 قسم'}
                imageUrl={'/images/user_box_light.svg'}
                color={'#F9D8D4'}
                txtColor={'red'}
              />
              <HeaderCard
                title={'مواقع التخزين'}
                num={'15 موقع'}
                imageUrl={'/images/officelife.svg'}
                color={'#6D54DE33'}
              />
              <HeaderCard
                title={'رصيد المنتجات داخل المخزن'}
                num={'500 منتج'}
                imageUrl={'/images/check.svg'}
                color={'#FFEDD0'}
              />
              <HeaderCard
                title={'رصيد المنتجات المحجوزة في المخزن'}
                num={'20 منتج'}
                imageUrl={'/images/coins.svg'}
                color={'#C050E838'}
              />
            </div>
            <div className='wh-details__content'>
              <div className='wh-details__content__uper'>
                <div className='wh-details__content__uper__right'>
                  <span className='wh-details__content__uper__right__wh__name'>{warehouse.name}</span>
                  <div className='primary'>
                    <div className='primary__data'>
                      <span className='primary__data--label'> كود المخزن: </span>
                      <span className='primary__data--text'>{warehouse.code}</span>
                    </div>
                    <div className='primary__data'>
                      <Chip label={warehouse.status.name} />
                    </div>
                    <span className='primary__data--title'>بيانات المخزن</span>
                    <div className='primary__data'>
                      <span className='primary__data--label'> موقع المخزن: </span>
                      <span className='primary__data--text'>{warehouse.location.name}</span>
                    </div>
                    <span className='primary__data--title'>مواقع المخزن</span>
                    <span className='primary__data--text'>{warehouse.address}</span>
                  </div>
                </div>
                <div className='wh-details__content__uper__left'>
                  <div className='primary'>
                    <span className='primary__data--title'>بيانات التواصل</span>
                    <div className='primary__data'>
                      <span className='primary__data--label'>طريقة التواصل: </span>
                      <span className='primary__data--text'>{warehouse.contactMethod.name}</span>
                    </div>
                    <div className='primary__data'>
                      <span className='primary__data--label'>رقم الهاتف: </span>
                      <span className='primary__data--text'>{warehouse.contactDetails}</span>
                    </div>
                    <span className='primary__data--title'>الملاحظات</span>
                    <div className='primary__data'>
                      <span className='primary__data--text'>{warehouse.remarks}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Tabs
              headers={['منتجات المخزن', 'اقسام المخزن', 'مواقع التخزين']}
              contents={[
                <WarehouseDetailsTable
                  haveChoice={false}
                  showId={false}
                  columns={[
                    { header: 'نوع المنتج', field: 'productType', isChip: false },
                    { header: 'فئة المنتج', field: 'productFamily', isChip: false },
                    { header: 'تصنيف المنتج', field: 'productCategory', isChip: false },
                    { header: 'اسم المنتج', field: 'product', isChip: false },
                    { header: 'إجمالي الكمية', field: 'totalQuantity', isChip: false },
                    { header: 'الكمية المتاحة', field: 'availableQuantity', isChip: false },
                    { header: 'سعر البيع', field: 'sellPrice', isChip: false }
                  ]}
                  urlName={`Warehouse/getStockSituation/${params.id}`}
                  headerTitle='رصيد مخزون السلعة'
                  addBtnText='إذن صرف'
                  index={0}
                />,
                <WarehouseDetailsTable
                  haveChoice={true}
                  choiceRoute='/warehouse/section/edit'
                  showId={true}
                  columns={[
                    { header: 'اقسام المخزن', field: 'name', isChip: false },
                    { header: 'حالة قسم المخزن', field: 'status.name', isChip: true },
                    { header: 'إجمالي الكمية في القسم', field: 'total', isChip: false },
                    { header: 'الكمية المتاحة في القسم', field: 'available', isChip: false }
                  ]}
                  urlName={`WarehousesSection/allByWarehouseId/${params.id}`}
                  addEditUrl='WarehousesSection'
                  headerTitle='قائمة اقسام المخزن'
                  addBtnText='قسم داخل المخزن'
                  entityNameDefined='قسم المخزن'
                  index={1}
                />,
                <WarehouseDetailsTable
                  haveChoice={true}
                  choiceRoute='/warehouse/location/edit'
                  showId={true}
                  columns={[
                    { header: 'مواقع تخزين', field: 'name', isChip: false },
                    { header: 'حالة موقع المخزن', field: 'status.name', isChip: true },
                    { header: 'قسم المخزن', field: 'warehouseSection.name', isChip: false },
                    { header: 'الـPlant كود', field: 'plantCode', isChip: false }
                  ]}
                  urlName={`WarehousesLocation/allByWarehouseId/${params.id}`}
                  addEditUrl='WarehousesLocation'
                  headerTitle='قائمة مواقع تخزين'
                  addBtnText='موقع تخزين للمخزن'
                  entityNameDefined='موقع المخزن'
                  index={2}
                />
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseDetails);
