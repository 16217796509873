import React from 'react';

import Sidebar from '../../../components/Sidebar/Sidebar';
import ListEntityTable from '../../../components/Tables/ListEntityTable';
import AddPayroll from '../../../components/Modals/AddPayroll';

export default function PayrollList() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title'>
          <i className='pi pi-database main__title--icon'></i>الموظفين
        </span>
        <div className='mt-5 pr-4 pl-4'>
          <ListEntityTable
            columns={[
              { header: 'اسم الموظف', field: 'employee.name', isChip: true },
              { header: 'تاريخ الاضافة', field: 'effectiveDate', isChip: false },
              { header: 'نوع الاضافة', field: 'payrollItem.name', isChip: false },
              { header: 'القيمة', field: 'amount', isChip: false },
              // { header: 'اسم المضيف', field: 'approvedBy.name', isChip: false },
              { header: 'الحالة', field: 'status.name', isChip: true }
            ]}
            urlName='EmployeesPayrollItem'
            headerTitle='مرتبات الموظفين'
            entityName='طلب مرتب'
            entityNameDefined='طلب المرتب'
            addBtnText='إضافة طلب اضافة/خصم للموظف'
            AddEntityComponent={AddPayroll}
          />
        </div>
      </div>
    </div>
  );
}
