import React from 'react';
import { connect } from 'react-redux';

import { login } from '../../../redux/user/userActions';
import Tabs from '../../../components/Tabs/Tabs';
import Sidebar from '../../../components/Sidebar/Sidebar';
import ComplaintTypeTable from '../../../components/Tables/ComplaintTypeTable';
import ComplaintFamilyTable from '../../../components/Tables/ComplaintFamilyTable';
import ComplaintCategoryTable from '../../../components/Tables/ComplaintCategoryTable';
import ContactMethodTable from '../../../components/Tables/ContantMethodTable';
import ListEntityTable from '../../../components/Tables/ListEntityTable';
import ProductsTable from '../../../components/Tables/ProductsTable';

function WarehousesLists() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title'>
          <i className='pi pi-database main__title--icon'></i>بيانات المخازن
        </span>

        <div className='mt-5 pr-4 pl-4'>
          <Tabs
            headers={['انواع المخازن', 'طرق الشحن', 'انواع اذنات الصرف']}
            contents={[
              <ListEntityTable
                columns={[
                  { header: 'انواع المخازن', field: 'name', isChip: true },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='WarehousesType'
                headerTitle='انواع المخازن'
                entityName='نوع المخزن'
                entityNameDefined='نوع المخزن'
                addBtnText='إضافة انواع مخازن جديدة'
                type={'generalList'}
              />,
              <ListEntityTable
                columns={[
                  { header: 'طرق الشحن', field: 'name', isChip: true },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='DeliveryTerm'
                headerTitle='طرق الشحن'
                entityName='بيانات طريقة الشحن'
                entityNameDefined='بيانات طريقة الشحن'
                addBtnText='إضافة طرق الشحن جديدة'
                type={'generalList'}
              />,
              <ListEntityTable
                columns={[
                  { header: 'اذونات الصرف', field: 'name', isChip: true },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='TransType'
                headerTitle='اذونات الصرف'
                entityName='اذن الصرف'
                entityNameDefined='اذن الصرف'
                addBtnText='إضافة اذونات صرف جديدة'
                type={'generalList'}
              />,
              <ProductsTable />
            ]}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) => dispatch(login(credentials))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehousesLists);
