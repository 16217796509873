import React, { useEffect, useRef, useState } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';

import { axiosInstance } from '../../../global';

import { EndpointEnum, EntitiesEnum } from './endpoints';

export function AddEditEndPointMatrix({
                                        title,
                                        entityName,
                                        visible,
                                        setVisible,
                                        onClosePrompt,
                                        onAddHandler,
                                        initialData,
                                        additionalFields = {}
                                      }) {
  const toast = useRef(null);
  const [list, setList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);

  const [entity, setEntity] = useState({
    id: null,
    endpointName: '',
    entityName: '',
    ids: []

  });

  useEffect(() => {
    if (entity.entityName === 'Status') {
      axiosInstance.get('/Status/dropdown').then(value => setList(value.data.data));
    }
  }, [entity.entityName]);
  useEffect(() => {
  }, [initialData]);

  function acceptClose() {
    setEntity({
      id: null,
      endpointName: '',
      entityName: '',
      ids: []
    });
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  const endpointOptions = Object.keys(EndpointEnum).map((key) => ({
    label: key,
    value: EndpointEnum[key]
  }));
  const entitiesOptions = Object.keys(EntitiesEnum).map((key) => ({
    label: key,
    value: key
  }));
  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>Endpoint</label>
              <div className='flex gap-1'>
                <Dropdown
                  name='endpointName'
                  options={endpointOptions}
                  value={entity.endpointName}
                  addOnBlur={true}
                  optionValue='value'
                  optionLabel='label'
                  className='w-full'
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>Entity</label>
              <div className='flex gap-1'>
                <Dropdown
                  name='entityName'
                  options={entitiesOptions}
                  value={entity.entityName}
                  addOnBlur={true}
                  optionValue='value'
                  optionLabel='label'
                  className='w-full'
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>Rows<span className='astrk'>*</span>
              </label>
              <div className='flex'>
                <MultiSelect
                  value={selectedList}
                  onChange={event => {
                    setSelectedList(event.target.value);
                    setEntity({...entity, ids: event.target.value.toString() });
                  }}
                  options={list}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  maxSelectedLabels={3}
                  placeholder='Select'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='Cancel' onClick={handleClose} size='small' outlined />
            <button className='modals__btn' onClick={() => onAddHandler(entity)}>
              اضافة {entityName}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
