import React, { useCallback, useMemo } from 'react';
import { DataTable, DataTableStateEvent } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Column } from 'primereact/column';
import { Chip } from 'primereact/chip';
import { Knob } from 'primereact/knob';
import { Rating } from 'primereact/rating';

import styles from '../GenericPage/GenericPage.module.scss';
import EmptyState from '../EmptyState/EmptyState';

interface DataTableValue {
  [key: string]: any;
}

interface ColumnProps {
  field: string;
  header: string;
  isBoolean?: boolean;
  isChip?: boolean;
  isKnob?: boolean;
  isRate?: boolean;
}

interface GenericDataTableProps<T extends DataTableValue> {
  data: T[];
  columns: ColumnProps[];
  page: number;
  pageSize: number;
  totalRecords: number;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  isLoading: boolean;
  onPageChange: (e: DataTableStateEvent) => void;
  onSort: (e: DataTableStateEvent) => void;
  onRowClick: (id: any) => void;
  edit: boolean;
  entityName: string;
  emptyStateUrl: string;
  emptyStateButtonClick?: () => void;
  allowExpansion?: (rowData: T) => boolean;
  rowExpansionTemplate?: (data: T) => React.ReactNode;
  expandedRows?: T[];
  onRowToggle?: (e: any) => void;
}

const BooleanColumn = ({ value }: { value: boolean }) => <span>{value ? 'Yes' : 'No'}</span>;

const ChipColumn = ({ value }: { value: string }) => {
  const isActive = value === 'Active';
  const chipStyles = useMemo(
    () => ({
      backgroundColor: isActive ? '#E8F8F2' : '#F8E9E9',
      border: `2px solid ${isActive ? '#39B54A' : '#B92026'}`,
      borderRadius: '25px',
      padding: '0 10px',
      alignItems: 'center'
    }),
    [isActive]
  );

  return (
    <Chip
      style={chipStyles}
      icon={
        <span
          style={{
            width: '10px',
            height: '10px',
            backgroundColor: isActive ? '#39B54A' : '#B92026',
            borderRadius: '50%',
            marginLeft: '8px'
          }}
        />
      }
      label={value}
    />
  );
};

const GenericDataTable = <T extends DataTableValue>({
  data,
  columns,
  page,
  pageSize,
  totalRecords,
  sortField,
  sortOrder,
  isLoading,
  onPageChange,
  onSort,
  onRowClick,
  edit,
  entityName,
  emptyStateUrl,
  emptyStateButtonClick,
  allowExpansion,
  rowExpansionTemplate,
  expandedRows,
  onRowToggle
}: GenericDataTableProps<T>) => {
  const renderBody = useCallback((rowData: T, column: ColumnProps) => {
    const field = column.field.split('.');
    const value = field.length > 1 ? rowData[field[0]][field[1]] : rowData[column.field];

    if (column.isBoolean) {
      return <BooleanColumn value={value} />;
    } else if (column.isChip) {
      return <ChipColumn value={value} />;
    } else if (column.isKnob) {
      return <Knob value={value} min={-365} max={365} size={50} valueColor='#EEBA00' />;
    } else if (column.isRate) {
      return <Rating value={value} cancel={false} />;
    } else {
      return <span>{value}</span>;
    }
  }, []);

  const renderOptions = useCallback(
    (rowData: T) => (
      <Button
        label={`${edit ? 'تعديل' : 'عرض'} ${entityName}`}
        icon={
          <img
            src={edit ? '/images/edit.svg' : '/images/information.svg'}
            alt={edit ? 'edit' : 'view'}
            style={{ paddingLeft: edit ? 0 : 4 }}
          />
        }
        className='button-options'
        severity='secondary'
        text
        onClick={() => onRowClick(rowData)}
      />
    ),
    [edit, entityName, onRowClick]
  );

  const memoizedColumns = useMemo(
    () =>
      columns.map((column) => (
        <Column
          key={column.field}
          field={column.field}
          header={column.header}
          sortable
          style={{ textAlign: 'right' }}
          body={(rowData) => renderBody(rowData, column)}
        />
      )),
    [columns, renderBody]
  );

  return (
    <div className={`${styles['data-container']} ${isLoading ? styles['flex-center'] : ''}`}>
      {isLoading ? (
        <ProgressSpinner />
      ) : data.length === 0 ? (
        <EmptyState
          title={`لا يوجد ${entityName}`}
          description={`لم تقم بإضافة أي ${entityName} حتى الآن، قم بالإضافة الآن`}
          buttonText={`إضافة ${entityName}`}
          buttonLink={emptyStateUrl}
          onButtonClick={emptyStateButtonClick}
        />
      ) : (
        <DataTable
          value={data}
          paginator
          rows={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          lazy
          first={page * pageSize}
          onPage={onPageChange}
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder === 'asc' ? 1 : -1}
          expandedRows={expandedRows}
          onRowToggle={onRowToggle}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          {allowExpansion && <Column expander={allowExpansion} style={{ width: '5rem' }} />}
          {memoizedColumns}
          {!allowExpansion && <Column header='خيارات' style={{ textAlign: 'right' }} body={renderOptions} />}
        </DataTable>
      )}
    </div>
  );
};

export default React.memo(GenericDataTable);
