import update from 'immutability-helper';

import {
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAILURE,
  CHANGE_PRODUCT_STATE
} from './productTypes';

const initialState = {
  product: {
      code: '',
      skuCode: '',
      name: '',
      productCategory: '',
      manufacturer: '',
      nbOfStages: '',
      status: '',
      countryOfOrigin: '',
      remarks: '',
      details: '',
      productsImages: [],
      brand:'',
      productsStages: [],
      installmentTypesIds: []
  },
  loading: false,
  error: ''
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        // ...state,
        product: action.payload,
        loading: false,
        error: ''
      };
    case FETCH_PRODUCT_FAILURE:
      return {
        // ...state,
        loading: false,
        error: action.payload
      };

    case ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload,
        loading: false,
        error: ''
      };
    case ADD_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case EDIT_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload,
        loading: false,
        error: ''
      };
    case EDIT_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CHANGE_PRODUCT_STATE:
      return update(state, {
        $merge: {
          product: {
            ...state.product,
            ...action.payload
          }
        }
      });

    default:
      return state;
  }
};

export default productReducer;
