import React from 'react';
import './HeaderCard.scss';

interface HeaderCardProps {
  title: string;
  num: number | string;
  imageUrl: string;
  percent?: string;
  chartImage?: string;
  color: string;
  txtColor?: string;
}

const HeaderCardTS: React.FC<HeaderCardProps> = ({ title, num, imageUrl, percent, chartImage, color, txtColor }) => {
  const style = { background: color };
  const style2 = { color: txtColor };
  
  return (
    <div className='card'>
      <div className='card__upper'>
        <div className='card__upper__img' style={style}>
          <img src={imageUrl} alt='CardImage' />
        </div>
        <div className='card__upper__title'>
          <span className='card__upper__title__txt'>{title}</span>

          <div className='card__upper__title__coll'>
            {chartImage && (
              <div className='card__upper__title__coll__chartimg'>
                <img src={chartImage} alt='ChartImage' />
              </div>
            )}
            <div className='card__upper__title__coll__percent'>
              <span style={style2}>{percent}</span>
            </div>
          </div>
        </div>
      </div>

      <div className='card__lower'>
        <span className='card__lower__num'>{num}</span>
      </div>
    </div>
  );
};

export default HeaderCardTS;
