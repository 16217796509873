import React, { useEffect, useRef, useState } from 'react';
import { Steps } from 'primereact/steps';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';

import Sidebar from '../../../components/Sidebar/Sidebar';
import { editSpares, fetchSpares } from '../../../redux/product/productActions';

import EditSpares2 from './EditSpares2';
import EditSpares1 from './EditSpares1';
import './EditSpares.scss';

const EditSpares = ({ activeIndexnum, editSpares, fetchSpares, product, token, loading, error }) => {
  const toast = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOk, setIsFormOk] = useState(false);
  const [editDone, setEditDone] = useState(false);

  useEffect(() => {
    fetchSpares(params.id, token);
    // console.log(product);
    if (activeIndexnum === '' || activeIndexnum === undefined) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndexnum);
    }
  }, []);

  useEffect(() => {
    if (editDone) {
      if (!loading && error.length) {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية تعديل القطعة' });
      } else {
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم تعديل القطعة بنجاح' });
        setTimeout(() => navigate('/productSpares'), 1000);
      }
    }
  }, [loading, error]);
  // const items = [{ label: 'تفاصيل القطعة الاساسية' }, { label: 'تفاصيل المراحل' }, { label: 'تفاصيل صور القطعة' }];
  const items = [{ label: 'تفاصيل القطعة الاساسية' }, { label: 'تفاصيل المراحل' }];

  function handleOnClick() {
    setActiveIndex(activeIndex + 1);
    if (activeIndex >= 1) {
      setActiveIndex(0);
    }
  }

  function handleِEditOnClick() {
    try {
      editSpares(product, token);
      setEditDone(true);
    } catch (erroreditSpares) {
      console.log(error);
      console.log(erroreditSpares);
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية تعديل القطعة' });
    }
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <div className='flex flex-column gap-5 edit__spares__main'>
        <div className='flex steps card'>
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true} />
        </div>
        <div>
          <div>
            {activeIndex === 0 && <EditSpares1 setCheck={setIsFormOk} productBase={product} />}
            {/* {activeIndex === 1 && <EditProduct2 setCheck={setIsFormOk} nbOfStages={nbOfStages} productBase={product} />} */}
            {/* {activeIndex === 2 && <EditProduct3 setCheck={setIsFormOk} productBase={product}/>} */}
          </div>
          <div className='btn mb-5'>
            {/* {activeIndex != 1 && (
              <Button className='btn__nextbtn' onClick={handleOnClick} disabled={!isFormOk}>
                الخطوة التالية
              </Button>
            )} */}
            {activeIndex === 0 && (
              // (loading ? (
              // <ProgressSpinner />
              // ) : (
              <Button className='btn__nextbtn' onClick={handleِEditOnClick} disabled={!isFormOk}>
                حفظ التعديلات
              </Button>
              // ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    product: state.product.product,
    loading: state.product.loading,
    error: state.product.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSpares: (id, token) => dispatch(fetchSpares(id, token)),
    editSpares: (product, token) => dispatch(editSpares(product, token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSpares);
