import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';
import { Rating } from 'primereact/rating';
import { Knob } from 'primereact/knob';

import EditEntity from '../Modals/EditEntity';
import AddEntity from '../Modals/AddEntity';
import { axiosInstance } from '../../global';
import '../../style/Tables.scss';

function CustomerEntityTable({
  token,
  columns,
  urlName,
  headerTitle,
  addBtnText,
  entityNameDefined,
  entityName,
  AddEntityComponent,
  isDevice,
  path,
  type,
  customerId
}) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [status, setStatus] = useState([]);
  const [addItemVisible, setAddItemVisible] = useState(false);
  const [editItemVisible, setEditItemVisible] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState(null);

  function fetchItems() {
    axiosInstance
      .get(`/${urlName}`)
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchItems();

    axiosInstance
      .get(`/Status/all`)
      .then((response) => {
        const tempStatus = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setStatus(tempStatus);
      })
      .catch((error) => {});
  }, []);

  function handleSearchNameChange(e) {}

  function handleAddItem(item) {
    axiosInstance
      .post(`/${urlName}`, item)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: `تم إضافة ${entityName} بنجاح!`, life: 3000 });
        setSelectedEditItem(null);
        fetchItems();
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: `فشلت عملية إضافة ${entityName} جديدة`, life: 3000 });
      });
  }

  function handleEditItem(item) {
    axiosInstance
      .put(`/${urlName}`, item)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: `'تم تعديل ${entityName} بنجاح!'`, life: 3000 });
        setSelectedEditItem(null);
        fetchItems();
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: `فشلت عملية تعديل ${entityName}`, life: 3000 });
      });
  }

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'> {headerTitle}</span>

        <Toast ref={toast} />

        {AddEntityComponent ? (
          <AddEntityComponent
            visible={addItemVisible || editItemVisible}
            setVisible={selectedEditItem ? setEditItemVisible : setAddItemVisible}
            title={selectedEditItem ? `تعديل ${entityName}` : addBtnText}
            entityName={entityName}
            onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية ${
              editItemVisible ? `تعديل ${entityName}` : `إضافة ${entityName}`
            } جديد؟`}
            onAddHandler={handleAddItem}
            onUpdateHandler={handleEditItem}
            initialData={selectedEditItem}
            statuses={status}
            onCloseHandler={() => setSelectedEditItem(null)}
          />
        ) : (
          <>
            <AddEntity
              visible={addItemVisible}
              setVisible={setAddItemVisible}
              title={addBtnText}
              entityName={entityName}
              statuses={status}
              onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية إضافة ${entityName} جديد؟`}
              onAddHandler={handleAddItem}
            />

            <EditEntity
              visible={editItemVisible}
              setVisible={setEditItemVisible}
              title={`تعديل ${entityName}`}
              entityName={entityName}
              entityBase={selectedEditItem}
              statuses={status}
              onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية تعديل ${entityName}؟`}
              onEditHandler={handleEditItem}
            />
          </>
        )}

        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              className='header__tools__input'
              placeholder='ابحث بالاسم'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>

        {type != 'followUp' && (
          <button className='tbl__visit__addbtn' onClick={() => setAddItemVisible(true)}>
            <img src='/images/plus.svg' alt={addBtnText} />
            {addBtnText}
          </button>
        )}
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={items}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          {isDevice ? (
            <Column header='ID' body={(rowData) => <span>#{rowData.device.id}</span>} />
          ) : (
            <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
          )}

          {type === 'installment' && [
            <Column header='اسم الجهاز' body={(rowData) => <span>{rowData.product.name}</span>} />,
            <Column header='فئة المنتج' body={(rowData) => <span>{rowData.product.productCategory?.name}</span>} />
          ]}
          {type === 'devices' && [
            <Column header='كود SKU' body={(rowData) => <span>{rowData.device.code}</span>} />,
            <Column header='اسم الجهاز' body={(rowData) => <span>{rowData.device.name}</span>} />,
            <Column header='فئة المنتج' body={(rowData) => <span>{rowData.device.productCategory?.name}</span>} />
          ]}
          {type === 'contracts' && [
            <Column header='اسم الجهاز' body={(rowData) => <span>{rowData.customerDevice.device?.name}</span>} />
          ]}
          {type === 'activity' && [
            <Column header='اسم الجهاز' body={(rowData) => <span>{rowData.customerDevice.device?.name}</span>} />,
            <Column
              header='فئة المنتج'
              body={(rowData) => <span>{rowData.customerDevice.device.productCategory?.name}</span>}
            />
          ]}
          {columns.map((column, index) => (
            <Column
              key={index}
              header={column.header}
              body={(rowData) => {
                if (column.isBoolean) {
                  return <span>{rowData[column.field] ? 'Yes' : 'No'}</span>;
                } else if (column.isChip) {
                  return (
                    <Chip
                      label={
                        column.field.split('.').length > 1
                          ? rowData[column.field.split('.')[0]][column.field.split('.')[1]]
                          : rowData[column.field.split('.')[0]]
                      }
                    />
                  );
                } else if (column.isRate) {
                  return column.field.split('.').length > 1 ? (
                    <Rating value={rowData[column.field.split('.')[0]][column.field.split('.')[1]]} cancel={false} />
                  ) : (
                    <Rating value={rowData[column.field.split('.')[0]]} cancel={false} />
                  );
                } else if (column.isKnob) {
                  return column.field.split('.').length > 1 ? (
                    <Knob
                      value={rowData[column.field.split('.')[0]][column.field.split('.')[1]]}
                      min={-365}
                      max={365}
                      size={50}
                      valueColor='#EEBA00'
                    />
                  ) : (
                    <Knob
                      value={rowData[column.field.split('.')[0]]}
                      min={-365}
                      max={365}
                      size={50}
                      valueColor='#EEBA00'
                    />
                  );
                } else {
                  return (
                    <span>
                      {column.field.split('.').length > 1
                        ? rowData[column.field.split('.')[0]][column.field.split('.')[1]]
                        : rowData[column.field.split('.')[0]]}
                    </span>
                  );
                }
              }}
            />
          ))}
          {type === 'devices' && (
            <Column
              header='خيارات'
              body={(rowData) => (
                <Link to={`${path}/${rowData.device.id}/${customerId}/customerView`}>
                  <Button label={entityNameDefined} icon='pi pi-exclamation-circle' severity='secondary' text />
                </Link>
              )}
            />
          )}
          {type === 'contracts' && (
            <Column
              header='خيارات'
              body={(rowData) => (
                <Link to={`${path}/${rowData.id}/${customerId}/contracts`}>
                  <Button label={entityNameDefined} icon='pi pi-exclamation-circle' severity='secondary' text />
                </Link>
              )}
            />
          )}
          {type === 'complaint' && (
            <Column
              header='خيارات'
              body={(rowData) => (
                <Link to={`${path}/${rowData.id}/${customerId}/complaint`}>
                  <Button label={entityNameDefined} icon='pi pi-exclamation-circle' severity='secondary' text />
                </Link>
              )}
            />
          )}
          {type === 'installment' && (
            <Column
              header='خيارات'
              body={(rowData) => (
                <Link to={`${path}/${rowData.id}`}>
                  <Button label={entityNameDefined} icon='pi pi-exclamation-circle' severity='secondary' text />
                </Link>
              )}
            />
          )}
          {type === 'activity' && (
            <Column
              header='خيارات'
              body={(rowData) => (
                <Link to={`${path}/${rowData.id}/${customerId}/activity`}>
                  <Button label={entityNameDefined} icon='pi pi-exclamation-circle' severity='secondary' text />
                </Link>
              )}
            />
          )}
          {type === 'followUp' && (
            <Column
              header='خيارات'
              body={(rowData) => (
                <Link to={`${path}/${rowData.id}/${customerId || rowData.customer.id}/followUp`}>
                  <Button label={entityNameDefined} icon='pi pi-exclamation-circle' severity='secondary' text />
                </Link>
              )}
            />
          )}
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEntityTable);
