import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Link } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { fill_product } from '../../redux/product/productActions';
import { axiosInstance } from '../../global';
import './Stages.scss';
const Stages = ({ title, stageNum, fill_product, setCheck, stageArray }) => {
  const Style = { color: 'red', padding: '0.7rem' };
  const [stageType, setStageType] = useState(null);
  const [stageItem, setStageItem] = useState(null);
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [Stage, setStage] = useState({
    id: '',
    serial: '',
    stageNb: stageNum,
    stageTypeId: '',
    stageItemId: '',
    mandatoryReplacement: false,
    replacmentMonths: '',
    remarks: ''
  });

  useEffect(() => {
    axiosInstance
      .get(`/ProductsStagesType/all`)
      .then((response) => {
        const tempStageType = response.data.data.map((stageType) => {
          return {
            id: stageType.id,
            name: stageType.name
          };
        });
        setStageType(tempStageType);
      })
      .catch((error) => {}); //product stage type

    axiosInstance
      .get(`/Product/stages`)
      .then((response) => {
        const tempStageItem = response.data.data.map((stageItem) => {
          return {
            id: stageItem.id,
            name: stageItem.name
          };
        });
        setStageItem(tempStageItem);
      })
      .catch((error) => {}); //product stage item
  }, []);

  useEffect(() => {
    stageArray[stageNum - 1] = { ...Stage };
    fill_product({ productsStages: stageArray });
    // if (
    //   Object.values(Stage).filter(
    //     (val, index) =>
    //       val === '' ||
    //       !val ||
    //       (Object.keys(Stage)[index] !== 'stageNb' && Object.keys(Stage)[index] !== 'mandatoryReplacement')
    //   ).length
    // ) {
    //   setCheck(false); //اقفل
    // } else {
    setCheck(true); //افتح
    // }
  }, [Stage]);

  function handleAddInputChange(e) {
    setStage({ ...Stage, [e.target.name]: e.target.value });
  }
  function handleChekedChange(e) {
    setStage({ ...Stage, mandatoryReplacement: e.checked });
  }
  return (
    <div className='Stage__content w-full flex gap-5'>
      <Accordion activeIndex={0}>
        <AccordionTab header={title}>
          <div className='w-full flex gap-5'>
            <div className='flex w-full flex-column gap-4'>
              <div className='flex w-full flex-column gap-4'>
                <label className='addproduct__label'>
                  رقم المرحلة<span style={Style}>*</span>
                </label>
                <InputNumber name='stageNb' value={Stage.stageNb} disabled={true} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addproduct__label'>
                  كود المرحلة<span style={Style}>*</span>
                </label>
                <InputText type='text' name='serial' value={Stage.serial} onChange={handleAddInputChange} />
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addproduct__label'>
                  نوع المرحلة<span style={Style}>*</span>
                </label>
                <Dropdown
                  name='stageTypeId'
                  value={Stage.stageTypeId}
                  onChange={handleAddInputChange}
                  options={stageType}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر نوع المرحلة'
                />
                <div className='flex flex-row gap-2'>
                  <button className='addbtn'>+</button>
                  <Link to={''} className='addtitle'>
                    اضافة / تعديل نوع مرحلة جديدة
                  </Link>
                </div>
              </div>
              <div className='flex w-full flex-column gap-4'>
                <label className='addproduct__label'>
                  المرحلة<span style={Style}>*</span>
                </label>
                <Dropdown
                  name='stageItemId'
                  value={Stage.stageItemId}
                  onChange={handleAddInputChange}
                  options={stageItem}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر المرحلة'
                />
                <div className='flex w-full flex-row gap-1'>
                  <Checkbox onChange={handleChekedChange} checked={Stage.mandatoryReplacement}></Checkbox>
                  <label className='addproduct__label'>يجب التغيير</label>
                </div>
              </div>

              <div className='flex w-full flex-column gap-4'>
                <label className='addproduct__label'>
                  عدد شهور تغير المرحلة<span style={Style}>*</span>
                </label>
                <Dropdown
                  name='replacmentMonths'
                  value={Stage.replacmentMonths}
                  onChange={handleAddInputChange}
                  options={months}
                  placeholder='اختر عدد شهور تغير المرحلة'
                />
              </div>

              <div className='flex w-full flex-column gap-4'>
                <label className='addproduct__label'>
                  ملاحاظات<span style={Style}>*</span>
                </label>
                <InputTextarea name='remarks' value={Stage.remarks} onChange={handleAddInputChange} rows={5} />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_product: (product) => dispatch(fill_product(product))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stages);
