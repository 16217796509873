import React, { lazy, startTransition, Suspense, useEffect, useRef, useState } from 'react';
import { Steps } from 'primereact/steps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
  addEmployee,
  clearEmployeeState,
  editEmployee,
  fetchEmployee,
  fill_employee
} from '../../../redux/employee/employeeActions';
import Sidebar from '../../../components/Sidebar/Sidebar';
import './AddEditEmployee.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const AddEditEmployee1 = lazy(() => import('./AddEditEmployee1'));
const AddEditEmployee2 = lazy(() => import('./AddEditEmployee2'));
const AddEditEmployee3 = lazy(() => import('./AddEditEmployee3'));

// const schema = yup.object().shape({
//   email: yup.string().email('البريد الالكترونى غير صحيح').required('البريد الالكترونى مطلوب'),
//   password: yup
//     .string()
//     .min(8, 'كلمة السر يجب ان تكون ٨ حروف على الاقل')
//     .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'كلمة السر غير صحيحة')
//     .required('كلمة السر مطلوبة'),
//   code: yup.string().required('كود الموظف مطلوب'),
//   name: yup
//     .string()
//     .matches(
//       /^(?=.*[ ])[a-zA-Z\u0600-\u06FF]+(([',. -][a-zA-Z\u0600-\u06FF ])?[a-zA-Z\u0600-\u06FF]*)*$/,
//       'الاسم غير صحيح'
//     )
//     .required('اسم الموظف مطلوب'),
//   nationalNb: yup
//     .string()
//     .length(14, 'الرقم القومي يجب ان يكون مكون من 14 رقم')
//     .matches(/^[123]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-9]\d)\d{4}\d$/, 'الرقم القومي غير صحيح')
//     .required('الرقم القومي مطلوب'),
//   phoneNumber: yup
//     .string()
//     .matches(/^(01[0-2,5]\d{8}|0\d{2}\d{7})$/, 'رقم الهاتف غير صحيح')
//     .required('رقم الهاتف مطلوب'),
//   genderId: yup.string().required('الجنس مطلوب او غير صحيح'),
//   hiringDate: yup.date().required('تاريخ التعيين مطلوب او غير صحيح'),
//   departmentId: yup.string().required('القسم مطلوب او غير صحيح'),
//   positionId: yup.string().required('الوظيفة مطلوبة او غير صحيحة'),
//   directManagerId: yup.string().nullable(),
//   attendanceTypeId: yup.string().required('نظام الحضور مطلوب او غير صحيح'),
//   teamIds: yup.array().of(yup.string()).min(1, 'الفريق مطلوب او غير صحيح').required('الفريق مطلوب او غير صحيح'),
//   statusId: yup.string().required('حالة الحساب مطلوبة او غير صحيحة'),
//   vacations: yup
//     .array()
//     .of(
//       yup.object().shape({
//         vacationFromDate: yup.date().required('من تاريخ مطلوب').typeError('من تاريخ مطلوب'),
//         vacationToDate: yup
//           .date()
//           .required('إلى تاريخ مطلوب')
//           .typeError('إلى تاريخ مطلوب')
//           .min(yup.ref('vacationFromDate'), 'الي تاريخ يجب ان يكون بعد من تاريخ'),
//         vacationTypeId: yup.string().required('نوع الإجازة مطلوب'),
//         vacationCount: yup.number().min(1, 'عدد الأيام يجب ان يكون أكثر من 1').required('عدد الأيام مطلوب')
//       })
//     )
//     .min(1, 'يجب أن تضيف مدة إجازة واحدة على الأقل'),
//   governorateId: yup.string().required('المحافظه مطلوبة'),
//   areaId: yup.string().required('المدينه مطلوبة'),
//   locationId: yup.string().required('المنطقه مطلوبة'),
//   payrollItems: yup
//     .array()
//     .of(
//       yup.object().shape({
//         name: yup.string().required('اسم مفرد المرتب مطلوب'),
//         payrollItemId: yup.string().required('معرف مفرد المرتب مطلوب'),
//         amount: yup.number().min(1, 'القيمة يجب أن تكون أكبر من 0').required('القيمة مطلوبة'),
//         effectiveDate: yup.date().required('تاريخ الاستحقاق مطلوب').typeError('تاريخ الاستحقاق مطلوب'),
//         endDate: yup
//           .date()
//           .required('تاريخ الانتهاء مطلوب')
//           .typeError('تاريخ الانتهاء مطلوب')
//           .min(yup.ref('effectiveDate'), 'تاريخ الانتهاء يجب ان يكون بعد تاريخ الاستحقاق'),
//         statusId: yup.string().required('الحالة مطلوبة')
//       })
//     )
//     .required('يجب إضافة مفردات المرتب')
// });

const AddEditEmployee = ({
  activeIndexnum,
  fetchEmployee,
  addEmployee,
  editEmployee,
  employee,
  token,
  loading,
  error,
  clearEmployeeState
}) => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOk, setIsFormOk] = useState(false);
  const [isPayrollValid, setIsPayrollValid] = useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // const { control, handleSubmit, watch, formState: { errors, isValid }, setValue, trigger } = useForm({
  //   resolver: yupResolver(schema),
  //   mode: 'onBlur',
  //   defaultValues: {
  //     // Initial form data setup
  //     ...employee
  //   }
  // });

  useEffect(() => {
    if (params.id) {
      setIsEditing(true);
      fetchEmployee(params.id);
    } else {
      clearEmployeeState();
    }
    return () => {
      clearEmployeeState();
    };
  }, [params.id, fetchEmployee, clearEmployeeState]);

  useEffect(() => {
    setActiveIndex(activeIndexnum ?? 0);
  }, [activeIndexnum]);

  const items = [
    { label: 'تفاصيل الموظف الاساسية' },
    { label: 'تفاصيل العنوان و المنطقة' },
    { label: 'تفاصيل الراتب' }
  ];

  const handleOnClick = () => {
    startTransition(() => {
      fill_employee(employee);
      setActiveIndex((prevIndex) => (prevIndex >= 2 ? 0 : prevIndex + 1));
    });
  };

  const handleBackClick = () => {
    startTransition(() => {
      fill_employee(employee);
      setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    });
  };

  useEffect(() => {
    if (submitDone && !loading) {
      if (error) {
        toast.current.show({
          severity: 'error',
          summary: 'عملية خاطئة',
          detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} الموظف: ${error}`
        });
      } else {
        toast.current.show({
          severity: 'success',
          summary: 'عملية ناجحة',
          detail: `تم ${isEditing ? 'تعديل' : 'إضافة'} الموظف بنجاح`
        });
        navigate('/employees');
      }
      setSubmitDone(false);
    }
  }, [loading, error, submitDone, navigate, isEditing]);

  const handleSubmitOnClick = () => {
    try {
      if (isEditing) {
        editEmployee(employee, token);
      } else {
        addEmployee(employee, token);
      }
      setSubmitDone(true);
    } catch (errorSubmit) {
      toast.current.show({
        severity: 'error',
        summary: 'عملية خاطئة',
        detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} الموظف`
      });
    }
  };

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <div className='flex flex-column gap-5 add-edit__emp__main'>
        <div className='flex steps card'>
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} />
        </div>
        <div>
          {loading ? (
            <ProgressSpinner style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
          ) : (
            <Suspense fallback={<ProgressSpinner />}>
              {activeIndex === 0 && <AddEditEmployee1 setCheck={setIsFormOk} employee={employee} />}
              {activeIndex === 1 && <AddEditEmployee2 setCheck={setIsFormOk} employee={employee} />}
              {activeIndex === 2 && (
                <AddEditEmployee3 setCheck={setIsFormOk} setPayrollValid={setIsPayrollValid} employee={employee} />
              )}
            </Suspense>
          )}
          <div className='btn mb-5'>
            {activeIndex !== 0 && (
              <Button className='btn__nextbtn' onClick={handleBackClick}>
                العودة للخطوة السابقة
              </Button>
            )}
            {activeIndex !== 2 && (
              <Button className='btn__nextbtn' onClick={handleOnClick} disabled={!isFormOk}>
                الخطوة التالية
              </Button>
            )}
            {activeIndex === 2 &&
              (loading ? (
                <ProgressSpinner />
              ) : (
                <Button className='btn__nextbtn' onClick={handleSubmitOnClick} disabled={!isFormOk || !isPayrollValid}>
                  {isEditing ? 'حفظ التعديلات' : 'إضافة الموظف'}
                </Button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  employee: state.employee.employee,
  loading: state.employee.loading,
  error: state.employee.error
});

const mapDispatchToProps = (dispatch) => ({
  fetchEmployee: (id) => dispatch(fetchEmployee(id)),
  addEmployee: (employee, token) => dispatch(addEmployee(employee, token)),
  editEmployee: (employee, token) => dispatch(editEmployee(employee, token)),
  clearEmployeeState: () => dispatch(clearEmployeeState())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditEmployee);
