import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Sidebar from '../../../components/Sidebar/Sidebar';
import Tabs from '../../../components/Tabs/Tabs';
import HeaderCard from '../../../components/Cards/Home header/HeaderCard';
import CustomerEntityTable from '../../../components/Tables/CustomerEntityTable';
import { axiosInstance } from '../../../global';


function FollowUpScreen() {
  const [status, setStatus] = useState([]);
  useEffect(() => {
    axiosInstance
      .get(`/Visit/allStatus`)
      .then((response) => {
        setStatus(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='main w-full'>
        <span className='main__title__custom'>
          <i className='main__title__custom--icon'>
            <img src='../images/briefcase1.svg' alt='' />
          </i>
          <span>الزيارات</span>
        </span>
        <button className='tbl__addbtn'>
          <Link className='tbl__addbtn__link' to={'/Activites/addVisitConnection'}>
            <img src='/images/plus.svg' alt={''} />
            <span>{'اضافة اتصال / زيارة جديدة'}</span>
          </Link>
        </button>

        <div className='mt-5 pr-4 pl-4'>
          <div className='customerdetails__header__card mb-4'>
            <HeaderCard
              title={'المتابعات المنتظرة'}
              num={'20'}
              imageUrl={'/images/sandclock.svg'}
              color={'#13DE8230'}
            />
            <HeaderCard title={'الزيارات المتأخرة'} num={'40'} imageUrl={'/images/altsleep.svg'} color={'#F9D8D4'} />
            <HeaderCard title={'تقيمات العملاء'} num={'60'} imageUrl={'/images/check.svg'} color={'#6D54DE33'} />
            <HeaderCard
              title={'الزيارات الغير محددة'}
              num={'30'}
              imageUrl={'/images/briefcase.svg'}
              color={'#FFEDD0'}
            />
          </div>

          <div className='details__table'>
            <Tabs
              headers={status.map((stat) => stat.name)}
              contents={status.map((stat) => (
                <CustomerEntityTable
                  key={stat.id}
                  columns={[
                    { header: 'العميل', field: 'customer.fullName', isChip: false },
                    { header: 'نوع الزيارة', field: 'type.name', isChip: false },
                    { header: 'الفنى', field: 'assignedRep.name', isChip: false },
                    { header: 'الاهمية', field: 'customerActivity.satisfactionLevel', isRate: true },
                    { header: 'تاريخ الزيارة', field: 'visitDate', isChip: false },
                    { header: 'الحالة', field: 'status.name', isChip: true }
                  ]}
                  urlName={`Visit/allByStatus/${stat.id}`}
                  headerTitle='قائمة المتابعات'
                  entityNameDefined='تفاصيل الزيارة'
                  entityName='الزيارة'
                  addBtnText='إضافة زيارة للعميل'
                  isDevice={false}
                  path='/customer/followUp/details'
                  type='followUp'
                />
              ))}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpScreen);
