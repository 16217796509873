import React, { useEffect, useRef, useState } from 'react';
import { Steps } from 'primereact/steps';

// import AddSpares2 from './AddSpares2';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';

import Sidebar from '../../../components/Sidebar/Sidebar';
import { addSpares } from '../../../redux/product/productActions';

import AddSpares1 from './AddSpares1';
import './AddSpares.scss';

const AddSpares = ({ activeIndexnum, addSpares, product, token, loading, error }) => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOk, setIsFormOk] = useState(false);
  const [addDone, setAddDone] = useState(false);

  useEffect(() => {
    if (activeIndexnum === '' || activeIndexnum === undefined) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndexnum);
    }
  }, []);

  useEffect(() => {
    if (addDone) {
      if (!loading && error.length) {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية إضافة القطعة' });
      } else {
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة القطعة بنجاح' });
        setTimeout(() => navigate('/productSpares/'), 1000);
      }
    }
  }, [loading, error]);

  // const items = [{ label: 'تفاصيل القطعة الاساسية' }, { label: 'تفاصيل المراحل' }];
  const items = [{ label: 'تفاصيل القطعة الاساسية' }];

  // function handleOnClick() {
  //   setActiveIndex(activeIndex + 1);
  //   if (activeIndex >= 1) {
  //     setActiveIndex(0);
  //   }
  // }

  function handleِAddOnClick() {
    try {
      addSpares(product, token);
      setAddDone(true);
    } catch (errorAddSpare) {
      console.log(error);
      console.log(errorAddSpare);
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية اضافة القطعة' });
    }
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <div className='flex flex-column gap-5 add__product__main'>
        <div className='flex steps card'>
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true} />
        </div>
        <div>
          <div>
            {activeIndex === 0 && <AddSpares1 setCheck={setIsFormOk} />}
            {/* {activeIndex === 1 && <AddSpares2 setCheck={setIsFormOk} />} */}
          </div>
          <div className='btn mb-5'>
            {/* {activeIndex != 1 && (
              <Button className='btn__nextbtn' onClick={handleOnClick} disabled={!isFormOk}>
                الخطوة التالية
              </Button>
            )} */}
            {activeIndex === 0 &&
              (loading ? (
                <ProgressSpinner />
              ) : (
                <Button className='btn__nextbtn' onClick={handleِAddOnClick} disabled={!isFormOk}>
                  اضافة القطعة
                </Button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    product: state.product.product,
    nbOfStages: state.product.product.nbOfStages,
    loading: state.product.loading,
    error: state.product.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSpares: (product, token) => dispatch(addSpares(product, token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSpares);
