const EndpointEnum = {
  General: 0,
  Country: 1,
  Client: 2,
  Employee: 3,
  Warehouse: 4,
  User: 5
};

const EntitiesEnum = {
  Status:0,
  Location:1,
  Types:2
};

export { EndpointEnum, EntitiesEnum };
