import React, { useEffect, useRef, useState, lazy, Suspense } from 'react';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';

import { addCustomer, editCustomer, fetchCustomer, fill_customer, clearCustomerState } from '../../../redux/customer/customerActions';
import Sidebar from '../../../components/Sidebar/Sidebar';
import './AddCustomer.scss';

const AddCustomer1 = lazy(() => import('./AddCustomer1'));
const AddCustomer2 = lazy(() => import('./AddCustomer2'));

const AddCustomer = ({ activeIndexnum, addCustomer, editCustomer, fetchCustomer, fill_customer, clearCustomerState, customer, token, loading, error }) => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOk, setIsFormOk] = useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (params.id) {
      setIsEditing(true);
      fetchCustomer(params.id);
    } else {
      clearCustomerState();
    }
    return () => {
      clearCustomerState();
    };
  }, [params.id, fetchCustomer, clearCustomerState]);

  useEffect(() => {
    setActiveIndex(activeIndexnum ?? 0);
  }, [activeIndexnum]);

  const items = [{ label: 'بيانات العميل الاساسية' }, { label: 'تفاصيل العنوان و المنطقة' }];

  useEffect(() => {
    if (submitDone && !loading) {
      if (error) {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} العميل: ${error}` });
      } else {
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: `تم ${isEditing ? 'تعديل' : 'إضافة'} العميل بنجاح` });
        navigate('/customers');
      }
      setSubmitDone(false);
    }
  }, [loading, error, submitDone, navigate, isEditing]);

  const handleOnClick = () => {
    fill_customer(customer);
    setActiveIndex((prevIndex) => (prevIndex >= 1 ? 0 : prevIndex + 1));
  };

  const handleBackClick = () => {
    fill_customer(customer);
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleSubmitOnClick = () => {
    try {
      if (isEditing) {
        editCustomer(customer, token);
      } else {
        addCustomer(customer, token);
      }
      setSubmitDone(true);
    } catch (errorSubmit) {
      console.error(errorSubmit);
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: `فشلت عملية ${isEditing ? 'تعديل' : 'إضافة'} العميل` });
    }
  };

  return (
    <div className="flex w-full">
      <Sidebar />
      <Toast ref={toast} />
      <div className="flex flex-column add__visit__main">
        <div className="details__header">
          <Button className="font-bold text-black-alpha-90" icon="pi pi-arrow-right" rounded text onClick={() => navigate(-1)} />
          <span className="details__header1__right--text">{isEditing ? 'تعديل عميل' : 'إضافة عميل جديد'}</span>
        </div>
        <div className="flex steps card">
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true} />
        </div>
        <div>
          {loading ? (
            <ProgressSpinner style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
          ) : (
            <Suspense fallback={<ProgressSpinner />}>
              {activeIndex === 0 && <AddCustomer1 setCheck={setIsFormOk} customer={customer} />}
              {activeIndex === 1 && <AddCustomer2 setCheck={setIsFormOk} customer={customer} />}
            </Suspense>
          )}
          <div className="btn mb-5">
            {activeIndex !== 0 && (
              <Button className="btn__nextbtn" onClick={handleBackClick}>
                العودة للخطوة السابقة
              </Button>
            )}
            {activeIndex !== 1 && (
              <Button className="btn__nextbtn" onClick={handleOnClick} disabled={!isFormOk}>
                الخطوة التالية
              </Button>
            )}
            {activeIndex === 1 && (
              <Button className="btn__nextbtn" onClick={handleSubmitOnClick} disabled={!isFormOk}>
                {isEditing ? 'حفظ التعديلات' : 'إضافة العميل'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  customer: state.customer.customer,
  loading: state.customer.loading,
  error: state.customer.error
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomer: (id) => dispatch(fetchCustomer(id)),
  addCustomer: (customer, token) => dispatch(addCustomer(customer, token)),
  editCustomer: (customer, token) => dispatch(editCustomer(customer, token)),
  fill_customer: (customer) => dispatch(fill_customer(customer)),
  clearCustomerState: () => dispatch(clearCustomerState())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);
