import React from 'react';

import Sidebar from '../../../components/Sidebar/Sidebar';
import Tabs from '../../../components/Tabs/Tabs';
import ListEntityTable from '../../../components/Tables/ListEntityTable';

import { AddEditWarehouseTransMatrix } from './AddEditWarehouseTransMatrix';
import { AddEditDestinationsSources } from './AddEditDestinationsSources';

export function WarehouseTransactionsMatrixList() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title'>
          <i className='pi pi-database main__title--icon'></i>Warehouses
        </span>
        <div className='mt-5 pr-4 pl-4'>
          <Tabs
            headers={['Warehouse Movement Matrix','Destinations Sources']}
            contents={[
              <ListEntityTable
                columns={[
                  { header: 'TransTypeName', field: 'transTypeName', isChip: true },
                  { header: 'DestinationSource', field: 'destinationSource.name', isChip: true },
                  { header: 'NextTransType', field: 'nextTransType.name', isChip: true },
                  { header: 'NextDestinationSource', field: 'nextDestinationSource.name', isChip: true },
                  { header: 'DefaultApprovalStatus', field: 'defaultApprovalStatus', isChip: true, isBoolean: true },
                  { header: 'InternalTransaction', field: 'internalTransaction', isChip: true, isBoolean: true },
                  { header: 'status', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='WarehouseMovementMatrix'
                headerTitle='Warehouse Movement Matrix'
                entityName='Warehouse Movement Matrix'
                entityNameDefined='Warehouse Movement Matrix'
                addBtnText='Add Warehouse Movement Matrix'
                AddEntityComponent={AddEditWarehouseTransMatrix}
                type={'generalList'}
              />,
              <ListEntityTable
                columns={[
                  { header: 'TransTypeName', field: 'name', isChip: true },
                  { header: 'DestinationSource', field: 'direction', isChip: true },
                  { header: 'DefaultApprovalStatus', field: 'allowFirstReceiving', isChip: true, isBoolean: true },
                  { header: 'status', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='DestinationsSource'
                headerTitle='Destinations Sources'
                entityName='Destinations Sources'
                entityNameDefined='Destinations Sources'
                addBtnText='Add Destinations Sources'
                AddEntityComponent={AddEditDestinationsSources}
                type={'generalList'}
              />
            ]}
          />
        </div>
      </div>
    </div>
  );
}
