import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

import { axiosInstance } from '../../../global';
import Tabs from '../../../components/Tabs/Tabs';
import Sidebar from '../../../components/Sidebar/Sidebar';


import '../Details/ProductsDetails.scss';
import ServicesListDetailsTable from '../../../components/Tables/ServicesListDetailsTable';

function ServicesListDetails({ token }) {
  const toast = useRef(null);
  const Style2 = { color: 'red' };

  const params = useParams();
  const navigate = useNavigate();

  function showConfirmDeleteProduct(e) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف القائمة',
      header: 'تأكيد حذف القائمة',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept: handleDeleteProduct,
      draggable: false,
      resizable: false
    });
  }

  function handleDeleteProduct(e) {
    axiosInstance
      .delete(`/ProductsPricesList/${params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'تم حذف القائمة',
          detail: '',
          life: 3000
        });
        setTimeout(() => navigate('/products'), 3000);
      })
      .catch(() => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء حذف القائمة',
          detail: '',
          life: 3000
        });
      });
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className=' main w-full'>
        <div className='details__header1'>
          <Toast ref={toast} />
          <ConfirmDialog />

          <div className='details__header1__right'>
            <Button
              className='font-bold text-black-alpha-90'
              icon='pi pi-arrow-right'
              rounded
              text
              onClick={() => navigate(-1)}
            />
            <span className='details__header1__right--text'> قائمة الخدمات</span>
          </div>

          <div className='details__header__left'></div>
        </div>
        <div className='mt-5'>
          <ServicesListDetailsTable />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesListDetails);
