import React, { useEffect, useState } from 'react';
import './Login.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link } from 'react-router-dom';
import { login } from '../../redux/user/userActions';
import { connect } from 'react-redux';

function Login({ login, loading, error }) {
  const [credentials, setCredentials] = useState({ userName: '', password: '' });
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (error) {
      setShowError(true);
      setErrorMsg('هناك خطأ في البريد الالكتروني او كلمة السر');
    } else {
      setShowError(false);
      setErrorMsg('');
    }
  }, [error]);

  function handleInputChange(event) {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  }

  function handleLogin(event) {
    event.preventDefault();
    if (!credentials.userName || !credentials.password) {
      setShowError(true);
      setErrorMsg('برجاء ادخال البريد الالكتروني وكلمة المرور');
      return;
    } else {
      setShowError(false);
      setErrorMsg('');
    }
    login(credentials);
  }

  return (
    <main className='login flex'>
      <div className='flex-shrink-0 login__banar'>
        <img src='/images/logo.png' alt='Best Aqua Solution' />
      </div>

      <div className='login__container'>
        <form className='login__form' onSubmit={handleLogin}>
          <span className='login__form__subheader'>مرحباً مجدداً</span>
          <span className='login__form__header'>تسجيل الدخول</span>
          {showError ? <span className='login__form__error mb-3'>{errorMsg}</span> : <></>}

          <div>
            <input
              type='text'
              placeholder='البريد الالكتروني'
              name='userName'
              autoComplete='true'
              className='login__form__input'
              onChange={handleInputChange}
            />
          </div>
          <div>
            <input
              type='password'
              placeholder='كلمة السر'
              name='password'
              autoComplete='true'
              className='login__form__input'
              onChange={handleInputChange}
            />
          </div>

          {loading ? (
            <ProgressSpinner />
          ) : (
            <button type='submit' className='login__form__btn'>
              سجل الدخول
            </button>
          )}

          <div className='login__form__link'>
            تواجة مشكلة؟ <Link>طلب تغيير كلمة السر</Link>
          </div>
        </form>
      </div>
    </main>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) => dispatch(login(credentials))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
