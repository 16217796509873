import React, { useEffect, useRef, useState, lazy, Suspense } from 'react';
import { Steps } from 'primereact/steps';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { clearContractState, editContract, fetchContract } from '../../../redux/contract/contractActions';
import './AddEditContract.scss';

const AddEditContract1 = lazy(() => import('./AddEditContract1'));
const AddEditContract2 = lazy(() => import('./AddEditContract2'));

// import {
//   addVisit,
//   addvisitInstallment,
//   addvisitStages,
//   addvisitFaults,
//   addvisitServices,
//   addvisitRequstedItems
// } from '../../../redux/visit/visitActions';
// const AddEditVisit2 = lazy(() => import('../../Visits/AddVisit/AddRealVisit/AddEditVisit2'));
// const AddEditVisit3 = lazy(() => import('../../Visits/AddVisit/AddRealVisit/AddEditVisit3'));
// const AddEditVisit4 = lazy(() => import('../../Visits/AddVisit/AddRealVisit/AddEditVisit4'));

const AddEditContract = ({
  activeIndexnum,
  addVisit,
  addvisitInstallment,
  addvisitStages,
  addvisitFaults,
  addvisitServices,
  addvisitRequstedItems,
  editContract,
  contract,
  visit,
  visitInstallments,
  visitFaults,
  visitRequstedItems,
  visitStages,
  visitServices,
  loadingVisit,
  visitError,
  fetchContract,
  clearContractState
}) => {
  const toast = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOk, setIsFormOk] = useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [visitContinueParameter, setVisitContinueParameter] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const items = [
    { label: 'بيانات العقد الاساسية' },
    { label: 'تفاصيل العقد الجديد' },
    { label: 'اضافة طلب زيارة' },
    { label: 'تفاصيل الزيارة' },
    { label: 'مراجعة الطلب' }
  ];

  useEffect(() => {
    setActiveIndex(activeIndexnum ?? 0);
  }, [activeIndexnum]);

  useEffect(() => {
    if (submitDone) {
      if (!loadingVisit && visitError.length) {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية إضافة زيارة' });
      } else if (!loadingVisit && !visitError.length && !visitContinueParameter) {
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة زيارة بنجاح' });
        setTimeout(() => navigate('/Visits'), 1000);
      } else if (!loadingVisit && !visitError.length && visitContinueParameter) {
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة زيارة بنجاح' });
      }
    }
  }, [loadingVisit, visitError]);

  useEffect(() => {
    if (params.type === 'Edit' && params.contractId) {
      setIsEditing(true);
      fetchContract(params.contractId);
    } else {
      clearContractState();
    }
    return () => {
      clearContractState();
    };
  }, [params.contractId, params.type, fetchContract, clearContractState]);

  function handleOnClick() {
    setActiveIndex(activeIndex + 1);
    if (activeIndex >= 3) {
      setActiveIndex(0);
    }
  }
  // function handleِAddVisitOnClick() {
  //   try {
  //     addVisit(visit);

  //     setSubmitDone(true);
  //   } catch (errorAddVisit) {
  //     console.log(errorAddVisit);
  //   }
  // }
  // function handleِAddVisit() {
  //   try {
  //     setVisitContinueParameter(true);
  //     addVisit(visit);
  //     setSubmitDone(true);
  //     handleOnClick();
  //   } catch (errorAddVisit) {
  //     console.log(errorAddVisit);
  //   }
  // }
  // function handleِAddVisitDetails() {
  //   try {
  //     try {
  //       addvisitInstallment(visitInstallments);
  //     } catch (Installmenterror) {
  //       console.log(Installmenterror);
  //     }
  //     try {
  //       addvisitStages(visitStages);
  //     } catch (Stageserror) {
  //       console.log(Stageserror);
  //     }
  //     try {
  //       addvisitFaults(visitFaults);
  //     } catch (Faultserror) {
  //       console.log(Faultserror);
  //     }
  //     try {
  //       addvisitRequstedItems(visitRequstedItems);
  //     } catch (ReqItemserror) {
  //       console.log(ReqItemserror);
  //     }
  //     try {
  //       addvisitServices(visitServices);
  //     } catch (Serviceserror) {
  //       console.log(Serviceserror);
  //     }
  //     setSubmitDone(true);
  //     toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة زيارة بنجاح' });
  //   } catch (error) {
  //     console.log(error);
  //     toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية إضافة زيارة' });
  //   }
  // }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <div className='flex flex-column gap-5 add__visit__main'>
        <div className='details__header'>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='details__header1__right--text'>إضافة طلب تجديد عقد</span>
        </div>
        <div className='flex steps card'>
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
        </div>
        <div>
          {loadingVisit ? (
            <ProgressSpinner style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
          ) : (
            <Suspense fallback={<ProgressSpinner />}>
              {activeIndex === 0 && <AddEditContract1 setCheck={setIsFormOk} contract={contract} />}
              {activeIndex === 1 && <AddEditContract2 />}
              {/* {activeIndex === 2 && <AddEditVisit2 setCheck={setIsFormOk} visit={visit} />}
              {activeIndex === 3 && <AddEditVisit3 setCheck={setIsFormOk} />}
              {activeIndex === 4 && <AddEditVisit4 setCheck={setIsFormOk} />} */}
            </Suspense>
          )}
          <div className='btn mb-5'>
            {activeIndex === 0 && (
              <Button className='btn__nextbtn' onClick={handleOnClick} disabled={!isFormOk}>
                التالى
              </Button>
            )}

            {activeIndex === 1 && (
              <Button className='btn__nextbtn' onClick={handleOnClick}>
                تأكيد اضافة العقد الجديد
              </Button>
            )}

            {activeIndex === 2 && (
              <Button className='btn__nextbtn' onClick={handleOnClick}>
                الخطوة التالية
              </Button>
            )}

            {activeIndex === 3 && (
              <Button className='btn__nextbtn' onClick={handleOnClick}>
                حفظ و انهاء الطلب
              </Button>
            )}

            {activeIndex === 4 && (
              <Button className='btn__nextbtn' onClick={handleOnClick}>
                حفظ و انهاء الطلب
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  contract: state.contract.contract
  // visit: state.visit.visit,
  // visitInstallments: state.visitInstallments.visitInstallments,
  // visitStages: state.visitStages.visitStages,
  // visitFaults: state.visitFaults.visitFaults,
  // visitRequstedItems: state.visitRequstedItems.visitRequstedItems,
  // visitServices: state.visitServices.visitServices,
  // loadingVisit: state.visit.loading,
  // visitError: state.visit.error
});

const mapDispatchToProps = (dispatch) => ({
  // addVisit: (visit) => dispatch(addVisit(visit)),
  // addvisitInstallment: (visitInstallments) => dispatch(addvisitInstallment(visitInstallments)),
  // addvisitRequstedItems: (visitRequstedItems) => dispatch(addvisitRequstedItems(visitRequstedItems)),
  // addvisitStages: (visitStages) => dispatch(addvisitStages(visitStages)),
  // addvisitFaults: (visitFaults) => dispatch(addvisitFaults(visitFaults)),
  // addvisitServices: (visitServices) => dispatch(addvisitServices(visitServices)),
  editContract: (contract) => dispatch(editContract(contract)),
  fetchContract: (id) => dispatch(fetchContract(id)),
  clearContractState: () => dispatch(clearContractState())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditContract);
