import React from 'react';
import { connect } from 'react-redux';

import Sidebar from '../../components/Sidebar/Sidebar';
import Tabs from '../../components/Tabs/Tabs';
import WarehousesTable from '../../components/Tables/WarehousesTable';

function Suppliers({ token }) {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title'>
          <i className='main__title--icon'>
            <img src='../images/note1.svg' alt='' />
          </i>
          إدارة الموردين
        </span>

        <div className='mt-5 pr-4 pl-4'>
          <WarehousesTable
            token={token}
            columns={[
              { header: 'الاسم', field: 'name', isChip: false },
              { header: 'التواصل', field: 'contactPerson', isChip: false },
              { header: 'رقم التليفون', field: 'phone', isChip: false },
              { header: 'البريد الالكتروني', field: 'email', isChip: false },
              { header: 'الموقع الالكتروني', field: 'website', isChip: false },
              { header: 'الحالة', field: 'status.name', isChip: true }
            ]}
            urlName='Supplier'
            routePrefix='/warehouses/supplier'
            headerTitle='الموردين'
            entityName='مورد'
            entityNameDefined='تفاصيل المورد'
            addBtnText='إضافة مورد جديد'
            addWithLink={true}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);
