import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import GenericPage from '../../../components/components/GenericPage/GenericPage';
import Sidebar from '../../../components/Sidebar/Sidebar';

const getEmployeeColumns = () => [
  { header: 'الموظف', field: 'name', isChip: false },
  { header: 'الحالة', field: 'statusName', isChip: true },
  { header: 'الدور', field: 'positionName', isChip: false },
  { header: 'الوظيفة', field: 'departmentName', isChip: false },
  { header: 'تاريخ التعيين', field: 'hiringDate', isChip: false }
];

function Employees() {
  const navigate = useNavigate();
  const columns = useMemo(getEmployeeColumns, []);
  const apiUrl = '/Employee/list';
  const routeUrl = '/employees/details/';
  const addUrl = '/employees/add';
  const tabs = [
    {
      label: 'قائمة الموظفين الحاليين',
      tableHeader: 'قائمة الموظفين الحاليين',
      entityName: 'الموظف',
      dataKey: 'tab1',
      columns: columns,
      apiUrl: apiUrl,
      edit: false,
      emptyStateUrl: addUrl,
      rowOnClick: (rowData) => {
        navigate(routeUrl + rowData.id);
      },
      extraParams: { status: 'Active' }
    },
    {
      label: 'قائمة الموظفين الغير مكتملين',
      tableHeader: 'قائمة الموظفين الغير مكتملين',
      dataKey: 'tab2',
      entityName: 'الموظف',
      columns: columns,
      apiUrl: apiUrl,
      edit: false,
      rowOnClick: (rowData) => {
        navigate(routeUrl + rowData.id);
      },
      emptyStateUrl: addUrl,
      extraParams: { status: 'awaiting' }
    },
    {
      label: 'قائمة الموظفين المحظورين',
      tableHeader: 'قائمة الموظفين المحظورين',
      dataKey: 'tab3',
      entityName: 'الموظف',
      columns: columns,
      apiUrl: apiUrl,
      edit: false,
      emptyStateUrl: addUrl,
      rowOnClick: (rowData) => {
        navigate(routeUrl + rowData.id);
      },
      extraParams: { status: 'In-Active' }
    }
  ];

  return (
    <div className='flex w-full'>
      <Sidebar />
      <GenericPage
        tabs={tabs}
        headerTitle='الموظفين'
        headerIcon='/images/alt.svg'
        headerButtons={[
          {
            label: 'إضافة موظف جديد',
            onClick: () => {
              navigate(addUrl);
            }
          }
        ]}
      />
    </div>
  );
}

export default Employees;
