import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { connect } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import './Modals.scss';
import { InputTextarea } from 'primereact/inputtextarea';

import { axiosInstance } from '../../global';

function AddCustomerDevice({ token, title, entityName, visible, setVisible, statuses, onClosePrompt, onAddHandler }) {
  const toast = useRef(null);
  const freeInstall = [
    { id: true, name: 'نعم' },
    { id: false, name: 'لا' }
  ];

  const [selectedStatus, setSelectedStatus] = useState(null);

  const [selectedWay, setSelectedWay] = useState(null);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [types, setTypes] = useState([]);

  const [entity, setEntity] = useState({
    customerId: '',
    deviceId: '',
    contractDate: '',
    installationDate: '',
    installmentType: '',
    sellingPrice: '',
    freeInstallation: false,
    statusId: null,
    remarks: ''
  });

  useEffect(() => {
    -axiosInstance
      .get(`/Customer/all`)
      .then((response) => {
        setCustomers(response.data.data);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/Product/all`)
      .then((response) => {
        setProducts(response.data.data);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/InstallmentsType/all`)
      .then((response) => {
        setTypes(response.data.data);
      })
      .catch((error) => {});
  }, []);

  function acceptClose() {
    setEntity({
      customerId: '',
      deviceId: '',
      contractDate: '',
      installationDate: '',
      installmentType: '',
      sellingPrice: '',
      freeInstallation: false,
      statusId: null,
      remarks: ''
    });
    setSelectedStatus(null);
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value || e.value });
  }
  function handleCustomersChange(e) {
    setSelectedCustomer(e.value);
    setEntity({ ...entity, customerId: e.value });
  }
  function handleProductsChange(e) {
    setSelectedProducts(e.value);
    setEntity({ ...entity, deviceId: e.value });
  }
  function handleFreeInstllationChange(e) {
    setSelectedWay(e.value);
    setEntity({ ...entity, freeInstallation: e.value });
  }
  function handleTypesChange(e) {
    setSelectedType(e.value);
    setEntity({ ...entity, installmentType: e.value });
  }
  function handleStatusChange(e) {
    setSelectedStatus(e.value);
    setEntity({ ...entity, statusId: e.value });
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog tagKey={2} />
      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر العميل</label>
              <div className='flex'>
                <Dropdown
                  value={selectedCustomer}
                  onChange={handleCustomersChange}
                  options={customers}
                  optionValue='id'
                  optionLabel='fullName'
                  display='chip'
                  placeholder='اختر العميل'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر الجهاز </label>
              <div className='flex'>
                <Dropdown
                  value={selectedProducts}
                  onChange={handleProductsChange}
                  options={products}
                  optionValue='device.id'
                  optionLabel='device.name'
                  display='chip'
                  placeholder='اختر الجهاز'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>تاريخ العقد </label>
              <div className='flex'>
                <Calendar
                  name='contractDate'
                  dateFormat='mm/dd/yy'
                  value={entity.contractDate}
                  onChange={handleInputChange}
                  showIcon
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'> تاريخ التثبيت </label>
              <div className='flex'>
                <Calendar
                  name='hiringDate'
                  dateFormat='mm/dd/yy'
                  value={entity.installationDate}
                  onChange={handleInputChange}
                  showIcon
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر نوع القسط </label>
              <div className='flex'>
                <Dropdown
                  value={selectedType}
                  onChange={handleTypesChange}
                  options={types}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  placeholder='اختر نوع القسط'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>سعر البيع</label>
              <InputText
                name='sellingPrice'
                value={entity.sellingPrice}
                className='w-full'
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>تركيب مجاني</label>
              <div className='flex'>
                <Dropdown
                  value={selectedWay}
                  onChange={handleFreeInstllationChange}
                  options={freeInstall}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  placeholder='اختر نوع التركيب'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر حالة {entityName}</label>
              <div className='flex'>
                <Dropdown
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  placeholder='اختر الحالة'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea name='remarks' value={entity.remarks} className='w-full' onChange={handleInputChange} />
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button className='modals__btn' onClick={() => onAddHandler(entity)}>
              اضافة {entityName}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerDevice);
