import React, { useEffect, useState } from 'react';
import './AddProductDetails.scss';
import { connect } from 'react-redux';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'antd';

import Stages from '../../../components/Stages/Stages';

function AddProduct2({ setCheck, nbOfStages }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const [stagesNum, setStagesNum] = useState('');
  const [check, setchecks] = useState(true);
  const stageArray = [];
  useEffect(() => {
    setStagesNum(nbOfStages);
  }, []);

  function handleStageNumChange(e) {
    setStagesNum(e.value);
  }

  function enableStageNum() {
    setchecks(false);
  }
  const numberOfRepetitions = stagesNum;
  const repeatedComponents = Array.from({ length: numberOfRepetitions }, (_, index) => (
    <Stages title={`${index + 1}مرحلة`} stageNum={index + 1} setCheck={setCheck} stageArray={stageArray} key={index} />
  ));

  return (
    <div className='product'>
      <div className='product__stages'>
        <span className='Title'>تفاصيل المراحل</span>
        <div className='w-full flex gap-5'>
          <div className='flex w-full flex-column gap-4'>
            <div className='stageNumNote'>
              <li className='pi pi-info-circle'></li>
              قم بأضافة عدد المراحل حتى يكون بأمكانك اضافة تفاصيل المراحل
            </div>
            <label className='addproduct__label'>
              عدد مراحل الفلتر المضافة<span style={Style}>*</span>
            </label>
            <InputNumber name='numStages' value={stagesNum} onChange={handleStageNumChange} disabled={check} />
            <div className='flex flex-row gap-2'>
              <Button to={''} className='addtitle' onClick={enableStageNum}>
                <li className=' icon pi pi-pencil'></li>
                تعديل عدد المراحل
              </Button>
            </div>
            <div>{repeatedComponents}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct2);
