import React, { useEffect, useState, useRef } from 'react';
import './EditProductDetails.scss';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { Link } from 'react-router-dom';

import { API_BASE_URL, axiosInstance } from '../../../global';
import { fill_product } from '../../../redux/product/productActions';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';

function EditProduct1({ token, fill_product, setCheck, productBase }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const [statuses, setStatuses] = useState(null);
  const [manufacturer, setManufacturer] = useState(null);
  const [productCategory, setProductCategory] = useState(null);
  const [brands, setBrands] = useState(null);
  const [country, setCountry] = useState(null);
  const [installments, setInstallments] = useState(null);
  const [product, setproduct] = useState({});
  const [installmentsIds, setInstallmentsIds] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`/ProductsCategory/all`)
      .then((response) => {
        const tempCategory = response.data.data.map((category) => {
          return {
            id: category.id,
            name: category.name
          };
        });
        setProductCategory(tempCategory);
      })
      .catch((error) => {}); // product category

    axiosInstance
      .get(`/Status/dropdown`)
      .then((response) => {
        const tempStatus = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setStatuses(tempStatus);
      })
      .catch((error) => {}); // product status

    axiosInstance
      .get(`/Manufacturer/all`)
      .then((response) => {
        const tempManufacturer = response.data.data.map((manufacturer) => {
          return {
            id: manufacturer.id,
            name: manufacturer.name
          };
        });
        setManufacturer(tempManufacturer);
      })
      .catch((error) => {}); // product manufacturer

    axiosInstance
      .get(`/Brand/all`)
      .then((response) => {
        const tempBrand = response.data.data.map((brand) => {
          return {
            id: brand.id,
            name: brand.name
          };
        });
        setBrands(tempBrand);
      })
      .catch((error) => {}); // product brand

    axiosInstance
      .get(`/Country/all`)
      .then((response) => {
        const tempCountry = response.data.data.map((country) => {
          return {
            id: country.id,
            name: country.name
          };
        });
        setCountry(tempCountry);
      })
      .catch((error) => {}); // product country of orgin

    axiosInstance
      .get(`/InstallmentsType/all`)
      .then((response) => {
        const tempInstallment = response.data.data.map((installment) => {
          return {
            id: installment.id,
            name: installment.name
          };
        });
        setInstallments(tempInstallment);
      })
      .catch((error) => {}); //installment types
  }, []);

  useEffect(() => {
    setproduct(productBase);
    setInstallmentsIds(productBase.installmentTypesIds);
    console.log(installmentsIds);
  }, [productBase.id]);

  useEffect(() => {
    fill_product(product);
    // if (
    //   Object.values(product).filter(
    //     (val, index) =>
    //       (val === '' || !val) && Object.keys(product)[index] !== 'remarks' && Object.keys(product)[index] !== 'code'
    //   ).length
    // ) {
    //   setCheck(false);
    // } else {
    setCheck(true);
    // }
  }, [product]);

  function handleAddInputChange(e) {
    setproduct({ ...product, [e.target.name]: e.target.value || e.value });
  }

  function handleAddInputNumberChange(e) {
    setproduct({ ...product, nbOfStages: e.target.value || e.value });
  }
  return (
    <div className='product'>
      <div className='product__content'>
        {/* page right content============================================== */}
        <div className='product__content__right'>
          <span className='Title'>عن المنتج</span>
          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>كود المنتج</label>
            <InputText name='code' value={product.code} onChange={handleAddInputChange} disabled={true} />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              رقم المنتج / SKU<span style={Style}>*</span>
            </label>
            <InputText name='skuCode' value={product.skuCode} onChange={handleAddInputChange} />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              اسم المنتج بالعربية<span style={Style}>*</span>
            </label>
            <InputText name='name' value={product.name} onChange={handleAddInputChange} />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              وصف المنتج بالعربية<span style={Style}>*</span>
            </label>
            <InputTextarea name='details' value={product.details || ''} onChange={handleAddInputChange} rows={5} />
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              فئة المنتج<span style={Style}>*</span>
            </label>
            <CustomDropdown
              name='productCategory'
              value={product.productCategory}
              onChange={handleAddInputChange}
              options={productCategory}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر الفئة'
            />
            <div className='flex flex-row gap-2'>
              <button className='addbtn'>+</button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل فئة جديدة
              </Link>
            </div>
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              تصنيف المنتج <span style={Style}>*</span>
            </label>
            <CustomDropdown
              name='manufacturer'
              value={product.manufacturer}
              onChange={handleAddInputChange}
              options={manufacturer}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر التصنيف'
            />
            <div className='flex flex-row gap-2'>
              <button className='addbtn'>+</button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل تصنيف جديدة
              </Link>
            </div>
          </div>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              الماركة<span style={Style}>*</span>
            </label>
            <CustomDropdown
              name='brand'
              value={product.brand}
              onChange={handleAddInputChange}
              options={brands}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر الماركة'
            />
            <div className='flex flex-row gap-2'>
              <button className='addbtn'>+</button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل ماركة جديدة
              </Link>
            </div>
          </div>
          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              بلد المنشأ<span style={Style}>*</span>
            </label>
            <CustomDropdown
              name='countryOfOrigin'
              value={product.countryOfOrigin}
              onChange={handleAddInputChange}
              options={country}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر بلد المنشأ'
            />
            <div className='flex flex-row gap-2'>
              <button className='addbtn'>+</button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل بلد منشأ جديدة
              </Link>
            </div>
          </div>
        </div>

        {/* page left content============================================== */}

        <div className='product__content__left'>
          <span className='Title'>مراحل المنتج</span>

          <div className='flex w-full flex-column gap-4'>
            <div className='stageNumNote'>
              <li className='pi pi-info-circle'></li>
              ستقوم ب أضافة المراحل بالتفصيل فالخطوة القادمة
            </div>
            <label className='addproduct__label'>
              عدد مراحل الفلتر<span style={Style}>*</span>
            </label>
            <InputNumber
              inputId='minmax-buttons'
              value={product.nbOfStages}
              onValueChange={handleAddInputNumberChange}
              mode='decimal'
              showButtons
              min={0}
              max={1000}
            />
          </div>

          <span className='Title'>عن الدفع و طرق التقسيط </span>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              طرق التقسيط<span style={Style}>*</span>
            </label>
            {/* {installments.map((installment, index) => ( */}
            <MultiSelect
              // key={index} // Unique key for each component
              name='installmentTypesIds'
              value={product.installmentTypesIds}
              onChange={handleAddInputChange}
              options={installments}
              optionValue='id'
              optionLabel='name'
              display='chip'
              placeholder='ابحث عن طرق التقسيط'
              maxSelectedLabels={5}
              // index={index} // You can also pass the index as a prop if needed
            />
            {/* ))} */}

            <div className='flex flex-row gap-2'>
              <button className='addbtn'>+</button>
              <Link to={''} className='addtitle'>
                اضافة / تعديل طرق تقسيط جديدة
              </Link>
            </div>
          </div>

          <span className='Title'>حالة المنتج</span>

          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>
              حالة المنتج<span style={Style}>*</span>
            </label>
            <CustomDropdown
              name='status'
              value={product.status}
              onChange={handleAddInputChange}
              options={statuses}
              optionValue='id'
              optionLabel='name'
              placeholder='اختر حالة الحساب'
            />
            <div className='flex flex-row gap-2'>
              <button className='addbtn'>+</button>
              <Link to={''} className='addtitle'>
                اضف حالات للمنتج جديدة
              </Link>
            </div>
          </div>
          <div className='flex w-full flex-column gap-4'>
            <label className='addproduct__label'>ملاحاظات</label>
            <InputTextarea name='remarks' value={product.remarks} onChange={handleAddInputChange} rows={5} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_product: (product) => dispatch(fill_product(product))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct1);
