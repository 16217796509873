import React, { useState, useEffect, useCallback, useRef } from 'react';
import { axiosInstance } from '../../../../global';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../../../../style/Tables.scss';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import { Knob } from 'primereact/knob';
import GenericDataTable from '../../../../components/components/GenericDataTable/GenericDataTable';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './AddConnectionDetails.scss';

function AddConnection1() {
  const [searchTerm, setSearchTerm] = useState('');
  const [newlist, setNewlist] = useState([]);
  const [filterdlist, setFilterdlist] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [myList, setMyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useRef(null);
  const params = useParams();
  const navigate = useNavigate();

  // useEffect(() => {
  //   axiosInstance
  //     .get(`/Customer/all`)
  //     .then((response) => {
  //       setMyList(response.data.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);
  const fetchCustomers = useCallback(() => {
    axiosInstance
      .get(`/Customer/all`)
      .then((response) => {
        setMyList(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.code === 'ECONNABORTED') {
          console.log('Request timed out');
        } else {
          console.log(error);
        }
      });
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  useEffect(() => {
    setNewlist(myList);
  }, [myList]);

  useEffect(() => {
    setNewlist(filterData());
    if (searchTerm === '') {
      setNewlist(myList);
    }
  }, [searchTerm]);

  const filterData = () => {
    setFilterdlist(newlist);
    return filterdlist.filter((item) => item.fullName.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  function handleSearchNameChange(e) {
    const inputString = e.target.value;
    setSearchTerm(inputString);
  }

  const allowExpansion = (rowData) => {
    return rowData.customersDevices.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className='p-3'>
        <ConfirmDialog />
        {params.type === 'Contract' ? (
          <DataTable value={data.contracts}>
            <Column field='deviceName' header='الجهاز'></Column>
            <Column field='membraneSerialNb' header='رقم ممبرين'></Column>
            <Column field='motorSerialNb' header='رقم الموتور'></Column>
            <Column field='customerDeviceType' header='نوع الجهاز'></Column>
            <Column field='installationDate' header='تاريخ التركيب'></Column>
            <Column field='status' header='حالة العقد'></Column>
            <Column field='remainingDays' header='صلاحية الضمان'></Column>
            <Column
              field='remainingTime'
              header='مؤشر التغيير'
              body={(rowData) => {
                return rowData.remainingTime > 1 ? (
                  <Knob value={rowData.remainingTime} min={-365} max={365} size={50} valueColor='#EEBA00' />
                ) : (
                  <Knob value={rowData.remainingTime} min={-365} max={365} size={50} valueColor='#FF0000' />
                );
              }}
            ></Column>
            <Column
              header='خيارات'
              body={(rowData) => (
                <Button
                  label={`تجديد العقد`}
                  icon='pi pi-plus'
                  text
                  className='primary__data--link text-blue-600'
                  onClick={() => confirm(rowData, data)}
                />
              )}
            />
          </DataTable>
        ) : (
          <DataTable value={data.customersDevices}>
            <Column field='device.name' header='الجهاز'></Column>
            <Column field='membraneSerialNb' header='رقم ممبرين'></Column>
            <Column field='motorSerialNb' header='رقم الموتور'></Column>
            <Column field='type.name' header='نوع الجهاز'></Column>
            <Column field='installationDate' header='تاريخ التركيب'></Column>
            <Column field='expiryDate' header='صلاحية الضمان'></Column>
            <Column
              field='device.remainingTime'
              header='مؤشر التغيير'
              body={(rowData) => {
                return rowData.device.remainingTime > 1 ? (
                  <Knob value={rowData.device.remainingTime} min={-365} max={365} size={50} valueColor='#EEBA00' />
                ) : (
                  <Knob value={rowData.device.remainingTime} min={-365} max={365} size={50} valueColor='#FF0000' />
                );
              }}
            ></Column>
            <Column
              header='خيارات'
              body={(rowData) => (
                <Link to={`/Activites/Add/${data.id}/${rowData.id}`}>
                  <Button label={`اضافة اتصال`} icon='pi pi-plus' text className='primary__data--link text-blue-600' />
                </Link>
              )}
            />
          </DataTable>
        )}
      </div>
    );
  };

  const accept = (rowData, data) => {
    navigate(`/Contract/Activity/Add/${rowData.contractId}/${data.id}/${rowData.customerDeviceId}`);
  };

  const reject = (rowData, data) => {
    navigate(`/Contract/Edit/${rowData.contractId}/${data.id}/${rowData.customerDeviceId}`);
  };

  const confirm = (rowData, data) => {
    confirmDialog({
      message: <span className='text-lg	font-bold text-black-alpha-80'>خطوة اختيارية، هل ترغب في اضافة اتصال؟</span>,
      icon: <img className='ml-2' src='/images/ExclamationCircle.svg' alt='Best Aqua Solution' />,
      defaultFocus: 'accept',
      acceptClassName: 'custom-accept-button',
      rejectClassName: 'custom-reject-button',
      acceptLabel: 'نعم، اريد اضافة اتصال',
      rejectLabel: 'متابعة بدون اتصال',
      accept: () => accept(rowData, data),
      reject: () => reject(rowData, data)
    });
  };

  const columns = [
    { field: 'fullName', header: 'العميل' },
    { field: 'phoneNb', header: 'رقم الهاتف' },
    { field: 'defaultAddress.name', header: 'العنوان الرئيسي' },
    { field: 'numberOfDevices', header: 'عدد الأجهزة' },
    { field: 'numberOfStages', header: 'عدد المراحل المستحقة' },
    { field: 'numberOfComplaints', header: 'شكاوى العميل' },
    { field: 'preferredContactMethod.name', header: 'طريقة التواصل' },
    { field: 'status.name', header: 'الحالة', isChip: true }
  ];

  return (
    <div className='Connection'>
      {params.type === 'Contract' ? (
        <div className='flex flex-row'>
          <Sidebar />
          <Toast ref={toast} />
          <div className='flex flex-column w-full'>
            <div className='details__header m-2 mb-4'>
              <Button
                className='font-bold text-black-alpha-90'
                icon='pi pi-arrow-right'
                rounded
                text
                onClick={() => navigate(-1)}
              />
              <span className='details__header1__right--text'>إضافة طلب تجديد عقد</span>
            </div>
            <div className='tbl ml-3 mb-4 mr-3'>
              <div className='tbl__visit__header'>
                <div>
                  <Toast ref={toast} />
                  <span className='header__title'> قائمة البحث بالعملاء </span>
                </div>
                <div className='header__visit__tools '>
                  <span className='p-input-icon-left'>
                    <i className='pi pi-search' />
                    <InputText
                      value={searchTerm}
                      className='header__tools__search'
                      placeholder='ابحث بأسم العميل'
                      onChange={handleSearchNameChange}
                      type='search'
                    />
                  </span>
                </div>
                <div className='flex gap-2 mr-2'>
                  <button className='tbl__visit__addbtn' onClick={() => navigate('/customers/addCustomer')}>
                    <img src='/images/plus.svg' alt={'إضافة عميل '} />
                    إضافة عميل
                  </button>
                  <button className='tbl__visit__addbtn' onClick={() => navigate('/products/addProduct')}>
                    <img src='/images/plus.svg' alt={'إضافة جهاز '} />
                    إضافة جهاز جديد
                  </button>
                </div>
              </div>
              <div className='tbl__table'>
                <Toast ref={toast} />
                {loading ? (
                  <div className='flex justify-content-center'>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <GenericDataTable
                    data={newlist}
                    columns={columns}
                    page={0}
                    pageSize={10}
                    totalRecords={newlist.length}
                    isLoading={loading}
                    onPageChange={() => {}}
                    onSort={() => {}}
                    onRowClick={() => {}}
                    edit={false}
                    entityName='العميل'
                    emptyStateUrl='/add-customer'
                    allowExpansion={allowExpansion}
                    rowExpansionTemplate={rowExpansionTemplate}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className='Note ml-2 mb-4 mr-3'>
            <li className='pi pi-info-circle'></li>
            يجب اضافة تفاصيل الاتصال اولاً حتى يكون بأمكانك اضافة الزيارة
            <br />
            قم بالبحث عن العميل او بأمكانك اضافة عميل جديد
          </div>
          <div className='tbl ml-3 mb-4 mr-3'>
            <div className='tbl__visit__header'>
              <div className='flex flex-row gap-4'>
                <Toast ref={toast} />
                <span className='header__title'> قائمة البحث بالعملاء </span>
              </div>

              <div className='header__visit__tools'>
                <span className='p-input-icon-left'>
                  <i className='pi pi-search' />
                  <InputText
                    value={searchTerm}
                    className='header__tools__search w-38rem'
                    placeholder='ابحث بأسم العميل'
                    onChange={handleSearchNameChange}
                    type='search'
                  />
                </span>
              </div>
              <button className='tbl__visit__addbtn' onClick={() => navigate('/customers/addCustomer')}>
                <img src='/images/plus.svg' alt={'إضافة عميل '} />
                إضافة عميل
              </button>
            </div>
            <div className='tbl__table'>
              <Toast ref={toast} />
              {loading ? (
                <div className='flex justify-content-center'>
                  <ProgressSpinner />
                </div>
              ) : (
                <GenericDataTable
                  data={newlist}
                  columns={columns}
                  page={0}
                  pageSize={10}
                  totalRecords={newlist.length}
                  isLoading={loading}
                  onPageChange={() => {}}
                  onSort={() => {}}
                  onRowClick={() => {}}
                  edit={false}
                  entityName='العميل'
                  emptyStateUrl='/add-customer'
                  allowExpansion={allowExpansion}
                  rowExpansionTemplate={rowExpansionTemplate}
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddConnection1;
