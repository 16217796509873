import React from 'react';

import './Home.scss';
import { connect } from 'react-redux';

import HeaderCard from '../../components/Cards/Home header/HeaderCard';
import BodyCard from '../../components/Cards/Home body/BodyCard';
import Sidebar from '../../components/Sidebar/Sidebar';
import { logout } from '../../redux/user/userActions';

function Home({ user }) {
  return (
    <main className='home'>
      <Sidebar />
      <div className='home__main'>
        <div className='home__header h-auto'>
          <div className='home__header__data'>
            <div className='home__header__data__text'>
              <div className='home__header__data__text__right'>
                <h4>مرحبا,</h4>
                <h1>{user.userName}</h1>
              </div>
              <div className='home__header__data__text__left'>
                <h5>
                  <span>*</span>
                  {user.userType.name}
                </h5>
                <img src='./images/information1.svg' alt='information' width={32} height={32} className='mt-3' />
              </div>
            </div>

            <div className='flex flex-column align-items-start'>
              <div className='home__header__data__stat'>
                <HeaderCard
                  title={'الإيرادات'}
                  num={'58.200k'}
                  imageUrl={'/images/money.svg'}
                  percent={'2.5% من الشهر الماضى'}
                  chartImage={'/images/Up.svg'}
                  color={'#13DE8230'}
                />
                <HeaderCard
                  title={'العملاء'}
                  num={'58.200k'}
                  imageUrl={'/images/user_box_light.svg'}
                  percent={'2.5% من الشهر الماضى'}
                  chartImage={'/images/down2.svg'}
                  color={'#F9D8D4'}
                  txtColor={'red'}
                />
                <HeaderCard
                  title={'الزيارات'}
                  num={'58.200k'}
                  imageUrl={'/images/officelife.svg'}
                  percent={'2.5% من الشهر الماضى'}
                  chartImage={'/images/Up.svg'}
                  color={'#6D54DE33'}
                />
                <HeaderCard
                  title={'التقيمات'}
                  num={'58.200k'}
                  imageUrl={'/images/check.svg'}
                  percent={'2.5% من الشهر الماضى'}
                  chartImage={'/images/Up.svg'}
                  color={'#FFEDD0'}
                />
                <HeaderCard
                  title={'الاقساط المطلوبة'}
                  num={'58.200k'}
                  imageUrl={'/images/coins.svg'}
                  percent={'2.5% من الشهر الماضى'}
                  chartImage={'/images/Up.svg'}
                  color={'#C050E838'}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='home__body'>
          <BodyCard
            ImageUrl={'/images/coins41.svg'}
            ImageAlt={'CardImage'}
            Description={'المبيعات'}
            color={'#BFA7F9'}
          />
          <BodyCard
            ImageUrl={'/images/coins41.svg'}
            ImageAlt={'CardImage'}
            Description={'الاقساط'}
            color={'#C050E838'}
          />
          {/* <BodyCard
            ImageUrl={'/images/coins41.svg'}
            ImageAlt={'CardImage'}
            Description={'الايرادات'}
            color={'#C050E838'}
          /> */}
        </div>
      </div>
    </main>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
