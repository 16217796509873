import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Chips } from 'primereact/chips';
import { SelectButton } from 'primereact/selectbutton';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './Modals.scss';
import { InputTextarea } from 'primereact/inputtextarea';

function AddStatus({ title, entityName, visible, setVisible, onClosePrompt, onAddHandler }) {
  const toast = useRef(null);
  const [selectedStatusType, setSelectedStatusType] = useState([]);
  const [entity, setEntity] = useState({
    names: [],
    isActive: false,
    remarks: ''
  });
  const options = [
    { label: 'غير متاح', value: false },
    { label: 'متاح', value: true }
  ];

  function acceptClose() {
    setEntity({
      names: [],
      isActive: false,
      remarks: ''
    });
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  function handleStatusTypeChange(e) {
    setSelectedStatusType(e.value);
    setEntity({ ...entity, isActive: e.target.value });
  }

  function handleStatusChange(e) {
    setSelectedStatus(e.value);
    setEntity({ ...entity, statusId: e.value[0] });
  }

  function handleAddItemChipBtn(e) {
    const kbEvent = new KeyboardEvent('keydown', {
      bubbles: true,
      cancelable: true,
      key: 'Enter'
    });
    document.body.dispatchEvent(kbEvent);
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اضف اسم/اسماء {entityName} الجديدة</label>
              <div className='flex gap-1'>
                <Chips
                  name='names'
                  value={entity.names}
                  addOnBlur={true}
                  className='w-full'
                  onChange={handleInputChange}
                />
                <Button label='اضف' severity='secondary' outlined raised onClick={handleAddItemChipBtn} />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>
                نوع الحالة<span className='astrk'>*</span>
              </label>
              <SelectButton value={entity.isActive} onChange={handleStatusTypeChange} options={options} />
            </div>
          </div>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea  name='remarks' value={entity.remarks} className='w-full' onChange={handleInputChange} />
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button className='modals__btn' onClick={() => onAddHandler(entity)}>
              اضافة {entityName}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default AddStatus;
