import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';

import { axiosInstance } from '../../global';
import '../../style/Tables.scss';

function PriceListDetailsTable({ token }) {
  const toast = useRef(null);
  const [governorates, setGovernorates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [currency, setCurrency] = useState({});
  const [loading, setLoading] = useState(true);
  const [countryCheck, setcountryCheck] = useState(true);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [newlist, setNewlist] = useState([]);
  const [filterdlist, setFilterdlist] = useState([]);
  const [items, setItems] = useState({
    governorateId: '',
    currencyId: '',
    date: null,
    remarks: '',
    statusId: '',
    details: [
      {
        productId: '',
        price: '',
        commission: ''
      }
    ]
  });

  useEffect(() => {
    axiosInstance
      .get(`/Product/all`)
      .then((response) => {
        const tempProductPriceList = response.data.data.map((ProductPrices) => {
          return {
            productId: ProductPrices.id,
            product: ProductPrices.name,
            productCategory: ProductPrices.productCategory.name,
            productFamily: ProductPrices.productCategory.productFamily.name,
            productType: ProductPrices.productCategory.productFamily.productType.name,
            price: 0,
            commission: 0
          };
        });
        setProducts(tempProductPriceList);
        setLoading(false);
      })
      .catch((error) => {});

    axiosInstance
      .get(`/Country/all`)
      .then((response) => {
        const tempCountry = response.data.data.map((Country) => {
          return {
            id: Country.id,
            name: Country.name
          };
        });
        setCountries(tempCountry);
      })
      .catch((error) => {}); // country

    axiosInstance
      .get(`/Status/all`)
      .then((response) => {
        const tempCountry = response.data.data.map((Country) => {
          return {
            id: Country.id,
            name: Country.name
          };
        });
        setStatuses(tempCountry);
      })
      .catch((error) => {}); // status
  }, []);

  useEffect(() => {
    setNewlist(products);
    const details = products.map((product) => {
      return {
        productId: product.productId,
        price: product.price,
        commission: product.commission
      };
    });
    setItems({ ...items, details });
  }, [products]);

  useEffect(() => {
    setNewlist(filterData());
    if (searchTerm === '') {
      setNewlist(products);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (selectedCountry) {
      axiosInstance
        .get(`/Country/${selectedCountry}`)
        .then((response) => {
          setCurrency(response.data.data.currency);
        })
        .catch((error) => {});
      setItems({ ...items, currencyId: currency.id });
    }
  }, [selectedCountry]);

  const filterData = () => {
    setFilterdlist(newlist);
    return filterdlist.filter((item) => item.product.includes(searchTerm));
  };

  function handleSearchNameChange(e) {
    const inputString = e.target.value;
    setSearchTerm(inputString);
  }

  function getGovernrates(id) {
    axiosInstance
      .get(`/Governorate/allByCountryId/${id}`)
      .then((response) => {
        const tempGovren = response.data.data.map((Govern) => {
          return {
            id: Govern.id,
            name: Govern.name
          };
        });
        setGovernorates(tempGovren);
        setcountryCheck(false);
      })
      .catch((error) => {}); // governrate
  }

  function handleEditorsChange(e, options) {
    let tempProducts = products.map((product) => {
      if (options.rowData.productId === product.productId) {
        product[e.target.name] = e.value;
      }
      return product;
    });
    setProducts(tempProducts);
  }

  const priceEditor = (options) => {
    return <InputNumber name='price' value={options.value} onValueChange={(e) => handleEditorsChange(e, options)} />;
  };
  const priceBodyTemplate = (rowData) => {
    return new Intl.NumberFormat('ar', { currency: 'EGP' }).format(rowData.price);
  };

  const commissionEditor = (options) => {
    return (
      <InputNumber name='commission' value={options.value} onValueChange={(e) => handleEditorsChange(e, options)} />
    );
  };
  const commissionBodyTemplate = (rowData) => {
    return new Intl.NumberFormat('ar', { currency: 'EGP' }).format(rowData.commission) + ' %';
  };

  function handleAddInputChange(e) {
    if (e.target.name === 'selectedCountry') {
      setSelectedCountry(e.target.value);
      getGovernrates(e.target.value);
      setcountryCheck(false);
    } else if (e.target.name === 'date') {
      setItems({
        ...items,
        [e.target.name]: e.target.value || e.value
      });
    } else {
      setItems({ ...items, [e.target.name]: e.target.value || e.value });
    }
  }

  function handleSaveChanges(e) {
    axiosInstance
      .post(`/ProductsPricesList`, items, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        toast.current.show({ severity: 'success', summary: `تم إضافة قائمة الاسعار بنجاح!`, life: 3000 });
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: `فشلت عملية إضافة قائمة اسعار جديدة`, life: 3000 });
      });
  }

  return (
    <div className='tbl ml-2 mb-4'>
      <div className='tbl__header'>
        <div className='flex flex-row gap-2'>
          <Toast ref={toast} />
          <span className='header__title__price'>قائمة أسعار </span>
          <Calendar
            name='date'
            value={items.date}
            onChange={handleAddInputChange}
            dateFormat='mm/dd/yy'
            placeholder='اختر التاريخ'
            className='header__pricedate'
          />
          <Dropdown
            name='selectedCountry'
            value={selectedCountry}
            onChange={handleAddInputChange}
            options={countries}
            optionValue='id'
            optionLabel='name'
            placeholder='اختر البلد'
            className='header__pricecountry'
          />
          <Dropdown
            name='governorateId'
            value={items.governorateId}
            onChange={handleAddInputChange}
            options={governorates}
            optionValue='id'
            optionLabel='name'
            placeholder='اختر المحافظة'
            className='header__pricegovernrate'
            disabled={countryCheck}
          />

          <Dropdown
            name='statusId'
            value={items.statusId}
            onChange={handleAddInputChange}
            options={statuses}
            optionValue='id'
            optionLabel='name'
            placeholder='اختر الحالة'
            className='header__pricegovernrate'
          />
        </div>

        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={searchTerm}
              className='header__tools__search'
              placeholder='البحث في المنتجات'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>

        <button className='tbl__addpriceList' onClick={handleSaveChanges}>
          <img src='/images/loading.svg' alt={'حفظ اتعديلات و تحديث القائمة'} />
          حفظ قائمة الاسعار الجديدة
        </button>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={products}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          <Column header='ID' body={(rowData) => <span>#{rowData.productId}</span>} />
          <Column header='نوع المنتج' field='productType' />
          <Column header='فئة المنتج' field='productFamily' />
          <Column header='تصنيف المنتج' field='productCategory' />
          <Column header='المنتج' field='product' />
          <Column
            field='price'
            key='price'
            header='السعر'
            body={priceBodyTemplate}
            editor={(options) => priceEditor(options)}
          ></Column>

          <Column
            field='commission'
            key='commission'
            header='العمولة'
            body={commissionBodyTemplate}
            editor={(options) => commissionEditor(options)}
          ></Column>
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceListDetailsTable);
