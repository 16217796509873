import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';

import { API_BASE_URL, axiosInstance } from '../../global';
import AddEntity from '../Modals/AddEntity';
import AddProduct from '../Modals/AddProduct';
import EditEntity from '../Modals/EditEntity';
import EditProduct from '../Modals/EditProduct';
import '../../style/Tables.scss';
import './ProductsTable.scss';

function ProductsTable({ token }) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [selectedTable, setSelectedTable] = useState(0);
  const [items, setItems] = useState([]);
  const [status, setStatus] = useState([]);
  const [addTypeVisible, setAddTypeVisible] = useState(false);
  const [addFamilyVisible, setAddFamilyVisible] = useState(false);
  const [addCategoryVisible, setAddCategoryVisible] = useState(false);
  const [editItemVisible, setEditItemVisible] = useState(false);
  const [editItem2Visible, setEditItem2Visible] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState(null);
  const tableOptions = [
    {
      value: 0,
      label: 'جدول انواع المنتجات',
      url: 'ProductsType',
      columnHeader: 'انواع المنتجات',
      entityName: 'انواع المنتجات',
      entityNameDefined: 'نوع المنتج'
    },
    {
      value: 1,
      label: 'جدول تصنيف المنتجات',
      url: 'ProductsFamily',
      columnHeader: 'تصنيفات المنتجات',
      entityName: 'تصنيف المنتجات',
      entityNameDefined: 'تصنيف المنتج'
    },
    {
      value: 2,
      label: 'جدول فئة المنتجات',
      url: 'ProductsCategory',
      columnHeader: 'فئات المنتجات',
      entityName: 'فئة المنتجات',
      entityNameDefined: 'فئة المنتج'
    }
  ];

  function fetchItems() {
    axiosInstance
      .get(`/${tableOptions[selectedTable].url}/all`)
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
  }

  useEffect(() => fetchItems(), [selectedTable]);
  useEffect(() => {
    axiosInstance
      .get(`/Status/all`)
      .then((response) => {
        const tempStatus = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setStatus(tempStatus);
      })
      .catch((error) => {});
  }, []);

  function handleSearchNameChange(e) {}

  function handleTableChange(e) {
    setSelectedTable(e.value);
  }

  function handleEditItemSelection(item) {
    setSelectedEditItem(item);
    switch (selectedTable) {
      case 0:
        setEditItemVisible(true);
        break;
      case 1:
        setEditItem2Visible(true);
        break;
      case 2:
        setEditItem2Visible(true);
        break;

      default:
        break;
    }
  }

  function handleAddItem(item) {
    axiosInstance
      .post(`/ProductsType`, item)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: `تم إضافة نوع المنتج بنجاح!`,
          life: 3000
        });
        fetchItems();
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: `فشلت عملية إضافة انواع منتجات جديدة`,
          life: 3000
        });
      });
  }
  function handleAddFamily(item) {
    axiosInstance
      .post(`/ProductsFamily`, item)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: `تم إضافة نوع المنتج بنجاح!`,
          life: 3000
        });
        fetchItems();
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: `فشلت عملية إضافة انواع منتجات جديدة`,
          life: 3000
        });
      });
  }
  function handleAddCategory(item) {
    axiosInstance
      .post(`/ProductsCategory`, item)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: `تم إضافة نوع المنتج بنجاح!`,
          life: 3000
        });
        fetchItems();
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: `فشلت عملية إضافة انواع منتجات جديدة`,
          life: 3000
        });
      });
  }

  function handleEditItem(item) {
    axiosInstance
      .put(`/ProductsType`, item)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: `'تم تعديل نوع المنتج بنجاح!'`,
          life: 3000
        });
        fetchItems();
        setEditItemVisible(false);
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: `فشلت عملية تعديل نوع المنتج`,
          life: 3000
        });
      });
  }
  function handleEditItem2(item) {
    axiosInstance
      .put(`/${selectedTable === 1 ? 'ProductsFamily' : 'ProductsCategory'}`, item)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: `'تم تعديل ${selectedTable === 1 ? 'تصنيف' : 'فئة'} المنتج بنجاح!'`,
          life: 3000
        });
        fetchItems();
        setEditItem2Visible(false);
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: `فشلت عملية تعديل ${selectedTable === 1 ? 'تصنيف' : 'فئة'} المنتج`,
          life: 3000
        });
      });
  }

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>جدول بيانات المنتجات</span>
        <div className='products__addbtns'>
          <button className='products__addbtn' onClick={() => setAddTypeVisible(true)}>
            <img src='/images/plus.svg' alt='إضافة انواع المنتجات' />
            إضافة انواع منتجات
          </button>
          <button className='products__addbtn' onClick={() => setAddFamilyVisible(true)}>
            <img src='/images/plus.svg' alt='إضافة تصنيف المنتجات' />
            إضافة تصنيف منتجات
          </button>
          <button className='products__addbtn' onClick={() => setAddCategoryVisible(true)}>
            <img src='/images/plus.svg' alt='إضافة فئة المنتجات' />
            إضافة فئة منتجات
          </button>
        </div>
        <Toast ref={toast} />

        <AddEntity
          visible={addTypeVisible}
          setVisible={setAddTypeVisible}
          title='إضافة نوع منتج'
          entityName='نوع المنتج'
          statuses={status}
          onClosePrompt='هل أنت متأكد من أنك تريد الخروج من عملية إضافة نوع منتج جديد؟'
          onAddHandler={handleAddItem}
          key={1}
        />
        <AddProduct
          selectedLevel={1}
          visible={addFamilyVisible}
          setVisible={setAddFamilyVisible}
          title='إضافة تصنيف منتج'
          entityName='تصنيف المنتج'
          statuses={status}
          onClosePrompt='هل أنت متأكد من أنك تريد الخروج من عملية إضافة تصنيف منتج جديد؟'
          onAddHandler={handleAddFamily}
          key={2}
        />
        <AddProduct
          selectedLevel={2}
          visible={addCategoryVisible}
          setVisible={setAddCategoryVisible}
          title='إضافة فئة منتج'
          entityName='فئة المنتج'
          statuses={status}
          onClosePrompt='هل أنت متأكد من أنك تريد الخروج من عملية إضافة فئة منتج جديد؟'
          onAddHandler={handleAddCategory}
          key={3}
        />

        <EditEntity
          visible={editItemVisible}
          setVisible={setEditItemVisible}
          title='تعديل نوع المنتج'
          entityName='نوع المنتج'
          entityBase={selectedEditItem}
          statuses={status}
          onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية تعديل نوع المنتج؟`}
          onEditHandler={handleEditItem}
          key={4}
        />
        <EditProduct
          selectedLevel={selectedTable}
          visible={editItem2Visible}
          setVisible={setEditItem2Visible}
          title={selectedTable === 1 ? 'تعديل تصنيف المنتج' : 'تعديل فئة المنتج'}
          entityName={selectedTable === 1 ? 'تصنيف المنتج' : 'فئة المنتج'}
          entityBase={selectedEditItem}
          statuses={status}
          onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية تعديل ${
            selectedTable === 1 ? 'تصنيف' : 'فئة'
          } المنتج؟`}
          onEditHandler={handleEditItem2}
          key={5}
        />

        <div className='header__tools'>
          <Dropdown
            options={tableOptions}
            value={selectedTable}
            onChange={handleTableChange}
            optionLabel='label'
            className='products__tbl-selection'
          />
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              className='header__tools__input'
              placeholder='ابحث بالاسم'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={items}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
          {selectedTable > 0 && (
            <Column
              header='نوع المنتج'
              body={(rowData) => <Chip label={rowData.productType?.name || rowData.productsType?.name} />}
            />
          )}
          {selectedTable > 1 && (
            <Column header='تصنيف المنتج' body={(rowData) => <Chip label={rowData.productFamily?.name} />} />
          )}
          <Column header={tableOptions[selectedTable].columnHeader} body={(rowData) => <Chip label={rowData.name} />} />
          <Column header='الحالة' body={(rowData) => <Chip label={rowData.status.name} />} />
          <Column header='ملاحظات' field='remarks' />
          <Column
            header='خيارات'
            body={(rowData) => (
              <span>
                <Button
                  label={`تعديل ${tableOptions[selectedTable].entityNameDefined}`}
                  icon='pi pi-pencil'
                  severity='secondary'
                  text
                  onClick={() => handleEditItemSelection(rowData)}
                />
              </span>
            )}
          />
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTable);
