import React from 'react';
import { connect } from 'react-redux';

import Sidebar from '../../components/Sidebar/Sidebar';
import Tabs from '../../components/Tabs/Tabs';
import WarehousesTable from '../../components/Tables/WarehousesTable';

function Warehouses({ token }) {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title'>
          <i className='main__title--icon'>
            <img src='../images/note1.svg' alt='' />
          </i>
          المخازن
        </span>

        <div className='mt-5 pr-4 pl-4'>
          <Tabs
            headers={['قائمة المخازن', 'اقسام المخازن', 'مواقع المخازن']}
            contents={[
              <WarehousesTable
                token={token}
                columns={[
                  { header: 'كود المخزن', field: 'code', isChip: false },
                  { header: 'اسم المخزن', field: 'name', isChip: false },
                  { header: 'نوع المخزن', field: 'type.name', isChip: true },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'رقم الهاتف', field: 'contactDetails', isChip: false },
                  { header: 'العنوان', field: 'address', isChip: false }
                ]}
                urlName='Warehouse'
                routePrefix='/warehouse'
                headerTitle='قائمة المخازن'
                entityName='مخزن'
                entityNameDefined='تفاصيل المخزن'
                addBtnText='إضافة مخزن جديد'
                addWithLink={true}
              />,
              <WarehousesTable
                token={token}
                columns={[
                  { header: 'اقسام المخازن', field: 'name', isChip: false },
                  { header: 'اسم المخزن', field: 'warehouse.name', isChip: false },
                  { header: 'حالة قسم المخزن', field: 'status.name', isChip: true },
                  { header: 'كود قسم المخزن', field: 'code', isChip: false }
                ]}
                urlName='WarehousesSection'
                routePrefix='/warehouse/section'
                headerTitle='قائمة اقسام المخازن'
                entityName='قسم المخزن'
                entityNameDefined='قسم المخزن'
                addBtnText='إضافة قسم مخزن جديد'
                addWithLink={false}
              />,
              <WarehousesTable
                token={token}
                columns={[
                  { header: 'مواقع التخزين', field: 'name', isChip: false },
                  { header: 'حالة مواقع التخزين', field: 'status.name', isChip: true },
                  { header: 'قسم المخزن', field: 'warehouseSection.name', isChip: false },
                  { header: 'كود الPlant', field: 'plantCode', isChip: false },
                  { header: 'كود موقع المخزن', field: 'code', isChip: false },
                  { header: 'اسم المخزن', field: 'warehouse.name', isChip: false }
                ]}
                urlName='WarehousesLocation'
                routePrefix='/warehouse/location'
                headerTitle='قائمة مواقع التخزين'
                entityName='موقع تخزين'
                entityNameDefined='موقع التخزين'
                addBtnText='إضافة موقع تخزين جديد'
                addWithLink={false}
              />
            ]}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Warehouses);
