import React, { useEffect, useState } from 'react';
import { Divider } from 'primereact/divider';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Badge, Calendar } from 'antd';

import { API_BASE_URL } from '../../global';
import './EmployeeCalendar.scss';

const getListData = (value) => {
  let listData;
  switch (value.date()) {
    case 8:
      listData = [
        {
          type: 'warning',
          content: 'This is warning event.'
        },
        {
          type: 'success',
          content: 'This is usual event.'
        }
      ];
      break;
    case 10:
      listData = [
        {
          type: 'warning',
          content: 'This is warning event.'
        },
        {
          type: 'success',
          content: 'This is usual event.'
        },
        {
          type: 'error',
          content: 'This is error event.'
        }
      ];
      break;
    case 15:
      listData = [
        {
          type: 'warning',
          content: 'This is warning event'
        },
        {
          type: 'success',
          content: 'This is very long usual event......'
        },
        {
          type: 'error',
          content: 'This is error event 1.'
        },
        {
          type: 'error',
          content: 'This is error event 2.'
        },
        {
          type: 'error',
          content: 'This is error event 3.'
        },
        {
          type: 'error',
          content: 'This is error event 4.'
        }
      ];
      break;
    default:
  }
  return listData || [];
};

const getMonthData = (value) => {
  if (value.month() === 8) {
    return 1394;
  }
};

function EmployeeCalendar() {
  function monthCellRender(value) {
    const num = getMonthData(value);
    return num ? (
      <div className='notes-month'>
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  }

  function dateCellRender(value) {
    const listData = getListData(value);
    return (
      <ul className='events'>
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  }

  function cellRender(current, info) {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  }

  return (
    <div className='employeecalendar'>
      <Calendar cellRender={cellRender} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeCalendar);
