import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Link, useParams } from 'react-router-dom';

import { axiosInstance } from '../../global';

import '../../style/Tables.scss';

function ProductsSalesTable({ token, columns, urlName, headerTitle, entityNameDefined }) {
  const toast = useRef(null);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [myList, setMyList] = useState([]);
  const [newlist, setNewlist] = useState([]);
  const [filterdlist, setFilterdlist] = useState([]);

  function handleSearchNameChange(e) {
    const inputString = e.target.value;
    setSearchTerm(inputString);
  }

  const filterData = () => {
    setFilterdlist(newlist);
    return filterdlist.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  useEffect(() => {
    fetchItems();
    // console.log(myList);
  }, []);

  useEffect(() => {
    setNewlist(myList);
  }, [myList]);

  useEffect(() => {
    setNewlist(filterData());
    if (searchTerm === '') {
      setNewlist(myList);
    }
  }, [searchTerm]);

  function fetchItems() {
    axiosInstance
      .get(`/${urlName}/all`)
      .then((response) => {
        setMyList(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSearchNameChange(e) {}

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>{headerTitle}</span>
        <Toast ref={toast} />
        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              className='header__tools__input'
              placeholder='ابحث بالاسم'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={newlist}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
          {columns.map((column, index) => (
            <Column
              key={index}
              header={column.header}
              body={(rowData) =>
                column.isChip ? (
                  <Chip
                    label={
                      column.field.split('.').length > 1
                        ? rowData[column.field.split('.')[0]][column.field.split('.')[1]]
                        : rowData[column.field.split('.')[0]]
                    }
                  />
                ) : (
                  <span>
                    {column.field.split('.').length > 1
                      ? rowData[column.field.split('.')[0]][column.field.split('.')[1]]
                      : rowData[column.field.split('.')[0]]}
                  </span>
                )
              }
            />
          ))}
          <Column
            header='خيارات'
            body={(rowData) => (
              <Link to={`/product/details/${rowData.id}`}>
                <Button label={entityNameDefined} icon='pi pi-exclamation-circle' severity='secondary' text />
              </Link>
            )}
          />
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsSalesTable);
