import React from 'react';
import { connect } from 'react-redux';

import Sidebar from '../../../components/Sidebar/Sidebar';
import Tabs from '../../../components/Tabs/Tabs';
import { API_BASE_URL } from '../../../global';
import EmployeesWorkingGroupsTable from '../../../components/Tables/EmployeesWorkingGroupsTable';
import EmployeeDailyAssignmentTable from '../../../components/Tables/EmployeeDailyAssignmentTable';

function EmployeesManagement({ token }) {
  return (
    <div className="flex w-full">
      <Sidebar />
      <div className="w-full">
        <span className="main__title">
          <i className="main__title--icon">
            <img src="../images/note1.svg" alt="" />
          </i>
          الموظفين
        </span>

        <div className="mt-5 pr-4 pl-4">
          <Tabs
            headers={['مجموعات العمل', 'جدول اﻻعمال']}
            contents={[<EmployeesWorkingGroupsTable />, <EmployeeDailyAssignmentTable />]}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesManagement);
