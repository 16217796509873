import React from 'react';

import Sidebar from '../../../components/Sidebar/Sidebar';
import ListEntityTable from '../../../components/Tables/ListEntityTable';
import VacationRequestTable from '../../../components/Tables/VacationRequestTable';

function VacationRequest() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title'>
          <i className='pi pi-database main__title--icon'></i>الموظفين
        </span>
        <div className='mt-5 pr-4 pl-4'>
          <VacationRequestTable />
        </div>
        {/*<div className='mt-5 pr-4 pl-4'>*/}
        {/*  <ListEntityTable*/}
        {/*    columns={[*/}
        {/*      { header: 'اسم الموظف', field: 'employee.name', isChip: true },*/}
        {/*      { header: 'نوع الاجازة', field: 'vacationType.name', isChip: true },*/}
        {/*      { header: 'مدة الاجازة', field: 'fromDate', isChip: false },*/}
        {/*      { header: 'عدد ايام الاجازة', field: 'days', isChip: false },*/}
        {/*      { header: 'مقبولة من', field: 'approvedBy.name', isChip: false },*/}
        {/*      { header: 'الحالة', field: 'status.name', isChip: true },*/}
        {/*    ]}*/}
        {/*    urlName='VacationsRequest'*/}
        {/*    headerTitle='طلبات الاجازات'*/}
        {/*    entityName='طلب اجازة'*/}
        {/*    entityNameDefined='طلب الاجازة'*/}
        {/*    addBtnText='إضافة طلب اجازة للموظف'*/}
        {/*  />*/}
      </div>
    </div>
  );
}

export default VacationRequest;
