import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import React, { useEffect } from 'react';
import useGeoLocation from '../../../hooks/useGeoLocation';
import { axiosInstance } from '../../../global';
import { useApiState } from '../../../hooks/useApiState';
import CustomFormField from '../../../components/components/Form/CustomFormField';

interface AddEditGovernorateProps {
  control: Control<any>;
  errors: FieldErrors;
  setValue: (name: string, value: any) => void;
  watch: UseFormWatch<any>;
}

const AddEditGovernorate: React.FC<AddEditGovernorateProps> = ({ control, errors, setValue, watch }) => {
  const geoLocation = useGeoLocation(true);
  const isEditMode = watch('id') !== null || watch("id") !== undefined ;
  const selectedCountry = watch('countryId');
  const selectedGovernorate = watch('governorateId');

  const fetchStatus = async () => {
    const response = await axiosInstance.get(`/Status/dropdown`);
    return response.data;
  };

  const {
    state: { isLoading: isStatusLoading, error: statusError, data: statusData },
    lazyApiCall: getStatusData
  } = useApiState({ apiCall: fetchStatus });

  useEffect(() => {
    if (selectedCountry) {
      geoLocation.handleCountrySelect({ id: selectedCountry, name: selectedCountry });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedGovernorate) {
      geoLocation.handleGovernorateSelect({ id: selectedGovernorate, name: selectedGovernorate });
    }
  }, [selectedGovernorate]);

  return (
    <div className='flex flex-column gap-5'>
      <CustomFormField
        name={'countryId'}
        label={'اختر الدولة'}
        control={control}
        errors={errors}
        type={'dropdown'}
        required={true}
        options={geoLocation.countryApiState.state.data || []}
        placeholder='اختر الدولة'
      />
      <CustomFormField
        name={isEditMode ? 'name' : 'names'}
        label={isEditMode ? 'اسم المحافظة' : 'اضف اسم/اسماء المحافظة الجديدة'}
        control={control}
        errors={errors}
        type={isEditMode ? 'text' : 'multi'}
        required={true}
        placeholder='Enter new location names'
      />
      <CustomFormField
        name={'statusId'}
        label={'اختر حالة المحافظة'}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />
      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </div>
  );
};

export default AddEditGovernorate;
