import React from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Tabs from '../../../components/Tabs/Tabs';
import ActivitesScreenTable from '../../../components/Tables/ActivitesScreenTable';
import { connect } from 'react-redux';

function VisitsScreen() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title__custom'>
          <i className='main__title__custom--icon'>
            <img src='../images/briefcase1.svg' alt='' />
          </i>
          <span>الزيارات</span>
        </span>

        <div className='mt-5 pr-4 pl-4'>
          <ActivitesScreenTable
            columns={[
              { header: 'العميل', field: 'customer.fullName', isChip: false },
              { header: 'نوع الزيارة', field: 'type.name', isChip: false },
              { header: 'الفنى', field: 'assignedRep.name', isChip: false },
              { header: 'الاهمية', field: 'customerActivity.satisfactionLevel', isRate: true },
              { header: 'تاريخ الزيارة', field: 'visitDate', isChip: false },
              { header: 'الحالة', field: 'status.name', isChip: true }
            ]}
            urlName='Visit/all'
            headerTitle='الزيارات'
            entityNameDefined='تفاصيل الزيارة'
            addBtnText='اضافة اتصال / زيارة جديدة'
            path='/Activites/addVisitConnection'
            type={'visit'}
            detailsPath='/visits/details'
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitsScreen);
