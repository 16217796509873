import React, { useEffect, useRef, useState } from 'react';
import { Steps } from 'primereact/steps';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';

import Sidebar from '../../../components/Sidebar/Sidebar';
import { addProduct } from '../../../redux/product/productActions';

import AddProduct2 from './AddProduct2';
import AddProduct1 from './AddProduct1';
import './AddProduct.scss';

const AddProduct = ({ activeIndexnum, addProduct, product, token, loading, error, nbOfStages }) => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [addDone, setAddDone] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (activeIndexnum === '' || activeIndexnum === undefined) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndexnum);
    }
  }, [activeIndexnum]);

  useEffect(() => {
    if (addDone) {
      if (!loading && error.length) {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية إضافة المنتج' });
      } else {
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة المنتج بنجاح' });
        setTimeout(() => navigate('/products'), 1000);
      }
    }
  }, [loading, error, addDone, navigate]);

  const items = [{ label: 'تفاصيل المنتج الاساسية' }, { label: 'تفاصيل المراحل' }];

  const handleOnClick = async () => {
    console.log('FormRef in handleOnClick:', formRef.current); // Debugging log
    if (activeIndex === 0 && formRef.current) {
      const isValid = await formRef.current.triggerValidation();
      if (isValid) {
        formRef.current.handleSubmit();
        setActiveIndex(activeIndex + 1);
      }
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handleAddOnClick = () => {
    try {
      addProduct(product, token);
      setAddDone(true);
    } catch (errorAddProduct) {
      console.log(error);
      console.log(errorAddProduct);
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية اضافة المنتج' });
    }
  };

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <div className='flex flex-column gap-5 add__product__main'>
        <div className='flex steps card'>
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true} />
        </div>
        <div>
          <div>
            {activeIndex === 0 && <AddProduct1 ref={formRef} />}
            {activeIndex === 1 && <AddProduct2 nbOfStages={nbOfStages} />}
          </div>
          <div className='btn mb-5'>
            {activeIndex !== 1 && (
              <Button className='btn__nextbtn' onClick={handleOnClick}>
                الخطوة التالية
              </Button>
            )}
            {activeIndex === 1 &&
              (loading ? (
                <ProgressSpinner />
              ) : (
                <Button className='btn__nextbtn' onClick={handleAddOnClick}>
                  اضافة المنتج
                </Button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  product: state.product.product,
  nbOfStages: state.product.product.nbOfStages,
  loading: state.product.loading,
  error: state.product.error
});

const mapDispatchToProps = (dispatch) => ({
  addProduct: (product, token) => dispatch(addProduct(product, token))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
