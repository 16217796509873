import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import './TableHeader.scss';

interface TableHeaderProps {
  title?: string;
  onSearch: (searchTerm: string) => void;
}

export const TableHeader: React.FC<TableHeaderProps> = ({ title, onSearch }) => {
  const [searchCondition, setSearchCondition] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCondition(e.target.value);
    onSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchCondition('');
    onSearch('');
  };

  const handleIconClick = () => {
    if (searchCondition) {
      handleClearSearch();
    } else {
      inputRef.current?.focus();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '10px',
        marginBottom: '15px',
        alignItems: 'center',
        borderBottom: '1px solid #EEE',
        justifyContent: 'space-between'
      }}
    >
      {title && <h2 style={{ fontWeight: 'bold', fontSize: '1.5rem', color: '#313131' }}>{title}</h2>}

      <span className='p-input-icon-left customSpan' style={{ direction: 'rtl', width: '232px' }}>
        <i
          className={`pi ${searchCondition ? 'pi-times' : 'pi-search'}`}
          style={{ borderRadius: '0.5rem', borderColor: '#000', cursor: 'pointer' }}
          onClick={handleIconClick}
        />
        <InputText
          placeholder={`البحث في ${title} ..`}
          style={{
            fontWeight: 400,
            fontSize: '.8rem',
            width: '100%',
            fontFamily: 'IBM Plex Sans Arabic'
          }}
          value={searchCondition}
          onChange={handleSearchChange}
          ref={inputRef}
        />
      </span>
    </div>
  );
};
