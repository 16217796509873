import React from 'react';
import { Dropdown, DropdownChangeEvent, DropdownProps } from 'primereact/dropdown';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './CustomDropDown.scss';

interface CustomDropdownProps extends DropdownProps {
  options: { label: string; value: string | number }[];
  value: string | number | null;
  onChange: (e: DropdownChangeEvent) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  filter?: boolean;
  optionLabel?: string;
  optionValue?: string;
  showClear?: boolean;
  editable?: boolean;
  disabled?: boolean;
}

const CustomDropdown = React.forwardRef<any, CustomDropdownProps>(
  (
    {
      options,
      value,
      onChange,
      placeholder,
      style,
      className,
      filter = true,
      optionLabel = 'label',
      optionValue = 'value',
      showClear = false,
      editable = false,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    return (
      <Dropdown
        value={value}
        options={options}
        onChange={onChange}
        placeholder={placeholder || 'أختر من القائمة'}
        style={{ fontFamily: 'IBM Plex Sans Arabic', ...style }}
        className={`${className} custom-font-dropdown`}
        filter={filter}
        optionLabel={optionLabel}
        optionValue={optionValue}
        showClear={showClear}
        editable={editable}
        disabled={disabled}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default CustomDropdown;
