export const FETCH_EMPLOYEE_REQUEST = 'FETCH_EMPLOYEE_REQUEST';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_EMPLOYEE_FAILURE = 'FETCH_EMPLOYEE_FAILURE';

export const ADD_EMPLOYEE_REQUEST = 'ADD_EMPLOYEE_REQUEST';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE';

export const EDIT_EMPLOYEE_REQUEST = 'EDIT_EMPLOYEE_REQUEST';
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS';
export const EDIT_EMPLOYEE_FAILURE = 'EDIT_EMPLOYEE_FAILURE';

export const CHANGE_EMPLOYEE_STATE = 'CHANGE_EMPLOYEE_STATE';
export const CHANGE_EMPLOYEE_BACKUP_STATE = 'CHANGE_EMPLOYEE_BACKUP_STATE';
export const CLEAR_EMPLOYEE_STATE = 'CLEAR_EMPLOYEE_STATE';
