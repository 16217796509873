import { axiosInstance } from '../../global';

import {
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAILURE,
  CHANGE_PRODUCT_STATE,
  CHANGE_PRICES_STATE
} from './productTypes';


export const fetchProduct = (id, token) => {
  return (dispatch) => {
    dispatch(fetch_product_request());
    axiosInstance
      .get(`/Product/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((responseProductData) => {
        let tempProduct = responseProductData.data.data;
        tempProduct.code = tempProduct.code;
        tempProduct.skuCode = tempProduct.skuCode;
        tempProduct.name = tempProduct.name;
        tempProduct.productCategory = tempProduct.productCategory.id;
        tempProduct.brand = tempProduct.brand.id;
        tempProduct.manufacturer = tempProduct.manufacturer.id;
        tempProduct.nbOfStages = tempProduct.nbOfStages;
        tempProduct.status = tempProduct.status.id;
        tempProduct.countryOfOrigin = tempProduct.countryOfOrigin.id;
        tempProduct.remarks = tempProduct.remarks;
        tempProduct.details = tempProduct.details;
        tempProduct.productsImages = tempProduct.productsImages;
        tempProduct.installmentTypesIds = tempProduct.installmentTypesIds;
        //get product stages===========================
        axiosInstance
          .get(`/ProductsStage/allByProductId/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then((responseProductStage) => {
            let productsStages = responseProductStage.data.data.map((item) => {
              return {
                id: item.id,
                serial: item.serial,
                stageNb: item.stageNb,
                stageTypeId: item.stageType.id,
                stageItemId: item.stageItem.id,
                mandatoryReplacement: item.mandatoryReplacement,
                replacmentMonths: item.replacmentMonths,
                remarks: item.remarks
              };
            });
            tempProduct = { ...tempProduct, productsStages };


            dispatch(fetch_product_success(tempProduct));
          })
          .catch((errorProductStage) => {
            dispatch(fetch_product_failure(errorProductStage.message));
          });
      })
      .catch((errorProductData) => {
        dispatch(fetch_product_failure(errorProductData.message));
      });
  };
};

export const fetchSpares = (id, token) => {
  return (dispatch) => {
    dispatch(fetch_product_request());
    axiosInstance
      .get(`/Product/spareParts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((responseProductData) => {
        let tempProduct = responseProductData.data.data;
        tempProduct.code = tempProduct.code;
        tempProduct.skuCode = tempProduct.skuCode;
        tempProduct.name = tempProduct.name;
        tempProduct.productCategory = tempProduct.productCategory.id;
        tempProduct.brand = tempProduct.brand.id;
        tempProduct.manufacturer = tempProduct.manufacturer.id;
        tempProduct.nbOfStages = tempProduct.nbOfStages;
        tempProduct.status = tempProduct.status.id;
        tempProduct.countryOfOrigin = tempProduct.countryOfOrigin.id;
        tempProduct.remarks = tempProduct.remarks;
        tempProduct.details = tempProduct.details;
        tempProduct.productsImages = tempProduct.productsImages;
        tempProduct.installmentTypesIds = tempProduct.installmentTypesIds;

      })
      .catch((errorProductData) => {
        dispatch(fetch_product_failure(errorProductData.message));
      });
  };
};

const fetch_product_request = () => {
  return {
    type: FETCH_PRODUCT_REQUEST
  };
};
const fetch_product_success = (product) => {
  return {
    type: FETCH_PRODUCT_SUCCESS,
    payload: product
  };
};
const fetch_product_failure = (error) => {
  return {
    type: FETCH_PRODUCT_FAILURE,
    payload: error
  };
};



export const addProduct = (product, token) => {
  return (dispatch) => {
    dispatch(add_product_request());
    axiosInstance
      .post(`/Product`, product, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(add_product_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_product_failure(error.message));
      });
  };
};

export const addSpares = (product, token) => {
  return (dispatch) => {
    dispatch(add_product_request());
    axiosInstance
      .post(`/Product/spareParts`, product, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(add_product_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_product_failure(error.message));
      });
  };
};

const add_product_request = () => {
  return {
    type: ADD_PRODUCT_REQUEST
  };
};
const add_product_success = (Product) => {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload: Product
  };
};
const add_product_failure = (error) => {
  return {
    type: ADD_PRODUCT_FAILURE,
    payload: error
  };
};

export const editProduct = (product, token) => {
  return (dispatch) => {
    dispatch(edit_product_request());
    axiosInstance
      .put(`/Product`, product, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(edit_product_success(product));
      })
      .catch((error) => {
        dispatch(edit_product_failure(error.message));
      });
  };
};
export const editSpares = (product, token) => {
  return (dispatch) => {
    dispatch(edit_product_request());
    axiosInstance
      .put(`/Product/spareParts`, product, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(edit_product_success(product));
      })
      .catch((error) => {
        dispatch(edit_product_failure(error.message));
      });
  };
};
const edit_product_request = () => {
  return {
    type: EDIT_PRODUCT_REQUEST
  };
};
const edit_product_success = (product) => {
  return {
    type: EDIT_PRODUCT_SUCCESS,
    payload: product
  };
};
const edit_product_failure = (error) => {
  return {
    type: EDIT_PRODUCT_FAILURE,
    payload: error
  };
};

export const fill_product = (product) => {
  return (dispatch) => {
    dispatch(change_product_state(product));
  };
};

const change_product_state = (product) => {
  return {
    type: CHANGE_PRODUCT_STATE,
    payload: product
  };
};



