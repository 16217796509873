import React from 'react';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';

import styles from './Header.module.scss';
import { clamp } from 'lodash';

interface PageHeaderProps {
  icon: string;
  iconOnClick?: () => void;
  buttons: {
    label: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }[];
  headerTitle: string;
  style?: React.CSSProperties;
  className?: string;
  extraStartContent?: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  icon,
  iconOnClick,
  buttons,
  headerTitle,
  style,
  className,
  extraStartContent
}) => {
  const startContent = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: iconOnClick ? 'pointer' : 'default'
      }}
    >
      <img src={icon} alt='' onClick={iconOnClick} />
      <div style={{ width: 5 }} />
      <h1 className={styles.title}>{headerTitle}</h1>
      <div style={{ width: 5 }} />
      {extraStartContent && extraStartContent}
    </div>
  );

  const endContent = buttons.map((btn, index) => {
    return (
      <Button
        key={index}
        label={btn.label}
        onClick={btn.onClick}
        icon={'pi pi-plus'}
        className={styles.button}
        style={{
          background: '#A2FB5D',
          borderColor: '#333',
          color: '#333',
          fontWeight: 'bold',
          marginRight: '.5rem',
          direction: 'rtl',
          minWidth: '180px'
        }}
      />
    );
  });

  return (
    <Toolbar
      start={startContent}
      end={
        <div
          style={{
            direction: 'ltr',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            gap: '0.2rem'
          }}
        >
          {endContent}
        </div>
      }
      style={{
        position: 'sticky',
        top: '0',
        zIndex: '999',
        boxShadow: '-3px 1px 3px 0px #888888',
        borderRadius: 0,
        backgroundColor: 'white',
        border: 'none',
        justifyItems: 'center',
        paddingRight: '1.5rem',
        paddingLeft: '1.5rem',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        ...style
      }}
    />
  );
};

export default PageHeader;
