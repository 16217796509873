import { axiosInstance } from '../../global';

import {
  ADD_COVENANT_FAILURE,
  ADD_COVENANT_REQUEST,
  ADD_COVENANT_SUCCESS,
  ADD_TRANSACTION_FAILURE,
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  ADD_WAREHOUSE_FAILURE,
  ADD_WAREHOUSE_REQUEST,
  ADD_WAREHOUSE_SUCCESS,
  CHANGE_COVENANT_STATE,
  CHANGE_TRANSACTION_STATE,
  CHANGE_WAREHOUSE_STATE,
  EDIT_TRANSACTION_FAILURE,
  EDIT_TRANSACTION_REQUEST,
  EDIT_TRANSACTION_SUCCESS,
  EDIT_WAREHOUSE_FAILURE,
  EDIT_WAREHOUSE_REQUEST,
  EDIT_WAREHOUSE_SUCCESS,
  FETCH_TRANSACTION_FAILURE,
  FETCH_TRANSACTION_REQUEST,
  FETCH_TRANSACTION_SUCCESS,
  FETCH_WAREHOUSE_FAILURE,
  FETCH_WAREHOUSE_REQUEST,
  FETCH_WAREHOUSE_SUCCESS
} from './warehouseTypes';

export const fetchWarehouse = (id, token) => {
  return (dispatch) => {
    dispatch(fetch_warehouse_request());
    axiosInstance
      .get(`/Warehouse/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((responseWarehouseData) => {
        let tempWarehouse = responseWarehouseData.data.data;
        dispatch(fetch_warehouse_success(tempWarehouse));
      })
      .catch((errorWarehouseData) => {
        dispatch(fetch_warehouse_failure(errorWarehouseData.message));
      });
  };
};
const fetch_warehouse_request = () => {
  return {
    type: FETCH_WAREHOUSE_REQUEST
  };
};
const fetch_warehouse_success = (warehouse) => {
  return {
    type: FETCH_WAREHOUSE_SUCCESS,
    payload: warehouse
  };
};
const fetch_warehouse_failure = (error) => {
  return {
    type: FETCH_WAREHOUSE_FAILURE,
    payload: error
  };
};

export const addWarehouse = (warehouse, token) => {
  return (dispatch) => {
    dispatch(add_warehouse_request());
    axiosInstance
      .post(`/Warehouse`, warehouse, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(add_warehouse_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_warehouse_failure(error.message));
      });
  };
};
const add_warehouse_request = () => {
  return {
    type: ADD_WAREHOUSE_REQUEST
  };
};
const add_warehouse_success = (warehouse) => {
  return {
    type: ADD_WAREHOUSE_SUCCESS,
    payload: warehouse
  };
};
const add_warehouse_failure = (error) => {
  return {
    type: ADD_WAREHOUSE_FAILURE,
    payload: error
  };
};

export const editWarehouse = (warehouse, token) => {
  return (dispatch) => {
    dispatch(edit_warehouse_request());
    axiosInstance
      .put(`/Warehouse`, warehouse, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(edit_warehouse_success(response.data.data));
      })
      .catch((error) => {
        dispatch(edit_warehouse_failure(error.message));
      });
  };
};
const edit_warehouse_request = () => {
  return {
    type: EDIT_WAREHOUSE_REQUEST
  };
};
const edit_warehouse_success = (warehouse) => {
  return {
    type: EDIT_WAREHOUSE_SUCCESS,
    payload: warehouse
  };
};
const edit_warehouse_failure = (error) => {
  return {
    type: EDIT_WAREHOUSE_FAILURE,
    payload: error
  };
};

export const fill_warehouse = (warehouse) => {
  return (dispatch) => {
    dispatch(change_warehouse_state(warehouse));
  };
};
const change_warehouse_state = (warehouse) => {
  return {
    type: CHANGE_WAREHOUSE_STATE,
    payload: warehouse
  };
};

export const fetchTransaction = (id) => {
  return (dispatch) => {
    dispatch(fetch_transaction_request());
    axiosInstance
      .get(`/WarehouseTransactionsGen/${id}`)
      .then((responseGen) => {
        axiosInstance
          .get(`/WarehouseTransactionsDet/allByGen/${id}`)
          .then((responseDet) => {
            let tempTransaction = { ...responseGen.data.data, products: responseDet.data.data };
            dispatch(fetch_transaction_success(tempTransaction));
          })
          .catch((errorTransactionData) => {
            dispatch(fetch_transaction_failure(errorTransactionData.message));
          });
      })
      .catch((errorTransactionData) => {
        dispatch(fetch_transaction_failure(errorTransactionData.message));
      });
  };
};
const fetch_transaction_request = () => {
  return {
    type: FETCH_TRANSACTION_REQUEST
  };
};
const fetch_transaction_success = (transaction) => {
  return {
    type: FETCH_TRANSACTION_SUCCESS,
    payload: transaction
  };
};
const fetch_transaction_failure = (error) => {
  return {
    type: FETCH_TRANSACTION_FAILURE,
    payload: error
  };
};

export const addTransaction = (transaction, token) => {
  return (dispatch) => {
    dispatch(add_transaction_request());
    axiosInstance
      .post(`/WarehouseTransactions`, transaction, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(add_transaction_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_transaction_failure(error.message));
      });
  };
};
const add_transaction_request = () => {
  return {
    type: ADD_TRANSACTION_REQUEST
  };
};
const add_transaction_success = (transaction) => {
  return {
    type: ADD_TRANSACTION_SUCCESS,
    payload: transaction
  };
};
const add_transaction_failure = (error) => {
  return {
    type: ADD_TRANSACTION_FAILURE,
    payload: error
  };
};

export const editTransaction = (transaction) => {
  return (dispatch) => {
    dispatch(edit_transaction_request());
    axiosInstance
      .put(`/WarehouseTransactions`, transaction)
      .then((response) => {
        dispatch(edit_transaction_success(response.data.data));
      })
      .catch((error) => {
        dispatch(edit_transaction_failure(error.message));
      });
  };
};
const edit_transaction_request = () => {
  return {
    type: EDIT_TRANSACTION_REQUEST
  };
};
const edit_transaction_success = (transaction) => {
  return {
    type: EDIT_TRANSACTION_SUCCESS,
    payload: transaction
  };
};
const edit_transaction_failure = (error) => {
  return {
    type: EDIT_TRANSACTION_FAILURE,
    payload: error
  };
};

export const fill_transaction = (transaction) => {
  return (dispatch) => {
    dispatch(change_transaction_state(transaction));
  };
};
const change_transaction_state = (transaction) => {
  return {
    type: CHANGE_TRANSACTION_STATE,
    payload: transaction
  };
};

export const addCovenant = (covenant, token) => {
  return (dispatch) => {
    dispatch(add_covenant_request());
    axiosInstance
      .post(`/WarehouseCovenants`, covenant, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(add_covenant_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_covenant_failure(error.message));
      });
  };
};
const add_covenant_request = () => {
  return {
    type: ADD_COVENANT_REQUEST
  };
};
const add_covenant_success = (covenant) => {
  return {
    type: ADD_COVENANT_SUCCESS,
    payload: covenant
  };
};
const add_covenant_failure = (error) => {
  return {
    type: ADD_COVENANT_FAILURE,
    payload: error
  };
};
export const fill_covenant = (covenant) => {
  return (dispatch) => {
    dispatch(change_covenant_state(covenant));
  };
};
const change_covenant_state = (covenant) => {
  return {
    type: CHANGE_COVENANT_STATE,
    payload: covenant
  };
};
