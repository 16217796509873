import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import '../../style/Tables.scss';
import { Link, useParams } from 'react-router-dom';

import { API_BASE_URL, axiosInstance } from '../../global';
import EditPayroll from '../Modals/EditPayroll';

function EmployeeSalaryTable({ token }) {
  const toast = useRef(null);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [addItemVisible, setAddItemVisible] = useState(false);
  const [editItemVisible, setEditItemVisible] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState(null);

  function fetchItems() {
    axiosInstance
      .get(`/EmployeesPayrollItem/all/${params.id}`)
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/Status/all`)
      .then((response) => {
        setStatuses(response.data.data);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchItems();
  }, []);

  function handleSearchNameChange(e) {}

  function handleEditItemSelection(item) {
    setSelectedEditItem(item);
    setEditItemVisible(true);
  }

  function handleEditItem(item) {
    axiosInstance
      .put(`/EmployeesPayrollItem`, item)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'تم تعديل مفرد المرتب', life: 3000 });
        setSelectedEditItem(null);
        fetchItems();
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'حدث خطأ اثناء تعديل مفرد المرتب', life: 3000 });
      });
  }

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>نشاطات اضافات مفردات المرتب</span>
        <Toast ref={toast} />
        <EditPayroll
          visible={editItemVisible}
          setVisible={setEditItemVisible}
          statuses={statuses}
          entityName='مفرد مرتب'
          title='تعديل مفرد المرتب'
          onClosePrompt='هل تريد الغاء عملية تعديل مفرد المرتب ؟'
          employeeId={params.id}
          initialData={selectedEditItem}
          onUpdateHandler={handleEditItem}
        />

        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              className='header__tools__input'
              placeholder='ابحث بالاسم'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={items}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
          <Column header='تاريخ اﻻضافة' field='effectiveDate' />
          <Column header='نوع اﻻضافة' field='payrollItem.name' />
          <Column header='القيمة' field='amount' />
          <Column header='اسم المضيف' field='employee.name' />
          <Column header='الحالة' body={(rowData) => <Chip label={rowData.status.name} />} />
          <Column
            header='خيارات'
            body={(rowData) => (
              <span>
                <Button
                  label='تفاصيل الخصم'
                  icon='pi pi-pencil'
                  severity='secondary'
                  text
                  onClick={() => handleEditItemSelection(rowData)}
                />
              </span>
            )}
          />
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSalaryTable);
