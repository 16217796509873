import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { ProgressSpinner } from 'primereact/progressspinner';

import HeaderCard from '../../../components/Cards/Home header/HeaderCard';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { axiosInstance } from '../../../global';
import './TransactionDetails.scss';
import { fill_transaction } from '../../../redux/warehouse/warehouseActions';

function TransactionDetails({ token, fill_transaction }) {
  const toast = useRef(null);

  const Style1 = { color: 'black' };
  const Style2 = { color: 'red' };

  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`/WarehouseTransactionsGen/${params.id}`)
      .then((responseGen) => {
        axiosInstance
          .get(`/WarehouseTransactionsDet/allByGen/${params.id}`)
          .then((responseDet) => {
            setTransaction({ ...responseGen.data.data, products: responseDet.data.data });
            setLoading(false);
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (!loading) fill_transaction(transaction);
  }, [loading]);

  function showConfirmDeleteTransaction(e) {
    confirmDialog({
      message: 'هل تريد تأكيد حذف الاذن؟',
      header: 'تأكيد حذف الاذن',
      acceptLabel: 'تأكيد',
      acceptClassName: 'p-button-danger',
      contentClassName: 'font-semibold	',
      rejectLabel: 'إلغاء',
      accept: handleDeleteTransaction,
      draggable: false,
      resizable: false
    });
  }

  function handleDeleteTransaction(e) {
    axiosInstance
      .delete(`/WarehouseTransactionsDet/${params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'تم حذف الاذن',
          detail: '',
          life: 3000
        });
        setTimeout(() => navigate('/warehouses/transactions'), 3000);
      })
      .catch(() => {
        toast.current.show({
          severity: 'error',
          summary: 'حدث خطأ اثناء حذف الاذن',
          detail: '',
          life: 3000
        });
      });
  }

  function getSrcDestName(rowData, type) {
    if (type === 1) {
      switch (rowData.source.name) {
        case 'Stock':
          return rowData.destinationWarehouse?.name;
        case 'Supplier':
          return rowData.supplier?.name;
        case 'Customer':
          return rowData.customer?.name;
        case 'PhysicalCount':
          return 'ﻻ يوجد معلومات';
        case 'Waste':
          return 'ﻻ يوجد معلومات';
        default:
          return 'ﻻ يوجد معلومات';
      }
    } else {
      switch (rowData.destination.name) {
        case 'Stock':
          return rowData.warehouse?.name;
        case 'Supplier':
          return rowData.supplier?.name;
        case 'Customer':
          return rowData.customer?.name;
        case 'PhysicalCount':
          return 'ﻻ يوجد معلومات';
        case 'Waste':
          return 'ﻻ يوجد معلومات';
        default:
          return 'ﻻ يوجد معلومات';
      }
    }
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className=' main w-full'>
        <div className='details__header1 bg-white'>
          <Toast ref={toast} />
          <ConfirmDialog />

          <div className='details__header1__right'>
            <Button
              className='font-bold text-black-alpha-90'
              icon='pi pi-arrow-right'
              rounded
              text
              onClick={() => navigate(-1)}
            />
            <span className='details__header1__right--text'>تفاصيل الاذن</span>
          </div>

          <div className='details__header__left'>
            <Link to={`/warehouses/transactions/edit/${params.id}`}>
              <Button className='details__header1__left__btn' icon='pi pi-pencil' style={Style1} text>
                تعديل الاذن
              </Button>
            </Link>
            <Button
              className='details__header1__left__btn'
              onClick={showConfirmDeleteTransaction}
              icon='pi pi-trash'
              style={Style2}
              text
            >
              حذف الاذن
            </Button>
          </div>
        </div>
        {loading ? (
          <div className='flex justify-content-center'>
            <ProgressSpinner />
          </div>
        ) : (
          <div className='mt-5'>
            <div className='details'>
              <div className='details__header__card'>
                <HeaderCard
                  title={'تاريخ اﻻنشاء'}
                  imageUrl={'/images/calendar.svg'}
                  num={new Date(transaction.transDateTime).toLocaleDateString('en-GB')}
                  color={'#13DE8230'}
                />
                <HeaderCard
                  title={'نوع الاذن'}
                  num={transaction.transType?.name}
                  imageUrl={'/images/briefcase.svg'}
                  color={'#F9D8D4'}
                />
                <HeaderCard
                  title={'حالة الاذن'}
                  num={transaction.approved ? 'تم اﻻستلام' : 'لم يتم اﻻستلام'}
                  imageUrl={'/images/briefcase.svg'}
                  color={'#F9D8D4'}
                />
                <HeaderCard
                  title={'منشئ الاذن'}
                  num={transaction.user?.userName || 'غير متوفر'}
                  imageUrl={'/images/drop_box.svg'}
                  color={'#F9D8D4'}
                />
              </div>
              <div className='transaction-details'>
                <span className='transaction-details__title'>بيانات الطلب</span>
                <div className='transaction-details__data'>
                  <span className='transaction-details__data--label'>تاريخ اﻻنشاء:</span>
                  <span className='transaction-details__data--text'>
                    {new Date(transaction.transDateTime).toLocaleDateString('en-GB')}
                  </span>
                </div>
                <div className='transaction-details__data'>
                  <span className='transaction-details__data--label'>حالة الطلب:</span>
                  <Chip label={transaction.approved ? 'تم اﻻستلام' : 'لم يتم اﻻستلام'} />
                </div>
                <div className='transaction-details__data'>
                  <span className='transaction-details__data--label'>منشئ الطلب:</span>
                  <span className='transaction-details__data--text'>{transaction.user?.userName || 'غير متوفر'}</span>
                </div>
                <span className='transaction-details__title mt-5'>بيانات اﻻتصال</span>
                <div className='transaction-details__data'>
                  <span className='transaction-details__data--label'>اسم اﻻذن:</span>
                  <span className='transaction-details__data--text'>{transaction.name}</span>
                </div>
                <div className='transaction-details__data'>
                  <span className='transaction-details__data--label'>نوع اﻻذن:</span>
                  <span className='transaction-details__data--text'>{transaction.transType.name}</span>
                </div>
                <div className='transaction-details__data'>
                  <span className='transaction-details__data--label'>المخزن:</span>
                  <span className='transaction-details__data--text'>{transaction.warehouse.name}</span>
                </div>
                <div className='transaction-details__data'>
                  <span className='transaction-details__data--label'>نوع المستقبل:</span>
                  <span className='transaction-details__data--text'>{transaction.destination.name}</span>
                </div>
                <div className='transaction-details__data'>
                  <span className='transaction-details__data--label'>المستقبل:</span>
                  <span className='transaction-details__data--text'>{getSrcDestName(transaction, 2)}</span>
                </div>
                <div className='transaction-details__data'>
                  <span className='transaction-details__data--label'>نوع المصدر:</span>
                  <span className='transaction-details__data--text'>{transaction.source.name}</span>
                </div>
                <div className='transaction-details__data'>
                  <span className='transaction-details__data--label'>المصدر:</span>
                  <span className='transaction-details__data--text'>{getSrcDestName(transaction, 1)}</span>
                </div>
                <div className='transaction-details__data'>
                  <span className='transaction-details__data--label'>ملاحظات اخرى:</span>
                  <span className='transaction-details__data--text'>{transaction.remarks}</span>
                </div>
                <span className='transaction-details__title mt-5'>بيان المنتجات</span>
                <DataTable
                  value={transaction.products}
                  paginator
                  showGridlines
                  rows={5}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  dataKey='id'
                  className='w-full p-2'
                  emptyMessage={
                    <div className='w-full flex justify-content-center'>
                      <span className='text-lg font-bold'>ﻻ يوجد منتجات لهذا الطلب</span>
                    </div>
                  }
                >
                  <Column header='اسم المنتج' field='product.name' />
                  <Column header='الكمية' field='qty' />
                  <Column header='سعر الوحدة' field='price' />
                  <Column header='اﻻجمالي' field='amount' />
                </DataTable>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_transaction: (transaction) => dispatch(fill_transaction(transaction))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);
