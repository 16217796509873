import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Link, useLocation } from 'react-router-dom';

import { axiosInstance } from '../../global';
import '../../style/Tables.scss';
import AddWarehouseSection from '../Modals/AddWarehouseSection';
import AddWarehouseLocation from '../Modals/AddWarehouseLocation';
import EditWarehouseSection from '../Modals/EditWarehouseSection';
import EditWarehouseLocation from '../Modals/EditWarehouseLocation';

function WarehousesTable({
  token,
  columns,
  urlName,
  headerTitle,
  addBtnText,
  entityName,
  entityNameDefined,
  routePrefix,
  addWithLink
}) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [newlist, setNewlist] = useState([]);
  const [filterdlist, setFilterdlist] = useState([]);
  const [selectedForEdit, setSelectedForEdit] = useState({});
  const [addSectionModalVisible, setAddSectionModalVisible] = useState(false);
  const [editSectionModalVisible, setEditSectionModalVisible] = useState(false);
  const [addLocationModalVisible, setAddLocationModalVisible] = useState(false);
  const [editLocationModalVisible, setEditLocationModalVisible] = useState(false);

  function handleSearchNameChange(e) {
    const inputString = e.target.value;
    setSearchTerm(inputString);
  }

  const filterData = () => {
    setFilterdlist(newlist);
    return filterdlist.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  function fetchItems() {
    setLoading(true);
    axiosInstance
      .get(`/${urlName}/all`)
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchItems();
  }, []);

  // useEffect(() => {
  //   setNewlist(myList);
  // }, [myList]);

  // useEffect(() => {
  //   setNewlist(filterData());
  //   if (searchTerm === '') {
  //     setNewlist(myList);
  //   }
  // }, [searchTerm]);

  useEffect(() => {
    if (!editSectionModalVisible || !editLocationModalVisible) setSelectedForEdit({});
  }, [editSectionModalVisible, editLocationModalVisible]);

  function showAddModal() {
    switch (urlName) {
      case 'WarehousesSection':
        setAddSectionModalVisible(true);
        break;
      case 'WarehousesLocation':
        setAddLocationModalVisible(true);
        break;

      default:
        break;
    }
  }

  function showEditModal(entity) {
    setSelectedForEdit(entity);
    switch (urlName) {
      case 'WarehousesSection':
        setEditSectionModalVisible(true);
        break;
      case 'WarehousesLocation':
        setEditLocationModalVisible(true);
        break;

      default:
        break;
    }
  }

  function addHandler(entity) {
    axiosInstance
      .post(`/${urlName}`, entity)
      .then((response) => {
        fetchItems();
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: `تم إضافة ${entityName} بنجاح` });
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: `فشلت عملية إضافة ${entityName}` });
      });
  }

  function editHandler(entity) {
    axiosInstance
      .put(`/${urlName}`, entity)
      .then((response) => {
        fetchItems();
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: `تم تعديل ${entityName} بنجاح` });
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: `فشلت عملية تعديل ${entityName}` });
      });
  }

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>{headerTitle}</span>
        {addWithLink ? (
          <button className='tbl__addbtn'>
            <Link to={`${routePrefix}/add`} className='tbl__addbtn__link'>
              <img src='/images/plus.svg' alt={addBtnText} />
              {addBtnText}
            </Link>
          </button>
        ) : (
          <>
            <button className='tbl__addbtn' onClick={showAddModal}>
              <img src='/images/plus.svg' alt={addBtnText} />
              {addBtnText}
            </button>

            {urlName === 'WarehousesSection' ? (
              <>
                <AddWarehouseSection
                  visible={addSectionModalVisible}
                  setVisible={setAddSectionModalVisible}
                  onAddHandler={addHandler}
                />
                <EditWarehouseSection
                  visible={editSectionModalVisible}
                  setVisible={setEditSectionModalVisible}
                  onEditHandler={editHandler}
                  entityBase={selectedForEdit}
                />
              </>
            ) : (
              <>
                <AddWarehouseLocation
                  visible={addLocationModalVisible}
                  setVisible={setAddLocationModalVisible}
                  onAddHandler={addHandler}
                />
                <EditWarehouseLocation
                  visible={editLocationModalVisible}
                  setVisible={setEditLocationModalVisible}
                  onEditHandler={editHandler}
                  entityBase={selectedForEdit}
                />
              </>
            )}
          </>
        )}

        <Toast ref={toast} />

        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={searchTerm}
              className='header__tools__input'
              placeholder='ابحث بالاسم'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={items}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
          emptyMessage={
            <div className='w-full flex justify-content-center'>
              <span className='text-lg font-bold'>
                لم تقم بإضافة اي بيانات الي {headerTitle} حتى الآن ،قم بالإضافة الآن
              </span>
            </div>
          }
        >
          <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
          {columns.map((column, index) => (
            <Column
              key={index}
              header={column.header}
              body={(rowData) =>
                column.isChip ? (
                  <Chip
                    label={
                      column.field.split('.').length > 1
                        ? rowData[column.field.split('.')[0]][column.field.split('.')[1]]
                        : rowData[column.field.split('.')[0]]
                    }
                  />
                ) : (
                  <span>
                    {column.field.split('.').length > 1
                      ? rowData[column.field.split('.')[0]][column.field.split('.')[1]]
                      : rowData[column.field.split('.')[0]]}
                  </span>
                )
              }
            />
          ))}
          <Column
            header='خيارات'
            body={(rowData) =>
              addWithLink ? (
                <Link to={`${routePrefix}/details/${rowData.id}`}>
                  <Button label={entityNameDefined} icon='pi pi-exclamation-circle' severity='secondary' text />
                </Link>
              ) : (
                <Button
                  label={`تعديل ${entityNameDefined}`}
                  icon='pi pi-exclamation-circle'
                  severity='secondary'
                  text
                  onClick={() => showEditModal(rowData)}
                />
              )
            }
          />
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehousesTable);
