import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Link, useLocation } from 'react-router-dom';

import { axiosInstance } from '../../global';
import '../../style/Tables.scss';

function WarehouseTransactionsTable({ routePrefix }) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [newlist, setNewlist] = useState([]);

  function fetchItems() {
    setLoading(true);
    axiosInstance
      .get(`/WarehouseTransactionsGen/all`)
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchItems();
  }, []);

  function getSrcDestName(rowData, type) {
    if (type === 1) {
      switch (rowData.source.name) {
        case 'Stock':
          return rowData.warehouse?.name;
        case 'Supplier':
          return rowData.supplier?.name;
        case 'Customer':
          return rowData.customer?.name;
        case 'PhysicalCount':
          return 'N/A';
        case 'Waste':
          return 'N/A';
        default:
          return 'N/A';
      }
    } else {
      switch (rowData.destination.name) {
        case 'Stock':
          return rowData.warehouse?.name;
        case 'Supplier':
          return rowData.supplier?.name;
        case 'Customer':
          return rowData.customer?.name;
        case 'PhysicalCount':
          return 'N/A';
        case 'Waste':
          return 'N/A';
        default:
          return 'N/A';
      }
    }
  }

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>قائمة الحركات اليومية</span>
        <div className='tbl__btns__container'>
          <button className='tbl__addbtn tbl__addbtn--cst3 m-0'>
            <Link to={`${routePrefix}/issuing/add`} className='tbl__addbtn__link'>
              <img src='/images/plus.svg' />
              إضافة إذن صرف
            </Link>
          </button>
          <button className='tbl__addbtn tbl__addbtn--cst3 m-0'>
            <Link to={`${routePrefix}/receiving/add`} className='tbl__addbtn__link'>
              <img src='/images/plus.svg' />
              إضافة إذن استلام
            </Link>
          </button>
          <button className='tbl__addbtn tbl__addbtn--cst3 m-0'>
            <Link to={`${routePrefix}/covenant/add`} className='tbl__addbtn__link'>
              <img src='/images/plus.svg' />
              إضافة عهدة للزيارة
            </Link>
          </button>
        </div>
        <Toast ref={toast} />
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={items}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
          emptyMessage={
            <div className='w-full flex justify-content-center'>
              <span className='text-lg font-bold'>لم تقم بإضافة اي حركات حتى الآن ،قم بالإضافة الآن</span>
            </div>
          }
        >
          <Column header='رقم العملية' field='transNb' />
          <Column header='اﻻسم' field='name' />
          <Column header='نوع اﻻذن' field='transType.name' />
          <Column header='نوع المصدر' field='source.name' />
          <Column header='المصدر' body={(rowData) => <span>{getSrcDestName(rowData, 1)}</span>} />
          <Column header='نوع المستقبل' field='destination.name' />
          <Column header='المستقبل' body={(rowData) => <span>{getSrcDestName(rowData, 2)}</span>} />
          <Column header='الحالة' body={(rowData) => <Chip label={rowData.approved ? 'مقبول' : 'مرفوض'} />} />

          <Column
            header='خيارات'
            body={(rowData) => (
              <Link to={`${routePrefix}/details/${rowData.id}`}>
                <Button label='تفاصيل اﻻذن' icon='pi pi-exclamation-circle' severity='secondary' text />
              </Link>
            )}
          />
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseTransactionsTable);
