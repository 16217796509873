import React, { useEffect, useRef, useState } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { InputTextarea } from 'primereact/inputtextarea';

import { axiosInstance } from '../../global';

function AddPayroll({
  employeeId,
  title,
  entityName,
  visible,
  setVisible,
  statuses,
  onClosePrompt,
  onAddHandler,
  onUpdateHandler,
  initialData
}) {
  const toast = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [payrollTypes, setPayrollTypes] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [entity, setEntity] = useState({
    employeeId: employeeId ? +employeeId : '',
    payrollItemId: '',
    amount: '',
    effectiveDate: '',
    statusId: '',
    remarks: ''
  });

  useEffect(() => {
    if (!employeeId) {
      axiosInstance
        .get(`/Employee/all`)
        .then((response) => {
          setEmployees(response.data.data);
        })
        .catch((error) => {});
    }

    if (initialData) {
      // If initialData is provided, set the state with the initial values
      setEntity({
        employeeId: initialData.employeeId,
        payrollItemId: initialData.payrollItemId,
        amount: initialData.amount,
        effectiveDate: initialData.effectiveDate,
        statusId: initialData.statusId,
        remarks: initialData.remarks
      });

      setSelectedStatus(initialData.statusId);
      setSelectedItem(initialData.payrollItemId);
      setSelectedEmployee(initialData.employeeId);
    }
  }, [employeeId, initialData]);

  function acceptClose() {
    setEntity({
      employeeId: employeeId ? +employeeId : selectedEmployee,
      payrollItemId: '',
      amount: '',
      effectiveDate: '',
      statusId: '',
      remarks: ''
    });
    setSelectedStatus(null);
    setSelectedItem(null);
    setSelectedEmployee(null);
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: onClosePrompt,
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      tagKey: 1
    });
  }

  useEffect(() => {
    axiosInstance
      .get(`/PayrollItem/all`)
      .then((response) => {
        setPayrollTypes(response.data.data);
      })
      .catch((error) => {});
  }, []);

  function handleInputChange(e) {
    setEntity({ ...entity, [e?.target?.name || e?.originalEvent?.target?.name]: e?.target?.value || e?.value });
  }

  function handleStatusChange(e) {
    setSelectedStatus(e.value);
    setEntity({ ...entity, statusId: e.value });
  }

  function handleItemChange(e) {
    setSelectedItem(e.value);
    setEntity({ ...entity, payrollItemId: e.value });
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog tagKey={1} />
      <Dialog
        header={title}
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          {employeeId || !initialData ? null : (
            <div className='w-full flex gap-5'>
              <div className='w-full flex flex-column gap-2'>
                <label className='modals__label'>اسم الموظف</label>
                <div className='flex gap-1'>
                  <Dropdown
                    value={entity.employeeId}
                    onChange={(e) => setEntity({ ...entity, employeeId: e.value })}
                    options={employees}
                    optionLabel='name' // Assuming 'employeeName' is the property in the employee object
                    optionValue='id' // Assuming 'employeeId' is the property in the employee object
                    className='w-full'
                    placeholder='اختر الموظف'
                    disabled={initialData}
                  />
                </div>
              </div>
            </div>
          )}

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>نوع الاضافة</label>
              <div className='flex gap-1'>
                <Dropdown
                  value={selectedItem}
                  onChange={handleItemChange}
                  options={payrollTypes}
                  optionLabel='name'
                  optionValue='id'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>القيمة</label>
              <div className='flex gap-1'>
                <InputNumber name='amount' className='w-full' value={entity.amount} onChange={handleInputChange} />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>تاريخ الاضافة</label>
              <div className='flex gap-1'>
                <Calendar
                  className='w-full'
                  name='effectiveDate'
                  value={entity.effectiveDate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر حالة {entityName}</label>
              <div className='flex'>
                <Dropdown
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  placeholder='اختر الحالة'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea name='remarks' value={entity.remarks} className='w-full' onChange={handleInputChange} />
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button
              className='modals__btn'
              onClick={() => {
                initialData ? onUpdateHandler(entity) : onAddHandler(entity);
                handleClose();
              }}
              disabled={!selectedStatus && !selectedItem}
            >
              {initialData ? 'تحديث' : `اضافة ${entityName}`}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default AddPayroll;
