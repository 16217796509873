import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

import { API_BASE_URL, axiosInstance } from '../../global';
import AddEntity from '../Modals/AddEntity';
import EditEntity from '../Modals/EditEntity';
import '../../style/Tables.scss';
import EditStatus from '../Modals/EditStatus';
import AddStatus from '../Modals/AddStatus';

function StatusTable({ token, urlName, headerTitle, addBtnText, entityName, entityNameDefined }) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  // const [status, setStatus] = useState([]);
  const [addItemVisible, setAddItemVisible] = useState(false);
  const [editItemVisible, setEditItemVisible] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState(null);

  function fetchItems() {
    axiosInstance
      .get(`/${urlName}/all`)
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchItems();

    // axios
    //   .get(`/Status/all`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`
    //     }
    //   })
    //   .then((response) => {
    //     console.log(response.data.data);
    //     const tempStatus = response.data.data.map((status) => {
    //       return {
    //         id: status.id,
    //         name: status.name
    //       };
    //     });
    //     setStatus(tempStatus);
    //   })
    //   .catch((error) => {});
  }, []);

  function handleSearchNameChange(e) {}

  function handleAddItem(item) {
    axiosInstance
      .post(`/${urlName}`, item)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: `تم إضافة ${entityNameDefined} بنجاح!`, life: 3000 });
        fetchItems();
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: `فشلت عملية إضافة ${entityName} جديدة`, life: 3000 });
      });
  }

  function handleEditItemSelection(item) {
    setSelectedEditItem(item);
    setEditItemVisible(true);
  }

  function handleEditPos(item) {
    axiosInstance
      .put(`/${urlName}`, item)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: `'تم تعديل ${entityNameDefined} بنجاح!'`, life: 3000 });
        fetchItems();
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: `فشلت عملية تعديل ${entityNameDefined}`, life: 3000 });
      });
  }

  function handleDeletePos(id) {
    axiosInstance
      .delete(`/${urlName}/${id}`)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: `'تم الحذف ${entityNameDefined} بنجاح!'`, life: 3000 });
        fetchItems();
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: `فشلت عملية الحذف ${entityNameDefined}`, life: 3000 });
      });
  }

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>جدول {headerTitle}</span>
        <button className='tbl__addbtn' onClick={() => setAddItemVisible(true)}>
          <img src='/images/plus.svg' alt={addBtnText} />
          {addBtnText}
        </button>
        <Toast ref={toast} />

        <AddStatus
          visible={addItemVisible}
          setVisible={setAddItemVisible}
          title={addBtnText}
          entityName={entityNameDefined}
          onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية إضافة ${entityName} جديد؟`}
          onAddHandler={handleAddItem}
          key={1}
        />

        <EditStatus
          visible={editItemVisible}
          setVisible={setEditItemVisible}
          title={`تعديل ${entityName}`}
          entityName={entityNameDefined}
          entityBase={selectedEditItem}
          onClosePrompt={`هل أنت متأكد من أنك تريد الخروج من عملية تعديل ${entityName}؟`}
          onEditHandler={handleEditPos}
          onDeleteHandler={handleDeletePos}
          key={2}
        />

        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              className='header__tools__input'
              placeholder='ابحث بالاسم'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={items}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
          <Column header='اسماء الحالات' body={(rowData) => <Chip label={rowData.name} />} />
          <Column header='انواع الحالات' body={(rowData) => <Chip label={rowData.isActive ? 'متاح' : 'غير متاح'} />} />
          {<Column header='تفاصيل الحالة' body={(rowData) => <span>{rowData.remarks}</span>} />}
          <Column
            header='خيارات'
            body={(rowData) => (
              <span>
                <Button
                  label={`تعديل ${entityNameDefined}`}
                  icon='pi pi-pencil'
                  severity='secondary'
                  text
                  onClick={() => handleEditItemSelection(rowData)}
                />
              </span>
            )}
          />
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusTable);
