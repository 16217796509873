import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import React, { useEffect } from 'react';

import CustomFormField from '../../../components/components/Form/CustomFormField';
import useGeoLocation from '../../../hooks/useGeoLocation';
import { axiosInstance } from '../../../global';
import { useApiState } from '../../../hooks/useApiState';

interface AddEditLocationProps {
  control: Control<any>;
  errors: FieldErrors;
  setValue: (name: string, value: any) => void;
  watch: UseFormWatch<any>;
}

const AddEditLocation: React.FC<AddEditLocationProps> = ({ control, errors, setValue, watch }) => {
  const geoLocation = useGeoLocation(true);
  const isEditMode = watch('id');
  const selectedCountry = watch('countryId');
  const selectedGovernorate = watch('governorateId');
  const selectedArea = watch('areaId');

  const fetchStatus = async () => {
    const response = await axiosInstance.get(`/Status/dropdown`);
    return response.data;
  };

  const {
    state: { isLoading: isStatusLoading, error: statusError, data: statusData },
    lazyApiCall: getStatusData
  } = useApiState({ apiCall: fetchStatus });

  useEffect(() => {
    if (selectedCountry) {
      geoLocation.handleCountrySelect({ id: selectedCountry, name: selectedCountry });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedGovernorate) {
      geoLocation.handleGovernorateSelect({ id: selectedGovernorate, name: selectedGovernorate });
    }
  }, [selectedGovernorate]);

  useEffect(() => {
    if (selectedArea) {
      geoLocation.handleAreaSelect({ id: selectedArea, name: selectedArea });
    }
  }, [selectedArea]);

  return (
    <div className='flex flex-column gap-5'>
      <CustomFormField
        name={'countryId'}
        label={'اختر الدولة'}
        control={control}
        errors={errors}
        type={'dropdown'}
        required={true}
        options={geoLocation.countryApiState.state.data || []}
        placeholder='اختر الدولة'
      />
      <CustomFormField
        name='governorateId'
        label='اختر المحافظة'
        control={control}
        errors={errors}
        type='dropdown'
        options={geoLocation.governorateApiState.state.data || []}
        required={true}
        placeholder='اختر المحافظة'
        disabled={!selectedCountry}
      />
      <CustomFormField
        name='areaId'
        label='اختر المدينة'
        control={control}
        errors={errors}
        type='dropdown'
        options={geoLocation.areaApiState.state.data || []}
        required={true}
        placeholder='اختر المدينة'
        disabled={!selectedGovernorate}
      />

      <CustomFormField
        name={isEditMode ? 'name' : 'names'}
        label={isEditMode ? 'اسم المنطقة' : 'اضف اسم/اسماء المنطقة الجديدة'}
        control={control}
        errors={errors}
        type={isEditMode ? 'text' : 'multi'}
        required={true}
        placeholder='Enter new location names'
      />
      <CustomFormField
        name={'statusId'}
        label={'اختر حالة المنطقة'}
        required={true}
        control={control}
        errors={errors}
        type={'dropdown'}
        placeholder={'اختر الحاله'}
        disabled={isStatusLoading}
        options={statusData?.data ?? []}
      />
      <CustomFormField name={'remarks'} label={'ملاحظات'} control={control} errors={errors} type={'textarea'} />
    </div>
  );
};

export default AddEditLocation;
