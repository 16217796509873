import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams , Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chip } from 'primereact/chip';

import HeaderCard from '../../../components/Cards/Home header/HeaderCard';
import Tabs from '../../../components/Tabs/Tabs';
import CustomerEntityTable from '../../../components/Tables/CustomerEntityTable';
import './CustomersDetails.scss';

function CustomersDetailsContent({ token, customer, user }) {
  const params = useParams();

  return (
    <div className='customerdetails'>
      <div className='customerdetails__header__card'>
        <HeaderCard
          title={'الاقساط المطلوبة'}
          num={'14 قسط'}
          imageUrl={'/images/dollar.svg'}
          color={'#F9D8D4'}
          txtColor={'red'}
        />
        <HeaderCard title={'الاقساط المدفوعة'} num={'1500 ج.م'} imageUrl={'/images/coins.svg'} color={'#6D54DE33'} />
        <HeaderCard title={'عدد الاجهزة'} num={'15 اجهزة'} imageUrl={'/images/drop_box.svg'} color={'#FFEDD0'} />
        <HeaderCard title={'شكاوى العميل'} num={'5 شكاوى'} imageUrl={'/images/Missed.svg'} color={'#C050E838'} />
      </div>

      <div className='customerdetails__content customerdetails__content--wrapper'>
        {/*=========================================== right content ================================================================= */}
        <div className='customerdetails__content__right'>
          <div className='customerdetails__content__right__image'>
            <img src='/images/user.svg'></img>
          </div>

          <div className='customerdetails__content__right__customer__name'>{customer?.fullName}</div>

          <div className='primary__data'>
            <span className='primary__data--label'> كود العميل: </span>
            <span className='primary__data--link'>{customer?.code}</span>
          </div>

          <div className='primary__data'>
            <span className='primary__data--label'>حالة المنتج: </span>
            <Chip label={customer?.status.name} />
          </div>

          <span className='primary__data--title mt-4'>
            بيانات العميل<img src='/images/2.svg'></img>
          </span>

          <div className='primary__data'>
            <span className='primary__data--label'> الرقم القومي: </span>
            <span className='primary__data--text'>{customer?.nationalNb}</span>
          </div>

          <div className='primary__data'>
            <span className='primary__data--label'> الوظــــــــيفــــــــة: </span>
            <span className='primary__data--text'>{customer?.jobTitle}</span>
          </div>
          <div className='primary__data'>
            <span className='primary__data--label'> العنوان الرئيسي: </span>
            <span className='primary__data--text'>{customer?.defaultAddress.name}</span>
          </div>
          <div className='primary__data'>
            <span className='primary__data--label'> تاريخ الإضافة: </span>
            <span className='primary__data--text'>{customer?.creationDateTime}</span>
          </div>
          <div className='primary__data mt-4'>
            <span className='primary__data--title'>الاجهزة المربوطة</span>
            <Chip label={`${customer?.numberOfDevices}اجهزة`} />
          </div>
        </div>

        {/*=========================================== left content ================================================================= */}
        <div className='customerdetails__content__left'>
          <span className='primary__data--title mt-4'>
            مواقع العميل <img src='/images/2.svg'></img>
          </span>
          <div className='primary__data'>
            <span className='primary__data--label'> موقع 1 : </span>
            <span className='primary__data--text'>{customer?.defaultAddress.name}</span>
          </div>
          <span className='primary__data--title mt-4'>
            الملاحظات <img src='/images/2.svg'></img>
          </span>
          {customer?.remarks == ' ' && (
            <span className='primary__data--text'>
              هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض
              النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسومادر أو الكلمات العشوائية إلى
              النص. إن كنت تريد أن تستخدم نص لوريم إيبسادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم{' '}
            </span>
          )}

          {customer?.remarks != '' && <span className='primary__data--text'>{customer?.remarks}</span>}

          <span className='primary__data--title mt-4'>
            بيانات التواصل <img src='/images/2.svg'></img>
          </span>
          <div className='primary__data'>
            <span className='primary__data--label'>طريقة التواصل : </span>
            <span className='primary__data--text'>{customer?.preferredContactMethod.name}</span>
          </div>
          <div className='primary__data'>
            <span className='primary__data--label'> رقم الهاتف : </span>
            <span className='primary__data--text'>{customer?.phoneNb}</span>
          </div>
          <div className='primary__data'>
            <span className='primary__data--label'> رقم الموبايل : </span>
            <span className='primary__data--text'>{customer?.mobile}</span>
          </div>
          <div className='primary__data'>
            <span className='primary__data--label'> رقم الواتساب : </span>
            <span className='primary__data--text'>{customer?.whatsappNb}</span>
          </div>
        </div>
      </div>

      <div className='customerdetails__content__down'>
        <Tabs
          headers={[
            'أجهزة العميل',
            'العقود',
            'متابعة المراحل',
            'اقساط العميل',
            'سجل المتابعات',
            'سجل الاتصال',
            'سجل الشكاوى'
          ]}
          contents={[
            <CustomerEntityTable
              columns={[
                // { header: 'كود SKU', field: 'device.code', isChip: false },
                // { header: 'اسم الجهاز', field: 'device.name', isChip: false },
                // { header: 'فئة المنتج', field: 'device.productCategory?.name', isChip: false },
                { header: 'نظام التقسيط', field: 'installmentsType.name', isChip: false },
                { header: 'حالة العقد', field: 'status.name', isChip: true },
                { header: 'تاريخ العقد', field: 'installationDate', isChip: false }
              ]}
              urlName={`CustomersDevice/allByCustomer/${params.id}`}
              headerTitle='قائمة الاجهزة'
              entityNameDefined='تفاصيل الجهاز'
              entityName='الجهاز'
              addBtnText='إضافة جهاز للعميل'
              isDevice={true}
              path='/product/details'
              type='devices'
              customerId={params.id}
            />, //الاجهزة

            <CustomerEntityTable
              columns={[
                // { header: 'اسم الجهاز', field: 'customerDevice.device.name', isChip: false },
                { header: 'الموظف المسؤل', field: 'beneficiaryEmployee.name', isChip: false },
                { header: 'تاريخ التعاقد', field: 'contractDate', isChip: false },
                { header: 'تاريخ الانتهاء', field: 'expiryDate', isChip: false },
                { header: 'نوع العقد', field: 'type.name', isChip: true },
                { header: 'حالة العقد', field: 'status.name', isChip: true }
              ]}
              urlName={`Contract/allByCustomer/${params.id}`}
              headerTitle='قائمة العقود'
              entityNameDefined='تفاصيل العقد'
              entityName='العقد'
              addBtnText='إضافة عقد للعميل'
              isDevice={false}
              path='/customer/contract/details'
              type='contracts'
              customerId={params.id}
            />, //العقود

            <CustomerEntityTable
              columns={[
                { header: 'اسم الجهاز', field: 'stageItem.name', isChip: false },
                { header: 'رقم المرحلة', field: 'stageNb', isChip: false },
                { header: 'المنتج', field: 'product.name', isChip: false },
                { header: 'السعر', field: 'price', isChip: false },
                { header: 'موعد التغير القادم', field: 'nextReplacementDate', isChip: false },
                { header: 'مؤشر التغير', field: 'remainingDays', isKnob: true }
              ]}
              urlName={`ContractsDeviceStage/allByCustomer/${params.id}`}
              headerTitle='متابعة المراحل'
              addBtnText='إضافة زيارة للعميل'
              isDevice={false}
              // path='/product/details'
              type='stages'
            />, //المراحل

            <CustomerEntityTable
              columns={[
                // { header: 'اسم الجهاز', field: 'product.name', isChip: false },
                // { header: 'فئة المنتج', field: 'product.productCategory.name', isChip: false },
                { header: 'نظام التقسيط', field: 'installmentsType.name', isChip: false },
                { header: 'تاريخ التقسيط', field: 'collectionDate', isChip: false },
                { header: 'قيمة القسط', field: 'installmentAmount', isChip: false },
                { header: 'المدفوع', field: 'totalPaidAmount', isChip: false },
                { header: 'المتبقى من السداد', field: 'remainingAmount', isChip: false },
                { header: 'الحالة', field: 'status.name', isChip: true }
              ]}
              urlName={`ContractsInstallment/allByCustomer/${params.id}`}
              headerTitle='قائمة الاقساط'
              entityNameDefined='تفاصيل القسط'
              entityName='القسط'
              addBtnText='إضافة جهاز للعميل'
              isDevice={false}
              // path='/product/details'
              type='installment'
            />, //تقسيطات العميل

            <Tabs
              headers={['الكل']}
              contents={[
                <CustomerEntityTable
                  columns={[
                    { header: 'نوع الزيارة', field: 'type.name', isChip: false },
                    { header: 'الفنى', field: 'assignedRep.name', isChip: false },
                    { header: 'الاهمية', field: 'customerActivity.satisfactionLevel', isRate: true },
                    { header: 'تاريخ الزيارة', field: 'visitDate', isChip: false },
                    { header: 'الحالة', field: 'status.name', isChip: true }
                  ]}
                  urlName={`Visit/allByCustomer/${params.id}`}
                  headerTitle='قائمة المتابعات'
                  entityNameDefined='تفاصيل الزيارة'
                  entityName='الزيارة'
                  addBtnText='إضافة زيارة للعميل'
                  isDevice={false}
                  path='/customer/followUp/details'
                  type='followUp'
                  customerId={params.id}
                />
              ]}
            />, //سجل المتابعات

            <CustomerEntityTable
              columns={[
                { header: 'نوع الزياره', field: 'activity.name', isChip: false },
                { header: 'الاهمية', field: 'satisfactionLevel', isRate: true },
                { header: 'تاريخ الزيارة', field: 'activityDateTime', isChip: false }
              ]}
              urlName={`CustomersActivity/allByCustomer/${params.id}`}
              headerTitle='قائمة الاتصال'
              entityNameDefined='تفاصيل الاتصال'
              entityName='الاتصال'
              addBtnText='إضافة زيارة للعميل'
              isDevice={false}
              path='/customer/activity/details'
              type='activity'
              customerId={params.id}
            />, //الاتصال

            <CustomerEntityTable
              columns={[
                // { header: 'فئة المنتج', field: 'code', isChip: false },
                { header: 'كود الشكوى', field: 'code', isChip: false },
                { header: 'تصنيف الشكوى', field: 'complaintFamily', isChip: false },
                { header: 'الشكوى', field: 'complaintType', isChip: false },
                { header: 'تاريخ الشكوى', field: 'complaintDate', isChip: false }
              ]}
              urlName={`CustomersComplaint/allByCustomer/${params.id}`}
              headerTitle='قائمة الشكاوى'
              entityNameDefined='تفاصيل الشكوى'
              entityName='الشكوى'
              addBtnText='إضافة شكوى للعميل'
              isDevice={false}
              path='/customer/complaint/details'
              type='complaint'
              customerId={params.id}
            /> //شكاوى العميل
          ]}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    user: state.user.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersDetailsContent);
