// import React, { useCallback, useEffect, useState, useRef } from 'react';
// import { connect } from 'react-redux';
// import { axiosInstance } from '../../../../global';
// import { InputText } from 'primereact/inputtext';
// import { InputTextarea } from 'primereact/inputtextarea';
// import { useParams, useNavigate } from 'react-router-dom';
// import { Toast } from 'primereact/toast';
// import { Calendar } from 'primereact/calendar';
// import { Controller, useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
// import { fill_activity } from '../../../../redux/activity/activityActions';
// import CustomDropdown from '../../../../components/components/Dropdown/CustomDropDown';
// import { addActivity } from '../../../../redux/activity/activityActions';
// import Sidebar from '../../../../components/Sidebar/Sidebar';
// import { Button } from 'primereact/button';
// import { ProgressSpinner } from 'primereact/progressspinner';
// import '../AddContractDetails.scss';

// // Validation Schema
// const schema = yup.object().shape({
//   activityId: yup.string().required('يجب اختيار طريقة اتصال'),
//   reasonId: yup.string().required('يجب اختيار سبب اتصال'),
//   decisionId: yup.string().required('يجب اختيار الاجراء المتخذ'),
//   activityDateTime: yup.date().required('يجب ادخال تاريخ الاتصال'),
//   reference: yup.string().nullable(),
//   requestDetails: yup.string().nullable(),
//   responseDetails: yup.string().nullable(),
//   remarks: yup.string().nullable()
// });

// function AddActivity({ fill_activity, addActivity, activity, activityError, loadingActivity }) {
//   const {
//     control,
//     handleSubmit,
//     formState: { errors, isValid },
//     watch,
//     setValue,
//     trigger
//   } = useForm({
//     resolver: yupResolver(schema),
//     mode: 'onBlur'
//   });

// const Style = { color: 'red', padding: '0.7rem' };
//   const params = useParams();
//   const toast = useRef(null);
//   const navigate = useNavigate();
//   const [loading1, setLoading1] = useState(true);
//   const [checkdd1, setCheckdd1] = useState(true);
//   const [checkdd2, setCheckdd2] = useState(true);
//   const [checkdd3, setCheckdd3] = useState(true);
//   const [check, setCheck] = useState(true);
//   const [submitDone, setSubmitDone] = useState(false);
//   const [activityContinueParameter, setActivityContinueParameter] = useState(true);

//   const [data, setData] = useState({
//     CustomerData: null,
//     ProductData: null,
//     decisions: null,
//     ActivitiesTypes: null,
//     ActivitiesFamilies: null,
//     ActivityCategories: null,
//     Activities: null,
//     reasons: null
//   });
//   const [otherData, setOtherData] = useState({
//     ActivityCategory: '',
//     ActivityFamily: '',
//     ActivityType: ''
//   });
//   const [headerData, setHeaderData] = useState({
//     customerName: '',
//     CustomerPhone: '',
//     device: ''
//   });

//   const activityData = watch();

//   const fetchInitialData = useCallback(async () => {
//     try {
//       const [decisionRes, reasonRes, activityTypeRes] = await Promise.all([
//         axiosInstance.get('/Decision/all'),
//         axiosInstance.get('/Reason/all'),
//         axiosInstance.get('/ActivitiesType/dropdown')
//       ]);

//       setData((prevData) => ({
//         ...prevData,
//         decisions: decisionRes.data.data.map((decision) => ({ id: decision.id, name: decision.name })),
//         reasons: reasonRes.data.data.map((reason) => ({ id: reason.id, name: reason.name })),
//         ActivitiesTypes: activityTypeRes.data.data.map((type) => ({ id: type.id, name: type.name }))
//       }));
//     } catch (error) {
//       console.error('Error fetching initial data:', error);
//     }
//   }, []);

//   const fetchDataByType = useCallback(async () => {
//     if (otherData.ActivityType) {
//       try {
//         const familyRes = await axiosInstance.get(`/ActivitiesFamily/allByTypeDropdown/${otherData.ActivityType}`);
//         setData((prevData) => ({
//           ...prevData,
//           ActivitiesFamilies: familyRes.data.data.map((family) => ({ id: family.id, name: family.name }))
//         }));
//         setCheckdd1(false);
//       } catch (error) {
//         console.error('Error fetching activity families:', error);
//       }
//     }
//   }, [otherData.ActivityType]);

//   const fetchDataByFamily = useCallback(async () => {
//     if (otherData.ActivityFamily) {
//       try {
//         const categoryRes = await axiosInstance.get(
//           `/ActivitiesCategory/allByFamilyDropdown/${otherData.ActivityFamily}`
//         );
//         setData((prevData) => ({
//           ...prevData,
//           ActivityCategories: categoryRes.data.data.map((category) => ({ id: category.id, name: category.name }))
//         }));
//         setCheckdd2(false);
//       } catch (error) {
//         console.error('Error fetching activity categories:', error);
//       }
//     }
//   }, [otherData.ActivityFamily]);

//   const fetchDataByCategory = useCallback(async () => {
//     if (otherData.ActivityCategory) {
//       try {
//         const activityRes = await axiosInstance.get(`/Activity/allByCategoryDropdown/${otherData.ActivityCategory}`);
//         setData((prevData) => ({
//           ...prevData,
//           Activities: activityRes.data.data.map((activity) => ({ id: activity.id, name: activity.name }))
//         }));
//         setCheckdd3(false);
//       } catch (error) {
//         console.error('Error fetching activities:', error);
//       }
//     }
//   }, [otherData.ActivityCategory]);

//   const fetchCustomerAndProductData = useCallback(async () => {
//     try {
//       const [customerRes, productRes] = await Promise.all([
//         axiosInstance.get(`/Customer/${params.customerId}`),
//         axiosInstance.get(`/CustomersDevice/${params.deviceId}`)
//       ]);

//       setData((prevData) => ({
//         ...prevData,
//         CustomerData: customerRes.data.data,
//         ProductData: productRes.data.data
//       }));

//       setHeaderData({
//         customerName: customerRes.data.data.fullName,
//         CustomerPhone: customerRes.data.data.phoneNb,
//         device: productRes.data.data.device.name
//       });

//       setValue('customerDeviceId', productRes.data.data.id);
//       setValue('customerId', customerRes.data.data.id);
//       setLoading1(false);
//     } catch (error) {
//       console.error('Error fetching customer and product data:', error);
//     }
//   }, [params.customerId, params.deviceId, setValue]);

//   useEffect(() => {
//     const subscription = watch((value, { name, type }) => {
//       fill_activity(value);
//       trigger(name);
//     });
//     return () => subscription.unsubscribe();
//   }, [watch, trigger]);

//   useEffect(() => {
//     fetchInitialData();
//     fetchCustomerAndProductData();
//   }, [fetchInitialData, fetchCustomerAndProductData]);

//   useEffect(() => {
//     fetchDataByType();
//   }, [fetchDataByType]);

//   useEffect(() => {
//     fetchDataByFamily();
//   }, [fetchDataByFamily]);

//   useEffect(() => {
//     fetchDataByCategory();
//   }, [fetchDataByCategory]);

//   useEffect(() => {
//     if (activity) {
//       fill_activity(activity);
//     }
//     if (isValid) {
//       setCheck(false);
//     }
//   }, [isValid, activity, setCheck]);

//   useEffect(() => {
//     if (submitDone) {
//       if (!loadingActivity && activityError.length) {
//         toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية إضافة الاتصال' });
//       } else if (!loadingActivity && !activityError.length && !activityContinueParameter) {
//         toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة الاتصال بنجاح' });
//         setTimeout(() => navigate('/Contract'), 1000);
//       } else if (!loadingActivity && !activityError.length && activityContinueParameter) {
//         toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة الاتصال بنجاح' });
//         setTimeout(() => navigate(`/Contract/Edit/${params.customerId}/${params.deviceId}`), 1000);
//       }
//     }
//   }, [loadingActivity, activityError]);

//   function handleOtherInputChange(e) {
//     setOtherData({ ...otherData, [e.target.name]: e.target.value || e.value });
//   }

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     if (['ActivityType', 'ActivityFamily', 'ActivityCategory'].includes(name)) {
//       setOtherData((prev) => ({ ...prev, [name]: value }));
//     } else {
//       setValue(name, value);
//     }
//   };

//   function handleAddActivity(activity) {
//     try {
//       addActivity(activity);
//       setSubmitDone(true);
//       setActivityContinueParameter(false);
//     } catch (errorAddactivity) {
//       console.log(errorAddactivity);
//     }
//   }

//   return (
//     <div className='visit'>
//       <div className='flex flex-row'>
//         <Sidebar />
//         <Toast ref={toast} />
//         <form className='w-full mr-4'>
// <div className='details__header m-2 mb-4'>
//   <Button
//     className='font-bold text-black-alpha-90'
//     icon='pi pi-arrow-right'
//     rounded
//     text
//     onClick={() => navigate(-1)}
//   />
//   <span className='details__header1__right--text'>إضافة اتصال</span>
// </div>
// <span className='mb-4 Title'>بيانات العميل</span>
// {loading1 ? (
//   <div className='flex justify-content-center'>
//     <ProgressSpinner />
//   </div>
// ) : (
//   <>
//     <div className='flex w-full flex-column gap-2 mt-4'>
//       <label className='addvisit__label'>
//         الاسم كامل<span style={Style}>*</span>
//       </label>
//       <InputText
//         name='customerName'
//         value={headerData.customerName}
//         onChange={handleInputChange}
//         disabled={true}
//         className='surface-200 opacity-100'
//       />
//     </div>

//     <div className='flex w-full flex-column gap-2 mt-4'>
//       <label className='addvisit__label'>
//         رقم الهاتف<span style={Style}>*</span>
//       </label>
//       <InputText
//         name='CustomerPhone'
//         value={headerData.CustomerPhone}
//         onChange={handleInputChange}
//         disabled={true}
//         className='surface-200 opacity-100'
//       />
//     </div>

//     <div className='flex w-full flex-column gap-2 mt-4'>
//       <label className='addvisit__label'>
//         الجهاز المشكو عنه<span style={Style}>*</span>
//       </label>
//       <InputText
//         name='device'
//         value={headerData.device}
//         onChange={handleInputChange}
//         disabled={true}
//         className='surface-200 opacity-100'
//       />
//     </div>
//   </>
// )}
// <span className='mt-6 mb-4 Title'>بيانات الاتصال (مطلوبة)</span>
// <div className='visit__content'>
//   {/* page right content============================================== */}
//   <div className='visit__content__right'>
//     <div className='flex w-full flex-column gap-4'>
//       <label className='addvisit__label'>
//         تاريخ الاتصال<span style={Style}>*</span>
//       </label>
//       <Controller
//         name='activityDateTime'
//         control={control}
//         render={({ field }) => <Calendar {...field} dateFormat='mm/dd/yy' showIcon />}
//       />
//       <span className='text-red-500'>{errors.activityDateTime?.message}</span>
//     </div>

//     <div className='flex w-full flex-column gap-4'>
//       <label className='addvisit__label'>
//         اختر نوع الاتصال<span style={Style}>*</span>
//       </label>
//       <CustomDropdown
//         name='ActivityType'
//         value={otherData.ActivityType}
//         onChange={handleOtherInputChange}
//         options={data.ActivitiesTypes}
//         optionValue='id'
//         optionLabel='name'
//         placeholder='اختر نوع الاتصال'
//       />
//     </div>

//     <div className='flex w-full flex-column gap-4'>
//       <label className='addvisit__label'>
//         اختر فئة الاتصال<span style={Style}>*</span>
//       </label>
//       <CustomDropdown
//         name='ActivityFamily'
//         value={otherData.ActivityFamily}
//         onChange={handleOtherInputChange}
//         options={data.ActivitiesFamilies}
//         optionValue='id'
//         optionLabel='name'
//         placeholder='اختر فئة الاتصال'
//         disabled={checkdd1}
//       />
//     </div>

//     <div className='flex w-full flex-column gap-4'>
//       <label className='addvisit__label'>
//         اختر تصنيف الاتصال<span style={Style}>*</span>
//       </label>
//       <CustomDropdown
//         name='ActivityCategory'
//         value={otherData.ActivityCategory}
//         onChange={handleOtherInputChange}
//         options={data.ActivityCategories}
//         optionValue='id'
//         optionLabel='name'
//         placeholder='اختر تصنيف الاتصال'
//         disabled={checkdd2}
//       />
//     </div>

//     <div className='flex w-full flex-column gap-4'>
//       <label className='addvisit__label'>
//         اختر طريقة الاتصال<span style={Style}>*</span>
//       </label>
//       <Controller
//         name='activityId'
//         control={control}
//         render={({ field }) => (
//           <CustomDropdown
//             {...field}
//             options={data.Activities}
//             optionValue='id'
//             optionLabel='name'
//             placeholder='اختر طريقة الاتصال'
//             disabled={checkdd3}
//           />
//         )}
//       />
//       <span className='text-red-500'>{errors.activityId?.message}</span>
//     </div>

//     <div className='flex w-full flex-column gap-4'>
//       <label className='addvisit__label'>
//         اختر سبب الاتصال<span style={Style}>*</span>
//       </label>
//       <Controller
//         name='reasonId'
//         control={control}
//         render={({ field }) => (
//           <CustomDropdown
//             {...field}
//             options={data.reasons}
//             optionValue='id'
//             optionLabel='name'
//             placeholder='اختر سبب الاتصال'
//           />
//         )}
//       />
//       <span className='text-red-500'>{errors.reasonId?.message}</span>
//     </div>

//     <div className='flex w-full flex-column gap-4'>
//       <label className='addvisit__label'>
//         اختر الاجراء المتخذ<span style={Style}>*</span>
//       </label>
//       <Controller
//         name='decisionId'
//         control={control}
//         render={({ field }) => (
//           <CustomDropdown
//             {...field}
//             options={data.decisions}
//             optionValue='id'
//             optionLabel='name'
//             placeholder='اختر الاجراء الاتصال'
//           />
//         )}
//       />
//       <span className='text-red-500'>{errors.decisionId?.message}</span>
//     </div>
//   </div>

//   {/* page left content============================================== */}

//   <div className='visit__content__left'>
//     <div className='flex w-full flex-column gap-4'>
//       <label className='addvisit__label'>تفاصيل الطلب</label>
//       <Controller
//         name='requestDetails'
//         control={control}
//         render={({ field }) => <InputTextarea {...field} rows={5} value={field.value || ''} />}
//       />
//     </div>

//     <div className='flex w-full flex-column gap-4'>
//       <label className='addvisit__label'>تفاصيل الرد على العميل</label>
//       <Controller
//         name='responseDetails'
//         control={control}
//         render={({ field }) => <InputTextarea {...field} rows={5} value={field.value || ''} />}
//       />
//     </div>

//     <div className='flex w-full flex-column gap-4'>
//       <label className='addvisit__label'>ملاحظات اخرى</label>
//       <Controller
//         name='remarks'
//         control={control}
//         render={({ field }) => <InputTextarea {...field} rows={5} value={field.value || ''} />}
//       />
//     </div>
//   </div>
// </div>
// <div className='flex gap-2 btn'>
// <Button className='btn__linkbtn' onClick={handleAddActivity} disabled={check}>
//   حفظ الاتصال و انهاء العملية
// </Button>
// {/* <Button className='btn__nextbtn' onClick={() => handleAddActivity(true)} disabled={check}>
//   حفظ الاتصال و البدأ في تحضير العقد
// </Button> */}
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// }

// const mapStateToProps = (state) => {
//   return {
//     activity: state.activity.activity,
//     loadingActivity: state.activity.loading,
//     activityError: state.activity.error
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     addActivity: (activity) => dispatch(addActivity(activity)),
//     fill_activity: (activity) => dispatch(fill_activity(activity))
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(AddActivity);

import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../../global';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomDropdown from '../../../../components/components/Dropdown/CustomDropDown';
import { addActivity, fill_activity } from '../../../../redux/activity/activityActions';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../../../Visits/AddVisit/AddRealVisit/AddVisitDetails.scss';

// Validation Schema
const schema = yup.object().shape({
  activityId: yup.string().required('يجب اختيار طريقة اتصال'),
  reasonId: yup.string().required('يجب اختيار سبب اتصال'),
  decisionId: yup.string().required('يجب اختيار الاجراء المتخذ'),
  activityDateTime: yup.date().required('يجب ادخال تاريخ الاتصال'),
  reference: yup.string().nullable(),
  requestDetails: yup.string().nullable(),
  responseDetails: yup.string().nullable(),
  remarks: yup.string().nullable()
});

function AddActivity({ fill_activity, addActivity, activity, activityError, loadingActivity }) {
  const navigate = useNavigate();
  const params = useParams();
  const toast = useRef(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
    trigger
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });
  const Style = { color: 'red', padding: '0.7rem' };
  const [loading1, setLoading1] = useState(true);
  const [checkdd1, setCheckdd1] = useState(true);
  const [checkdd2, setCheckdd2] = useState(true);
  const [checkdd3, setCheckdd3] = useState(true);
  const [check, setCheck] = useState(true);
  const [submitDone, setSubmitDone] = useState(false);
  const [activityContinueParameter, setActivityContinueParameter] = useState(true);

  const [data, setData] = useState({
    CustomerData: null,
    ProductData: null,
    decisions: null,
    ActivitiesTypes: null,
    ActivitiesFamilies: null,
    ActivityCategories: null,
    Activities: null,
    reasons: null
  });

  const [otherData, setOtherData] = useState({
    ActivityCategory: '',
    ActivityFamily: '',
    ActivityType: ''
  });

  const [headerData, setHeaderData] = useState({
    customerName: '',
    CustomerPhone: '',
    device: ''
  });

  const activityData = watch();

  const fetchInitialData = useCallback(async () => {
    try {
      const [decisionRes, reasonRes, activityTypeRes] = await Promise.all([
        axiosInstance.get('/Decision/all'),
        axiosInstance.get('/Reason/all'),
        axiosInstance.get('/ActivitiesType/dropdown')
      ]);

      setData((prevData) => ({
        ...prevData,
        decisions: decisionRes.data.data.map((decision) => ({ id: decision.id, name: decision.name })),
        reasons: reasonRes.data.data.map((reason) => ({ id: reason.id, name: reason.name })),
        ActivitiesTypes: activityTypeRes.data.data.map((type) => ({ id: type.id, name: type.name }))
      }));
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  }, []);

  const fetchDataByType = useCallback(async () => {
    if (otherData.ActivityType) {
      try {
        const familyRes = await axiosInstance.get(`/ActivitiesFamily/allByTypeDropdown/${otherData.ActivityType}`);
        setData((prevData) => ({
          ...prevData,
          ActivitiesFamilies: familyRes.data.data.map((family) => ({ id: family.id, name: family.name }))
        }));
        setCheckdd1(false);
      } catch (error) {
        console.error('Error fetching activity families:', error);
      }
    }
  }, [otherData.ActivityType]);

  const fetchDataByFamily = useCallback(async () => {
    if (otherData.ActivityFamily) {
      try {
        const categoryRes = await axiosInstance.get(
          `/ActivitiesCategory/allByFamilyDropdown/${otherData.ActivityFamily}`
        );
        setData((prevData) => ({
          ...prevData,
          ActivityCategories: categoryRes.data.data.map((category) => ({ id: category.id, name: category.name }))
        }));
        setCheckdd2(false);
      } catch (error) {
        console.error('Error fetching activity categories:', error);
      }
    }
  }, [otherData.ActivityFamily]);

  const fetchDataByCategory = useCallback(async () => {
    if (otherData.ActivityCategory) {
      try {
        const activityRes = await axiosInstance.get(`/Activity/allByCategoryDropdown/${otherData.ActivityCategory}`);
        setData((prevData) => ({
          ...prevData,
          Activities: activityRes.data.data.map((activity) => ({ id: activity.id, name: activity.name }))
        }));
        setCheckdd3(false);
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
    }
  }, [otherData.ActivityCategory]);

  const fetchCustomerAndProductData = useCallback(async () => {
    try {
      const [customerRes, productRes] = await Promise.all([
        axiosInstance.get(`/Customer/${params.customerId}`),
        axiosInstance.get(`/CustomersDevice/${params.deviceId}`)
      ]);

      setData((prevData) => ({
        ...prevData,
        CustomerData: customerRes.data.data,
        ProductData: productRes.data.data
      }));

      setHeaderData({
        customerName: customerRes.data.data.fullName,
        CustomerPhone: customerRes.data.data.phoneNb,
        device: productRes.data.data.device.name
      });

      setValue('customerDeviceId', productRes.data.data.id);
      setValue('customerId', customerRes.data.data.id);
      setLoading1(false);
    } catch (error) {
      console.error('Error fetching customer and product data:', error);
    }
  }, [params.customerId, params.deviceId, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      fill_activity(value);
      trigger(name);
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  useEffect(() => {
    fetchInitialData();
    fetchCustomerAndProductData();
  }, [fetchInitialData, fetchCustomerAndProductData]);

  useEffect(() => {
    fetchDataByType();
  }, [fetchDataByType]);

  useEffect(() => {
    fetchDataByFamily();
  }, [fetchDataByFamily]);

  useEffect(() => {
    fetchDataByCategory();
  }, [fetchDataByCategory]);

  useEffect(() => {
    // if (activityData) {
    //   fill_activity(activityData);
    // }
    if (isValid) {
      setCheck(false);
    }
  }, [isValid]);

  useEffect(() => {
    if (submitDone) {
      if (!loadingActivity && activityError.length) {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية إضافة الاتصال' });
      } else if (!loadingActivity && !activityError.length && !activityContinueParameter) {
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة الاتصال بنجاح' });
        setTimeout(() => navigate('/Contract'), 1000);
      } else if (!loadingActivity && !activityError.length && activityContinueParameter) {
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة الاتصال بنجاح' });
        setTimeout(() => navigate(`/Contract/Edit/${params.contractId}/${params.customerId}/${params.deviceId}`), 1000);
      }
    }
  }, [
    submitDone,
    loadingActivity,
    activityError,
    activityContinueParameter,
    navigate,
    params.customerId,
    params.deviceId
  ]);

  function handleOtherInputChange(e) {
    const { name, value } = e.target;
    setOtherData((prev) => ({ ...prev, [name]: value || e.value }));
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (['ActivityType', 'ActivityFamily', 'ActivityCategory'].includes(name)) {
      setOtherData((prev) => ({ ...prev, [name]: value }));
    } else {
      setValue(name, value);
    }
  };

  const handleAddActivity = async (activity) => {
    try {
      await addActivity(activity);
      setSubmitDone(true);
      setActivityContinueParameter(true); // Adjust according to your logic
    } catch (error) {
      console.error('Error adding activity:', error);
    }
  };
  const handleAddActivityandClose = async (activity) => {
    try {
      await addActivity(activity);
      setSubmitDone(true);
      setActivityContinueParameter(false); // Adjust according to your logic
    } catch (error) {
      console.error('Error adding activity:', error);
    }
  };

  return (
    <div className='visit' style={{ padding: '0px', margin: '0px' }}>
      <div className='flex flex-row pl-4'>
        <Sidebar />
        <Toast ref={toast} />
        <form className='w-full mr-4 mb-4'>
          <div className='details__header m-2 mb-4'>
            <Button
              className='font-bold text-black-alpha-90'
              icon='pi pi-arrow-right'
              rounded
              text
              onClick={() => navigate('/Contract/AddConnection')}
            />
            <span className='details__header1__right--text'>إضافة اتصال</span>
          </div>
          <span className='mb-4 Title'>بيانات العميل</span>
          {loading1 ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : (
            <>
              <div className='flex w-full flex-column gap-2 mt-4'>
                <label className='addvisit__label'>
                  الاسم كامل<span style={Style}>*</span>
                </label>
                <InputText
                  name='customerName'
                  value={headerData.customerName}
                  onChange={handleInputChange}
                  disabled={true}
                  className='surface-200 opacity-100'
                />
              </div>

              <div className='flex w-full flex-column gap-2 mt-4'>
                <label className='addvisit__label'>
                  رقم الهاتف<span style={Style}>*</span>
                </label>
                <InputText
                  name='CustomerPhone'
                  value={headerData.CustomerPhone}
                  onChange={handleInputChange}
                  disabled={true}
                  className='surface-200 opacity-100'
                />
              </div>

              <div className='flex w-full flex-column gap-2 mt-4'>
                <label className='addvisit__label'>
                  الجهاز المشكو عنه<span style={Style}>*</span>
                </label>
                <InputText
                  name='device'
                  value={headerData.device}
                  onChange={handleInputChange}
                  disabled={true}
                  className='surface-200 opacity-100'
                />
              </div>
            </>
          )}
          <span className='mt-6 mb-4 Title'>بيانات الاتصال (مطلوبة)</span>
          <div className='visit__content'>
            {/* page right content============================================== */}
            <div className='visit__content__right'>
              <div className='flex w-full flex-column gap-4'>
                <label className='addvisit__label'>
                  تاريخ الاتصال<span style={Style}>*</span>
                </label>
                <Controller
                  name='activityDateTime'
                  control={control}
                  render={({ field }) => <Calendar {...field} dateFormat='mm/dd/yy' showIcon />}
                />
                <span className='text-red-500'>{errors.activityDateTime?.message}</span>
              </div>

              <div className='flex w-full flex-column gap-4'>
                <label className='addvisit__label'>
                  اختر نوع الاتصال<span style={Style}>*</span>
                </label>
                <CustomDropdown
                  name='ActivityType'
                  value={otherData.ActivityType}
                  onChange={handleOtherInputChange}
                  options={data.ActivitiesTypes}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر نوع الاتصال'
                />
              </div>

              <div className='flex w-full flex-column gap-4'>
                <label className='addvisit__label'>
                  اختر فئة الاتصال<span style={Style}>*</span>
                </label>
                <CustomDropdown
                  name='ActivityFamily'
                  value={otherData.ActivityFamily}
                  onChange={handleOtherInputChange}
                  options={data.ActivitiesFamilies}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر فئة الاتصال'
                  disabled={checkdd1}
                />
              </div>

              <div className='flex w-full flex-column gap-4'>
                <label className='addvisit__label'>
                  اختر تصنيف الاتصال<span style={Style}>*</span>
                </label>
                <CustomDropdown
                  name='ActivityCategory'
                  value={otherData.ActivityCategory}
                  onChange={handleOtherInputChange}
                  options={data.ActivityCategories}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='اختر تصنيف الاتصال'
                  disabled={checkdd2}
                />
              </div>

              <div className='flex w-full flex-column gap-4'>
                <label className='addvisit__label'>
                  اختر طريقة الاتصال<span style={Style}>*</span>
                </label>
                <Controller
                  name='activityId'
                  control={control}
                  render={({ field }) => (
                    <CustomDropdown
                      {...field}
                      options={data.Activities}
                      optionValue='id'
                      optionLabel='name'
                      placeholder='اختر طريقة الاتصال'
                      disabled={checkdd3}
                    />
                  )}
                />
                <span className='text-red-500'>{errors.activityId?.message}</span>
              </div>

              <div className='flex w-full flex-column gap-4'>
                <label className='addvisit__label'>
                  اختر سبب الاتصال<span style={Style}>*</span>
                </label>
                <Controller
                  name='reasonId'
                  control={control}
                  render={({ field }) => (
                    <CustomDropdown
                      {...field}
                      options={data.reasons}
                      optionValue='id'
                      optionLabel='name'
                      placeholder='اختر سبب الاتصال'
                    />
                  )}
                />
                <span className='text-red-500'>{errors.reasonId?.message}</span>
              </div>

              <div className='flex w-full flex-column gap-4'>
                <label className='addvisit__label'>
                  اختر الاجراء المتخذ<span style={Style}>*</span>
                </label>
                <Controller
                  name='decisionId'
                  control={control}
                  render={({ field }) => (
                    <CustomDropdown
                      {...field}
                      options={data.decisions}
                      optionValue='id'
                      optionLabel='name'
                      placeholder='اختر الاجراء الاتصال'
                    />
                  )}
                />
                <span className='text-red-500'>{errors.decisionId?.message}</span>
              </div>
            </div>

            {/* page left content============================================== */}

            <div className='visit__content__left'>
              <div className='flex w-full flex-column gap-4'>
                <label className='addvisit__label'>تفاصيل الطلب</label>
                <Controller
                  name='requestDetails'
                  control={control}
                  render={({ field }) => <InputTextarea {...field} rows={5} value={field.value || ''} />}
                />
              </div>

              <div className='flex w-full flex-column gap-4'>
                <label className='addvisit__label'>تفاصيل الرد على العميل</label>
                <Controller
                  name='responseDetails'
                  control={control}
                  render={({ field }) => <InputTextarea {...field} rows={5} value={field.value || ''} />}
                />
              </div>

              <div className='flex w-full flex-column gap-4'>
                <label className='addvisit__label'>ملاحظات اخرى</label>
                <Controller
                  name='remarks'
                  control={control}
                  render={({ field }) => <InputTextarea {...field} rows={5} value={field.value || ''} />}
                />
              </div>
            </div>
          </div>
          <div className='flex gap-2 btn'>
            <Button onClick={() => handleAddActivityandClose(activity)} className='btn__linkbtn' disabled={check}>
              حفظ الاتصال و انهاء العملية
            </Button>
            <Button onClick={() => handleAddActivity(activity)} className='btn__nextbtn' disabled={check}>
              حفظ الاتصال و البدأ في تحضير العقد
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    activity: state.activity.activity,
    loadingActivity: state.activity.loading,
    activityError: state.activity.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addActivity: (activityData) => dispatch(addActivity(activityData)),
    fill_activity: (activityData) => dispatch(fill_activity(activityData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddActivity);
