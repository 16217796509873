import axios from 'axios';
import { logout } from './redux/user/userActions';
import store from './redux/store';

const API_BASE_URL = (true) ? 'http://62.72.21.7:9090/api/v1' : 'http://localhost:9090/api/v1';

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 50000, // 10 seconds timeout
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let retryQueue = [];

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error); // Ignore cancellation errors
    }

    const originalRequest = error.config;

    if (error.response) {
      // If the error is due to unauthorized access, and it's not a token refresh request
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;

          try {
            const response = await axios.post(`${API_BASE_URL}/auth/refresh-token`, {}, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('refresh_token')}`
              }
            });
            const { access_token } = response.data.data;
            localStorage.setItem('token', access_token);
            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            retryQueue.forEach((retryFunction) => retryFunction(access_token));
            retryQueue = [];
            return axios(originalRequest);
          } catch (refreshError) {
            isRefreshing = false;
            store.dispatch(logout());
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        } else {
          // If refresh is already in progress, wait and retry
          return new Promise((resolve) => {
            // Queue the original request for retry
            const retryOriginalRequest = (newToken) => {
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              resolve(axios(originalRequest));
            };

            // Add the retry function to the queue
            retryQueue.push(retryOriginalRequest);
          });
        }
      }
    } else {
      // Handle cases where error.response is undefined
      console.error('Error response is undefined', error);
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
