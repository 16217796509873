import { axiosInstance } from '../../global';

import {
  ADD_EMPLOYEE_FAILURE,
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_SUCCESS,
  CHANGE_EMPLOYEE_BACKUP_STATE,
  CHANGE_EMPLOYEE_STATE,
  EDIT_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_REQUEST,
  EDIT_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEE_REQUEST,
  FETCH_EMPLOYEE_SUCCESS,
  CLEAR_EMPLOYEE_STATE
} from './employeeTypes';


export const fetchEmployee = (id) => {
  return (dispatch) => {
    dispatch(fetch_employee_request());
    axiosInstance
      .get(`/Employee/${id}`)
      .then((responseEmployeeData) => {
        const tempEmployee = responseEmployeeData.data.data;
        const formattedEmployee = {
          id: tempEmployee.id,
          email: tempEmployee.user.email,
          password: tempEmployee.user.password,
          code: tempEmployee.code,
          name: tempEmployee.name,
          nationalNb: tempEmployee.nationalNb,
          phoneNumber: tempEmployee.user.phoneNumber,
          genderId: tempEmployee.gender.id,
          remarks: tempEmployee.remarks,
          hiringDate: new Date(tempEmployee.hiringDate),
          departmentId: tempEmployee.department.id,
          positionId: tempEmployee.position.id,
          directManagerId: tempEmployee.directManager?.id,
          attendanceTypeId: tempEmployee.attendanceType.id,
          teamIds: tempEmployee.teams,
          statusId: tempEmployee.status.id,
          locationId: tempEmployee.location.id,
          governorateId : tempEmployee.governorate.id,
          areaId : tempEmployee.area.id,
          address: tempEmployee.address,
          payrollList: [], // Assuming you need to fetch this separately
          vacations: tempEmployee.vacations.map(vacation => ({
            vacationFromDate: new Date(vacation.vacationFromDate),
            vacationToDate: new Date(vacation.vacationToDate),
            vacationTypeId: vacation.vacationTypeId,
            vacationCount: vacation.vacationCount,
          }))
        };
        axiosInstance
          .get(`/EmployeesPayrollItem/all/${id}`)
          .then((responseEmployeePayroll) => {
            formattedEmployee.payrollList = responseEmployeePayroll.data.data.map((item) => ({
              id: item.id,
              payrollItemId: item.payrollItem.id,
              name: item.payrollItem.name,
              amount: item.amount,
              effectiveDate: new Date(item.effectiveDate),
              endDate: new Date(item.endDate),
              statusId: item.status.id
            }));
            dispatch(fetch_employee_success(formattedEmployee));
          })
          .catch((errorEmployeePayroll) => {
            dispatch(fetch_employee_failure(errorEmployeePayroll.message));
          });
      })
      .catch((errorEmployeeData) => {
        dispatch(fetch_employee_failure(errorEmployeeData.message));
      });
  };
};

const fetch_employee_request = () => {
  return {
    type: FETCH_EMPLOYEE_REQUEST
  };
};

const fetch_employee_success = (employee) => {
  return {
    type: FETCH_EMPLOYEE_SUCCESS,
    payload: employee
  };
};

const fetch_employee_failure = (error) => {
  return {
    type: FETCH_EMPLOYEE_FAILURE,
    payload: error
  };
};

export const addEmployee = (employee, token) => {
  debugger;
  return (dispatch) => {
    dispatch(add_employee_request());
    axiosInstance
      .post(`/Employee`, employee, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(add_employee_success(response.data.data));
      })
      .catch((error) => {
        dispatch(add_employee_failure(error.response.data.message));
      });
  };
};

export const clearEmployeeState = () => {
  return {
    type: CLEAR_EMPLOYEE_STATE
  };
};

const add_employee_request = () => {
  return {
    type: ADD_EMPLOYEE_REQUEST
  };
};

const add_employee_success = (employee) => {
  return {
    type: ADD_EMPLOYEE_SUCCESS,
    payload: employee
  };
};

const add_employee_failure = (error) => {
  return {
    type: ADD_EMPLOYEE_FAILURE,
    payload: error
  };
};

export const editEmployee = (employee, token) => {
  const payload = {
    id: employee.id,
    email: employee.email,
    password: employee.password,
    code: employee.code,
    name: employee.name,
    nationalNb: employee.nationalNb,
    phoneNumber: employee.phoneNumber,
    genderId: employee.genderId,
    remarks: employee.remarks,
    hiringDate: employee.hiringDate,
    departmentId: employee.departmentId,
    positionId: employee.positionId,
    directManagerId: employee.directManagerId,
    attendanceTypeId: employee.attendanceTypeId,
    teamIds: employee.teamIds,
    statusId: employee.statusId,
    locationId: employee.locationId,
    address: employee.address,
    payrollList: employee.payrollList.map((item) => ({
      id: item.id,
      payrollItemId: item.payrollItemId,
      amount: item.amount,
      effectiveDate: item.effectiveDate,
      endDate: item.endDate,
      statusId: item.statusId,
    })),
    vacations: employee.vacations.map((vac) => ({
      vacationFromDate: vac.vacationFromDate,
      vacationToDate: vac.vacationToDate,
      vacationTypeId: vac.vacationTypeId,
      vacationCount: vac.vacationCount,
    }))
  };

  return (dispatch) => {
    dispatch(edit_employee_request());
    axiosInstance
      .put(`/Employee`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(edit_employee_success(response.data.data));
      })
      .catch((error) => {
        dispatch(edit_employee_failure(error.response.data.message));
      });
  };
};
const edit_employee_request = () => {
  return {
    type: EDIT_EMPLOYEE_REQUEST
  };
};

const edit_employee_success = (employee) => {
  return {
    type: EDIT_EMPLOYEE_SUCCESS,
    payload: employee
  };
};
const edit_employee_failure = (error) => {
  return {
    type: EDIT_EMPLOYEE_FAILURE,
    payload: error
  };
};

export const fill_employee = (employee) => {
  return {
    type: CHANGE_EMPLOYEE_STATE,
    payload: employee
  };
};

export const fill_employee_backup = (employeeBackup) => {
  return {
    type: CHANGE_EMPLOYEE_BACKUP_STATE,
    payload: employeeBackup
  };
};
