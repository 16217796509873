import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';

import { fill_customer } from '../../../redux/customer/customerActions';
import { axiosInstance } from '../../../global';
import './EditCustomersDetails.scss';
import CustomDropdown from '../../../components/components/Dropdown/CustomDropDown';

function EditCustomers2({ token, fill_customer, setCheck, customerBase }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const [governrate, setGovernrate] = useState(null);
  const [area, setArea] = useState(null);
  const [location, setLocation] = useState(null);
  const [areaID, setAreaID] = useState(null);
  const [governratID, setGovernratID] = useState(null);
  const [governrateDd, setGovernrateDd] = useState(null);
  const [areaDd, setAreayDd] = useState(null);
  const [GeoData, setGeoData] = useState(null);
  const [customerLocation, setCustomerLocation] = useState({
    name: '',
    address: '',
    landmark: '',
    locationId: '',
    remarks: ''
  });
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setCustomerLocation({
      name: customerBase.defaultAddress.name,
      locationId: customerBase.defaultAddress.location.id,
      address: customerBase.defaultAddress.address,
      landmark: customerBase.defaultAddress.landmark,
      remarks: customerBase.defaultAddress.remarks
    });
    axiosInstance
      .get(`/Governorate/all`)
      .then((response) => {
        const tempStatus = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setGovernrate(tempStatus);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/Location/${customerLocation.locationId}`);
        setGeoData(response.data.data);
      } catch (error) {
        console.error('Error fetching location data:', error);
      }
    };

    fetchData();
  }, [customerLocation]);

  useEffect(() => {
    if (GeoData != null) {
      setGovernrateDd(GeoData.area.governorate.id);
      setGovernratID(GeoData.area.governorate.id);
      setAreayDd(GeoData.area.id);
      setAreaID(GeoData.area.id);
    }
  }, [GeoData]);

  useEffect(() => {
    axiosInstance
      .get(`/Area/all`)
      .then((response) => {
        const tempArea = response.data.data.map((area) => {
          return {
            id: area.id,
            name: area.name,
            governratsID: area.governorate.id
          };
        });
        console.log();
        const filterdTempArea = tempArea.filter((item) => item.governratsID === governratID);
        setArea(filterdTempArea);
      })
      .catch((error) => {});
  }, [governrate, governratID]);

  useEffect(() => {
    fill_customer({ defaultAddress: customerLocation });
    if (!customerLocation.address || !customerLocation.locationId) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [customerLocation]);

  useEffect(() => {
    axiosInstance
      .get(`/Location/all`)
      .then((response) => {
        const tempLocation = response.data.data.map((location) => {
          return {
            id: location.id,
            name: location.name,
            areaId: location.area.id
          };
        });
        const filterdTempLocation = tempLocation.filter((item) => item.areaId === areaID);
        setLocation(filterdTempLocation);
        setFirstLoad(false);
      })
      .catch((error) => {});
  }, [area, areaID]);

  function handleDdInputChange(e) {
    setGovernrateDd(e.target.value);
    setGovernratID(e.target.value);
  }
  function handleDd1InputChange(e) {
    setAreayDd(e.target.value);
    setAreaID(e.target.value);
  }
  function handleAddInputChange(e) {
    setCustomerLocation({ ...customerLocation, [e.target.name]: e.target.value || e.value });
  }

  return (
    <div className='customer'>
      <span className='Title'>بيانات العنوان </span>

      <div className='customer__adrress'>
        <img className='customer__adrress__img' src='../../images/Bg.png' alt='' />
      </div>

      <div className='flex w-full flex-row gap-4'>
        <div className='flex w-full flex-column gap-4'>
          <label className='addcustomer__label'>
            اسم العنوان <span style={Style}>*</span>
          </label>
          <InputText name='name' value={customerLocation.name} onChange={handleAddInputChange} />
        </div>

        <div className='flex w-full flex-column gap-4'>
          <label className='addcustomer__label'>
            المحافظة<span style={Style}>*</span>
          </label>
          <CustomDropdown
            name='governerate'
            value={governrateDd}
            onChange={handleDdInputChange}
            options={governrate}
            optionValue='id'
            optionLabel='name'
            placeholder='اختر المحافظة'
          />
        </div>

        <div className='flex w-full flex-column gap-4'>
          <label className='addcustomer__label'>
            المدينة<span style={Style}>*</span>
          </label>
          <CustomDropdown
            name='area'
            value={areaDd}
            onChange={handleDd1InputChange}
            options={area}
            optionValue='id'
            optionLabel='name'
            placeholder='اختر المدينة'
            disabled={!governrateDd}
          />
        </div>

        <div className='flex w-full flex-column gap-4'>
          <label className='addcustomer__label'>
            المنطقة<span style={Style}>*</span>
          </label>
          <CustomDropdown
            name='locationId'
            value={customerLocation.locationId}
            onChange={handleAddInputChange}
            options={location}
            optionValue='id'
            optionLabel='name'
            placeholder='اختر المنطقة'
            disabled={!areaDd}
          />
        </div>
      </div>

      <div className='flex w-full flex-column gap-4 mt-4'>
        <label className='addcustomer__label'>
          علامة مميزة <span style={Style}>*</span>
        </label>
        <InputText name='landmark' value={customerLocation.landmark} onChange={handleAddInputChange} />
      </div>

      <div className='flex w-full flex-column gap-4 mt-4'>
        <label className='addcustomer__label'>
          العنوان<span style={Style}>*</span>
        </label>
        <InputTextarea name='address' value={customerLocation.address} onChange={handleAddInputChange} rows={5} />
      </div>

      <div className='flex w-full flex-column gap-4 mt-4'>
        <label className='addcustomer__label'>
          ملاحظات اخرى<span style={Style}>*</span>
        </label>
        <InputTextarea name='remarks' value={customerLocation.remarks} onChange={handleAddInputChange} rows={5} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_customer: (customerLocation) => dispatch(fill_customer(customerLocation))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomers2);
