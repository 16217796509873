export const FETCH_USER = 'FETCH_USER';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const USER_RESETPASSWORD_REQUEST = 'USER_RESETPASSWORD_REQUEST';
export const USER_RESETPASSWORD_SUCCESS = 'USER_RESETPASSWORD_SUCCESS';
export const USER_RESETPASSWORD_FAILURE = 'USER_RESETPASSWORD_FAILURE';

export const USER_LOGOUT = 'USER_LOGOUT';
