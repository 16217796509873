import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Chips } from 'primereact/chips';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './Modals.scss';
import { InputTextarea } from 'primereact/inputtextarea';

function AddWorkingGroups({ userId, visible, setVisible, statuses, onAddHandler }) {
  const toast = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [entity, setEntity] = useState({
    userId,
    code: 'TEAMCODE',
    name: '',
    statusId: null,
    remarks: ''
  });

  function acceptClose() {
    setEntity({
      userId,
      code: 'TEAMCODE',
      name: '',
      statusId: null,
      remarks: ''
    });
    setSelectedStatus(null);
    setVisible(false);
  }

  function handleClose() {
    confirmDialog({
      header: 'هل تريد الغاء عملية اضافة مجموعة عمل ؟',
      accept: acceptClose,
      acceptLabel: 'تأكيد',
      acceptClassName: 'modals__btn',
      reject: confirmDialog().hide(),
      rejectLabel: 'إلغاء',
      rejectClassName: 'p-button-outlined',
      closeOnEscape: false,
      draggable: false,
      resizable: false,
      tagKey: 1
    });
  }

  function handleInputChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  function handleStatusChange(e) {
    setSelectedStatus(e.value);
    setEntity({ ...entity, statusId: e.value });
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog tagKey={1} />
      <Dialog
        header='اضافة جموعة عمل جديدة'
        visible={visible}
        onHide={handleClose}
        className='modals'
        draggable={false}
        resizable={false}
        closeOnEscape={false}
      >
        <div className='flex flex-column gap-5'>
          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اضف اسم المجموعة</label>
              <div className='flex gap-1'>
                <InputText name='name' value={entity.name} onChange={handleInputChange} className='w-full' />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>اختر حالة المجموعة</label>
              <div className='flex'>
                <Dropdown
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={statuses}
                  optionValue='id'
                  optionLabel='name'
                  display='chip'
                  className='w-full'
                />
              </div>
            </div>
          </div>

          <div className='w-full flex gap-5'>
            <div className='w-full flex flex-column gap-2'>
              <label className='modals__label'>ملاحظات</label>
              <InputTextarea name='remarks' value={entity.remarks} className='w-full' onChange={handleInputChange} />
            </div>
          </div>

          <div className='flex justify-content-end'>
            <Button label='إلغاء' onClick={handleClose} size='small' outlined />
            <button className='modals__btn' onClick={() => onAddHandler(entity)}>
              اضف إلى محموعات العمل
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default AddWorkingGroups;
