import React from 'react';
import { connect } from 'react-redux';

import Sidebar from '../../../components/Sidebar/Sidebar';
import Tabs from '../../../components/Tabs/Tabs';
import ListEntityTable from '../../../components/Tables/ListEntityTable';

function CustomerGeoLocation() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title__custom'>
          <i className='main__title__custom--icon'>
            <img src='../images/productbox.svg' alt='' />
          </i>
          <span>العملاء</span>
        </span>

        <div className='mt-5 pr-4 pl-4'>
          <Tabs
            headers={['قائمة مواقع العملاء']}
            contents={[
              <ListEntityTable
                columns={[
                  { header: 'العميل', field: 'customer.fullName', isChip: false },
                  { header: 'المواقع', field: 'location.name', isChip: false },
                  { header: 'علامة مميزة', field: 'landmark', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true }
                ]}
                urlName='CustomersAddress'
                headerTitle='مواقع العملاء'
                entityName='موقع'
                entityNameDefined='الموقع'
                addBtnText='إضافة موقع جديد'
                isCustomerGeo={true}
              />
            ]}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerGeoLocation);
