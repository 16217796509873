import React from 'react';
import { connect } from 'react-redux';

import Sidebar from '../../../components/Sidebar/Sidebar';
import Tabs from '../../../components/Tabs/Tabs';
import ProductsScreenTable from '../../../components/Tables/ProductsScreenTable';

function ProductsScreen() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title__custom'>
          <i className='main__title__custom--icon'>
            <img src='../images/productbox.svg' alt='' />
          </i>
          <span>المنتجات</span>
        </span>

        <div className='mt-5 pr-4 pl-4'>
          <Tabs
            headers={['قائمة المنتجات']}
            contents={[
              <ProductsScreenTable
                columns={[
                  // { header: 'كود  المنتج', field: 'code', isChip: false },
                  // { header: 'كود SKU', field: 'skuCode', isChip: false },
                  // { header: 'اسم المنتج', field: 'name', isChip: false },
                  // { header: 'فئة المنتج', field: 'productCategory.productFamily.name', isChip: false },
                  { header: 'الكمية المتاحة', field: 'skuCode', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'تاريخ الاضافة', field: 'creationDateTime', isChip: false }
                ]}
                urlName='Product/all'
                headerTitle='المنتجات'
                entityNameDefined='تفاصيل المنتج'
                addBtnText='إضافة منتج جديد'
                path='/products/addProduct'
                type={'device'}
              />
            ]}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsScreen);
