import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Link, useLocation } from 'react-router-dom';

import { axiosInstance } from '../../global';
import '../../style/Tables.scss';
import AddPaymentTerm from '../Modals/AddPaymentTerm';
import EditPaymentTerm from '../Modals/EditPaymentTerm';

function PaymentTermsTable({
  token,
  columns,
  urlName,
  headerTitle,
  addBtnText,
  entityName,
  entityNameDefined,
  routePrefix,
  addWithLink
}) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [newlist, setNewlist] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [filterdlist, setFilterdlist] = useState([]);
  const [selectedForEdit, setSelectedForEdit] = useState({});
  const [addSectionModalVisible, setAddSectionModalVisible] = useState(false);
  const [editSectionModalVisible, setEditSectionModalVisible] = useState(false);

  function handleSearchNameChange(e) {
    const inputString = e.target.value;
    setSearchTerm(inputString);
  }

  const filterData = () => {
    setFilterdlist(newlist);
    return filterdlist.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  function fetchItems() {
    axiosInstance
      .get(`/${urlName}/all`)
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/Status/all`)
      .then((response) => {
        const tempStatus = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setStatuses(tempStatus);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    if (!editSectionModalVisible) {
      setSelectedForEdit({});
    }
  }, [editSectionModalVisible]);

  // useEffect(() => {
  //   setNewlist(myList);
  // }, [myList]);

  // useEffect(() => {
  //   setNewlist(filterData());
  //   if (searchTerm === '') {
  //     setNewlist(myList);
  //   }
  // }, [searchTerm]);

  function showAddModal() {
    setAddSectionModalVisible(true);
  }

  function showEditModal(entity) {
    setSelectedForEdit(entity);
    setEditSectionModalVisible(true);
  }

  function addHandler(entity) {
    axiosInstance
      .post(`/${urlName}`, entity)
      .then((response) => {
        fetchItems();
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: `تم إضافة ${entityName} بنجاح` });
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: `فشلت عملية إضافة ${entityName}` });
      });
  }

  function editHandler(entity) {
    axiosInstance
      .put(`/${urlName}`, entity)
      .then((response) => {
        fetchItems();
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: `تم تعديل ${entityName} بنجاح` });
        setSelectedForEdit({});
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: `فشلت عملية تعديل ${entityName}` });
      });
  }

  function deleteHandler(entity) {
    axiosInstance
      .delete(`/${urlName}/${entity.id}`)
      .then((response) => {
        fetchItems();
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: `تم حذف ${entityName} بنجاح` });
        setSelectedForEdit({});
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: `فشلت عملية حذف ${entityName}` });
      });
  }

  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>{headerTitle}</span>
        <button className='tbl__addbtn' onClick={showAddModal}>
          <img src='/images/plus.svg' alt={addBtnText} />
          {addBtnText}
        </button>
        <AddPaymentTerm
          entityName='الى شروط الدفع'
          title='اضف شروط دفع جديدة'
          visible={addSectionModalVisible}
          setVisible={setAddSectionModalVisible}
          onAddHandler={addHandler}
          statuses={statuses}
          onClosePrompt='هل تريد الغاء عملية اضافة شروط دفع جديدة ؟'
        />
        <EditPaymentTerm
          visible={editSectionModalVisible}
          setVisible={setEditSectionModalVisible}
          entityName='شرط الدفع'
          title='تعديل شروط دفع'
          statuses={statuses}
          onClosePrompt='هل تريد الغاء عملية تعديل شرط الدفع ؟'
          onUpdateHandler={editHandler}
          onDeleteHandler={deleteHandler}
          initialData={selectedForEdit}
        />
        <Toast ref={toast} />
        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={searchTerm}
              className='header__tools__input'
              placeholder='ابحث بالاسم'
              onChange={handleSearchNameChange}
              type='search'
            />
          </span>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={items}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
          emptyMessage={
            <div className='w-full flex justify-content-center'>
              <span className='text-lg font-bold'>لم تقم بإضافة اي شروط دفع حتى الآن ،قم بالإضافة الآن</span>
            </div>
          }
        >
          <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
          <Column header='كود شرط الدفع' field='code' />
          <Column header='الاسم' field='name' />
          <Column header='الايام' field='days' />
          <Column
            header='قيمة الخصم'
            body={(rowData) => (
              <span>{rowData.discountAmount ? `${rowData.discountAmount} ج.م` : `%${rowData.discountPercentage}`}</span>
            )}
          />
          <Column header='الحالة' body={(rowData) => <Chip label={rowData.status.name} />} />
          <Column
            header='خيارات'
            body={(rowData) => (
              <Button
                label={`تعديل ${entityNameDefined}`}
                icon='pi pi-exclamation-circle'
                severity='secondary'
                text
                onClick={() => showEditModal(rowData)}
              />
            )}
          />
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTermsTable);
