import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Chips } from 'primereact/chips';

import CustomDropdown from '../Dropdown/CustomDropDown';

interface FormFieldProps {
  name: string;
  label: string;
  control: Control<any>;
  errors: FieldErrors;
  type: 'text' | 'textarea' | 'dropdown' | 'calendar' | 'multi' | 'decimal' | string;
  options?: any[];
  optionValue?: string;
  optionLabel?: string;
  placeholder?: string;
  disabled?: boolean;
  rows?: number;
  required?: boolean;
}

const CustomFormField: React.FC<FormFieldProps> = ({
  name,
  label,
  control,
  errors,
  type,
  options = [],
  optionValue = 'id',
  optionLabel = 'name',
  placeholder = '',
  disabled = false,
  rows = 3,
  required
}) => {
  const errorMessage = errors[name]?.message as string;

  return (
    <div className='w-full flex flex-column gap-2'>
      <label style={{ fontSize: '1em', fontWeight: '700', color: '#313131', lineHeight: 'normal' }}>
        {label} {required && <span className='text-red-500'>*</span>}
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          switch (type) {
            case 'text':
              return (
                <>
                  <InputText {...field} className='w-full' disabled={disabled} />
                  {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                </>
              );
            case 'textarea':
              return (
                <>
                  <InputTextarea {...field} className='w-full' rows={rows} disabled={disabled} />
                  {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                </>
              );
            case 'dropdown':
              return (
                <>
                  <CustomDropdown
                    {...field}
                    options={options}
                    optionValue={optionValue}
                    optionLabel={optionLabel}
                    placeholder={placeholder}
                    className='w-full'
                    disabled={disabled}
                    onChange={(e) => field.onChange(e.value)}
                  />
                  {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                </>
              );
            case 'calendar':
              return (
                <>
                  <Calendar {...field} dateFormat='mm/dd/yy' showIcon className='w-full' disabled={disabled} />
                  {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                </>
              );
            case 'multi':
              return (
                <>
                  <Chips
                    {...field}
                    value={Array.isArray(field.value) ? field.value : []}
                    addOnBlur={true}
                    className='w-full'
                    disabled={disabled}
                  />
                  {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                </>
              );
            case 'decimal':
              return (
                <>
                  <InputText
                    {...field}
                    keyfilter='num'
                    className='w-full'
                    disabled={disabled}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value as any) && value.match(/^\d*\.?\d*$/)) {
                        field.onChange(value);
                      }
                    }}
                  />
                  {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                </>
              );
            default:
              return (
                <>
                  <InputText {...field} className='w-full' disabled={disabled} placeholder={placeholder} />
                  {errorMessage && <span className='text-red-500'>{errorMessage}</span>}
                </>
              );
          }
        }}
      />
    </div>
  );
};

export default CustomFormField;
