import {
  CHANGE_VISITCOST_STATE
} from './visitTypes';

const initialState = {
  visitCost :{
  code:'',
  date:'',
  cost: 0
  },
  loading: false,
  error: ''
};

const visitCostReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_VISITCOST_STATE:
      return {
        ...state,
        visitCost: action.payload,
        loading: false,
        error: ''
      };

    default:
      return state;
  }
};

export default visitCostReducer;
