import {
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  ADD_CUSTOMER_REQUEST,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAILURE,
  EDIT_CUSTOMER_REQUEST,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILURE,
  CHANGE_CUSTOMER_STATE,
  CLEAR_CUSTOMER_STATE
} from './customerTypes';

const initialState = {
  customer: {},
  loading: false,
  error: null
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_REQUEST:
    case ADD_CUSTOMER_REQUEST:
    case EDIT_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: action.payload
      };
    case ADD_CUSTOMER_SUCCESS:
    case EDIT_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: action.payload
      };
    case FETCH_CUSTOMER_FAILURE:
    case ADD_CUSTOMER_FAILURE:
    case EDIT_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CHANGE_CUSTOMER_STATE:
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload
        }
      };
    case CLEAR_CUSTOMER_STATE:
      return initialState;
    default:
      return state;
  }
};

export default customerReducer;
