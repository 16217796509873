import React, { useEffect, useState } from 'react';
import './EditWarehouseDetails.scss';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';

import { axiosInstance } from '../../../global';
import { fill_warehouse } from '../../../redux/warehouse/warehouseActions';

function EditWarehouse1({ token, warehouseBase, fill_warehouse, setCheck }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const [statuses, setStatuses] = useState(null);
  const [types, setTypes] = useState(null);
  const [connectionMethods, setConnectionMethods] = useState(null);
  const [warehouse, setWarehouse] = useState({});

  useEffect(() => {
    setWarehouse({
      ...warehouseBase,
      statusId: warehouseBase?.status?.id,
      typeId: warehouseBase?.type?.id,
      contactMethodId: warehouseBase?.contactMethod?.id
    });
    axiosInstance
      .get(`/Status/dropdown`)
      .then((response) => {
        const tempStatus = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setStatuses(tempStatus);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/WarehousesType/dropdown`)
      .then((response) => {
        const tempTypes = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setTypes(tempTypes);
      })
      .catch((error) => {});
    axiosInstance
      .get(`/ContactMethod/all`)
      .then((response) => {
        const tempConnectionMethods = response.data.data.map((status) => {
          return {
            id: status.id,
            name: status.name
          };
        });
        setConnectionMethods(tempConnectionMethods);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fill_warehouse(warehouse);
    if (Object.values(warehouse).filter((val, index) => val === '' || !val).length) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [warehouse]);

  function handleAddInputChange(e) {
    setWarehouse({ ...warehouse, [e.target.name]: e.target.value || e.value });
  }

  return (
    <div className='warehouse'>
      <form>
        <div className='warehouse__content'>
          <div className='warehouse__content__right'>
            <span className='Title'>بيانات المخزن</span>
            <div className='flex w-full flex-column gap-4'>
              <label className='addwarehouse__label'>
                كود المخزن<span style={Style}>*</span>
              </label>
              <InputText name='code' value={warehouse.code} disabled={true} className='surface-300 opacity-100' />
            </div>
            <div className='flex w-full flex-column gap-4'>
              <label className='addwarehouse__label'>
                اسم المخزن<span style={Style}>*</span>
              </label>
              <InputText name='name' value={warehouse.name} onChange={handleAddInputChange} />
            </div>
            <div className='flex w-full flex-column gap-4'>
              <label className='addwarehouse__label'>
                نوع المخزن<span style={Style}>*</span>
              </label>
              <Dropdown
                name='typeId'
                value={warehouse.typeId}
                onChange={handleAddInputChange}
                options={types}
                optionValue='id'
                optionLabel='name'
                placeholder='اختر نوع المخزن'
              />
            </div>
            <span className='Title'>حالة المخزن</span>
            <div className='flex w-full flex-column gap-4'>
              <label className='addwarehouse__label'>
                حالة المخزن<span style={Style}>*</span>
              </label>
              <Dropdown
                name='statusId'
                value={warehouse.statusId}
                onChange={handleAddInputChange}
                options={statuses}
                optionValue='id'
                optionLabel='name'
                placeholder='اختر حالة المخزن'
              />
            </div>
          </div>
          <div className='warehouse__content__left'>
            <span className='Title'>بيانات التواصل</span>
            <div className='flex w-full flex-column gap-4'>
              <label className='addwarehouse__label'>
                طريقة التواصل<span style={Style}>*</span>
              </label>
              <Dropdown
                name='contactMethodId'
                value={warehouse.contactMethodId}
                onChange={handleAddInputChange}
                options={connectionMethods}
                optionValue='id'
                optionLabel='name'
                placeholder='اختر طريقة التواصل'
              />
            </div>
            <div className='flex w-full flex-column gap-4'>
              <label className='addwarehouse__label'>
                تفاصيل طريقة التواصل<span style={Style}>*</span>
              </label>
              <InputText name='contactDetails' value={warehouse.contactDetails} onChange={handleAddInputChange} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_warehouse: (warehouse) => dispatch(fill_warehouse(warehouse))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditWarehouse1);
