import React, { useEffect, useRef, useState, lazy, Suspense } from 'react';
import { Steps } from 'primereact/steps';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import {
  addActivity,
  clearActivityState,
  editActivity,
  fetchActivity
} from '../../../../redux/activity/activityActions';
import {
  addVisit,
  addvisitInstallment,
  addvisitStages,
  addvisitFaults,
  addvisitServices,
  addvisitRequstedItems,
  fetchVisit,
  fetchvisitInstallment,
  fetchvisitStages,
  fetchvisitRequstedItems,
  fetchvisitFaults,
  fetchvisitServices,
  editVisit,
  editvisitInstallment,
  editvisitStages,
  editvisitFaults,
  editvisitServices,
  editvisitRequstedItems,
  clearVisitState,
  clearvisitInstallmentState,
  clearvisitFaultState,
  clearvisitStageState,
  clearvisitRequstedItemState,
  clearvisitServiceState
} from '../../../../redux/visit/visitActions';
import './AddEditVisit.scss';

const AddEditVisit1 = lazy(() => import('./AddEditVisit1'));
const AddEditVisit2 = lazy(() => import('./AddEditVisit2'));
const AddEditVisit3 = lazy(() => import('./AddEditVisit3'));
const AddEditVisit4 = lazy(() => import('./AddEditVisit4'));

const AddEditVisit = ({
  activeIndexnum,
  addActivity,
  addVisit,
  addvisitInstallment,
  addvisitStages,
  addvisitFaults,
  addvisitServices,
  addvisitRequstedItems,
  editActivity,
  editVisit,
  editvisitInstallment,
  editvisitStages,
  editvisitFaults,
  editvisitRequstedItems,
  editvisitServices,
  fetchActivity,
  fetchVisit,
  fetchvisitInstallment,
  fetchvisitStages,
  fetchvisitRequstedItems,
  fetchvisitFaults,
  fetchvisitServices,
  activity,
  clearActivityState,
  clearVisitState,
  clearvisitInstallmentState,
  clearvisitStageState,
  clearvisitFaultState,
  clearvisitRequstedItemState,
  clearvisitServiceState,
  visit,
  visitInstallments,
  visitFaults,
  visitRequstedItems,
  visitStages,
  visitServices,
  loadingActivity,
  loadingVisit,
  activityError,
  visitError
}) => {
  const toast = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOk, setIsFormOk] = useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [activityContinueParameter, setActivityContinueParameter] = useState(false);
  const [visitContinueParameter, setVisitContinueParameter] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const items = [
    { label: 'بيانات الاتصال الاساسية' },
    { label: 'اضافة طلب زيارة' },
    { label: 'تفاصيل الزيارة' },
    { label: 'مراجعة الطلب' }
  ];

  useEffect(() => {
    setActiveIndex(activeIndexnum ?? 0);
  }, [activeIndexnum]);

  useEffect(() => {
    if (params.type === 'Edit' && params.activityId) {
      setIsEditing(true);
      fetchActivity(params.activityId);
      setActiveIndex(0);
    } else {
      clearActivityState();
    }
    return () => {
      clearActivityState();
    };
  }, [params.activityId, params.type, fetchActivity, clearActivityState]);

  useEffect(() => {
    if (params.type === 'EditVisit' && params.visitId) {
      setIsEditing(true);
      fetchVisit(params.visitId);
      fetchvisitInstallment(params.visitId);
      fetchvisitStages(params.visitId);
      fetchvisitRequstedItems(params.visitId);
      fetchvisitFaults(params.visitId);
      fetchvisitServices(params.visitId);
      setActiveIndex(1);
    } else {
      clearVisitState();
      clearvisitInstallmentState();
      clearvisitStageState();
      clearvisitFaultState();
      clearvisitRequstedItemState();
      clearvisitServiceState();
    }
    return () => {
      clearVisitState();
      clearvisitInstallmentState();
      clearvisitStageState();
      clearvisitFaultState();
      clearvisitRequstedItemState();
      clearvisitServiceState();
    };
  }, [params.visitId, params.type, fetchVisit, clearVisitState]);

  useEffect(() => {
    if (submitDone) {
      if (!loadingActivity && activityError.length) {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية إضافة الاتصال' });
      } else if (!loadingActivity && !activityError.length && !activityContinueParameter) {
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة الاتصال بنجاح' });
        setTimeout(() => navigate('/Activites'), 1000);
      } else if (!loadingActivity && !activityError.length && activityContinueParameter) {
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة الاتصال بنجاح' });
      }
    }
  }, [loadingActivity, activityError]);

  useEffect(() => {
    if (submitDone) {
      if (!loadingVisit && visitError.length) {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية إضافة زيارة' });
      } else if (!loadingVisit && !visitError.length && !visitContinueParameter) {
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة زيارة بنجاح' });
        setTimeout(() => navigate('/Visits'), 1000);
      } else if (!loadingVisit && !visitError.length && visitContinueParameter) {
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة زيارة بنجاح' });
      }
    }
  }, [loadingVisit, visitError]);

  function handleOnClick() {
    setActiveIndex(activeIndex + 1);
    if (activeIndex >= 3) {
      setActiveIndex(0);
    }
  }
  function handleِAddActivityOnClick() {
    try {
      if (isEditing) {
        editActivity(activity);
      } else {
        addActivity(activity);
      }
      setSubmitDone(true);
    } catch (errorAddactivity) {
      console.log(errorAddactivity);
    }
  }
  function handleِAddActivityandStartVisitOnClick() {
    setActivityContinueParameter(true);
    handleِAddActivityOnClick();
    handleOnClick();
  }
  function handleِAddVisitOnClick() {
    try {
      if (isEditing) {
        editVisit(visit);
      } else {
        addVisit(visit);
      }
      setSubmitDone(true);
    } catch (errorAddVisit) {
      console.log(errorAddVisit);
    }
  }
  function handleِAddVisit() {
    try {
      setVisitContinueParameter(true);
      if (isEditing) {
        editVisit(visit);
      } else {
        addVisit(visit);
      }
      setSubmitDone(true);
      handleOnClick();
    } catch (errorAddVisit) {
      console.log(errorAddVisit);
    }
  }
  function handleِAddVisitDetails() {
    try {
      try {
        addvisitInstallment(visitInstallments);
      } catch (Installmenterror) {
        console.log(Installmenterror);
      }
      try {
        addvisitStages(visitStages);
      } catch (Stageserror) {
        console.log(Stageserror);
      }
      try {
        addvisitFaults(visitFaults);
      } catch (Faultserror) {
        console.log(Faultserror);
      }
      try {
        addvisitRequstedItems(visitRequstedItems);
      } catch (ReqItemserror) {
        console.log(ReqItemserror);
      }
      try {
        addvisitServices(visitServices);
      } catch (Serviceserror) {
        console.log(Serviceserror);
      }
      setSubmitDone(true);
      toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم إضافة زيارة بنجاح' });
    } catch (error) {
      console.log(error);
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية إضافة زيارة' });
    }
  }
  function handleِEditVisitDetails() {
    try {
      try {
        editVisit(visit);
      } catch (Visiterror) {
        console.log(Visiterror);
      }
      try {
        editvisitInstallment(visitInstallments);
      } catch (Installmenterror) {
        console.log(Installmenterror);
      }
      try {
        editvisitStages(visitStages);
      } catch (Stageserror) {
        console.log(Stageserror);
      }
      try {
        editvisitFaults(visitFaults);
      } catch (Faultserror) {
        console.log(Faultserror);
      }
      try {
        editvisitRequstedItems(visitRequstedItems);
      } catch (ReqItemserror) {
        console.log(ReqItemserror);
      }
      try {
        editvisitServices(visitServices);
      } catch (Serviceserror) {
        console.log(Serviceserror);
      }
      toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم تعديل الزيارة بنجاح' });
    } catch (error) {
      console.log(error);
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية تعديل زيارة' });
    }
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <div className='flex flex-column gap-5 add__visit__main'>
        <div className='details__header'>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='details__header1__right--text'>
            {isEditing ? 'تعديل اتصال / زيارة  ' : 'اضافة اتصال / زيارة جديدة '}
          </span>
        </div>
        <div className='flex steps card'>
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
        </div>
        <div>
          {loadingActivity ? (
            <ProgressSpinner style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
          ) : (
            <Suspense fallback={<ProgressSpinner />}>
              {activeIndex === 0 && <AddEditVisit1 setCheck={setIsFormOk} activity={activity} />}
              {activeIndex === 1 && <AddEditVisit2 setCheck={setIsFormOk} visit={visit} />}
              {activeIndex === 2 && <AddEditVisit3 setCheck={setIsFormOk} />}
              {activeIndex === 3 && <AddEditVisit4 setCheck={setIsFormOk} />}
            </Suspense>
          )}
          <div className='btn mb-5'>
            {activeIndex === 0 &&
              (isEditing ? (
                <Button className='btn__linkbtn' onClick={handleِAddActivityOnClick} disabled={!isFormOk}>
                  حفظ التعديلات و انهاء العملية
                </Button>
              ) : (
                <>
                  <Button className='btn__linkbtn' onClick={handleِAddActivityOnClick} disabled={!isFormOk}>
                    حفظ الاتصال و انهاء العملية
                  </Button>
                  <Button
                    className='btn__nextbtn'
                    onClick={handleِAddActivityandStartVisitOnClick}
                    disabled={!isFormOk}
                  >
                    حفظ و تحضير زيارة
                  </Button>
                </>
              ))}

            {activeIndex === 1 &&
              (isEditing ? (
                <>
                  <Button className='btn__linkbtn' onClick={handleِAddActivityOnClick} disabled={!isFormOk}>
                    حفظ التعديلات و انهاء العملية
                  </Button>
                  <Button className='btn__nextbtn' onClick={handleOnClick} disabled={!isFormOk}>
                    الخطوة التالية
                  </Button>
                </>
              ) : (
                <>
                  <Button className='btn__linkbtn' onClick={handleِAddVisitOnClick} disabled={!isFormOk}>
                    حفظ الزيارة و انهاء العملية
                  </Button>
                  <Button className='btn__nextbtn' onClick={handleِAddVisit} disabled={!isFormOk}>
                    الخطوة التالية
                  </Button>
                </>
              ))}

            {activeIndex === 2 && (
              <Button className='btn__nextbtn' onClick={handleOnClick}>
                الخطوة التالية
              </Button>
            )}

            {activeIndex === 3 &&
              (isEditing ? (
                <Button className='btn__nextbtn' onClick={handleِEditVisitDetails}>
                  حفظ التعديلات
                </Button>
              ) : (
                <Button className='btn__nextbtn' onClick={handleِAddVisitDetails}>
                  حفظ و انهاء الطلب
                </Button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  activity: state.activity.activity,
  visit: state.visit.visit,
  visitInstallments: state.visitInstallments.visitInstallments,
  visitStages: state.visitStages.visitStages,
  visitFaults: state.visitFaults.visitFaults,
  visitRequstedItems: state.visitRequstedItems.visitRequstedItems,
  visitServices: state.visitServices.visitServices,
  loadingActivity: state.activity.loading,
  loadingVisit: state.visit.loading,
  activityError: state.activity.error,
  visitError: state.visit.error
});

const mapDispatchToProps = (dispatch) => ({
  addActivity: (activity) => dispatch(addActivity(activity)),
  addVisit: (visit) => dispatch(addVisit(visit)),
  addvisitInstallment: (visitInstallments) => dispatch(addvisitInstallment(visitInstallments)),
  addvisitRequstedItems: (visitRequstedItems) => dispatch(addvisitRequstedItems(visitRequstedItems)),
  addvisitStages: (visitStages) => dispatch(addvisitStages(visitStages)),
  addvisitFaults: (visitFaults) => dispatch(addvisitFaults(visitFaults)),
  addvisitServices: (visitServices) => dispatch(addvisitServices(visitServices)),

  fetchActivity: (id) => dispatch(fetchActivity(id)),
  fetchVisit: (id) => dispatch(fetchVisit(id)),
  fetchvisitInstallment: (id) => dispatch(fetchvisitInstallment(id)),
  fetchvisitStages: (id) => dispatch(fetchvisitStages(id)),
  fetchvisitRequstedItems: (id) => dispatch(fetchvisitRequstedItems(id)),
  fetchvisitFaults: (id) => dispatch(fetchvisitFaults(id)),
  fetchvisitServices: (id) => dispatch(fetchvisitServices(id)),

  editActivity: (activity) => dispatch(editActivity(activity)),
  editVisit: (visit) => dispatch(editVisit(visit)),
  editvisitInstallment: (visitInstallment) => dispatch(editvisitInstallment(visitInstallment)),
  editvisitStages: (visitStages) => dispatch(editvisitStages(visitStages)),
  editvisitRequstedItems: (VisitRequestedItems) => dispatch(editvisitRequstedItems(VisitRequestedItems)),
  editvisitFaults: (VisitFaults) => dispatch(editvisitFaults(VisitFaults)),
  editvisitServices: (visitServices) => dispatch(editvisitServices(visitServices)),

  clearActivityState: () => dispatch(clearActivityState()),
  clearVisitState: () => dispatch(clearVisitState()),
  clearvisitInstallmentState: () => dispatch(clearvisitInstallmentState()),
  clearvisitFaultState: () => dispatch(clearvisitFaultState()),
  clearvisitStageState: () => dispatch(clearvisitStageState()),
  clearvisitRequstedItemState: () => dispatch(clearvisitRequstedItemState()),
  clearvisitServiceState: () => dispatch(clearvisitServiceState())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditVisit);
