import React, { useEffect, useRef, useState } from 'react';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';

import Sidebar from '../../../components/Sidebar/Sidebar';
import { editCustomer, fetchCustomer } from '../../../redux/customer/customerActions';

import EditCustomers2 from './EditCustomers2';
import EditCustomers1 from './EditCustomers1';
import './EditCustomers.scss';

const EditCustomers = ({ activeIndexnum, editCustomer, fetchCustomer, customer, token, loading, error }) => {
  const params = useParams();
  const toast = useRef(null);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOk, setIsFormOk] = useState(false);
  const [editDone, setEditDone] = useState(false);

  useEffect(() => {
    fetchCustomer(params.id, token);

    if (activeIndexnum === '' || activeIndexnum === undefined) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndexnum);
    }
  }, []);

  useEffect(() => {
    if (editDone) {
      if (!loading && error.length) {
        toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية تعديل العميل' });
      } else {
        toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم تعديل العميل بنجاح' });
        setTimeout(() => navigate('/customers'), 1000);
      }
    }
  }, [loading, error]);

  const items = [{ label: 'بيانات العميل الاساسية' }, { label: 'تفاصيل العنوان و المنطقة' }];

  function handleOnClick() {
    setActiveIndex(activeIndex + 1);
    if (activeIndex >= 1) {
      setActiveIndex(0);
    }
  }

  function handleEditOnClick() {
    try {
      editCustomer(customer, token);
      setEditDone(true);
    } catch (errorEditCustomer) {
      console.log(error);
      console.log(errorEditCustomer);
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية تعديل العميل' });
    }
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <div className='flex flex-column add__visit__main'>
        <div className='details__header'>
          <Button
            className='font-bold text-black-alpha-90'
            icon='pi pi-arrow-right'
            rounded
            text
            onClick={() => navigate(-1)}
          />
          <span className='details__header1__right--text'>تعديل بيانات العميل</span>
        </div>
        <div className='flex steps card'>
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true} />
        </div>
        {loading ? (
          <ProgressSpinner />
        ) : (
          <div>
            <div>
              {activeIndex === 0 && <EditCustomers1 setCheck={setIsFormOk} customerBase={customer} />}
              {activeIndex === 1 && <EditCustomers2 setCheck={setIsFormOk} customerBase={customer} />}
              {/* {activeIndex === 1 && <AddVisit2 setCheck={setIsFormOk} nbOfStages={nbOfStages} />} */}
            </div>
            <div className='btn mb-5'>
              {activeIndex === 0 && (
                <Button className='btn__nextbtn' onClick={handleOnClick}>
                  الخطوة التالية
                </Button>
              )}
              {activeIndex === 1 && (
                <>
                  {/* <Button className='btn__linkbtn' onClick={handleOnClick}>
                  حفظ الموقع و اضافه موقع اخر
                </Button> */}
                  <Button className='btn__nextbtn' onClick={handleEditOnClick}>
                    حفظ و انهاء التعديل
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    customer: state.customer.customer,
    loading: state.customer.loading,
    error: state.customer.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomer: (id, token) => dispatch(fetchCustomer(id, token)),
    editCustomer: (customer, token) => dispatch(editCustomer(customer, token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomers);
