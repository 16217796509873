import React, { FunctionComponent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';

import styles from './EmptyState.module.scss';

export interface EmptyStateProps {
  title: string;
  description: string;
  buttonText: string;
  buttonLink?: string;
  onButtonClick?: () => void;
}

const EmptyState: FunctionComponent<EmptyStateProps> = ({ title, description, buttonText, buttonLink, onButtonClick }) => {
  const handleButtonClick = useCallback(() => {
    if (onButtonClick) {
      onButtonClick();
    }
  }, [onButtonClick]);

  return (
    <div className={styles.emptyStateContainer}>
      <img className={styles.icon} alt="" src="/images/empty.svg" />
      <b className={styles.title}>{title}</b>
      <div className={styles.description}>{description}</div>
      {buttonLink ? (
        <Link to={buttonLink} className={styles.buttonLink}>
          <Button
            label={buttonText}
            icon="pi pi-plus"
            className={styles.button}
          />
        </Link>
      ) : (
        <Button
          label={buttonText}
          icon="pi pi-plus"
          className={styles.button}
          onClick={handleButtonClick}
        />
      )}
    </div>
  );
};

export default EmptyState;
