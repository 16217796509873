import React, { useEffect, useRef, useState } from 'react';
import './AddCovenantDetails.scss';
import '../../../style/Tables.scss';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
// import AddTransactionProduct from '../../../components/Modals/AddTransactionProduct';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';

import { fill_covenant } from '../../../redux/warehouse/warehouseActions';
import { API_BASE_URL, axiosInstance } from '../../../global';

function AddCovenant1({ token, fill_covenant, setCheck }) {
  const Style = { color: 'red', padding: '0.7rem' };
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [searchTerms, setSearchTerms] = useState({ name: '', startDate: '', endDate: '' });
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  // const [addProductVisible, setAddProductVisible] = useState(false);
  const [covenant, setTransaction] = useState({
    items: [],
    currencyId: 1
  });

  useEffect(() => {
    fill_covenant(covenant);
    if (Object.values(covenant).filter((val, index) => val === '' || !val).length) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [covenant]);

  function handleAddProduct(newItem) {
    newItem.amount = +newItem.qty * +newItem.price;
    const _items = [...covenant.items, newItem];
    setTransaction({ ...covenant, items: _items });
  }

  const qtyEditor = (options) => {
    return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} />;
  };

  const onRowEditComplete = (e) => {
    let _items = [...covenant.items];
    let { newData, index } = e;
    newData.amount = +newData.qty * +newData.price;
    _items[index] = newData;
    setTransaction({ ...covenant, items: _items });
  };

  function handleRemoveRow(e, rowData) {
    let _items = [...covenant.items];
    _items.splice(_items.indexOf(rowData), 1);
    setTransaction({ ...covenant, items: _items });
  }

  function handleSearch(e) {}

  return (
    <div className='covenant'>
      <div className='flex justify-content-between'>
        <div className='flex flex-column gap-2'>
          <label htmlFor='username'>اسم المندوب</label>
          <Dropdown
            name='name'
            options={employees}
            value={selectedEmployee}
            optionLabel='name'
            optionValue='id'
            onChange={(e) => {
              setSelected(e.value);
              setSearchTerms({ ...searchTerms, name: e.value });
            }}
          />
        </div>
        <div className='flex flex-column gap-2'>
          <label htmlFor='username'>تاريخ من</label>
          <Calendar
            name='startDate'
            value={searchTerms.startDate}
            onChange={(e) => {
              setSelected(e.value);
              setSearchTerms({ ...searchTerms, startDate: e.value });
            }}
          />
        </div>
        <div className='flex flex-column gap-2'>
          <label htmlFor='username'>تاريخ الى</label>
          <Calendar
            name='endDate'
            value={searchTerms.endDate}
            onChange={(e) => {
              setSelected(e.value);
              setSearchTerms({ ...searchTerms, endDate: e.value });
            }}
          />
        </div>
        <button className='tbl__addbtn tbl__addbtn--cst4' onClick={handleSearch}>
          بحث
        </button>
      </div>
      <div className='flex flex-column'>
        <span className='Title'>المنتجات</span>
        <div className='tbl'>
          <div className='tbl__header'>
            <span className='header__title'>المنتجات</span>
            {/* <div className='tbl__btns__container mt-0'>
              <button className='tbl__addbtn tbl__addbtn--cst4' onClick={() => setAddProductVisible(true)}>
                <img src='/images/plus.svg' />
                إضافة منتج
              </button>
            </div> */}
            <Toast ref={toast} />
            {/* <AddTransactionProduct
              visible={addProductVisible}
              setVisible={setAddProductVisible}
              onAddHandler={handleAddProduct}
              warehouseId={last_covenant.warehouseId}
            /> */}
          </div>
          {loading ? (
            <div className='flex justify-content-center'>
              <ProgressSpinner />
            </div>
          ) : (
            <DataTable
              value={covenant.items}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
              dataKey='productId'
              className='tbl__table'
              editMode='row'
              onRowEditComplete={onRowEditComplete}
              emptyMessage={
                <div className='w-full flex justify-content-center'>
                  <span className='text-lg font-bold'>لم تضافة اي منتجات للموظف في هذه الفترة</span>
                </div>
              }
            >
              <Column header='اسم المنتج' field='name' />
              <Column header='الكمية المتاحة' field='availableQty' />
              <Column header='الكمية' field='qty' editor={(options) => qtyEditor(options)} />
              <Column header='سعر الوحدة' body={(rowData) => <span>{rowData.price}ج.م</span>} />
              <Column header='إجمالي' body={(rowData) => <span>{rowData.amount}ج.م</span>} />

              <Column header='خيارات' rowEditor headerStyle={{ width: '1rem' }} bodyStyle={{ textAlign: 'end' }} />
              <Column
                headerStyle={{ width: '1rem' }}
                body={(rowData) => (
                  <Button
                    icon='pi pi-trash'
                    severity='danger'
                    onClick={(e) => handleRemoveRow(e, rowData)}
                    text
                    rounded
                  />
                )}
              />
            </DataTable>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fill_covenant: (covenant) => dispatch(fill_covenant(covenant))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCovenant1);
