import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Link, useParams } from 'react-router-dom';
import { InputMask } from 'primereact/inputmask';

import { axiosInstance } from '../../global';

import '../../style/Tables.scss';

function ProductsPricesTable({ token, columns, urlName, headerTitle }) {
  const toast = useRef(null);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [searchTerm1, setSearchTerm1] = useState('');
  const [searchTerm2, setSearchTerm2] = useState('');
  const [myList, setMyList] = useState([]);
  const [newlist, setNewlist] = useState([]);
  const [filterdlist, setFilterdlist] = useState([]);

  const filterData = () => {
    setFilterdlist(newlist);
    return filterdlist.filter((item) => item.date > searchTerm1 && item.date < searchTerm2);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    setNewlist(myList);
  }, [myList]);

  useEffect(() => {
    setNewlist(filterData());
    if (searchTerm1 === '' || searchTerm2 === '') {
      setNewlist(myList);
    }
  }, [searchTerm1, searchTerm2]);

  function fetchItems() {
    axiosInstance
      .get(`/${urlName}/allByProduct/${params.id}`)
      .then((response) => {
        setMyList(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSearchTerm1Change(e) {
    const inputString = e.target.value;
    setSearchTerm1(inputString);
  }

  function handleSearchTerm2Change(e) {
    const inputString = e.target.value;
    setSearchTerm2(inputString);
  }
  return (
    <div className='tbl'>
      <div className='tbl__header'>
        <span className='header__title'>{headerTitle}</span>
        <Toast ref={toast} />
        <div className='header__tools'>
          <span className='p-input-icon-left'>
            <label>من تاريخ</label>
            <InputMask
              value={searchTerm1}
              className='header__tools__input'
              onChange={handleSearchTerm1Change}
              placeholder='mm/dd/yyyy'
              mask='99/99/9999'
              slotChar='mm/dd/yyyy'
            />
            <label>إلى تاريخ</label>
            <InputMask
              value={searchTerm2}
              className='header__tools__input'
              onChange={handleSearchTerm2Change}
              placeholder='mm/dd/yyyy'
              mask='99/99/9999'
              slotChar='mm/dd/yyyy'
            />
          </span>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-content-center'>
          <ProgressSpinner />
        </div>
      ) : (
        <DataTable
          value={myList}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey='id'
          className='tbl__table'
        >
          <Column header='ID' body={(rowData) => <span>#{rowData.id}</span>} />
          {columns.map((column, index) => (
            <Column
              key={index}
              header={column.header}
              body={(rowData) =>
                column.isChip ? (
                  <Chip
                    label={
                      column.field.split('.').length > 1
                        ? rowData[column.field.split('.')[0]][column.field.split('.')[1]]
                        : rowData[column.field.split('.')[0]]
                    }
                  />
                ) : (
                  <span>
                    {column.field.split('.').length > 1
                      ? rowData[column.field.split('.')[0]][column.field.split('.')[1]]
                      : rowData[column.field.split('.')[0]]}
                  </span>
                )
              }
            />
          ))}
        </DataTable>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPricesTable);
