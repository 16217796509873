import React, { useEffect, useRef, useState } from 'react';
import { Steps } from 'primereact/steps';

import Sidebar from '../../../components/Sidebar/Sidebar';

import './AddSupplier.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '../../../global';

import AddSupplier1 from './AddSupplier1';

const AddSupplier = ({ token }) => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [supplier, setSupplier] = useState({
    name: '',
    countryId: '',
    phone: '',
    email: '',
    website: '',
    paymentTermId: '',
    deliveryTermId: '',
    contactPerson: '',
    contactPersonMobile: '',
    address: '',
    remarks: '',
    statusId: 1
  });
  const [isFormOk, setIsFormOk] = useState(false);

  function handleِAddOnClick() {
    try {
      axiosInstance
        .post('/Supplier', supplier)
        .then((res) => {
          toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم اضافة مورد بنجاح' });
          setTimeout(() => navigate('/warehouses/suppliers/'), 1500);
        })
        .catch((error) => {
          toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية اضافة مورد' });
        });
    } catch (errorAddSupplier) {
      console.log(error);
      console.log(errorAddSupplier);
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية اضافة مورد' });
    }
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <div className='flex flex-column gap-5 add__supplier__main'>
        <div className='flex steps card'></div>
        <div>
          <div>
            <AddSupplier1 setCheck={setIsFormOk} supplier={supplier} setSupplier={setSupplier} />
          </div>
          <div className='btn mb-5'>
            <Button className='btn__nextbtn' onClick={handleِAddOnClick} disabled={!isFormOk}>
              تأكيد اضافة المورد
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSupplier);
