import React, { useEffect, useRef, useState } from 'react';
import { DataTableStateEvent } from 'primereact/datatable';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import PageHeader from '../Header/PageHeader';
import { axiosInstance } from '../../../global';
import { useApiState } from '../../../hooks/useApiState';
import GenericDataTable from '../GenericDataTable/GenericDataTable';
import styles from './GenericPage.module.scss';
import { TableHeader } from './TableHeader';
import { PageAnalyticsHeader } from './PageAnalyticsHeader';

interface ColumnProps {
  field: string;
  header: string;
  isBoolean?: boolean;
  isChip?: boolean;
  isRate?: boolean;
  isKnob?: boolean;
}

interface TabProps {
  rowOnClick: (rowData: any) => void;
  emptyStateButtonClick?: (() => void) | undefined;
  label: string;
  tableHeader: string;
  entityName: string;
  dataKey: string;
  columns: ColumnProps[];
  apiUrl: string;
  emptyStateUrl: string;
  edit: boolean;
  isHidden?: boolean;
  extraParams?: Record<string, string>;
}

export interface AnalyticsCardHeaderProps {
  title: string;
  num: number | string;
  imageUrl: string;
  percent?: string;
  chartImage?: string;
  color: string;
  txtColor?: string;
}

interface GenericDataTableProps<T> {
  tabs: TabProps[];
  headerTitle: string;
  headerIcon: string;
  analyticsCards?: AnalyticsCardHeaderProps[];
  cardsNum?:string;
  headerIconOnClick?: () => void;
  headerButtons: {
    label: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }[];
}

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const GenericPage = <T,>({
  tabs,
  headerTitle,
  analyticsCards,
  cardsNum,
  headerIconOnClick,
  headerIcon,
  headerButtons
}: GenericDataTableProps<T>) => {
  const toast = useRef<Toast>(null);
  const [selectedTab, setSelectedTab] = useState<TabProps>(tabs[0]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>(undefined);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const fetchData = async (params = { page, size: pageSize, search: debouncedSearchTerm, sortField, sortOrder }) => {
    const sortParam = sortField && sortOrder ? `${sortField},${sortOrder}` : undefined;

    const response = await axiosInstance.get(selectedTab.apiUrl, {
      params: { ...params, ...selectedTab.extraParams, sort: sortParam }
    });
    return response.data;
  };

  const {
    lazyApiCall: fetchTabData,
    state: { isLoading, error, data }
  } = useApiState({
    isLazy: true,
    apiCall: fetchData
  });

  useEffect(() => {
    fetchTabData();
  }, [selectedTab, page, pageSize, sortOrder, sortField, debouncedSearchTerm]);

  useEffect(() => {
    if (toast.current && error) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch data', life: 3000 });
    }
  }, [error]);

  const handleTabClick = (tab: TabProps) => {
    setSelectedTab(tab);
    setPage(0);
    setPageSize(5);
    setSearchTerm('');
    setSortField(undefined);
  };

  const onPageChange = (e: DataTableStateEvent) => {
    if (e.page !== undefined) {
      setPage(e.page);
    }
    if (e.rows !== undefined) {
      setPageSize(e.rows);
    }
  };

  const onSort = (e: DataTableStateEvent) => {
    setSortField(e.sortField);
    const order = e.sortOrder === 1 ? 'asc' : 'desc';
    setSortOrder(order);

    setPage(0);
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
    setPage(0);
  };

  return (
    <div className={styles.genericPage}>
      <Toast ref={toast} />
      <PageHeader iconOnClick={headerIconOnClick} icon={headerIcon} buttons={headerButtons} headerTitle={headerTitle} />
      <PageAnalyticsHeader analyticsCards={analyticsCards} cardsNum={cardsNum}/>
      <div className={styles.container}>
        {tabs.some((tab) => !tab.isHidden) && (
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: 25 }}>
            {tabs.map((tab) => (
              <button
                key={tab.dataKey}
                className={selectedTab.dataKey === tab.dataKey ? 'tabs--selected' : 'tabs--unselected'}
                style={{
                  marginLeft: '0.25rem',
                  marginBottom: '0.5rem',
                  display: tab.isHidden ? 'none' : 'inline-block'
                }}
                onClick={() => handleTabClick(tab)}
              >
                {tab.label}
              </button>
            ))}
          </div>
        )}

        <Card style={{ width: 'auto', margin: '1.5rem', borderRadius: '1.5rem' }} className={'tableCard'}>
          <TableHeader title={selectedTab.tableHeader} onSearch={handleSearch} />
          <GenericDataTable
            data={data?.data || []}
            columns={selectedTab.columns}
            page={page}
            pageSize={pageSize}
            totalRecords={data?.totalCount || 0}
            sortField={sortField}
            sortOrder={sortOrder}
            isLoading={isLoading}
            onPageChange={onPageChange}
            onSort={onSort}
            onRowClick={(rowData) => selectedTab.rowOnClick(rowData)}
            edit={selectedTab.edit}
            entityName={selectedTab.entityName}
            emptyStateButtonClick={selectedTab.emptyStateButtonClick}
            emptyStateUrl={selectedTab.emptyStateUrl}
          />
        </Card>
      </div>
    </div>
  );
};

export default GenericPage;
