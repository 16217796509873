import React, { useEffect, useRef, useState } from 'react';
import { Steps } from 'primereact/steps';

import Sidebar from '../../../components/Sidebar/Sidebar';

import './AddWarehouse.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { addWarehouse } from '../../../redux/warehouse/warehouseActions';

import AddWarehouse2 from './AddWarehouse2';
import AddWarehouse1 from './AddWarehouse1';

const AddWarehouse = ({ activeIndexnum, addWarehouse, warehouse, token, loading, error }) => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOk, setIsFormOk] = useState(false);

  useEffect(() => {
    if (activeIndexnum === '' || activeIndexnum === undefined) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndexnum);
    }
  }, []);

  const items = [{ label: 'بيانات المخزن  الاساسية' }, { label: 'تفاصيل العنوان و المنطقة' }];

  function handleOnClick() {
    setActiveIndex(activeIndex + 1);
    if (activeIndex >= 1) {
      setActiveIndex(0);
    }
  }

  function handleِAddOnClick() {
    try {
      addWarehouse(warehouse, token);
      toast.current.show({ severity: 'success', summary: 'عملية ناجحة', detail: 'تم اضافة المخزن بنجاح' });
      setTimeout(() => navigate('/warehouses'), 1500);
    } catch (errorAddWarehouse) {
      console.log(error);
      console.log(errorAddWarehouse);
      toast.current.show({ severity: 'error', summary: 'عملية خاطئة', detail: 'فشلت عملية اضافة المخزن' });
    }
  }

  return (
    <div className='flex w-full'>
      <Sidebar />
      <Toast ref={toast} />
      <div className='flex flex-column gap-5 add__warehouse__main'>
        <div className='flex steps card'>
          <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} />
        </div>
        <div>
          <div>
            {activeIndex === 0 && <AddWarehouse1 setCheck={setIsFormOk} />}
            {activeIndex === 1 && <AddWarehouse2 setCheck={setIsFormOk} />}
          </div>
          <div className='btn mb-5'>
            {activeIndex != 1 && (
              <Button className='btn__nextbtn' onClick={handleOnClick} disabled={!isFormOk}>
                الخطوة التالية
              </Button>
            )}
            {activeIndex === 1 &&
              (loading ? (
                <ProgressSpinner />
              ) : (
                <Button className='btn__nextbtn' onClick={handleِAddOnClick} disabled={!isFormOk}>
                  اضافة المخزن
                </Button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    warehouse: state.warehouse.warehouse,
    loading: state.warehouse.loading,
    error: state.warehouse.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addWarehouse: (warehouse, token) => dispatch(addWarehouse(warehouse, token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWarehouse);
