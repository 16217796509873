import React from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Tabs from '../../../components/Tabs/Tabs';
import ListEntityTable from '../../../components/Tables/ListEntityTable';
import { connect } from 'react-redux';

function VisitsGeneralList() {
  return (
    <div className='flex w-full'>
      <Sidebar />
      <div className='w-full'>
        <span className='main__title__custom'>
          <i className='main__title__custom--icon'>
            <img src='../images/database.svg' alt='' />
          </i>
          <span>بيانات خدمة العملاء</span>
        </span>

        <div className='mt-5 pr-4 pl-4'>
          <Tabs
            headers={[
              'انواع الزيارات',
              'انواع مستوى الاهميه',
              'انواع الاجرائات المتخذة',
              'انواع اسباب الاتصال',
              'انواع الاتصال',
              'انواع التقييم',
              'انواع المراجعة'
            ]}
            contents={[
              <ListEntityTable
                columns={[
                  { header: 'انواع الزيارات', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='VisitsType' // تعديل
                headerTitle='الزيارات'
                entityName='زيارة'
                entityNameDefined='نوع الزيارات'
                addBtnText='إضافة نوع زيارة جديد'
                type='generalList'
              />,
              <ListEntityTable
                columns={[
                  { header: 'انواع مستوى الاهمية', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='Priority' // تعديل
                headerTitle='مستوى الاهمية'
                entityName='مستوى الاهمية'
                entityNameDefined='نوع مستوى الاهمية'
                addBtnText='إضافة نوع مستوى أهمية جديد'
                type='generalList'
              />,
              <ListEntityTable
                columns={[
                  { header: 'انواع الاجرائات المتخذة', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='Decision' // تعديل
                headerTitle='الاجرائات المتخذة'
                entityName='الاجراء المتخذ'
                entityNameDefined='نوع الاجراء المتخذ'
                addBtnText='إضافة نوع اجراء جديد'
                type='generalList'
              />,
              <ListEntityTable
                columns={[
                  { header: 'انواع اسباب الاتصال', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='Reason' // تعديل
                headerTitle='اسباب الاتصال'
                entityName='سبب الاتصال'
                entityNameDefined='نوع سبب الاتصال'
                addBtnText='إضافة نوع سبب اتصال'
                type='generalList'
              />,
              <ListEntityTable
                columns={[
                  { header: 'انواع الاتصال', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='ActivitiesType'
                headerTitle='الاتصال'
                entityName='اتصال'
                entityNameDefined='نوع الاتصال'
                addBtnText='إضافة نوع اتصال جديد'
                type='generalList'
              />,
              <ListEntityTable
                columns={[
                  { header: 'انواع التقييم', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='EvaluationsTypesList'
                headerTitle='التقييم'
                entityName='تقييم'
                entityNameDefined='نوع التقييم'
                addBtnText='إضافة نوع تقييم جديد'
                type='generalList'
              />,
              <ListEntityTable
                columns={[
                  { header: 'انواع المراجعة', field: 'name', isChip: false },
                  { header: 'الحالة', field: 'status.name', isChip: true },
                  { header: 'ملاحظات', field: 'remarks', isChip: false }
                ]}
                urlName='RevisionsType'
                headerTitle='المراجعة'
                entityName='مراجعة'
                entityNameDefined='نوع المراجعة'
                addBtnText='إضافة نوع مراجعة جديد'
                type='generalList'
              />
            ]}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitsGeneralList);
